export default {
    template: `
      <div class="sk-chip" :class="{'sk-chip--clearable': clearable}" @click.stop="onRemove()">
      <slot></slot>
      <i class="fas fa-times" v-if="clearable"></i>
      </div>
    `,
    data: function () {
        return {};
    },
    props: {
        clearable: {
            default: function () {
                return false;
            }
        },
    },
    computed: {},
    methods: {
        onRemove() {
            if (this.clearable) {
                this.$emit('on-remove');
            }
        }
    }
};
