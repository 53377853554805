export default {
    template: `
      <div class="hotel-info">
      <div class="hotel-info__name" style="visibility: hidden;">
        ?@ name @
      </div>
      <div class="hotel-info__category" style="visibility: hidden;">
            <span class="fa-sun-wrap" v-for="n in whole">
                <i class="fas fa-sun yellow"></i>
            </span>
        <span class="fa-sun-wrap half" v-if="hasFraction">
                <i class="fas fa-sun yellow"></i>
            </span>
      </div>
      </div>
    `,
    props: {
        name: {
            default: ''
        },
        category: {
            default: 0
        }
    },
    computed: {
        whole() {
            return Math.floor(this.category);
        },
        hasFraction() {
            return this.category % 1 !== 0;
        },

        isDescriptionOrOffer(){
            return document.querySelector('#pType') !== null;
        }
    }
};
