import Sorting from './base';

export default class RegionSorting extends Sorting {
    constructor() {
        super();

        const $regions = $('.ibe-list-item.destination:not(.ibe-list-item-special)');
        const $topRegion = $('.ibe-list-item-special.destination');
        const $regionsWrap = $regions.parent();

        this.data = {
            active: 'recommendation',
            categories: {
                temperature: {
                    label: 'Lufttemperatur',
                    direction: 'desc',
                    sorting: 'dual',
                    type: 'sub'
                },
                duration: {
                    label: 'Flugdauer',
                    direction: 'asc',
                    sorting: 'dual',
                    type: 'sub'
                },
                price: {
                    label: 'Preis',
                    direction: 'asc',
                    sorting: 'single',
                    type: 'top'
                },
                destination: {
                    label: 'Reiseziel',
                    direction: 'asc',
                    sorting: 'dual',
                    type: 'top'
                },
                recommendation: {
                    label: 'Empfehlungen',
                    direction: 'asc',
                    sorting: 'single',
                    type: 'top'
                }
            }
        };

        this.methods = Object.assign(this.methods, {
            _getSortValue(el, field, direction, type) {
                const $el = $(el);
                const $region = this._getRegion($el);
                const $subRegions = this._getSubRegions($el);

                let value = this._getValue($region, field);

                if (el._sorted !== field + direction) {
                    this._sortSubItems($subRegions, field, direction);
                    el._sorted = field + direction;
                }

                if (type === 'sub' || !value) {
                    value = this._getValue($subRegions.first(), field) || value;
                }

                return value;
            },

            _getRegion($el) {
                return $el.children('.searchable');
            },

            _getSubRegions($el) {
                return $('.ibe-list-infobox.destination', $el).children('.searchable');
            },

            _getValue($el, field) {
                const value = $el.data('sort-' + field);
                return field === 'destination' ? value : parseFloat(value);
            },

            _sortSubItems($subRegion, field, direction) {
                const $wrap = $subRegion.parent();

                $subRegion.detach();

                $subRegion.sort((a, b) => {
                    return this._sort(this._getValue($(a), field), this._getValue($(b), field), direction);
                });

                $wrap.append($subRegion);
            },

            sort() {
                this.$nextTick(() => {
                    const active = this.active;
                    const field = active === 'recommendation' ? 'price' : active;
                    const direction = this.categories[active].direction;
                    const type = this.categories[active].type;

                    $topRegion[active === 'recommendation' ? 'show' : 'hide']();

                    $regions.detach();

                    $regions.sort((a, b) => {
                        return this._sort(this._getSortValue(a, field, direction, type), this._getSortValue(b, field, direction, type), direction);
                    });

                    $regionsWrap.append($regions);

                    const $toggleButton = $topRegion.find('.mainregion');
                    if (!$toggleButton.is('.open')) {
                        $toggleButton.click();
                    }
                });
            },
        });
    }
}