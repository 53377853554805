/**
 * iAdvize Main Tag for sonnenklar.tv
 */
(function () {
    try {
        if (window.self === window.top) {
            var idz = document.createElement('script');
            idz.type = 'text/plain';
            idz.async = true;
            idz.dataset.usercentrics = 'iAdvize';
            idz.src = document.location.protocol + '//halc.iadvize.com/iadvize.js?sid=6450&lang=de';

            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(idz, s);
        }
    }
    catch (ex) {}
})();

/**
 * iAdvize Custom Tags for sonnenklar.tv
 */
try {
    if (/^\/angebot/i.test(window.location.pathname)) { // Statische Hotelseite
        window.idzCustomData = {
            'orderId': hotelData.orderId
        }
    } else if (/^\/buchen/i.test(window.location.pathname)) { // Buchungsseite
        var priceUnconfirmed = bookingData.frontendInfo.originalTravellerList.children === 0
            ? bookingData.frontendInfo.originalTravellerList.adults * bookingData.listPrice
            : 'N/A';
        var travellerNumber = bookingData.frontendInfo.originalTravellerList.adults;

        for (var travalerId in bookingData.frontendInfo.originalTravellerList.list) {
            if (bookingData.frontendInfo.originalTravellerList.list[travalerId].type === 'child') {
                travellerNumber += ',' + bookingData.frontendInfo.originalTravellerList.list[travalerId].age;
            }
        }

        window.idzCustomData = {
            'destination': bookingData.region,
            'departureDate': bookingData.fromDate,
            'departureAirport': bookingData.data.hasFlight && bookingData.frontendInfo.flights !== undefined
                ? bookingData.frontendInfo.flights.outbound.departureAirportName
                : '',
            'returnDate': bookingData.toDate,
            'duration': bookingData.frontendInfo.tour.duration,
            'travelType': (bookingData.data.hasFlight) ? 'Hotel_Flug' : 'Hotel',
            'operator': bookingData.operator,
            'priceConfirmed': bookingData.frontendInfo.prices.finalPrice,
            'priceUnconfirmed': priceUnconfirmed,
            'hotelName': bookingData.frontendInfo.hotel.name,
            'hotelStars': bookingData.frontendInfo.hotel.stars,
            'hotelCity': bookingData.frontendInfo.hotel.city,
            'hotelCountry': bookingData.frontendInfo.hotel.location,
            'orderId': bookingData.data.hotelData.orderId,
            'travellerNumber': travellerNumber
        }
    } else if (/^\/suchen\/.*\/(id|code|bestellnummer)\(.*\)\//i.test(window.location.pathname)) { // Terminliste
        window.idzCustomData = {
            'destination': SKTV.searchObj.destination,
            'departureDate': SKTV.searchObj.fromDate.date.match(/.+?(?= )/)[0],
            'departureAirport': SKTV.searchObj.departureAirport,
            'returnDate': SKTV.searchObj.toDate.date.match(/.+?(?= )/)[0],
            'duration': SKTV.searchObj.minDuration,
            'travelType': SKTV.searchObj.travelType,
            'operator': SKTV.searchObj.tourOperator,
            'hotelName': hotelData.name,
            'hotelStars': hotelData.stars,
            'hotelCity': hotelData.city,
            'hotelCountry': hotelData.location,
            'orderId': hotelData.orderId,
            'travellerNumber': (SKTV.searchObj.adults + ',' +
                SKTV.searchObj.children.join(',')).replace(/,$/, ''),
            'firstOfferTotalPrice': hotelData.firstOfferTotalPrice,
        }
    } else if (/^\/suchen/i.test(window.location.pathname)) { // Hotelliste
        window.idzCustomData = {
            'destination': SKTV.searchObj.destination,
            'departureDate': SKTV.searchObj.fromDate.date.match(/.+?(?= )/)[0],
            'departureAirport': SKTV.searchObj.departureAirport,
            'returnDate': SKTV.searchObj.toDate.date.match(/.+?(?= )/)[0],
            'duration': SKTV.searchObj.minDuration,
            'travelType': SKTV.searchObj.travelType,
            'operator': SKTV.searchObj.tourOperator,
            'travellerNumber': (SKTV.searchObj.adults + ',' +
                SKTV.searchObj.children.join(',')).replace(/,$/, '')
        }
    }
}
catch (ex) {}
