import BaseModel from './base';
import CruiseAreaModel from "./cruiseareas";
import CruiseLineModel from "./cruiselines";
import CruiseShipModel from "./cruiseships";

export default class CruisesModel extends BaseModel {
    constructor() {
        super();
        this.cruiseArea = new CruiseAreaModel();
        this.cruiseLine = new CruiseLineModel();
        this.cruiseShip = new CruiseShipModel();
        this.update = 0;
        this.autocomplete = [];
        this.cruiseAutoComplete = [];
        this.cruisesRadioButtons = [];
        this.cruisesRadioButtons = [];
        this.packageCheckbox = null;
        this.cruisesCount = 0;
        this.cruiseFromHasInput = false;
        this.cruiseToHasInput = false;
        this.cruiseDurationHasInput = false;
        this.cruiseHasChanges = false;

        this.requestData = {'sea': 1};
    }

    initRadioButtons(data){
        let self = this;
        data.reverse().forEach(function(object){
            self.cruisesRadioButtons.push(self.buildRadioButtonsObject(object.id, object.text, object.selected));
        });
    }

    initPackageCheckbox(withPackage){
        this.packageCheckbox = {label: 'Nur Pakete', code: 'package', checked: withPackage };
    }
    selectPackageCheckbox(){
        this.packageCheckbox.checked = !this.packageCheckbox.checked
    }
    selectRadioButton(code){
        this.cruisesRadioButtons.forEach(function(button){
           if(button.code == code){
               button.checked = true;
           } else {
               button.checked = false;
           }
        });
    }

    buildRadioButtonsObject(id, text, selected){
        if(selected === undefined){
            selected = false;
        }
        return {
            code: id,
            label: text,
            checked:  selected
        };
    }

    initCanvas(data, type) {
        let list = [];
        let model = null;
        let subValues = null;
        let subValuesLabel = null;
        let groupLabel = null;
        let self = this;
        if(type === 'area'){
            model = this.cruiseArea;
            subValues = 'children';
            groupLabel = 'text';
            subValuesLabel = 'text';
        } else if (type === 'line'){
            model = this.cruiseLine;
            subValues = 'data';
            groupLabel = 'title';
            subValuesLabel = 'text';
        } else if (type === 'ship'){
            model = this.cruiseShip;
            subValues = 'data';
            groupLabel = 'text';
            subValuesLabel = 'name';
        }
        if(data !== undefined){
            Object.values(data).forEach(function(value){
                let entry = {
                    checked: value['selected'] !== undefined ? value['selected'] : false,
                    code: value.id,
                    label: value[groupLabel],
                    subValues: [],
                    visible: true
                };
                if(value[subValues] !== undefined){
                    value[subValues].forEach(function(subValuesValue){
                        let subEntry = {
                            checked: subValuesValue['selected'] !== undefined ? subValuesValue['selected'] : false,
                            code: subValuesValue.id,
                            label: subValuesValue[subValuesLabel],
                            visible: true,
                            subValues: []
                        };

                        if(subValuesValue[subValues] !== undefined && subValuesValue[subValues].length > 0){
                            subValuesValue[subValues].forEach(function(subSubEntryValue){
                                let subSubEntry = {
                                    checked: subSubEntryValue['selected'] !== undefined ? subSubEntryValue['selected'] : false,
                                    code: subSubEntryValue.id,
                                    label: subSubEntryValue[subValuesLabel],
                                    visible: true,
                                }
                                subEntry.subValues.push(subSubEntry);
                            });
                        }
                        entry.subValues.push(subEntry);
                    });
                }
                list.push(entry);
            });

        }

        model._options = list;

        list.forEach(function(group){
            if( group.checked ) {
                self.select(group.code, type, true);
            }
            group['subValues'].forEach(function(subValue){
                if( subValue.checked ) {
                    self.select(subValue.code, type, true);
                }
                if(subValue['subValues'] !== undefined && subValue['subValues'].length > 0){
                    subValue['subValues'].forEach(function(subSubValue){
                        if( subSubValue.checked ) {
                            self.select(subSubValue.code, type, true);
                        }
                    });
                }
            });
        });
        model.dataLoaded = true;
        this.initCruiseAutoSuggest(model, type);
    }

    initCruiseAutoSuggest(model, type) {
        let autocomplete = model.cruiseAutoComplete;
        autocomplete = [];
        let $input = $('input#cruise-' + type + '-filter');
        model._options.forEach(function(group) {

            if(group.visible){
                autocomplete.push(group.label);
            }
            group['subValues'].forEach(function(subValue){
                if(subValue.visible){
                    autocomplete.push(group.label + ' | ' + subValue.label);
                }
                if(subValue['subValues'].length > 0 && type !== 'line'){
                    subValue['subValues'].forEach(function(subSubValue){
                        if(subSubValue.visible){
                            autocomplete.push(subValue.label + ' | ' + subSubValue.label);
                        }
                    });
                }
            });
        });

        $input.autocompleteHL({
            source: autocomplete,
            minLength: 3,
            select: (event, ui) => {
                $(this).siblings('button.reset-autocomplete').addClass('visible');

                const value = ui.item.value.split(' | ');
                this.select(this.getCodeFromLabel(value[value.length - 1], type), type);
                $input.val('');
                return false;
            },
            open: function () {
                const $that = $(this);
                $that.autocompleteHL('instance').menu.element.css('width', $that.width() + 10);
            }
        });
    }
    getCodeFromLabel(label, type){
        let code;
        let model;

        if(type === 'area'){
            model = this.cruiseArea;
        } else if (type === 'line'){
            model = this.cruiseLine;
        } else {
            model = this.cruiseShip;
        }

        model._options.forEach(function(group){
            if(group.label === label){
                code = group.code;
            }else {
                group['subValues'].forEach(function(subValue){
                    if(subValue.label === label){
                        code = subValue.code;
                    }
                    if(subValue['subValues'].length > 0){
                        subValue['subValues'].forEach(function(subSubValue){
                            if(subSubValue.label === label){
                                code = subSubValue.code;
                            }
                        });
                    }
                });
            }
        });
       return code;
    }

    select(code, type, onlyTags) {
        let self = this;

        if(onlyTags === undefined){
            onlyTags = false;
        }
        let cruiseOptions;
        let model;
        let tags = [];
        let subValueTags = [];
        let subSubValueTags = [];
        let tagsUrl = [];

        if(type === 'area'){
            model = this.cruiseArea;
            cruiseOptions = model._options;
        } else if (type === 'line'){
            model = this.cruiseLine;
            cruiseOptions = model._options;
        } else {
            model = this.cruiseShip;
            cruiseOptions = model._options;
        }

        cruiseOptions.forEach(function(group){
            if( (group.code === code || 'group-' + code === code ) && !onlyTags){
                group.checked = !group.checked;
            }

            if(group["subValues"].length > 0){
                group["subValues"].forEach(function (subValue) {
                    if(!onlyTags){
                        if(group.code === code){
                            subValue.checked = group.checked;
                        } else if (subValue.code === code) {
                            subValue.checked = !subValue.checked;
                        }
                    }
                    if(subValue['subValues'].length > 0 && type !== 'line'){
                        subValue['subValues'].forEach(function(subSubValue){
                            if(!onlyTags){
                                if(subValue.code === code){
                                    subSubValue.checked = subValue.checked;
                                } else if (group.code === code){
                                    subSubValue.checked = group.checked;
                                } else if (subValue.checked){
                                    subSubValue.checked = subValue.checked;
                                } else if (subSubValue.code === code) {
                                    subSubValue.checked = !subSubValue.checked;
                                }
                            }
                            if(subSubValue.checked && !subSubValueTags.filter(obj => obj.code === subSubValue.code).length > 0 ){
                                subSubValueTags.push({label: subSubValue.label, code: subSubValue.code});
                            }
                        });
                        if(!onlyTags){
                            subValue.checked = subValue["subValues"].every(subSubValue => subSubValue.checked === true);
                        }
                    }
                    tagsUrl = tagsUrl.concat(subSubValueTags);
                    if(subValue.checked && !tags.filter(obj => obj.code === subValue.code).length > 0) {
                        tags.push({label: subValue.label, code: subValue.code});
                        tagsUrl.push({label: subValue.label, code: subValue.code});
                        subSubValueTags = [];
                    }
                    tags = tags.concat(subSubValueTags);
                    subSubValueTags = [];

                });
                if(!onlyTags){
                    group.checked = group["subValues"].every(subValue => subValue.checked === true);
                }
            }

            tagsUrl = tagsUrl.concat(subValueTags);
            if(group.checked && !tags.filter(obj => obj.code === group.code).length > 0 && type !== 'ship') {
                tags.push({label: group.label, code: group.code});
                tagsUrl.push({label: group.label, code: group.code});
                subValueTags = [];
            }
            tags = tags.concat(subValueTags);
            subValueTags = [];

        });
        if(JSON.stringify(model._cruiseTags) != JSON.stringify(tags)){
            self.cruiseHasChanges = true;
        }
        if(tags.length > 0 ) {
            tags.unshift({label: 'Alle abwählen', code: 'All'});
        }

        model._cruiseTags = tags;
        model._cruiseTagsForUrl = tagsUrl;

        if(!onlyTags){
            model._options = cruiseOptions;
        }
        this.update = this.update++;
    }

    checkCruiseLineFromCruiseShip(cruiseShipOptions){
        let self = this;
        let checkedCruiseLinesList = [];
        cruiseShipOptions.forEach(function(cruiseShipGroup){
            let checkedCruiseShips = cruiseShipGroup['subValues'].filter(subValue => subValue.checked === true);
            if(checkedCruiseShips.length > 0){
                checkedCruiseLinesList = checkedCruiseLinesList.concat(cruiseShipGroup.code);
            }
        });

        self.cruiseLine._options.forEach(function(cruiseLineGroup){
            cruiseLineGroup['subValues'].forEach(function(subValue){
                let cruiseLineCodeIsInCheckedCruiseLinesList = checkedCruiseLinesList.filter(cruiseLine => cruiseLine === subValue.code).length > 0;

                if(!cruiseLineCodeIsInCheckedCruiseLinesList ){
                    subValue.checked = false;
                } else {
                    self.select(subValue.code, 'line');
                }
            });
        });
        this.initCruiseAutoSuggest(this.cruiseShip, 'ship');

    }

    toString(type) {
        let model;
        if(type === 'area'){
            model = this.cruiseArea
        }else if(type === 'line'){
            model = this.cruiseLine;
        } else {
            model = this.cruiseShip;
        }
        return model._cruiseTagsForUrl.map(ct => ct.label).join(', ');
    }
}
