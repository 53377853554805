import enquire from '../Libs/enquire.js'
import datepickerFactory from 'jquery-datepicker'

;(function (window, $) {
    'use strict';

    var SearchPresse = {
        init: function () {
            if ($('#news-search-form').length > 0) {
                datepickerFactory($);
                $.datepicker.setDefaults({
                    dateFormat: 'dd.mm.yy',
                    prevText: '&#x3c;zurück', prevStatus: '',
                    prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
                    nextText: 'Vor&#x3e;', nextStatus: '',
                    nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
                    currentText: 'heute', currentStatus: '',
                    todayText: 'heute', todayStatus: '',
                    clearText: '-', clearStatus: '',
                    closeText: 'schließen', closeStatus: '',
                    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                    showMonthAfterYear: false,
                    firstDay: 1,
                    numberOfMonths: 3,
                    changeMonth: true,
                    maxDate: "now",
                    gotoCurrent: true,
                });

                var $fromInput = $('#news-minimumDate'),
                    $toInput = $('#news-maximumDate'),
                    $fromContainer = $('#news-date-from'),
                    $toContainer = $('#news-date-to');

                $fromContainer.datepicker({
                    altField: '#news-minimumDate',
                    onSelect: function (selectedDate) {
                        $toContainer.datepicker("option", "minDate", selectedDate);
                        $fromContainer.hide();
                    }
                }).datepicker('setDate', null);

                $toContainer.datepicker({
                    altField: '#news-maximumDate',
                    onSelect: function (selectedDate) {
                        $fromContainer.datepicker("option", "maxDate", selectedDate);
                        $toContainer.hide();
                    }
                }).datepicker('setDate', null);

                $fromInput
                    .on('focus click', function () {
                        $fromContainer.show();
                    }).on('blur', function (e) {
                        console.log(e);
                });

                $toInput
                    .on('focus click', function () {
                        $toContainer.show();
                    });

                $(document).on('click', function (e) {
                    var $target = $(e.target);
                    if (!$target.closest('.hasDatepicker').length && !$target.closest('.ui-datepicker-header').length) {
                        if (!$target.is($fromInput)) {
                            $fromContainer.hide();
                        }
                        if (!$target.is($toInput)) {
                            $toContainer.hide();
                        }
                    }
                });

                enquire.register('screen and (max-width: 37.44em)', function () {
                    $fromContainer.datepicker('option','numberOfMonths', 1);
                    $toContainer.datepicker('option','numberOfMonths', 1);
                });

                enquire.register('screen and (min-width: 37.5em) and (max-width: 59.94em)', function () {
                    $fromContainer.datepicker('option','numberOfMonths', 2);
                    $toContainer.datepicker('option','numberOfMonths', 2);
                });

                enquire.register('screen and (min-width: 60em)', function () {
                    $fromContainer.datepicker('option','numberOfMonths', 3);
                    $toContainer.datepicker('option','numberOfMonths', 3);
                });
            }
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.SearchPresse = SearchPresse;
})(window, $);
