import BaseModel from './base';
import CateringHydrator from '../hydrator/catering';

export default class CateringModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new CateringHydrator({
            cateringType: '_catering'
        });

        this._prefix = 'verpflegung';

        this._defaults = {
            _catering: ''
        };

        this._options = [
            {
                code: 'dummy',
                label: 'beliebige Auswahl'
            },
            {
                code: 'uf',
                label: 'mind. Frühstück'
            },
            {
                code: 'hp',
                label: 'mind. Halbpension'
            },
            {
                code: 'vp',
                label: 'mind. Vollpension'
            },
            {
                code: 'ai',
                label: 'mind. All Inclusive'
            },
            {
                code: 'ov',
                label: 'Ohne Verpflegung'
            }
        ];

        this._options.forEach(option => option.checked = option.filtered = false);

        this.reset();
    }

    select(code) {
        this._catering = this._catering !== code ? code : '';
        this.updateOptions();
    }

    updateOptions() {
        this._options.forEach(option => option.checked = option.code === this._catering);
    }

    updateFilter(filter) {
        this._options.forEach(option => option.filtered = !(option.code.toUpperCase() in filter));
    }

    toString() {
        return this.isDefault() ? '' : `${this._options.filter(option => option.code === this._catering).map(option => option.label).join('')}`;
    }

    createInputFieldString() {
        let result = [];

        result = this._options.filter(entry => entry.checked);

        if (result !== null && result.length > 0) {
            return result[0].label;
        } else {
            return '';
        }
    }

    toUrl() {
        return this.isDefault() || this._catering === 'dummy' ? '' : `${this._prefix}(${this._catering})`;
    }

    getUnfiltered() {
        return this._options.filter(option => !option.filtered);
    }

    getPlaceholder() {
        if (window.SKTV.searchFilter && window.SKTV.searchFilter.boards &&
            Object.keys(window.SKTV.searchFilter.boards).length === 1 && this.getUnfiltered().length == 0) {
            return window.SKTV.searchFilter.boards[Object.keys(window.SKTV.searchFilter.boards)[0]];
        } else {
            return 'Verpflegung';
        }
    }
}
