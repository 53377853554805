import ImageContainer from './image-container';

export default Vue.component('thumbnail-slider', {
    template: `
    <div class="skg-thumbnail-slider" ref="slider">
        <slick ref="slick" v-bind:options="slickOptions">
            <div class="skg-thumbnail-slider__item" v-for="image in images" v-bind:key="image.id">
                <image-container v-bind:src="image.isVideo ? image.thumbnail : image.src" v-bind:title="image.title"
                                 v-bind:is-video-thumb="image.isVideo" v-bind:as-background-image="false"/>
            </div>
        </slick> 
    </div>
    `,
    components: {ImageContainer},
    data () {
        return {
            slickOptions: {
                asNavFor: '.skg-slider .slick-slider',
                dots: false,
                centerMode: true,
                focusOnSelect: true,
                arrows: false,
                swipeToSlide: true,
                infinite: true,
                slidesToShow: 5,
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            }
        };
    },
    props: {
        images: Array,
        isOpen: Boolean
    },
    methods: {
        next () {
            this.$refs.slick.next();
        },
        prev () {
            this.$refs.slick.prev();
        },
        reInit () {
            this.$refs.slick.destroy();
            this.$nextTick(() => {
                this.$refs.slick.create();
                this.$refs.slick.goTo(0, true);
            });
        }
    },
    mounted () {
        this.reInit();
    },
    watch: {
        images: function (newImages) {
            this.reInit();
        },
        isOpen: function (newIsOpen) {
            this.reInit();
        }
    }
});
