export default {
    props: {
        cruisesModel: {
            default() {
                return null;
            }
        }
    },
    data () {
        return {

        }
    },
    methods: {
        requestNewData: function (code,type) {
            this.$emit('requestnewdata', {code: code, type: type});
        },
        submitCruiseSearch: function (code,type) {
            this.$emit('submitcruisesearch');
        },

    },
    template: `
      <div class="search-home">
      <div class="search-input dropmenu radio-group row">
        <div class="col col6 cruise-radio-button-container">
          <div v-if="cruisesModel.cruisesRadioButtons !== []" v-for="radioButton in cruisesModel.cruisesRadioButtons" class="col col3" :class="{ selected: radioButton.checked, radio0: radioButton.code == 0, radio1: radioButton.code == 1 }"
               @click="cruisesModel.selectRadioButton(radioButton.code), requestNewData(radioButton.code, 'sea')" :key="radioButton.code">
            <div class="radio">
              <input type="radio" :checked="radioButton.checked"/>
              <span>?@ radioButton.label @</span>
            </div>
          </div>
          <div v-if="cruisesModel.packageCheckbox !== null" class="col col6 checkbox" :class="{ selected: cruisesModel.packageCheckbox.checked }"
               @click="cruisesModel.selectPackageCheckbox(), requestNewData(null, 'package')" :key="cruisesModel.packageCheckbox.code">
            <div class="checkbox">
              <input type="checkbox" :checked="cruisesModel.packageCheckbox.checked"/>
              <span>?@ cruisesModel.packageCheckbox.label @</span>
            </div>
          </div>
        </div>
        <div class="col col6 submit-cruises">
          <div class="row">
            <div class="search__submit is--arrow" @click="submitCruiseSearch">
              Angebote anzeigen
            </div>
          </div>
          <div class="row">
            <div class="powered-by-cruise-host col12">
              <span>Powered By CRUISEHOST</span>
            </div>
          </div>
        </div>
      </div>
      </div>
    `
};
