function StickyVideoThumb() {
    var self = this;

    // class properties
    self.$videoContainer = $('#live-stream');             // video container
    self.$video = $('#live-stream .video');     // video itself
    self.$close = $('#live-stream .close-thumb');
    self.videoHeight = self.$video.height();
    self.videoOffset = self.$video.offset().top;
    self.winOffset = window.pageYOffset;
    self.triggerPos = 0;
    self.stickable = true;

    // methods
    self.calcTriggerPos = function () {
        self.winOffset = window.pageYOffset;
        if (!self.$video.hasClass('video--sticky')) {
            self.videoHeight = self.$video.height();
            self.triggerPos = self.videoOffset + self.videoHeight;
        }
    };
    self.setContainerHeight = function () {
        // set wrapper height to prevent site 'flickering' when setting video player to fixed position
        // (remove height of container first as video is full responsible and adjusts to height of container)
        self.$videoContainer.css('height', '').css('height', self.$video.height() + 'px');
    };
    self.removeContainerHeight = function () {
        //self.$videoContainer.removeAttr('style');
    };
    self.closeThumb = function (el) {
        SKTV.Common.videoJsGallery.pauseAllPlayers();
        self.toggleSticky(el, false);
        self.stickable = false;
    };

    self.toggleSticky = function ($el, state) {

        var $parent = $el.parent();

        if (typeof state === 'undefined') {
            var $stickyHeader = $('.sticky-header--visible');
            var triggerPos = (self.triggerPos - ($stickyHeader.length > 0 ? $stickyHeader.height() : 0));
            state = self.winOffset > triggerPos;
        }

        if (state === true) {
            $parent.height($parent.height());
        }

        $el.toggleClass('video--sticky', state);

        if (state !== true) {
            $parent.height('');
        }
    };


    // add swipe behaviour using hammer lib
    $('#live-stream .video').hammer().bind('swipe', function () {
        self.closeThumb($(this));
    });

    // adding scroll event
    window.addEventListener('scroll', function () {
        self.calcTriggerPos();

        if (self.winOffset === 0) {
            self.stickable = true;
            self.setContainerHeight();
        }

        if (self.stickable) {
            self.toggleSticky(self.$video);
        }
    });

    // adding resize event
    window.addEventListener('resize', function () {

        if (self.$video.offset().top >= 0) {
            self.toggleSticky(self.$video, false);
            self.videoOffset = self.$video.offset().top;
        }

        self.calcTriggerPos();
        self.setContainerHeight();
    }, false);

    self.$close.on('click', function () {
        self.closeThumb(self.$video);
    });
}


;(function (window, $) {
    'use strict';
    var Tvlive = {
        videoPlayState: false,
        stickyVideo: false,
        init: function () {
            this.initTvLive();

        },
        /**
         * init tv live teaser on home and tv live on mediathek landingpage
         */
        initTvLive: function () {
            var $livetv = $('#livetv'),
                self = this;
            if ($livetv.length > 0) {
                var type = $livetv.data('type');
                var header = $livetv.data('header');
                var starttime = $livetv.data('starttime');
                var endtime = $livetv.data('endtime');

                $.ajax({
                    url: '/ajax/tvLive',
                    method: "POST",
                    data: {type: type, header: header, starttime: starttime, endtime: endtime},
                    beforeSend: function (xhrObj) {
                    },
                    error: function (data) {
                    },
                    success: function (data) {
                        $livetv.html(data);
                        if (type === 'page') {
                            SKTV.Common.videoJsGallery.initLivestream(self.initStickyVideo);
                        } else {
                            self.initLazyPlayer($livetv);
                        }
                        if (document.getElementById('live-stream') !== null) {

                            SKTV.Common.videoJsGallery.playLiveStream();
                            if (this.videoPlayState === true) {
                                setTimeout(function () {
                                    SKTV.Common.videoJsGallery.playLiveStream();
                                }, 500);
                            }

                        }
                    }
                });
            }
        },
        initStickyVideo: function () {
            SKTV.Tvlive.stickyVideo = new StickyVideoThumb();
        },
        initLazyPlayer: function ($video) {
            function getPlayerPosition() {
                return $video[0].getBoundingClientRect();
            }

            function getViewport() {
                var root = document.compatMode === 'CSS1Compat' ? document.documentElement : document.body;

                return {
                    top: window.pageYOffset || root.scrollTop,
                    left: window.pageXOffset || root.scrollLeft,
                    width: window.innerWidth || root.clientWidth,
                    height: window.innerHeight || root.clientHeight
                };
            }

            function inViewport(clientRect, viewport) {
                return !(clientRect.bottom < 0 || clientRect.top > viewport.height);
            }

            function checkViewport() {
                if (inViewport(getPlayerPosition(), getViewport())) {
                    $(window).off('scroll.lazyplayer');
                    SKTV.Common.videoJsGallery.initLivestream();
                }
            }

            $(window).on('scroll.lazyplayer', checkViewport);
            checkViewport();
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.Tvlive = Tvlive;

})(window, $);
