const Api = {
    sData: [],
    submit(name) {
        if (name) {
            window._satellite.track(name);
        } else {
            window._satellite.pageBottom();
        }
    },
    mergeEvents(a, b) {
        if (!a) {
            a = [];
        }
        if (!b) {
            b = [];
        }
        return [...$.isArray(a) ? a : a.split(','), ...$.isArray(b) ? b : b.split(',')]
            .filter((event, idx, events) => {
                return events.indexOf(event) === idx;
            })
            .join(',');
    },
    resetEvents() {
        window.s.events = '';
    },
    extend(args) {
        let dirty = false;

        if (typeof args[0] === 'string' && typeof args[1] !== 'undefined') {
            if (args[0] === 'events') {
                args[1] = Api.mergeEvents(window.s.events, args[1]);
            }
            window.s[args[0]] = args[1];
            dirty = true;
        } else if ($.isPlainObject(args[0])) {
            if ('events' in args[0]) {
                args[0].events = Api.mergeEvents(window.s.events, args[0].events);
            }
            window.s = $.extend(true, {}, window.s, args[0]);
            dirty = true;
        }

        return dirty;
    },
    isDefined() {
        return typeof window._satellite !== 'undefined';
    },
    create() {
        return {
            addToSatellite() {
                Api.sData.push(arguments);
            },
            updateSatellite() {
                if (!Api.isDefined()) {
                    return;
                }

                Api.sData.forEach((payload) => {
                    Api.extend(payload);
                });
            },
            track() {
                if (!Api.isDefined()) {
                    return;
                }

                const name = typeof arguments[0] === 'string' && (arguments.length > 2 || $.isPlainObject(arguments[1])) ? arguments[0] : false,
                    data = Array.prototype.slice.call(arguments, name ? 1 : 0);

                if (Api.extend(data)) {
                    Api.submit(name);
                }
            },
            reset() {
                if (!Api.isDefined()) {
                    return;
                }

                Api.resetEvents();
            },
            formatId(ids) {
                if (ids.euvia) {
                    return 'offerId-' + ids.euvia;
                }
                if (ids.giata) {
                    return 'giataId-' + ids.giata;
                }
                return 'edvCode-' + ids.edv;
            }
        };
    }
};

export default Api;