export default {
    props: {
        label: {
            default() {
                return 'Hotelkategorie ab';
            }
        }
    },
    data () {
        return {
            rating: null,
        }
    },
    methods: {
        select(rating) {
            if(this.rating === rating){
                this.rating = null
            } else {
                this.rating = rating;
            }
            SKTV.searchApp.parameters.category.addCategory(this.rating);
        }

    },
    template: `
      <div class="search-input category col col4" :class="{ 'is--disabled': $parent.step >= 4 }">
      <div class="label-color pull-left" v-text="label"></div>
      <div class="category-stars">
        <template v-for="value in [5,4,3,2,1]">
          <input type="radio" :id="'star-' + value" :value="value" name="category" @click="select(value)"
                 v-model="rating">
          <label :for="'star-' + value" class="full fas fa-sun" :data-stars="value"></label>
        </template>
      </div>
      </div>
    `
};
