;(function ($) {
    "use strict";

    /**
     * overwriting nasty _isLocal method that starts loading content via xhr
     * when the tab href is different to window.location.href
     *
     * @extends
     * $.ui.tabs
     *
     */

    $.widget( "sktv.tabs", $.ui.tabs, {
        _isLocal: function( anchor ) {
                return true;
            }
    });

})(jQuery);