import Sorting from './base';

export default class MetasearchSorting extends Sorting {
    constructor() {
        super();

        const $items = $('.resultList > tbody > tr');
        const $itemsWrap = $items.parent();

        this.data = {
            active: 'relevance',
            categories: {
                name: {
                    label: 'Name',
                    direction: 'asc',
                    sorting: 'single'
                },
                recommendation: {
                    label: 'Bewertung',
                    direction: 'desc',
                    sorting: 'single'
                },
                price: {
                    label: 'Preis',
                    direction: 'asc',
                    sorting: 'single'
                },
                relevance: {
                    label: 'Relevanz',
                    direction: 'desc',
                    sorting: 'single'
                }
            }
        };

        this.methods = Object.assign(this.methods, {
            _getValue($el, field) {
                return $el.data('sort-' + field);
            },

            sort() {
                this.$nextTick(() => {
                    const active = this.active;
                    const direction = this.categories[active].direction;

                    $items.detach();

                    $items.sort((a, b) => {
                        return this._sort(this._getValue($(a), active), this._getValue($(b), active), direction);
                    });

                    $itemsWrap.append($items);
                });
            },
        });

        this.ready = function () {
            this.sort();
        }
    }
}