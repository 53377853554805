var url_string = window.location.href;
var url = new URL(url_string);

var utmContent = url.searchParams.get('utm_content');

if (utmContent === '426667') {
    document.cookie = 'utm_content=' + utmContent + ';path=/;domain=' + location.hostname + ';max-age=' + (60 * 60 * 12);

    url.searchParams.delete('utm_content');
    window.history.replaceState({}, document.title,url.pathname + url.search);
}
