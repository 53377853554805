var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li>\n            <a onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.videos');SKTV.Common\n            .videoJsGallery.initVideoSlider(this, '#hotelinfo')\"\n               class=\"tab-videos\">Videos</a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <li>\n            <a class=\"ratings tab-ratings\"\n               onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.rating');\n                       SKTV.IBE.hotelPages.ratingsTab({\n                       giataId:'"
    + alias4(((helper = (helper = helpers.giataId || (depth0 != null ? depth0.giataId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"giataId","hash":{},"data":data}) : helper)))
    + "',\n                       isAppointmentList:false,\n                       type:'"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "',\n                       hcId:'"
    + alias4(((helper = (helper = helpers.hcId || (depth0 != null ? depth0.hcId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hcId","hash":{},"data":data}) : helper)))
    + "',\n                       iffCode:'"
    + alias4(((helper = (helper = helpers.iffCode || (depth0 != null ? depth0.iffCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iffCode","hash":{},"data":data}) : helper)))
    + "',\n                       recommendation: '"
    + alias4(((helper = (helper = helpers.recommendation || (depth0 != null ? depth0.recommendation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recommendation","hash":{},"data":data}) : helper)))
    + "',\n                       countReviews: '"
    + alias4(((helper = (helper = helpers.countReviews || (depth0 != null ? depth0.countReviews : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"countReviews","hash":{},"data":data}) : helper)))
    + "'\n                       });\n                       \">Bewertungen</a>\n        </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <li>\n            <a onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.climate');\" class=\"tab-klima\">Klima</a>\n        </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<ul class='infotabs row'>\n    <li class=\"active\"><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.description')\"\n                          class=\"tab-beschreibung\">Beschreibung</a></li>\n\n    <li>\n        <a onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.pictures');SKTV.Common.initSlider(this, '#hotelinfo')\">Bilder</a>\n    </li>\n\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.euviaVideocount : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.countReviews : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.climateData : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <li>\n        <a onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.support');\" class=\"tab-support\">\n            <i class=\"fas fa-phone-alt\" aria-hidden=\"true\"></i> Hotline</a>\n    </li>\n\n</ul>\n";
},"useData":true});