import VideoContainer from './video-container';
import ImageContainer from './image-container';

export default Vue.component('slider', {
    template: `
    <div class="skg-slider">
        <slick ref="slickSlider" v-bind:options="slickOptions" v-on:beforeChange="handleBeforeChange">
            <div class="skg-slider__item" v-for="(element, index) in elements" v-bind:key="element.id">
                <video-container v-if="element.isVideo" v-bind:src="element.src" v-bind:thumb="element.thumbnail"
                                v-bind:show-nav-buttons="true" v-bind:on-prev-click="prev" v-bind:id="element.id"
                                v-bind:on-next-click="next" v-bind:title="'(' + (index + 1) + '/' + elements.length + ') ' + element.title"
                                preload="none"/>
                <image-container v-else v-bind:src="element.src" v-bind:is-video-thumb="element.isVideoThumb"
                                v-bind:as-background-image="false" v-bind:show-nav-buttons="true"
                                v-bind:on-prev-click="prev" v-bind:on-next-click="next"
                                v-bind:title="'(' + (index + 1) + '/' + elements.length + ') ' + element.title"/>
            </div>
        </slick>
    </div>
    `,
    components: {
        ImageContainer,
        VideoContainer
    },
    data () {
        return {
            slickOptions: {
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.skg-thumbnail-slider .slick-slider',
                dots: false,
                arrows: false,
                fade: true
            }
        };
    },
    props: {
        elements: Array,
        isOpen: Boolean
    },
    methods: {
        next () {
            this.$refs.slickSlider.next();
        },
        prev () {
            this.$refs.slickSlider.prev();
        },
        reInit () {
            this.$refs.slickSlider.destroy();
            this.$nextTick(() => {
                this.$refs.slickSlider.create();
                this.$refs.slickSlider.goTo(0, true);
                setTimeout((() => {
                    this.$root.$emit('finishedRendering');
                }), 1500);
            });
        },
        handleBeforeChange(event, slick, breakpoint) {
            this.$root.$emit('interruptVideos');
        }
    },
    mounted () {
        this.reInit();
    },
    watch: {
        elements: function (newElements) {
            this.reInit();
        },
        isOpen: function (newIsOpen) {
            this.reInit();
        }
    }
});
