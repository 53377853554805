export default {
    
    data() {
        return {
            isOpen: false,
            usrInput: '',
            listItem: -1,
            localOptions: this.options
        };
    },
    props: {
        options: {
            default() {
                return [];
            }
        },
        select: {
            default() {
                return '';
            }
        },
        handler: {
            default: function () {
                return () => {};
            }
        },
        hideButton: {
            default: function () {
                return false;
            }
        }
    },
    computed: {
        filtered() {
            return this.filterBy(this.usrInput);
        },
        active() {
            const filtered = this.filterBy(this.usrInput, true);
            return this.usrInput && filtered.length ? filtered[0] : null;
        }
    },
    methods: {
        setActive(item) {
            this.usrInput = item && item.label || '';
            this.$nextTick(function () {
                this.isOpen = false;
            });
        },
        filterBy(value, strict) {
            value = value.toLowerCase();
            return this.localOptions.filter(function (option) {
                return strict ? option.label.toLowerCase() === value || option.value.toLowerCase() === value
                    : option.label.toLowerCase().indexOf(value) > -1;
            });
        },
        moveDown() {
            this.isOpen = true;
            if (this.listItem + 1 < this.filtered.length) {
                this.listItem += 1;
            }
        },
        moveUp() {
            if (this.listItem > 0) {
                this.listItem -= 1;
            }
        },
        reset() {
            this.setActive('');
        },
        submit() {
            if (this.listItem !== -1) {
                this.setActive(this.filtered[this.listItem]);
                this.listItem = -1;
            }
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        fetchOptions() {
            if (this.xhr) {
                this.xhr.abort();
                this.xhr = null;
            }

            if (this.usrInput.length > 2) {
                this.xhr = $.get('/ajax/fetchEkolumbusCompletion/' + this.usrInput)
                    .done((data) => {
                        const options = [];
                        if (data.GEO) {
                            data.GEO.forEach((geo) => {
                                options.push({
                                    label: geo.NAME,
                                    value: geo.NAME,
                                    data: geo
                                });
                            })
                        }
                        this.localOptions = options;
                    });
            }
        }
    },
    template: `
    <div class="autocompl search-input">
        <div class="autocompl__input input-and-button-wrap">
            <input class="reiseziel-input" type="text" v-model="usrInput" @keydown.up.prevent="moveUp()"
            @keydown.down.prevent="moveDown()" @keydown.enter.prevent="submit()" placeholder="Reiseziel (beliebig)">
            <div class="autocompl__reset" :class="{'no-toggle': hideButton}" @click="reset()"
             v-show="usrInput !== ''"><i class="far fa-trash"></i></div>
            <button class="dropmenu-trigger destination-trigger" @click="toggle()" v-if="!hideButton"><i 
            class="fas fa-bars closed"></i><i class="fas fa-bars open"></i></button>
        </div>
        <ul v-show="isOpen && localOptions.length">
            <li :class="{\'is--active\': listItem === idx}" v-for="(option, idx) in filtered" @click="setActive(option)">?@ option.label @</li>
        </ul>
    </div>
    `,
    created() {
        if (this.select) {
            this.usrInput = this.filterBy(this.select, true)[0].label;
        }
    },
    mounted() {
        this.$watch('usrInput', function (value) {
            if (value === '' && !this.isOpen
                || this.filtered[0] && this.filtered[0].label === value) {
                this.listItem = -1;
                this.isOpen = false;
            } else {
                this.fetchOptions();
                this.listItem = 0;
                this.isOpen = true;
            }
            this.handler(this.active && this.active.data || value);
        });

        this.$watch('isOpen', function (value) {
            $(document).off('click.autocompl');
            if (value) {
                $(document).on('click.autocompl', function (e) {
                    const $that = $(e.target);
                    if (!$that.is('.autocompl') && !$that.closest('.autocompl').length) {
                        this.isOpen = false;
                    }
                }.bind(this));
            }
        });

        this.$watch('listItem', function (idx) {
            if (idx > -1) {
                const $list = $('ul', this.$el),
                    $child = $list.children().first();
                $list.scrollTop($child.outerHeight() * idx);
            }
        });
    }
};