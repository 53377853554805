export default Vue.component('slider-filter', {
    template: `
    <div class="skg-filter">
            <!--div class="skg-filter__buttons">
                <div class="skg-filter__button" v-bind:class="{'skg-filter__button--active': showVideos}">
                    <span class="skg-filter__video-button" v-on:click="setShowVideos()">
                        Videos
                        <i class="fa" v-bind:class="{'fa-eye': showVideos, 'fa-eye-slash': !showVideos}"></i>
                    </span>
                </div>
                <div class="skg-filter__button" v-bind:class="{'skg-filter__button--active': showImages}">
                    <span class="skg-filter__image-button" v-on:click="setShowImages()">
                        Bilder
                        <i class="fa" v-bind:class="{'fa-eye': showImages, 'fa-eye-slash': !showImages}"></i>
                    </span>
                </div>
            </div-->
            <div class="skg-filter__categories">
                <ul>
                    <li class="skg-filter__category" v-bind:class="{'skg-filter__category--active': category.value === activeCategory}"
                        v-for="category in categories" v-bind:key="category.value" v-on:click="onCategoryClicked(category.value)">
                        <span class="skg-filter__category-name" v-html="category.title"></span> (?@ category.length @)</li>
                </ul>
                <select v-model="activeCategory" @change="onCategoryClicked(activeCategory)">
                    <option v-for="category in categories" :value="category.value" :key="category.value">
                        <span v-html="category.title"></span> (?@ category.length @)
                    </option>
                </select>
            </div>
        </div>
    `,
    data () {
        return {
            showVideos: true,
            showImages: true,
            activeCategory: ''
        }
    },
    props: {
        categories: Array
    },
    methods: {
        setShowVideos () {
            if (!this.showImages && this.showVideos) {
                this.showImages = true;
            }
            this.showVideos = !this.showVideos;
            this.fireFilterEvent(this.activeCategory);
        },
        setShowImages () {
            if (!this.showVideos && this.showImages) {
                this.showVideos = true;
            }
            this.showImages = !this.showImages;
            this.fireFilterEvent(this.activeCategory);
        },
        setActiveCategory (value) {
            this.activeCategory = value;
        },
        fireFilterEvent (value) {
            let type = this.showVideos && this.showImages ? 'both' : this.showVideos ? 'videos' : 'images';
            this.$root.$emit('filterSliderElements', {type: type, category: value});
        },
        onCategoryClicked (value) {
            this.setActiveCategory(value);
            this.fireFilterEvent(value);
        }
    },
    watch: {
        categories: function (newCategories) {
            let found = this.categories.find((element) => {
                return this.activeCategory === element.value;
            });
            if (found === undefined) {
                this.activeCategory = this.categories[0].value;
                this.fireFilterEvent(this.activeCategory);
            }
        }
    }
});
