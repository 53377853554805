export default Vue.component('image-container', {
    template: `
    <div v-if="asBackgroundImage" class="skg-image skg-image--as-background" 
         v-bind:style="{backgroundImage: 'url(\\'' + src + '\\')'}" v-on:click="onClick">
        <div v-if="isVideoThumb" class="skg-image__play-icon"><i class="fas fa-play"></i></div>
    </div>
    <div v-else class="skg-image">
        <div class="skg-image__container">
            <span v-if="showNavButtons" class="skg-image__prev" v-on:click="onPrevClick"><i class="fas fa-angle-left"></i></span>
            
            <div class="skg-image__wrapper">
                <div class="skg-image__img" :style="{ 'background-image': 'url(' + src + ')' }"></div>
                <div v-if="isVideoThumb" class="skg-image__play-icon"><i class="fas fa-play"></i></div>
            </div>
            <span v-if="showNavButtons" class="skg-image__next" v-on:click="onNextClick"><i class="fas fa-angle-right"></i></span>
            <p v-if="title" v-html="title"></p>
        </div>
    </div>
    `,
    props: {
        title: String,
        src: String,
        isVideoThumb: Boolean,
        onClick: {
            type: Function,
            default: () => {}
        },
        asBackgroundImage: {
            type: Boolean,
            default: true
        },
        showNavButtons: {
            type: Boolean,
            default: false
        },
        onPrevClick: {
            type: Function,
            default: () => {}
        },
        onNextClick: {
            type: Function,
            default: () => {}
        },
    }
});
