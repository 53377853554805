import BaseModel from './base';
import CodeHydrator from '../hydrator/code';

export default class CodeModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new CodeHydrator({
            code: '_code'
        });
        this.export = false;

        this._prefix = 'code';
        this._defaults = {
            _code: []
        };

        this.reset();
    }

    addCode(code) {
        this._code.push(code);
    }

    toUrl() {
        return this.isDefault() ? '' : `${this._prefix}(${(this._code instanceof Array ? this._code : Object.values(this._code)).join(',')})`;
    }
}