import Vue from 'vue';

window.Vue = Vue;

document.addEventListener("DOMContentLoaded", (event) => {

    Vue.mixin({
        methods: {
            boldIt(str, needle) {
                let strRegExp = new RegExp(`(${needle})`, 'ig');
                return str.replace(strRegExp, `<b>$1</b>`);
            },
        }
    });
});
