;(function (window, $) {
    'use strict';

    // Doc & API: http://www.woothemes.com/flexslider/

    var Slider = {
        init: function () {
            this.teaser.init();
            this.static.init();
        },
        teaser: {
            init: function () {
                this.adw();
                this.adt();
                this.adtw();
                this.top5();
                this.promotion();
                this.favorites();
            },
            adw: function () {
                $('.flex-adw').flexslider({
                    directionNav: false
                });
            },
            adt: function () {
                $('.flex-adt').flexslider({
                    directionNav: false
                });
            },
            adtw: function () {
                $('.flex-adtw').flexslider({});
            },
            top5: function () {
                $('.flex-top5').flexslider({
                    directionNav: false
                });
            },
            promotion: function () {
                var promoSlider = $('#promotionslider');

                if (promoSlider.length > 0) {
                    promoSlider.cflow();
                }
            },
            favorites: function () {
                $('.flex-favorites').flexslider({});
            }
        },
        static: {
            init: function () {
                this.hotel();
            },
            hotel: function () {
                var slider = $('.slider-hotel-promotion .flexslider');

                if (slider.length > 0) {

                    // init slider
                    slider.flexslider({
                        animation: 'slide',
                        controlNav: 'thumbnails',
                        start: function (slider) {
                            SKTV.UI.bLazy.revalidate();
                            //Update thumbnail container height with invisible scroll bar (auto scrolling in "after" option)
                            var updateHeight = function () {
                                var thumbnailMaxHeight = $(slider).find('.flex-viewport').height();
                                $(slider).find('.flex-control-nav').css('max-height', thumbnailMaxHeight);
                            };
                            $(window).resize(updateHeight);
                        },
                        after: function (slider) {
                            SKTV.UI.bLazy.revalidate();
                            //vertical scroll/slide to active thumbnail image
                            var container = $(slider).find('.flex-control-nav'),
                                scrollTo = $(container).find('.flex-active');
                            container.animate({
                                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
                            });
                        }
                    });

                    // show only first slider
                    slider.each(function (index) {
                        if (index > 0) {
                            $(this).hide();
                        }
                    });

                    setTimeout(function () {
                        $(window).trigger('resize');
                    }, 300);

                    // initialize slider select

                    $('#hotel-slider-select label').on('click', function () {
                        // hide all sliders
                        slider.hide();

                        // show selected slider
                        $('.' + $(this).attr('id')).show();

                        // fire event to show hidden slider correct when visible after .show() method
                        $(window).trigger('resize');

                        SKTV.UI.bLazy.revalidate();
                    });

                }
            },
            video: function () {
                var videoSlider = $('#videos');

                if (videoSlider.length > 0) {
                    SKTV.Common.videoJsGallery.initVideoSlider(videoSlider, '#info-' + videoSlider.data('info-id'));
                }
            }
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.Slider = Slider;

})(window, $);
