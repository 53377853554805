(function (win, App) {
    'use strict';

    var Storage = (function () {
        function Storage(name, type, noexpire) {
            if (!(this instanceof Storage)) {
                return new Storage(name, type);
            }
            var date = new Date();
            var uniq = ['sktv', name];
            if (!noexpire) {
                uniq.concat(date.getFullYear(), date.getMonth() + 1, date.getDate());
            }
            this.uniq = uniq.join('_');
            this.type = (type ? type : 'session') + 'Storage';
            this.persistent = Storage.isAvailable(this.type);
        }

        Storage.isAvailable = function (type) {
            var available = true,
                storage = win[type],
                item = '__storage-test__';

            try {
                storage.setItem(item, item);
                storage.removeItem(item);
            } catch (e) {
                available = false;
            }

            return available;
        };

        Storage.prototype._getStorage = (function () {
            var fallback = {
                getItem: function (key) {
                    return this[key] || null;
                },
                setItem: function (key, value) {
                    this[key] = value;
                },
                removeItem: function (key) {
                    delete this[key];
                }
            };
            try {
                fallback = Object.create(fallback);
            } catch (e) {
            }

            return function () {
                return this.persistent ? win[this.type] : fallback;
            };
        }());

        Storage.prototype._prefix = function (key) {
            return [this.uniq, key].join('_');
        };

        Storage.prototype.getItem = function (key) {
            var value = this._getStorage().getItem(this._prefix(key));
            try {
                value = JSON.parse(value);
            } catch (e) {
            }
            return value;
        };

        Storage.prototype.removeItem = function (key) {
            return this._getStorage().removeItem(this._prefix(key));
        };

        Storage.prototype.setItem = function (key, value) {
            try {
                value = JSON.stringify(value);
            } catch (e) {
            }
            return this._getStorage().setItem(this._prefix(key), value);
        };

        return Storage;
    }());

    App.Storage = Storage;
}(window, window.SKTV || (window.SKTV = {})));