var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<li><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#regioninfo', '.food')\">Essen & Trinken</a></li>";
},"3":function(container,depth0,helpers,partials,data) {
    return "<li><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#regioninfo', '.activity')\">Urlaubsorte & Ausflüge</a></li>";
},"5":function(container,depth0,helpers,partials,data) {
    return "<li><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#regioninfo', '.climate');\">Klima</a></li>";
},"7":function(container,depth0,helpers,partials,data) {
    return "<li><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#regioninfo', '.businessprofile')\">Wirtschaftsprofil</a></li>";
},"9":function(container,depth0,helpers,partials,data) {
    return "<li><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#regioninfo', '.accommodation')\">Unterkunft</a></li>";
},"11":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"regioninfo\" class=\"regioninfo visible\">\n\n    <div class=\"row\">\n        <div class=\"col12\">\n            <ul class=\"infotabs\">\n                <li class=\"active\"><a onclick=\"SKTV.IBE.common.toggleTabs(this, '#regioninfo', '.common')\">Allgemeine Informationen</a></li>\n                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.countryData : depth0)) != null ? stack1.SocialConventions : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.countryData : depth0)) != null ? stack1.ResortsExcursions : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.climateData : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.countryData : depth0)) != null ? stack1.BusinessProfile : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.countryData : depth0)) != null ? stack1.Accommodation : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </ul>\n        </div>\n    </div>\n\n    <div class=\"tabs-content-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/regions/common.hbs"),depth0,{"name":"common","fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/regions/food.hbs"),depth0,{"name":"food","fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/regions/activity.hbs"),depth0,{"name":"activity","fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <div class=\"row tab-content climate active\">"
    + ((stack1 = ((helper = (helper = helpers.climateData || (depth0 != null ? depth0.climateData : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"climateData","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/regions/businessprofile.hbs"),depth0,{"name":"businessprofile","fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/regions/accommodation.hbs"),depth0,{"name":"accommodation","fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});