import SearchObject from "../SearchObject";
import Results from "../Results";
import ItemOfferEuvia from "./Components/ItemOfferEuvia";
import ItemHotelGiata from "./Components/ItemHotelGiata";
import ItemLocation from "./Components/ItemLocation";

const axios = require("axios");
import debounce from 'lodash/debounce';
import Vue from "vue";
import SearchModel from "../../../Search/model/search";


export default {
template: `
  <form ref="form" action="/suchergebnis" method="post" id="offer-search-form" name="offersearchform" v-on:submit.prevent >
      <input type="hidden"
             name="tx_offer_search[search][needle]"
             v-model="searchObj.Needle"/>
      <input type="hidden"
             name="tx_offer_search[search][searchType]"
             v-model="searchObj.SearchType"/>
      <input type="hidden"
             name="tx_offer_search[search][idsEuvia]"
             v-model="searchResults.idsEuvia"/>
      <input type="hidden"
             name="tx_offer_search[search][idsGiata]"
             v-model="searchResults.idsGiata"/>
      <input type="hidden"
             name="tx_offer_search[search][locations]"
             v-model="searchResults.locationsLocations"/>
      <div id="offer-search" class="container">
        <div class="needle-offer-search" :class="{'hasresults': showResultsOnDesktop, 'show-results-on-mobile': showResultsOnMobile}">
          <div class="needle-offer-search__box">
            <input placeholder="Reiseziel, Hotelname, TV-Bestellnummer"
                   id="search-needle"
                   class="needle-input"
                   @input="search"
                   @click="changeToOffersearch"
                   @keydown.esc="reset"
                   @keydown.enter="submitForm(searchObj.SEARCH_TYPE_ALL)"
                   @focus="focus"
                   @blur="blur"
                   type="text"
                   tabindex="1"
                   autocomplete="off"
                   spellcheck="false"
                   v-model="searchObj.Needle">
            <span v-show="hasResults"
                  class="offer-search__icon offer-search--clear"
                  @click="reset">
              x
            </span>
            <button class="offer-search__icon offer_search--search"
                    @mousedown="submitForm(searchObj.SEARCH_TYPE_ALL)">
               <i class="fa fa-search"></i>
            </button>
          </div>
          <ul v-show="results" class="offer-search__results">
            <slot name="results">
              <ul v-if="searchResults.offerEuvias.length > 0" class="grouped">
                <li class="offer-search__results__header">
                  <div class="header-text">TV-Bestellnummer</div>
                </li>
                <li v-for="euvia in searchResults.offerEuvias" class="offer-search__results__item">
                  <item-offer-euvia v-bind:item="euvia" v-bind:needle="searchObj.Needle"/>
                </li>
              </ul>

              <ul v-if="searchResults.hotelGiatas.length > 0" class="grouped">
                <li class="offer-search__results__header">
                  <div class="header-text">Hotels</div>
                  <button class="header-link" @mousedown="submitForm(searchObj.SEARCH_TYPE_HOTEL)">Alle anzeigen</button>
                </li>
                <li v-if="searchResults.hotelGiatas.length > 0" v-for="giata in searchResults.hotelGiatas" class="offer-search__results__item">
                  <item-hotel-giata v-bind:item="giata" v-bind:needle="searchObj.Needle"/>
                </li>
              </ul>

              <ul v-if="searchResults.locations.length > 0" class="grouped last-grouped">
                <li class="offer-search__results__header">
                  <div class="header-text">Angebote in der Region</div>
                  <button class="header-link" @mousedown="submitForm(searchObj.SEARCH_TYPE_MULTI_REGION)">Alle anzeigen</button>
                </li>
                <li v-for="(location,index) in searchResults.locations" class="offer-search__results__item">
                  <item-location @submitLocation="submitForm(searchObj.SEARCH_TYPE_SINGLE_REGION, index)" v-bind:item="location" v-bind:needle="searchObj.Needle"/>
                </li>
              </ul>

              <ul class="grouped no-background">
                <!-- no results -->
                <li v-if="noResultMessage"
                    class="offer-search__results__item autocomplete__no-results">
                  Die Suche lieferte keine Ergebnisse.
                </li>
                <li class="offer-search__results__header" v-else>
                  <button class="header-link" @mousedown="submitForm(searchObj.SEARCH_TYPE_ALL)" >Alle Ergebnisse zu "<b>?@searchObj.Needle@</b>" anzeigen</button>
                </li>
              </ul>
            </slot>
          </ul>
        </div>
      </div>
  </form>
`,


  name: "OfferSearch",
  components: {
    ItemOfferEuvia,
    ItemHotelGiata,
    ItemLocation
  },
  data() {
    return {
      error: null,
      eventListener: false,
      isFocussed: false,
      loading: false,
      searchObj: new SearchObject(),
      searchResults: new Results(),
      results: [],
      selectedId: null,
      selectedIndex: null,
      showResultsOnDesktop: false,
      showResultsOnMobile: false,
      value: null,
      xhr: null,
      maxItemsOffer: 5,
      maxItemsHotel: 3,
      maxItemsLocation: 10,
      minSearchChars: 3,
      parameters: new SearchModel()
    };
  },
  methods: {
    search: debounce(function () {
      if (this.searchObj.Needle.length < this.minSearchChars) {
        this.results = [];
        this.showResultsOnDesktop = false;
        this.showResultsOnMobile = false;
        this.searchObj.resetRegion();
        this.searchObj.resetSearchType();
        return;
      }
      if (SKTV.Tracking && SKTV.Tracking.tracker) {
        SKTV.Tracking.tracker.setType('quicksearch');
      }


      this.requestApi();
    }, 250),
    async requestApi() {
      self = this;
      await axios.post(
          SKTV.cdnHost + SKTV.offerSearchApiPath,
          JSON.stringify(this.searchObj.apiSuggestObject()),
          {
            headers: {
              'Accept': 'application/json'
            },
            transformResponse: [function (data) {
              let blacklistOfferIds = [
                247555,
                'euvia_247555',
                247788,
                'euvia_247788',
                247790,
                'euvia_247790'
              ];
              let tmpData = JSON.parse(data).Items || [],
                  offerEuvia = [],
                  hotelGiata = [],
                location = [];

              self.searchResults.reset();

              self.dropdown = tmpData.length > 2;

              for (let i = 0; i < tmpData.length; i++) {
                if (tmpData[i].Type === 'undefined'){
                  continue;
                }

                if (blacklistOfferIds.includes(tmpData[i].orderNumber) || blacklistOfferIds.includes(tmpData[i].Id)) {
                  continue;
                }

                switch (tmpData[i].Type) {
                  case 'offer-euvia':
                    self.searchResults.appendEuviaId(tmpData[i].orderNumber);
                    if (tmpData[i].orderNumber === self.searchObj.Needle){
                      self.searchResults.removeOfferEuvias();
                      offerEuvia = [];
                      offerEuvia.push(tmpData[i])
                    } else if (offerEuvia.length >= self.maxItemsOffer) {
                      continue;
                    } else {
                      offerEuvia.push(tmpData[i]);
                    }

                    self.searchResults.appendOfferEuvia(tmpData[i]);
                    break;
                  case 'hotel-giata':
                    self.searchResults.appendGiataId(tmpData[i].Id);
                    if (hotelGiata.length >= self.maxItemsHotel ) {
                      continue;
                    }
                    hotelGiata.push(tmpData[i]);
                    self.searchResults.appendHotelGiata(tmpData[i]);
                    break;

                  case 'country':
                  case 'region':
                    if (location.length >= self.maxItemsLocation || !tmpData[i].title.toLowerCase().includes(self.searchObj.Needle.toLowerCase()) ) {
                      continue;
                    }
                    location.push(tmpData[i]);
                    self.searchResults.appendLocation(tmpData[i]);
                    break;
                }
              }
              self.searchResults.locations.sort(function(a, b) {
                return a.Type === "country" ? -1 : 1;
              });
              self.searchResults.locations =  self.searchResults.locations.slice(0,3);

              return [...location, ...offerEuvia, ...hotelGiata];
            }],
          })
          .then(response => {
            this.results = response.data;
            if (this.results.length)
              this.showResultsOnDesktop = true;
              this.showResultsOnMobile = true;
          })
          .catch(error => {
            console.log('error', error)
          });
    },
    focus() {
      this.isFocussed = true;
    },
    blur() {
      this.isFocussed = false;
      this.showResultsOnDesktop = false;
    },
    reset() {
      this.showResultsOnMobile = false;
      this.results = [];
      this.showResultsOnDesktop = false;
      this.searchObj.Needle = '';
      this.searchObj.resetRegion();
    },

    triggerReset(evt){
      document.querySelector('.search-home-mobile-btn-bar').style.display = 'block';
      document.querySelector('.canvas-hr').style.display = 'block';
      document.querySelector('.main-canvas-page').style.display = 'block';
      document.querySelector('.footerbuttonone').style.display = 'block';
      document.querySelector('.footerbuttontwo').style.visibility = 'hidden';
      this.reset();
    },

    //remove canvassearch elements when performing operator search
    changeToOffersearch(){
      document.querySelector('.search-home-mobile-btn-bar').style.display = 'none';
      document.querySelector('.canvas-hr').style.display = 'none';
      document.querySelector('.main-canvas-page').style.display = 'none';
      document.querySelector('.footerbuttonone').style.display = 'none';
      document.querySelector('.footerbuttontwo').style.visibility = 'visible';
      document.querySelector('.sk-modal__header-back').addEventListener('click',   this.triggerReset);
    },

    open(obj) {
      this.searchObj.openWindow(obj);
    },
    enter() {
      this.results = [];
      this.showResultsOnDesktop = false;
      this.showResultsOnMobile = false;
    },
    toUrl() {
      return '/suchen/' + Object.values(this.parameters).map(parameter => parameter.toUrl()).filter(parameter => parameter).join('/');
    },
    submitForm(searchType, locationIndex) {
      this.searchObj.SearchType = searchType;
      if(searchType === this.searchObj.SEARCH_TYPE_MULTI_REGION || searchType === this.searchObj.SEARCH_TYPE_SINGLE_REGION) {
        this.searchResults.idsGiata = [];
        this.searchResults.idsEuvia = [];
        if(searchType === this.searchObj.SEARCH_TYPE_SINGLE_REGION){
          let destination = this.searchResults.locations[locationIndex];
          if(destination.locations[0].Region !== ""){
            self.parameters.destinations.select(destination.locations[0].Region);
          } else {
            self.parameters.destinations.select(destination.locations[0].Country);
          }

        } else if(searchType === this.searchObj.SEARCH_TYPE_MULTI_REGION) {
          let self = this;
          this.searchResults.locations.forEach( (location, index) => {
            location.locations.forEach((value, index) => {
              if(value.Region !== ""){
                self.parameters.destinations.select(value.Region);
              } else {
                self.parameters.destinations.select(value.Country);
              }
            });
          });
        }

        this.parameters.interval._from = moment().add(3, 'days').format('DD.MM.YYYY');
        this.parameters.interval._to = moment().add(3, 'days').add(3, 'months').format('DD.MM.YYYY');
        this.parameters.traveltype._traveltype = 'pauschal';
        window.location = this.toUrl();
      } else {
        let orderNrPattern = /^B?\d{6}$/i;
        let searchValue = encodeURIComponent(this.searchObj.Needle.trim().replace(/\//g, ''));
        if (orderNrPattern.test(searchValue)) {
          window.location.href = `/angebot/` + this.searchObj.Needle.trim() + '.html';
        } else {
          Vue.nextTick(() => {
            this.$refs.form.submit();
          });
        }
      }
    }
  },
  computed: {
    noResultMessage() {
      return this.results.length === 0 && this.searchObj.Needle.length >= this.minSearchChars;

    },
    hasResults() {
      return this.results.length;
    },
  },
};
