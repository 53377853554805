

export default class SearchObject {


    constructor(props) {

        this.SEARCH_TYPE_NEEDLE = 'NEEDLE';
        this.SEARCH_TYPE_ALL = 'ALL';
        this.SEARCH_TYPE_HOTEL= 'HOTEL';
        this.SEARCH_TYPE_MULTI_REGION = 'MULTI_REGION';
        this.SEARCH_TYPE_SINGLE_REGION = 'SINGLE_REGION';

        const instance = this.constructor.instance;
        if (instance) {
            return instance;
        }

        this.constructor.instance = this;

        // suggest api options
        this.Needle = props.Needle || '';
        this.Country = props.Country || [];
        this.Region = props.Region || [];
        this.SubRegion = props.SubRegion || [];
        this.City = props.City || [];
        this.Type = props.Type || ['offer-euvia', 'hotel-giata', 'country','region','subRegion', 'city'];
        this.Limit = props.Limit || 100;
        this.SearchType = props.SearchType || this.SEARCH_TYPE_ALL;
        this.Id = props.Id || '';
        this.SelectedLocationIndex = props.SelectedLocationIndex || 0;
    }

    resetRegion() {
        this.Country = [];
        this.Region = [];
        this.SubRegion = [];
        this.City = [];
    }

    resetSearchType(){
        this.SearchType = this.SEARCH_TYPE_ALL;
    }

    resetType(){
        this.Type = ['offer-euvia','hotel-giata', 'country', 'region', 'subRegion', 'city'];
    }

    openWindow(obj) {
        switch (obj.Type) {
            case 'offer-euvia':
                window.location.href = `/angebot/${obj.orderNumber}` + '.html';
                break;
            case 'hotel-giata':
                window.location.href = `/beschreibung/${obj.descriptionUrl}`;
                break;
            case 'country':
            case 'region':
            case 'subRegion':
            case 'city':
                window.location.href = `/suchergebnis/${obj.title}`;
        }
    }

    /**
     * generate suggest object for api request
     *
     * @returns {object}
     */
    apiSuggestObject() {
        return {
            "Needle": this.Needle,
            "Country": this.Country,
            "Region": this.Region,
            "SubRegion": this.SubRegion,
            "City": this.City,
            "Type": this.Type,
            "Limit": this.Limit,
            "SearchType": this.SEARCH_TYPE_NEEDLE
        }
    }

    /**
     * generate object for search result page
     *
     * @returns {object}
     */
    searchObject() {
        return {
            "Needle": this.Needle,
            "Country": this.Country,
            "Region": this.Region,
            "SubRegion": this.SubRegion,
            "City": this.City,
            "Type": this.Type,
            "Limit": this.Limit,
            "SearchType": this.SearchType
        }
    }

    toString() {
        return JSON.stringify(this.searchObject());
    }
}
