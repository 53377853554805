/**
 * {{getweekday date}} renders the weekday in german, uses the jquery datepicker parseDate function
 *
 * @param string date "tt.mm.yyyy"
 * @return string
 */
module.exports = function (date, options) {
    if (date) {
        var days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            day = $.datepicker.parseDate('dd.mm.yy', date);
        return days[day.getDay()];
    }
    return '';
};