;(function (window, $) {
    'use strict';
    var Navigation = {
        init: function () {
            this.desktopNavigation();
            this.mobileNavigation();
            this.subNavigation();
            this.subNavigationResponsive();
        },
        track: function (name) {
            if (window._satellite) {
                window.AdobeHeaderClickName = name;
                window._satellite.track('NavClick');
            }
        },
        desktopNavigation: function () {
            var touch = {
                el: null,
                count: 0
            };

            function showOverlay() {
                $('nav.nav-desktop > .overlay').addClass('active');
            }

            function hideOverlay() {
                $('nav.nav-desktop > .overlay').removeClass('active');
            }

            $('nav.nav-desktop ul.first > li.navItem')
                .on('touchstart', function (e) {
                    if (touch.el !== this) {
                        touch.el = this;
                        touch.count = 0;
                    }
                    touch.count += 1;
                })
                .on('click', '.dropdown a', function (e) {
                    Navigation.track($(this).text().trim());
                    e.stopPropagation();
                })
                .click(function (e) {
                    if (touch.count !== 1) {
                        var $el = $(this).children('a'),
                            navLink = $el.attr('href');
                        if (navLink[0] !== '/') {
                            navLink = '/' + navLink;
                        }
                        Navigation.track($el.text().trim());
                        location.href = navLink;
                    }
                    e.stopPropagation();
                    e.preventDefault();
                })
                .on('mouseenter', showOverlay)
                .on('mouseleave', function () {
                    hideOverlay();
                    if (touch.el === this) {
                        touch.el = null;
                    }
                });

            $('nav.nav-desktop > .overlay').click(function () {
                touch.el = null;
            });
        },
        mobileNavigation: function () {
            function showOverlay() {
                $('nav.nav-mobile > .overlay').addClass('openTouch');
            }

            function hideOverlay() {
                $('#mobileNavButton').removeClass('open');
                $('nav.nav-mobile > .overlay').removeClass('openTouch');
                $('ul.first').hide();
            }

            $('#mobileNavButton, .mobileNavButton > i').on('click', function () {
                $('header .info .mobile-and-search').removeClass('mobile-search-active');
                var $navBtn = $('#mobileNavButton');
                $('ul.first').show();
                $navBtn.toggleClass('open');

                if ($navBtn.hasClass('open')) {
                    showOverlay();
                } else {
                    hideOverlay();
                }
            });

            $('.mobileNavCaret').on('click', function () {
                var li = $(this).parent();
                li.toggleClass('open');
                $(this).find('i').toggleClass('fa-caret-down fa-caret-up');

                li.siblings('li').removeClass('open');
                li.siblings('li').find('.mobileNavCaret > i').removeClass('fa-caret-up').addClass('fa-caret-down');
            });

            $('header .info .mobile-search-button').on('click', function () {
                hideOverlay();
                $('header .info .mobile-and-search').toggleClass('mobile-search-active');
                $('.header-serach-input').focus();
            });

            $('nav.nav-mobile > .overlay').on('click', hideOverlay);

            $('nav.nav-mobile').on('click', 'a', function () {
                Navigation.track($(this).text().trim());
            });
        },
        /**
         * initialise subnavigation of page template service pages.
         * there are some known 'bugs' on orientation change on mobile devices and
         * desktop window resizing. In some cases there is a wrong appearance of
         * the subnavi. After page reload the appearance is okay again.
         */
        subNavigation: function () {

            // TODO/Note: if wanted crossbrowser sticky uncomment this line and remove css from
            //$('.col3-service').stick_in_parent();

            if (window.ismobile) {
                $('#subnavi-dynamic').accordion({
                    active: false,      // all accordions are initially collapsed
                    collapsible: true,
                    icons: false,
                    heightStyle: 'content',
                    beforeActivate: function (event, ui) {
                        // on pages without subpages prevent accordion behaviour and open url instead
                        if ($(ui.newHeader).data('hassubpages') === false) {
                            event.preventDefault();
                            // if the subnavi has iconview first expand subnavi to full size
                            if ($('.subnavigation').hasClass('iconview')) {
                                $('.subnavigation').removeClass('iconview');
                            } else {
                                // finally link to page
                                $.when($('.subnavigation').addClass('iconview').removeClass('default')).done(function () {
                                    window.location = $(ui.newHeader).attr('href');
                                });
                            }
                        } else {
                            $('.subnavigation').removeClass('iconview');
                        }
                    },
                    create: function () {
                        //// TODO/Note: maybe needed in future => open active accordion on page reload if accordion is initially visible
                        //var subNavItems = $('#subnavi-dynamic > li');
                        //subNavItems.each(function(index, elem){
                        //    if ($(elem).hasClass('active') && $(elem).hasClass('hassubpages')) {
                        //        $('#subnavi-dynamic').accordion('option', 'active', index);
                        //    }
                        //});

                        // on mobile open the subnavi as iconview first
                        $('.subnavigation').addClass('iconview').removeClass('default');
                    }
                });
                $('.subnavigation .close').on('click', function () {
                    $('#subnavi-dynamic .ui-state-active').click();
                    $('.subnavigation').addClass('iconview').removeClass('default');
                });
            } else {
                // only call destroy method when widget is initialized
                if ($('#subnavi-dynamic').accordion('instance')) {
                    $('#subnavi-dynamic').accordion('destroy');
                }
                $('.subnavigation .close').off('click');
                $('.subnavigation .iconview').removeClass('iconview');
            }
        },

        subNavigationResponsive: function () {
            //checking window width and initialising the accordion again, between desktop and mobile mode
            if (".subnavigation") {
                $(window).resize(function () {
                    if (window.innerWidth < 960) {
                        SKTV.Navigation.subNavigation();
                    } else {
                        SKTV.Navigation.subNavigation();
                        //adding/removing those classes to get back the css styles after destroying the accordion (see default at SubNavigationServicePages.html)
                        $('.subnavigation').removeClass('iconview');
                        $('#subnavi-dynamic').addClass('ui-accordion ui-widget ui-helper-reset');
                        $('#subnavi-dynamic > li > ul').addClass('ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom');
                        $('#subnavi-dynamic > li > a').addClass('ui-accordion-header ui-state-default ui-accordion-icons ui-state-hover');
                    }
                });
            }
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.Navigation = Navigation;

})(window, $);
