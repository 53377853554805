export default {
    data: function() {
        return {
            isExpanded: this.expanded,
            isActive: this.active
        }
    },
    props: {
        options: {
            default: function () {
                return [];
            }
        },
        active: {
            default: function () {
                return null
            }
        },
        icon: {
            default: function () {
                return 'fa-map-marker';
            }
        },
        handler: {
            default: function () {
                return () => {};
            }
        },
        placeholder: {},
        expanded: {
            default: function () {
                return false;
            }
        }
    },
    methods: {
        setOption(idx) {
            this.isActive = this.isActive !== idx ? idx : null;
            this.isExpanded = false;
            this.handler(this.isActive);
        },
        getLabel(option) {
            return option.hasOwnProperty('@text') ? option['@text'] : option.label;
        },
        toggle() {
            this.isExpanded = !this.isExpanded;
        }
    },
    computed: {
        activeOption() {
            return this.isActive === null || this.options.length === 0 ? '' : this.getLabel(this.options[this.isActive]);
        }
    },
    template: `
    <div class="search-input dropmenu col col4" :class="{ show: isExpanded }">
        <div class="input-and-button-wrap" @click="toggle()">
            <i :class="'input-icon fa ' + icon"></i>
            <input type="text" :placeholder="placeholder" readonly="readonly" :value="activeOption">
            <button class="dropmenu-trigger destination-trigger">
                <i class="fas fa-bars closed"></i><i class="fas fa-bars open"></i>
            </button>
        </div>
        <div class="dropmenu-wrapper row">
            <div class="dropmenu-inner row">
                <ul>
                    <li v-for="(option, index) in options" @click="setOption(index)" :class="{ selected: isActive == index }">
                    ?@ getLabel(option) @</li>
                </ul>
            </div>
        </div>
    </div>
    `
};