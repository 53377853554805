import ThumbnailSlider from './thumbnail-slider';
import Slider from './slider';
import HotelInformation from './hotel-information';
import SliderFilter from './slider-filter';

export default Vue.component('overlay', {
    template: `
    <div class="skg-overlay" :class="{ 'skg-overlay--open': isOpen, 'skg-overlay--content-loading': isContentLoading }">
        <div class="skg-overlay__close" @click="closeOverlay">
            <i class="icon icon-video-image-gallery-close" />
        </div>
        <div class="skg-overlay__loading">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div class="skg-wrapper">
            <div class="skg-wrapper__header">
                <hotel-information v-bind:hotel-data="hotelData" />    
            </div>
            <div class="skg-wrapper__slider">
                <slider v-bind:elements="sliderContent" v-bind:isOpen="isOpen"/>
            </div>
            <div class="skg-wrapper__filter">        
                <slider-filter v-bind:categories="categories" />
            </div>
            <div class="skg-wrapper__thumbnail">
                <thumbnail-slider v-bind:images="sliderContent" v-bind:isOpen="isOpen"/>
            </div>
        </div>
    </div>
    `,
    components: {SliderFilter, HotelInformation, Slider, ThumbnailSlider},
    data () {
        return {
            isOpen: false,
            isContentLoading: true
        };
    },
    props: {
        sliderContent: Array,
        categories: Array,
        hotelData: Object
    },
    methods: {
        closeOverlay: function () {
            document.getElementsByTagName('body')[0].classList.remove('overlayOpen');
            this.$root.$emit('interruptVideos');
            this.isOpen = false;
        }

    },
    mounted: function () {
        this.$root.$on('openOverlay', () => {
            this.isOpen = true;
            setTimeout((() => {
                this.$root.$emit('finishedRendering');
            }), 1500);
        });
        this.$root.$on('startRendering', () => {
            this.isContentLoading = true;
        });
        this.$root.$on('finishedRendering', () => {
            this.isContentLoading = false;
        });
    }
});
