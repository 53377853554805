var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " has-alternative";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1), depth0))
    + "\n                    "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1),1,{"name":"compare","hash":{"operator":"==="},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "Tage";
},"6":function(container,depth0,helpers,partials,data) {
    return "Tag";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    ab "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <div class=\"col6\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                        <h2><i class=\"fas fa-plane\"></i> Hinflug</h2>\n                        <div class=\"departure-data\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(45, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <br>\n\n                        <h2><i class=\"fas fa-plane\"></i> Rückflug</h2>\n\n                        <div class=\"return-data\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(45, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <br>\n                    </div>\n                    <div class=\"flightinfo-link col6\">\n                        <a href=\"#\"\n                           onclick=\"SKTV.IBE.xhr.getLightboxFlightData(event, { id: '"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dateid : stack1), depth0))
    + "' }); return false;\">\n                            Infos zu den Flugzeiten\n                        </a>\n                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <h2>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n                                ("
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.code : stack1), depth0))
    + ")\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </h2>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                        <i class=\"fas fa-exchange-alt\"></i>\n                                        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.arrivalAirport : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n                                        ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.arrivalAirport : depth0)) != null ? stack1.code : stack1), depth0))
    + ")\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <h2>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                <i class=\"fas fa-exchange-alt\"></i>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n                                ("
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.code : stack1), depth0))
    + ")\n                            </h2>\n                        ";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.arrivalAirport : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n                                        ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.arrivalAirport : depth0)) != null ? stack1.code : stack1), depth0))
    + ")\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.isDummyFlight : stack1),{"name":"unless","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isDummyFlight : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "                                        <span class=\"bold\">\n                                            "
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/getweekday.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.departureDate : depth0),{"name":"getweekday","hash":{},"data":data}))
    + ", "
    + alias1(container.lambda((depth0 != null ? depth0.departureDate : depth0), depth0))
    + "\n                                        </span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "                                            <br>\n                                            "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.departureTime : depth0), depth0))
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.arrivalTime : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return "                                                - "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.arrivalTime : depth0), depth0))
    + " Uhr\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "                                                Uhr - Unbekannt\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundDayDifference : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundStops : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(43, data, 0),"data":data})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                        (+"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundDayDifference : stack1), depth0))
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundDayDifference : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundDayDifference : stack1),1,{"name":"compare","hash":{"operator":"==="},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    return "                                            Tage)\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                                            Tag)\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                        <br>\n                                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundStops : stack1), depth0))
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundStops : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outboundStops : stack1),1,{"name":"compare","hash":{"operator":"==="},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    return "                                            Stopps\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "                                            Stopp\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "                                        <br>\n                                        Direktflug\n";
},"45":function(container,depth0,helpers,partials,data) {
    return "                                Unbekannt\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inbound : stack1)) != null ? stack1["0"] : stack1)) != null ? stack1.isDummyFlight : stack1),{"name":"unless","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundDayDifference : stack1),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundStops : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(43, data, 0),"data":data})) != null ? stack1 : "");
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                        (+"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundDayDifference : stack1), depth0))
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundDayDifference : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundDayDifference : stack1),1,{"name":"compare","hash":{"operator":"==="},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                        <br>\n                                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundStops : stack1), depth0))
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundStops : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.inboundStops : stack1),1,{"name":"compare","hash":{"operator":"==="},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"offer-ribbon\">\n                        Alternatives Angebot\n                    </div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <div class=\"\">\n                        <div class=\"\">\n                            <ul class=\"flight-departure list-indent\"></ul>\n                        </div>\n                        <div class=\"\">\n                            <ul class=\"flight-return list-indent\">\n                                <li>\n                                    <i class=\"fas fa-calendar-alt\"></i>\n                                    "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/formatDate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.fromDate : stack1),{"name":"formatDate","hash":{},"data":data}))
    + " - "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/formatDate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.toDate : stack1),{"name":"formatDate","hash":{},"data":data}))
    + "\n                                </li>\n                                <li>\n                                    "
    + alias2(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1), depth0))
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1),1,{"name":"compare","hash":{"operator":">"},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1),1,{"name":"compare","hash":{"operator":"=="},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                </li>\n                            </ul>\n                        </div>\n                    </div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    return "                                        Tage";
},"58":function(container,depth0,helpers,partials,data) {
    return "                                        Tag";
},"60":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda;

  return "<div class=\"date available"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.isAlternative : depth0),true,{"name":"compare","hash":{"operator":"==="},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div id=\"item-"
    + container.escapeExpression(alias2(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dateid : stack1), depth0))
    + "\">\n        <div class=\"header-bar\">\n            <div class=\"duration-info\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.hotelOffer : stack1)) != null ? stack1.travelDate : stack1)) != null ? stack1.duration : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1)) != null ? stack1.outbound : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n            <span class=\"status-check\"><i class=\"fas fa-thumbs-up\"></i> Das Angebot ist verfügbar. <span\n                class=\"valid-timer\">Gültig für <span class=\"valid-timer__value\"></span> Minuten.</span></span>\n        </div>\n\n        <div class=\"data-wrapper grid\">\n            <div class=\"col4\" style=\"min-height: 1px;\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.isAlternative : depth0),true,{"name":"compare","hash":{"operator":"==="},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.flightOffer : stack1),{"name":"unless","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n\n            <div class=\"col4\">\n                <div class=\"organizer\">\n                    "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.contentOrganizer : stack1), depth0)) != null ? stack1 : "")
    + "\n                </div>\n\n                <div class=\"benefits\">\n                    <h2>Leistungen:</h2>\n                    <ul>\n                        "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.contentBenefits : stack1), depth0)) != null ? stack1 : "")
    + "\n                    </ul>\n                </div>\n\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/date-item/infobutton-datelist.hbs"),depth0,{"name":"infobutton-datelist","fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            </div>\n\n            <div class=\"col4 price-col\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/date-item/price-advanced.hbs"),depth0,{"name":"price-advanced","fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n\n";
},"usePartial":true,"useData":true});