var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"tabs-content-toggle\" onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.rating');SKTV.IBE.hotelPages\n                            .ratingsTab({\n                            giataId:'"
    + alias4(((helper = (helper = helpers.giataId || (depth0 != null ? depth0.giataId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"giataId","hash":{},"data":data}) : helper)))
    + "',\n                            isAppointmentList:false,\n                            type:'"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "',\n                            hcId:'"
    + alias4(((helper = (helper = helpers.hcId || (depth0 != null ? depth0.hcId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hcId","hash":{},"data":data}) : helper)))
    + "',\n                            iffCode:'"
    + alias4(((helper = (helper = helpers.iffCode || (depth0 != null ? depth0.iffCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iffCode","hash":{},"data":data}) : helper)))
    + "',\n                            recommendation: '"
    + alias4(((helper = (helper = helpers.recommendation || (depth0 != null ? depth0.recommendation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recommendation","hash":{},"data":data}) : helper)))
    + "',\n                            countReviews: '"
    + alias4(((helper = (helper = helpers.countReviews || (depth0 != null ? depth0.countReviews : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"countReviews","hash":{},"data":data}) : helper)))
    + "'\n                            })\">\n                        Bewertungen\n                        <i class=\"fas fa-chevron-down tabs-content-toggle-icon\"></i>\n                        <i class=\"fas fa-chevron-up tabs-content-toggle-icon\"></i>\n                    </div>\n                    <div class=\"row tab-content rating\">\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "                    <div class=\"tabs-content-toggle\" onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.climate')\">\n                        Klima\n                        <i class=\"fas fa-chevron-down tabs-content-toggle-icon\"></i>\n                        <i class=\"fas fa-chevron-up tabs-content-toggle-icon\"></i>\n                    </div>\n                    <div class=\"row tab-content weather climate\" id=\"climatePlugin\">\n                        "
    + ((stack1 = ((helper = (helper = helpers.climateData || (depth0 != null ? depth0.climateData : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"climateData","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"hotelinfo\" class=\"ibe-list-infobox row visible\">\n    <aside>\n        <div class=\"infobox-tabs-and-content-wrapper row\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/hotelinfosctabutton.hbs"),depth0,{"name":"hotelinfosctabutton","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/tabsMediaBoxNavigation.hbs"),depth0,{"name":"tabsMediaBoxNavigation","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"tabs-content-wrapper row\" style=\"overflow: hidden;\">\n                <div class=\"tabs-content-toggle active\" onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo',\n                 '.description')\">\n                    Beschreibung\n                    <i class=\"fas fa-chevron-down tabs-content-toggle-icon\"></i>\n                    <i class=\"fas fa-chevron-up tabs-content-toggle-icon\"></i>\n                </div>\n                <div class=\"active row tab-content description\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/tabs-description.hbs"),depth0,{"name":"tabs-description","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n                <div class=\"tabs-content-toggle\" onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.pictures');SKTV.Common.initSlider\n                    (this, '#hotelinfo')\">\n                    Bilder\n                    <i class=\"fas fa-chevron-down tabs-content-toggle-icon\"></i>\n                    <i class=\"fas fa-chevron-up tabs-content-toggle-icon\"></i>\n                </div>\n                <div class=\"row tab-content pictures\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/tabs-image-gallery.hbs"),depth0,{"name":"tabs-image-gallery","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.countReviews : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.climateData : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                <div class=\"tabs-content-toggle\" onclick=\"SKTV.IBE.common.toggleTabs(this, '#hotelinfo', '.support')\">\n                    <i class=\"fas fa-phone-alt\" aria-hidden=\"true\"></i> Hotline\n                    <i class=\"fas fa-chevron-down tabs-content-toggle-icon\"></i>\n                    <i class=\"fas fa-chevron-up tabs-content-toggle-icon\"></i>\n                </div>\n                <div class=\"row tab-content support\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/tabs-support-content.hbs"),depth0,{"name":"tabs-support-content","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/hotelinfosctabutton.hbs"),depth0,{"name":"hotelinfosctabutton","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </aside>\n</div>\n";
},"usePartial":true,"useData":true});