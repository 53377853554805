export default class BaseHydrator {
    constructor(map) {
        this._dataMap = map;
    }

    hydrate(data, model) {
        for (let key in data) {
            if (key in this._dataMap) {
                model[this._dataMap[key]] = data[key];
            }
        }

        return model;
    }

    dehydrate(model) {
        const ret = {};
        for (let key in this._dataMap) {
            ret[key] = model[this._dataMap[key]]
        }

        return ret;
    }
}