var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"ui-widget-bestprice-day\" id=\""
    + alias4(((helper = (helper = helpers.dayId || (depth0 != null ? depth0.dayId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dayId","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"date "
    + alias4(((helper = (helper = helpers.cssWeekEnd || (depth0 != null ? depth0.cssWeekEnd : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssWeekEnd","hash":{},"data":data}) : helper)))
    + "\">\n        <b>"
    + alias4(((helper = (helper = helpers.day || (depth0 != null ? depth0.day : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"day","hash":{},"data":data}) : helper)))
    + "."
    + alias4(((helper = (helper = helpers.monthName || (depth0 != null ? depth0.monthName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"monthName","hash":{},"data":data}) : helper)))
    + ".</b><br>\n        "
    + alias4(((helper = (helper = helpers.weekDay || (depth0 != null ? depth0.weekDay : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"weekDay","hash":{},"data":data}) : helper)))
    + "\n    </div>\n    <div class=\"priceBarWrap\"></div>\n    <div class=\"checkbox\"></div>\n</li>";
},"useData":true});