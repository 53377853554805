;(function (window, $) {
    'use strict';

    $.widget('sktv.back2top', {
        _init: function () {
            var self = this;
            this._handler = function () {
                self._update();
            };

            this.element.append(this.$button = this._render());
            this._bind()._update();
        },
        _bind: function () {
            this._unbind();

            if (window.addEventListener) {
                window.addEventListener('scroll', this._handler);
            } else {
                $(window).on('scroll.back2top', this._handler);
            }

            return this;
        },
        _unbind: function () {
            if (window.removeEventListener) {
                window.removeEventListener('scroll', this._handler);
            } else {
                $(window).off('scroll.back2top');
            }

            return this;
        },
        _render: function () {
            var self = this;

            return $('<a/>', {
                "class": 'back-to-top',
                href: '#top',
                html: '<i class="fas fa-arrow-alt-circle-up"></i>',
                on: {
                    click: function (e) {
                        e.preventDefault();

                        self.sleep = true;

                        $('body, html').animate({
                            scrollTop: 0
                        }, 800, function () {
                            self.sleep = false;
                            self._update();
                        });
                    }
                }
            });
        },
        _update: (function () {
            var scrollElement = window,
                scrollProp = 'pageYOffset',
                sizeElement = window,
                sizeProp = 'innerHeight';

            function getDocumentElement() {
                return document.compatMode === 'CSS1Compat' ?
                    document.documentElement :
                document.body.parentNode || document.body;
            }

            if (scrollElement[scrollProp] === undefined) {
                scrollElement = getDocumentElement();
                scrollProp = 'scrollTop';
            }

            if (sizeElement[sizeProp] === undefined) {
                sizeElement = getDocumentElement();
                sizeProp = 'clientHeight';
            }

            return function () {
                var height = sizeElement[sizeProp],
                    y = scrollElement[scrollProp],
                    deltaY = y - this.y,
                    deltaHeight = height - this.height,
                    scrollY = deltaY < 0 ? -1 : deltaY > 0 ? 1 : 0;

                if (!this.sleep && deltaHeight === 0 && (scrollY !== this.scrollY || deltaY !== 0)) {
                    this.$button.toggleClass('is-visible', deltaY < 0 && y > 0);
                }

                this.y = y;
                this.scrollY = scrollY;
                this.height = height;

                return this;
            };
        }())
    });
})(window, $);
