;(function ($) {
    "use strict";

    /**
     * jQuery UI Overlay Base Widget
     *
     * @requires
     * handlebars templates
     * widgetinfolayer.hbs
     */

    $.widget('sktv.overlayBase', {
        options: {
            template: 'widgetinfolayer',                 // default template
            buttonText: 'Schließen',
            content: '<p>foo</p>',
            flexHeight: false,
        },
        _init: function () {
            this._render();
        },
        _create: function () {
            var $body = $('body'),
                _this = this;
            this._scrollTop = $body.scrollTop() || $('html').scrollTop() || 0;
            this._bodyContent = $body.children(':not("script")');
            this._bodyWrap = $('<div/>').append(this._bodyContent).css({
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100vh',
                overflow: 'hidden'
            }).appendTo($body).scrollTop(this._scrollTop);

            // adding basic event handlers
            this._on({
                'click .ui-widget-overlay-close': function (evt) {
                    evt.stopPropagation();
                    this.destroy();
                    $body.off('keyup.overlayKeyFunctions');
                },
                'click .ui-widget-overlay-wrap': function (evt) {
                    evt.stopPropagation();
                },
                'click .ui-widget-overlay': function (evt) {
                    evt.stopPropagation();
                    this.destroy();
                }
            });

            $body.on('keyup.overlayKeyFunctions', function (evt) {
                // close overlay
                if (evt.keyCode === 27) {
                    evt.stopPropagation();
                    _this.destroy();
                    $(this).off(evt);
                }

                //scroll up || down
                if (evt.keyCode === 38 || evt.keyCode === 40) {
                    var $content = $body.find('.ui-widget-overlay-content');
                    $content[0].scrollBy(0, (evt.keyCode === 40 ? $content.height()/2 : (0-($content.height()/2))));
                }
            });

            this._instances = [];

            $('body').trigger("overlay:opened");
        },
        _render: function () {
            // get handlebars template
            this._instances.push($(SKTV.templates[this.options.template](this.options)));
            // insert to dom
            this._instances[this._instances.length - 1].appendTo(this.element);
            this._renderOverlay();
        },
        _renderOverlay: function () {
            var self = this;
            this._instances.forEach(function (instance, idx) {
                instance.css('background', idx === self._instances.length - 1 ? '' : 'none');
            });

            if(this.options.flexHeight === true){
                $('.ui-widget-overlay').addClass('flex-height');
            }
        },
        destroy: function () {
            this._instances.pop().remove();
            this._renderOverlay();

            if (!this._instances.length) {
                this._super();
            }
        },
        _destroy: function () {
            this._bodyContent.appendTo('body');
            this._bodyWrap.remove();
            $('.ui-widget-overlay', this.element).remove();
            $('html, body').scrollTop(this._scrollTop);

            $('body').trigger( "overlay:closed");
        },
        _setOptions: function (key, value) {
            this._super(key, value);
        }
    });

})(jQuery);
