export default {
    template:
        `<div class="datepickr" @click.capture="clickInside">
            <div class="datepickr__backdrop" v-if="backdrop" @click.stop="clickBackdrop"></div>
            <div class="datepickr__wrap">
                <div>
                  <calend-r :from="from" :first="first" :last="last" :selected="fromSelected" @select="select('from', $event)" slot="content">
                  </calend-r>
                </div>
                <dpr-reset @clicked="reset"></dpr-reset>
            </div>
        </div>`,
    props: {
        locale: {
            default: function () {
                return 'de';
            }
        },
        backdrop: {
            default: function () {
                return true;
            }
        },
        format: {
            default: function () {
                return 'DD.MM.YYYY';
            }
        },
        fromDate: {
            default: function () {
                return moment().locale(this.locale).add(10, 'days').format(this.format);
            }
        },
        first: {
            default: function () {
                return moment().locale(this.locale).add(5, 'days').format(this.format);
            }
        },
        last: {
            default: function () {
                return moment().locale(this.locale).add(3, 'years').format(this.format);
            }
        },
    },
    data: function () {
        let date = moment().locale(this.locale);
        let from = moment(this.fromDate, this.format, this.locale);


        return {
            date: date,
            from: from,
            focus: '',
            dateFrom: null
        };
    },
    watch: {
        fromDate: function() {
            this.from = moment(this.fromDate, this.format, this.locale);
            if (this.from.isBefore(this.first)) {
                this.from = moment(this.first).clone();
                this.submit();
            }
        },
    },
    computed: {
        fromSelected: function () {
            if(this.fromDate !== 'all'){
                return this.fromDate
            } else {
                return this.first;
            }
        },
    },
    methods: {
        hasFocus: function (field) {
            return this.focus === field || !this[field];
        },
        select: function (field, value) {
            let div = document.querySelectorAll('[sync="' + value + '"]');
            let cruiseFrom = document.querySelectorAll('.calendr__day--cruise-from');
            if(cruiseFrom.length > 0){
                cruiseFrom[0].classList.remove('calendr__day--cruise-from');
            }
            if(div.length > 0){
                div[0].classList.add('calendr__day--cruise-from');
            }
            this.submit(value);
        },
        toggle: function (value) {

        },

        submit: function (value) {
            this.$emit('submitcruise', value !== 'all' ? moment(value).format(this.format) : 'all');
            this.$emit('close');
        },
        reset: function () {
            this.submit('all');

        },
        clickInside: function (e) {
            if (!$(e.target).closest('.datepickr__dropdown').length && !$(e.target).is('.datepickr__backdrop')) {
                this.toggle();
            }
        },
        clickBackdrop: function () {
            if (!this.focus) {
                this.$emit('close');
            }
            else {
                this.toggle();
            }
        },
    },
    components: {
        dprReset: {
            template:
                `<a class="datepickr__reset" @click.stop="click">
                    <i class="fas fa-times"></i>&nbsp;
                    <span>Auswahl aufheben</span>
                </a>`,
            methods: {
                click: function () {
                    this.$emit('clicked');
                }
            }
        },
        calendR: {
            template:
                `<div class="calendr">
                    <clr-nav :first="first" :current="current" @update="update({month: $event})">
                        <clr-select :selected="getMonth" :options="monthsAvailable" @update="update({month: $event})"></clr-select>
                        <clr-select :selected="getYear" :options="yearsAvailable" @update="update({year: $event})"></clr-select>
                    </clr-nav>
                    <div class="calendr__spacer"></div>
                    <clr-month :current="current" :first="first" :from="from" :last="last"
                            @select="select"></clr-month>
                    <slot></slot>
                </div>`,
            props: ['from', 'first', 'last', 'selected', 'fromDate'],
            data: function () {
                return {
                    year: null,
                    current: moment(this.selected, 'DD.MM.YYYY'),
                    names: moment(this.selected).localeData().months()
                };
            },
            computed: {
                firstAvailable: function () {
                    let hidinp = document.getElementById('intervalinfo');
                    if(typeof(hidinp) != 'undefined' && hidinp != null){
                        if(hidinp.getAttribute('value').split('-').length > 1){
                            let btnurl = hidinp.getAttribute('value');
                            let favdate = btnurl.split('-')[0];
                            return moment(favdate);
                        } else {
                            return this.current.isBefore(this.first, 'day') ? this.current : this.first;
                        }
                    } else {
                        return this.current.isBefore(this.first, 'day') ? this.current : this.first;
                    }
                },
                getMonth:function(){
                  return this.current.month();
                },
                getYear:function(){
                    return this.current.year();
                },
                lastAvailable: function () {
                        return moment().add(3, 'Y');
                },
                monthsAvailable: function () {
                    let months = [],
                        start = 0,
                        end = 11;
                    if(this.current.year() === moment(this.last.toString(), 'DD.MM.YYYY').year()){
                        end = moment(this.last.toString(), 'DD.MM.YYYY').month();
                    }
                    if(this.current.year() === moment(this.first.toString(), 'DD.MM.YYYY').year()){
                        start = moment(this.first.toString(), 'DD.MM.YYYY').month();
                    }
                    for (; start <= end; start++) {
                        if(start <= end){
                            months.push({val: start, lbl: this.names[start]});
                        }
                    }
                    return months;
                },
                yearsAvailable: function () {
                    let yrs = [],
                        start = moment(this.first, 'dd.MM.YYYY').year(),
                        end = moment(this.last, 'dd.MM.YYYY').year();

                    for (; start <= end; start++) {
                        yrs.push({val: start, lbl: start});
                    }

                    return yrs;
                }
            },
            methods: {
                select: function (val) {
                    this.$emit('select', val);
                },
                update: function (update) {
                    let date = this.current.clone().set(update);
                    if (date.isBefore(moment(this.first,'DD.MM.YYYY'), 'day')) {
                        date = moment(this.first,'DD.MM.YYYY').clone();
                    }
                    else if (date.isAfter(moment(this.last, 'DD.MM.YYYY'), 'day')) {
                        date = moment(this.last, 'DD.MM.YYYY').clone();
                    }
                    this.current = date;
                }
            },
            components: {
                clrNav: {
                    template:
                        `<div class="calendr__nav">
                            <a class="calendr__btn" :class="{'calendr__btn--disabled': isFirst}" @click="update(-1)"><i class="fas fa-angle-left"></i></a>
                            <slot></slot>
                            <a class="calendr__btn" :class="{'calendr__btn--disabled': isLast}" @click="update(1)"><i class="fas fa-angle-right"></i></a>
                        </div>`,
                    props: ['first', 'current'],
                    computed: {
                        isFirst: function () {
                            return moment(this.first).isSame(this.current, 'month');
                        },
                        isLast: function () {
                            return moment().add(3,'Y').isSame(this.current, 'month');
                        }
                    },
                    methods: {
                        update: function (val) {
                            this.$emit('update', this.current.month() + val);
                        }
                    }
                },
                clrMonth: {
                    template:
                        `<div class="calendr__month">
                            <div class="calendr__lbl" v-for="lbl in labels">?@ lbl @</div>
                            <clr-day v-for="day in days" :key="day.toString()" :day="day" :current="current" :first="first" :last="last"
                                    :sync.from="day.toString()" :from="from" @select="select"></clr-day>
                        </div>`,
                    props: ['current', 'first', 'from', 'last', 'fromDate'],
                    data: function () {
                        return {
                            labels: ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO']
                        };
                    },
                    computed: {
                        days: function () {
                            let first = this.current.clone().startOf('month').startOf('isoWeek');
                            let last = this.current.clone().endOf('month').endOf('isoWeek');
                            let days = [];
                            while (first.isSameOrBefore(last, 'day')) {
                                days.push(first.clone());
                                first.add(1, 'day');
                            }
                            return days;
                        }
                    },
                    methods: {
                        select: function (val) {
                            this.$emit('select', val);
                        }
                    },
                    components: {
                        clrDay: {
                            template:
                                `<div class="calendr__day" :key="from.day()" :class="{'calendr__day--inactive': inactive,'calendr__day--disabled':
                                            disabled, 'calendr__day--cruise-from': start}" @click="select(day)">
                                    ?@ day.date() @
                                </div>`,
                            props: ['day', 'current', 'first', 'last', 'from'],
                            computed: {
                                inactive: function () {
                                    return this.day.month() !== this.current.month();
                                },
                                disabled: function () {
                                    return !moment(this.day.toString()).isBetween(moment(this.first, 'DD.MM.YYYY'), moment(this.last, 'DD.MM.YYYY'), 'day', '[]');
                                },
                                start: function () {
                                    return this.day.isSame(this.from, 'day');
                                }
                            },
                            methods: {
                                select: function (day) {
                                    if (!this.disabled) {
                                        this.$emit('select', day.toString());
                                    }
                                }
                            }
                        }
                    }
                },
                clrSelect: {
                    template:
                        `<label class="calendr__select" v-if="options">
                            <div class="calendr__lbl">?@ current.lbl @</div>
                            <select v-model="isSelected" @change="update($event.target.value)">
                                <option v-for="opt in options" :key="opt.val" :value="opt.val">?@opt.lbl@</option>
                            </select>
                        </label>`,
                    data: function () {
                        return {
                            isSelected: this.selected
                        }
                    },
                    props: ['selected', 'options'],
                    computed: {
                        current: function () {
                            const selected = this.isSelected;
                            return this.options.filter(function (opt) {
                                return opt.val === selected;
                            })[0];
                        }
                    },
                    methods: {
                        update: function (val) {
                            this.$emit('update', val);
                        }
                    },
                    watch: {
                        selected: function () {
                            this.isSelected = this.selected
                        }
                    }
                }
            }
        }
    }
}
