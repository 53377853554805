import videojs from 'video.js';
import httpStreaming from '@videojs/http-streaming';
// import '@videojs/http-streaming';



;(function (window, $) {
    'use strict';

    var autoplayed = false;

    var VideoJsGallery = {
        _hashKey: '202108251002',

        /**
         * This function initializes the video player in a given video slider element only once.
         *
         * @param elem {object} the current clicked (this) element
         * @param id {string} selector of slider to initialize
         */
        initVideoSlider: function (elem, id) {
            var initialized = $(elem).attr('data-videoslider');

            if (typeof initialized === 'undefined') {
                $(elem).attr('data-videoslider', true);

                let videoElem = document.querySelector(id + ' .video-js');
                let liElem = document.querySelector(id + ' .videos.flex-control-thumbs li');

                let sourceElem = document.createElement('source');
                sourceElem.src = liElem.dataset.videourl;
                sourceElem.type = 'video/mp4';

                liElem.classList.add('active');

                var ischrome = false;
                if(videoElem.tagName == 'DIV'){
                    videoElem = videoElem.firstChild;
                    ischrome = true;
                }

                videoElem.appendChild(sourceElem);

                let videoPlayer = videojs(videoElem);

                if(videoElem.src == undefined || videoElem.src == ''){
                    videoElem.src = sourceElem.src;
                }


                videoPlayer.on('error', function() {

                    let slides = this.el().closest('.promoRegionVideoGallery').querySelectorAll('.slides li');
                    let counter = null;

                    if( slides.length > 1) {
                        for(let i = 0; i < slides.length; i++) {
                            if( counter == null && !slides[i].classList.contains('active')) {
                                continue;
                            } else if( counter == null && slides[i].classList.contains('active') && i < (slides.length - 1)) {

                                VideoJsGallery.testVideoSrc(slides[i].dataset.videourl, function(){
                                    slides[i].remove();
                                });

                                counter = i;
                                continue;
                            } else if( counter == null && slides[i].classList.contains('active') && i == (slides.length - 1)) {
                                slides[i].classList.remove('active');
                                slides[0].classList.add('active');

                                this.src({
                                    src: slides[0].dataset.videourl,
                                    type: 'video/mp4'
                                });

                                VideoJsGallery.testVideoSrc(slides[0].dataset.videourl, function(){
                                    slides[0].remove();
                                });

                            } else if( counter != null && !slides[i].classList.contains('active')) {
                                slides[counter].classList.remove('active');
                                slides[i].classList.add('active');

                                this.src({
                                    src: slides[i].dataset.videourl,
                                    type: 'video/mp4'
                                });

                                VideoJsGallery.testVideoSrc(slides[i].dataset.videourl, function(){
                                    slides[i].remove();
                                });
                            }
                        }
                    } else if(slides.length <= 1){
                        VideoJsGallery.testVideoSrc(sourceElem.src, function(){
                            $(id).parent().children('h2').remove();
                            $(id).remove();
                        });
                    }
                });



                // add click event listener to other slider elements for changing and play video
                var liElements = document.querySelectorAll(id + ' .videos.flex-control-thumbs li');
                for (let i = 0; i < liElements.length; i++) {
                    liElements[i].addEventListener('click', (event) => {
                        let videoElem = document.querySelector(id + ' video');

                        if (videoElem !== undefined) {
                            // remove active class
                            document.querySelectorAll(id + ' .videos.flex-control-thumbs li').forEach((el) => el.classList.remove('active'));
                            // add active class to current element
                            event.currentTarget.classList.add('active');

                            let sourceElem = document.createElement('source');
                            sourceElem.src = event.currentTarget.dataset.videourl;
                            sourceElem.type = 'video/mp4';

                            let child = videoElem.querySelector('source');
                            if(child !== undefined && typeof child === "object"){
                                if(videoElem !== undefined && typeof videoElem === "Node"){
                                    videoElem.removeChild(videoElem.querySelector('source'));
                                }
                            }
                            videoElem.appendChild(sourceElem);

                            videoElem.src = event.currentTarget.dataset.videourl;

                            videoElem.load();
                            if(ischrome && navigator.userAgent.indexOf("Chrome") != -1 || (navigator.userAgent.includes('Mozilla') && navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Mobile') && navigator.userAgent.includes('Safari') && navigator.userAgent.includes('AppleWebKit') && navigator.userAgent.includes('KHTML, like Gecko') && navigator.userAgent.includes('Version'))){
                                //videoElem.muted = true;
                            }
                            videoElem.play();
                        }
                    });
                }
                if(ischrome && navigator.userAgent.indexOf("Chrome") != -1 || ischrome && (navigator.userAgent.includes('Mozilla') && navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Mobile') && navigator.userAgent.includes('Safari') && navigator.userAgent.includes('AppleWebKit') && navigator.userAgent.includes('KHTML, like Gecko') && navigator.userAgent.includes('Version'))){
                    var stopListen = function (ev) {
                        let bigButt = document.querySelector('.vjs-big-play-button');
                        let litButt = document.querySelector('.vjs-play-control');
                        videoElem.pause();
                        videoElem.removeEventListener(ev.type, ev.target);
                        videoElem.removeEventListener('click', stopListen);
                        videoElem.addEventListener('click',
                            playListen, { once: true });
                        bigButt.removeEventListener(ev.type, ev.target);
                        litButt.removeEventListener(ev.type, ev.target);
                        bigButt.removeEventListener('click', stopListen);
                        litButt.removeEventListener('click', stopListen);
                        bigButt.addEventListener('click',
                            playListen, { once: true });
                        litButt.addEventListener('click',
                            playListen, { once: true });
                        litButt.classList.remove('vjs-playing');
                        litButt.classList.add('vjs-paused');
                    }

                    var stopListenMobile = function (ev) {
                        let bigButt = document.querySelector('.vjs-big-play-button');
                        let litButt = document.querySelector('.vjs-play-control');
                        videoElem.pause();
                        videoElem.removeEventListener('touchstart', stopListenMobile);
                        videoElem.removeEventListener('click', stopListenMobile);
                        litButt.removeEventListener('click', stopListenMobile);
                        bigButt.removeEventListener('click', stopListenMobile);
                        videoElem.addEventListener('touchstart',
                            playListenMobile, { once: true });
                        bigButt.removeEventListener('touchstart', stopListenMobile);
                        litButt.removeEventListener('touchstart', stopListenMobile);
                        bigButt.addEventListener('click',
                            playListenMobile, { once: true });
                        litButt.addEventListener('click',
                            playListenMobile, { once: true });
                        litButt.classList.remove('vjs-playing');
                        litButt.classList.add('vjs-paused');
                    }

                    var playListen = function (ev) {
                        let bigButt = document.querySelector('.vjs-big-play-button');
                        let litButt = document.querySelector('.vjs-play-control');
                        let vidElem = document.querySelector('video');
                        vidElem.play();
                        vidElem.removeEventListener('click', playListen);
                        vidElem.addEventListener('click',
                            stopListen, { once: true });
                        bigButt.removeEventListener('click', playListen);
                        litButt.removeEventListener('click', playListen);
                        bigButt.addEventListener('click',
                            stopListen, { once: true });
                        litButt.addEventListener('click',
                            stopListen, { once: true });
                        litButt.classList.remove('vjs-paused');
                        litButt.classList.add('vjs-playing');
                    }

                    var playListenMobile = function (ev) {
                        let bigButt = document.querySelector('.vjs-big-play-button');
                        let litButt = document.querySelector('.vjs-play-control');
                        videoElem.play();
                        videoElem.removeEventListener('touchstart', playListenMobile);
                        videoElem.addEventListener('touchstart',
                            stopListenMobile, { once: true });
                        bigButt.removeEventListener('touchstart', playListenMobile);
                        litButt.removeEventListener('touchstart', playListenMobile);
                        bigButt.addEventListener('click',
                            stopListenMobile, { once: true });
                        litButt.addEventListener('click',
                            stopListenMobile, { once: true });
                        litButt.classList.remove('vjs-paused');
                        litButt.classList.add('vjs-playing');
                    }

                    var clickListen = function (ev) {
                        var liElements = document.querySelectorAll(id + ' .videos.flex-control-thumbs li');
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        let bigButt = document.querySelector('.vjs-big-play-button');
                        let litButt = document.querySelector('.vjs-play-control');
                        videoElem.removeEventListener('click', clickListen);
                        bigButt.removeEventListener('click', clickListen);
                        litButt.removeEventListener('click', clickListen);
                        liElements[0].dispatchEvent(clickEvent);
                        ev.target.addEventListener('click',
                            stopListen, { once: true });
                        bigButt.addEventListener('click',
                            stopListen, { once: true });
                        litButt.addEventListener('click',
                            stopListen, { once: true });
                        litButt.classList.remove('vjs-paused');
                        litButt.classList.add('vjs-playing');
                    }


                    var clickListenMobile = function (ev) {
                        let bigButt = document.querySelector('.vjs-big-play-button');
                        let litButt = document.querySelector('.vjs-play-control');
                        var liElements = document.querySelectorAll(id + ' .videos.flex-control-thumbs li');
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        liElements[0].dispatchEvent(clickEvent);
                        videoElem.load();
                        videoElem.play();
                        videoElem.addEventListener('touchstart',
                            stopListenMobile, { once: true });
                        bigButt.addEventListener('touchstart',
                            stopListenMobile, { once: true });
                        litButt.addEventListener('touchstart',
                            stopListenMobile, { once: true });
                        videoElem.removeEventListener('click',
                            clickListen, { once: true });
                        videoElem.removeEventListener('touchstart',
                            clickListenMobile, { once: true });
                        litButt.removeEventListener('touchstart',
                            clickListenMobile, { once: true });
                        bigButt.removeEventListener('touchstart',
                            clickListenMobile, { once: true });
                        videoElem.addEventListener('click',
                            stopListen, { once: true });
                        litButt.removeEventListener('click',
                            clickListen, { once: true });
                        bigButt.removeEventListener('click',
                            clickListen, { once: true });
                        litButt.classList.remove('vjs-paused');
                        litButt.classList.add('vjs-playing');

                    }

                    videoElem.addEventListener('click',
                        clickListen, { once: true });

                    videoElem.addEventListener('touchstart',
                        clickListenMobile, { once: true });

                    let bigButt = document.querySelector('.vjs-big-play-button');
                    let litButt = document.querySelector('.vjs-play-control');
                    let bigButtClone = bigButt.cloneNode(true);
                    bigButt.parentNode.replaceChild(bigButtClone, bigButt);
                    bigButt = bigButtClone;
                    let litButtClone = litButt.cloneNode(true);
                    litButt.parentNode.replaceChild(litButtClone, litButt);
                    litButt = litButtClone;

                    bigButt.addEventListener('click',
                        clickListen, { once: true });
                    bigButt.addEventListener('touchstart',
                        clickListenMobile, { once: true });
                    bigButt.classList.add('clickthroughbutton');

                    litButt.addEventListener('click',
                        clickListen, { once: true });
                    litButt.addEventListener('touchstart',
                        clickListenMobile, { once: true });
                }

            }
        },

        testVideoSrc: function(src, callback){
            let video = document.createElement('video');
            video.src = src;
            video.onerror = function () {
                console.log('error loading video source - removed player');
                callback();
            }
            video.remove();
        },


        /**
         * Initializes livestream (teaser or large version)
         */
        initLivestream: function (callback) {
            var _callback = callback;

            var liveStream = $('#live-stream');

            if (liveStream.length > 0) {
                var config = {
                    rtmp: "rtmps://euvia80livefs.fplive.net/euvia80live-live/",
                    live: true,
                    muted: SKTV.Common.Browser.isSafari() && !SKTV.Common.Browser.isMobile(),
                    native_fullscreen: true,
                    clip: {
                        autoplay: !SKTV.Common.Browser.isMobile(),
                        sources: [
                            {
                                type: "application/dash+xml",
                                src: "https://euvia.cdn.ses-ps.com/secure/DASH-Live/manifest.mpd"
                            },
                            {
                                type: "application/x-mpegURL",
                                // !IMPORTANT! has to be no-SSL URL, otherwise causes problems in some browsers, so the stream does not play
                                src: "http://euvia-live.hls.adaptive.level3.net/ses/euvia/index.m3u8"
                            },
                            {
                                type: "video/flash",
                                src: "stream60326"
                            },

                        ]
                    }
                };

                let videoElem = document.querySelector('#live-stream .video-js');

                let sourceElem = document.createElement('source');

                sourceElem.src = 'https://euvia.cdn.ses-ps.com/secure/DASH-Live/manifest.mpd';
                sourceElem.type = 'application/dash+xml';
                videoElem.appendChild(sourceElem);

                sourceElem = document.createElement('source');

                sourceElem.src = 'https://euvia.cdn.ses-ps.com/secure/HLS-Live/index.m3u8';
                sourceElem.type = 'application/x-mpegURL';
                videoElem.appendChild(sourceElem);

                SKTV.Common.LivestreamPlayer = videojs(videoElem);

                if ($.isFunction(_callback)) {
                    _callback();
                }

                // adjust stlying of controls for mobile device
                if (SKTV.Common.Browser.isMobile()) {
                    liveStream.addClass('mobile');
                }
            }
        },

        initArchiveVideos: function (el) {
            let videos = el.getElementsByClassName('video-js');

            if (videos.length > 1) {
                for (let i = 0; i < videos.length; i++) {
                    videojs(videos[i]);
                }
            } else {
                videojs(videos[0]);
            }
        },

        pauseAllPlayers: function () {
            let videos = document.getElementsByClassName('video-js');
            if (videos.length > 0) {
                for (let i = 0; i < videos.length; i++) {
                    videos[i].player.pause();
                }
            }
        },

        isPlayerPlaying: function () {
            let videos = document.getElementsByClassName('video-js');

            if (videos.length > 0) {
                for (let i = 0; i < videos.length; i++) {
                    if (videos[i].player.paused() !== true) {
                        return true;
                    }
                }
            }
        },

        isLiveStreamPlaying: function () {
            let liveStreamObj = document.querySelector('#live-stream .video-js');

            if (liveStreamObj !== null) {
                return !liveStreamObj.player.paused();
            }
        },

        playLiveStream: function () {
            ['load', 'scroll', 'resize'].forEach(function(e){
                window.addEventListener(e,function () {
                    let liveStreamObj = document.querySelector('#live-stream .video-js');
                    if (!autoplayed &&
                        liveStreamObj !== null) {
                        let rect = liveStreamObj.getBoundingClientRect();
                        if(rect.top >= 0 &&
                            rect.left >= 0 &&
                            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                        ) {
                            let player = videojs(liveStreamObj);
                            player.autoplay('muted');
                            autoplayed = true;
                        }
                    }
                });
            });
        }


    };

    window.SKTV = window.SKTV || {};
    window.SKTV.Common.videoJsGallery = VideoJsGallery;

})(window, $);
