var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <h2 class=\"info-head\">Infos zu den Flugzeiten</h2>\n        <h2 class=\"airports-head\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "            <i class=\"fas fa-exchange-alt\"></i>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.arrivalAirport : stack1)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "        </h2>\n\n        <div class=\"col6 hinflug-col\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.outboundLegList : stack1),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <span class=\"stops\"><b>"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/getstops.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.outboundLegList : stack1),{"name":"getstops","hash":{},"data":data}))
    + "</b></span>\n        </div>\n\n        <div class=\"col6 rueckflug-col\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.inboundLegList : stack1),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <span class=\"stops\"><b>"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/getstops.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.inboundLegList : stack1),{"name":"getstops","hash":{},"data":data}))
    + "</b></span>\n        </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.departureAirport : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.outboundLegList : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.departureAirportName : depth0), depth0))
    + " ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.arrivalAirport : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.flightOffer : depth0)) != null ? stack1.flight : stack1)) != null ? stack1.inboundLegList : stack1),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.last),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.stopOverDuration : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                <div class=\"hinflug-item\">\n                    <span class=\"directions first\"><i class=\"fas fa-plane\"></i> Hinflug</span>\n                    <span class=\"directions anschluss\"><i class=\"fas fa-plane\"></i> Anschlussflug</span>\n                    <ul class=\"flight-list departure list-indent\">\n                        <li class=\"route\"><span>"
    + alias4(((helper = (helper = helpers.departureAirportCode || (depth0 != null ? depth0.departureAirportCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"departureAirportCode","hash":{},"data":data}) : helper)))
    + "</span> <i class=\"fas fa-exchange-alt\"> </i> <span>"
    + alias4(((helper = (helper = helpers.arrivalAirportCode || (depth0 != null ? depth0.arrivalAirportCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"arrivalAirportCode","hash":{},"data":data}) : helper)))
    + "</span></li>\n                        <li class=\"bold\">"
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/getweekday.js")).call(alias1,(depth0 != null ? depth0.departureDate : depth0),{"name":"getweekday","hash":{},"data":data}))
    + ". "
    + alias4(alias5((depth0 != null ? depth0.departureDate : depth0), depth0))
    + "</li>\n                        <li>"
    + alias4(alias5((depth0 != null ? depth0.departureTime : depth0), depth0))
    + " - "
    + alias4(alias5((depth0 != null ? depth0.arrivalTime : depth0), depth0))
    + " Uhr</li>\n                        <li class=\"airlineimage\">\n                            <img src=\"/fileadmin/sonnenklar/admin/airlinelogos/"
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/toLowerCase.js")).call(alias1,(depth0 != null ? depth0.flightCarrierCode : depth0),{"name":"toLowerCase","hash":{},"data":data}))
    + ".gif\"\n                                     alt=\""
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.flight : depth0)) != null ? stack1.carrierName : stack1), depth0))
    + "\">\n                            <span class=\"flightnumber\">Flugnummer: <br> "
    + alias4(alias5((depth0 != null ? depth0.flightCarrierCode : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? depth0.flightNumber : depth0), depth0))
    + "</span>\n                        </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["class"] : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                     </ul>\n                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <div class=\"hinflug-item\">\n                        <span class=\"directions\"><i class=\"fas fa-map-marker-alt\"></i> Zwischenstopp</span>\n                        <ul class=\"flight-list departure list-indent\">\n                            <li>\n                                "
    + alias2(alias1((depth0 != null ? depth0.departureAirportName : depth0), depth0))
    + " ("
    + alias2(alias1((depth0 != null ? depth0.departureAirportCode : depth0), depth0))
    + ")\n                            </li>\n                            <li>\n                                Aufenthalt: "
    + alias2((helpers.formatDuration || (depth0 && depth0.formatDuration) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.stopOverDuration : depth0),{"name":"formatDuration","hash":{},"data":data}))
    + "\n                            </li>\n                        </ul>\n                    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                        <li>\n                            <span class=\"bold\">Klasse</span>: "
    + container.escapeExpression(container.lambda((depth0 != null ? depth0["class"] : depth0), depth0))
    + "</span>\n                        </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.stopOverDuration : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <div class=\"rueckflug-item\">\n                <span class=\"directions first\"><i class=\"fas fa-plane\"></i> Rückflug</span>\n                <span class=\"directions anschluss\"><i class=\"fas fa-plane\"></i> Anschlussflug</span>\n                <ul class=\"flight-list return list-indent flight-data\">\n                    <li class=\"route\"><span>"
    + alias4(((helper = (helper = helpers.departureAirportCode || (depth0 != null ? depth0.departureAirportCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"departureAirportCode","hash":{},"data":data}) : helper)))
    + "</span> <i class=\"fas fa-exchange-alt\"> </i> <span>"
    + alias4(((helper = (helper = helpers.arrivalAirportCode || (depth0 != null ? depth0.arrivalAirportCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"arrivalAirportCode","hash":{},"data":data}) : helper)))
    + "</span></li>\n                    <li class=\"bold\">"
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/getweekday.js")).call(alias1,(depth0 != null ? depth0.departureDate : depth0),{"name":"getweekday","hash":{},"data":data}))
    + ". "
    + alias4(alias5((depth0 != null ? depth0.departureDate : depth0), depth0))
    + "</li>\n                    <li>"
    + alias4(alias5((depth0 != null ? depth0.departureTime : depth0), depth0))
    + " - "
    + alias4(alias5((depth0 != null ? depth0.arrivalTime : depth0), depth0))
    + " Uhr</li>\n                    <li class=\"airlineimage\">\n                        <img src=\"/fileadmin/sonnenklar/admin/airlinelogos/"
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/toLowerCase.js")).call(alias1,(depth0 != null ? depth0.flightCarrierCode : depth0),{"name":"toLowerCase","hash":{},"data":data}))
    + ".gif\"\n                             alt=\""
    + alias4(alias5((depth0 != null ? depth0.carrierName : depth0), depth0))
    + "\">\n\n                        <span class=\"flightnumber\">Flugnummer: <br> "
    + alias4(alias5((depth0 != null ? depth0.flightCarrierCode : depth0), depth0))
    + " "
    + alias4(alias5((depth0 != null ? depth0.flightNumber : depth0), depth0))
    + "</span>\n                    </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["class"] : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <div class=\"rueckflug-item\">\n                <span class=\"directions\"><i class=\"fas fa-map-marker-alt\"></i> Zwischenstopp</span>\n                <ul class=\"flight-list return list-indent flight-data\">\n                    <li>\n                        "
    + alias2(alias1((depth0 != null ? depth0.departureAirportName : depth0), depth0))
    + " ("
    + alias2(alias1((depth0 != null ? depth0.departureAirportCode : depth0), depth0))
    + ")\n                    </li>\n                    <li>\n                        Aufenthalt: "
    + alias2((helpers.formatDuration || (depth0 && depth0.formatDuration) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.stopOverDuration : depth0),{"name":"formatDuration","hash":{},"data":data}))
    + "\n                    </li>\n                </ul>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"lightbox-flightdata\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.flightOffer : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});