var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"row\">\n            <div class=\"real-col8 bold green\">Sofortige Anzahlung ("
    + alias4(((helper = (helper = helpers.paymentPercentage || (depth0 != null ? depth0.paymentPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentPercentage","hash":{},"data":data}) : helper)))
    + "%)\n                <span class=\"info-tip\"><i class=\"fas fa-info-circle\"></i><span class=\"info-tip-text\">\n                    <span>Die Zusatzleistungen Versicherung und Parkplatz sind von der Anzahlung ausgeschlossen.</span>\n                    <span class=\"info-tip-text--triangle\"></span>\n                </span></span>\n            </div>\n            <div class=\"real-col4 bold green tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.advanceAmount : depth0),{"name":"asPrice","hash":{},"data":data}))
    + "</div>\n        </div>\n        <div class=\"row\">\n            <div class=\"real-col8 bold\">Restbetrag (fällig in "
    + alias4(((helper = (helper = helpers.payDayDiff || (depth0 != null ? depth0.payDayDiff : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payDayDiff","hash":{},"data":data}) : helper)))
    + " Tagen am "
    + alias4(((helper = (helper = helpers.payDay || (depth0 != null ? depth0.payDay : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payDay","hash":{},"data":data}) : helper)))
    + ")</div>\n            <div class=\"real-col4 bold tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.remainingAmount : depth0),{"name":"asPrice","hash":{},"data":data}))
    + " </div>\n        </div>\n        <div class=\"row delim-2\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div>\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.paymentPercentage : depth0),0,{"name":"compare","hash":{"operator":"!="},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"price-tag\">\n        <div class=\"price-tag__label\">\n            Gesamtpreis\n        </div>\n        <div class=\"price-tag__value\">\n            "
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + container.escapeExpression(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.finalPrice : depth0),{"name":"asPrice","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n</div>\n\n<div class=\"row delim-2\"></div>\n\n";
},"useData":true});