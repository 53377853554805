import Vue from 'vue';

var Autocomplete = Vue.extend({
    data: function () {
        return {
            isOpen: false,
            usrInput: '',
            listItem: -1
        };
    },
    props: {
        options: {
            default: function () {
                return [];
            }
        },
        select: {
            default: function () {
                return '';
            }
        }
    },
    computed: {
        filtered: function () {
            return this.filterBy(this.usrInput);
        },
        active: function () {
            var filtered = this.filterBy(this.usrInput, true);
            return this.usrInput && filtered.length ? filtered[0] : null;
        }
    },
    methods: {
        setActive: function (item) {
            this.usrInput = item && item.label || '';
            this.$nextTick(function () {
                this.isOpen = false;
                window.location.pathname = decodeURIComponent(window.location.pathname).replace(/\/ort\(([^)]+)\)|$/i, !this.usrInput ? '' : '/ort(' + item.value.replace(/\+/g, '%2B') + ')');
            });
        },
        filterBy: function (value, strict) {
            var values = value.toLowerCase().split(',');

            return this.options.filter(function (option) {
                var doFilter = false;

                values.forEach(function(val){
                    if(doFilter !== true){
                        if(strict){
                            doFilter = option.label.toLowerCase() === val || option.value.toLowerCase().split(',').indexOf(val) > -1;
                        } else {
                            doFilter = option.label.toLowerCase().indexOf(val) > -1;
                        }
                        return doFilter;
                    }
                });

                return doFilter;
            });
        },
        moveDown: function () {
            this.isOpen = true;
            if (this.listItem + 1 < this.filtered.length) {
                this.listItem += 1;
            }
        },
        moveUp: function () {
            if (this.listItem > 0) {
                this.listItem -= 1;
            }
        },
        reset: function () {
            this.setActive('');
        },
        submit: function () {
            if (this.listItem !== -1) {
                this.setActive(this.filtered[this.listItem]);
                this.listItem = -1;
            }
        },
        toggle: function () {
            this.isOpen = !this.isOpen;
        }
    },
    template: '<div class="autocompl">' +
    '<div class="autocompl__input"><input type="text" v-model="usrInput" id="citiesFilter" @keydown.up.prevent="moveUp()" @keydown.down.prevent="moveDown()" @keydown.enter.prevent="submit()" placeholder="Ort wählen:">' +
    '<div class="autocompl__reset" @click="reset()" v-show="usrInput !== \'\'"><i class="far fa-trash"></i></div>' +
    '</div>' +
    '<ul v-show="isOpen"><li :class="{\'is--active\': listItem === idx}" v-for="(option, idx) in filtered"' +
    ' @click="setActive(option)" :value="option.value">?@ option.label @</li></ul>' +
    '<div class="autocompl__toggle" :class="{\'is--active\': isOpen}" @click="toggle()"><i class="fas fa-caret-down"></i></div>' +
    '</div>',
    created: function () {
        if (this.select) {
            var sItem = this.filterBy(decodeURIComponent(this.select), true);

            if(typeof sItem !== 'undefined'){
                this.usrInput = sItem[0].label;
            }
        }
    },
    mounted: function () {
        this.$watch('usrInput', function (value) {
            if (value === '' && !this.isOpen || !this.filtered.length || this.filtered[0] && this.filtered[0].label === value) {
                this.listItem = -1;
                this.isOpen = false;
            } else {
                this.listItem = 0;
                this.isOpen = true;
            }
        });

        this.$watch('isOpen', function (value) {
            $(document).off('click.autocompl');
            if (value) {
                $(document).on('click.autocompl', function (e) {
                    var $that = $(e.target);
                    if (!$that.is('.autocompl') && !$that.closest('.autocompl').length) {
                        this.isOpen = false;
                    }
                }.bind(this));
            }
        });

        this.$watch('listItem', function (idx) {
            if (idx > -1) {
                var $list = $('ul', this.$el),
                    $child = $list.children().first();
                $list.scrollTop($child.outerHeight() * idx);
            }
        });
    }
});

Vue.component('autocomplete', Autocomplete);