import BaseModel from './base';
import CategoryHydrator from '../hydrator/category';

export default class CategoryModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new CategoryHydrator({
            category: '_category'
        });

        this._prefix = 'kategorie';
        this._defaults = {
            _category: ''
        };

        this.reset();
    }

    addCategory(category) {
        this._category = category;
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${this._category})`;
    }
}
