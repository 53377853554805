import BaseModel from './base';
import HotelHydrator from '../hydrator/hotel';

export default class HotelModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new HotelHydrator({
            hotel: '_hotel'
        });
        this.export = false;

        this._prefix = 'id';
        this._defaults = {
            _hotel: {}
        };

        this.reset();
    }

    setHotel (hotel) {
        this._hotel = hotel;
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${this._hotel.api ? this._hotel.api + '-' : ''}${this._hotel.id.join(',')})`;
    }
}