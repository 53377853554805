import BaseModel from './base';
import DestinationsModel from './destinations';
import CityModel from './city';
import AirportsModel from './airports';
import TraveltypeModel from './traveltype';
import IntervalModel from './interval';
import PersonsModel from './persons';
import OperatorModel from './operator';
import RoomsModel from './rooms';
import CateringModel from './catering';
import NameModel from './name';
import HotelModel from './hotel';
import OfferModel from './offer';
import CodeModel from './code';
import CategoryModel from './category';
import PriceModel from './price';
import RatingModel from './rating';
import KeywordsModel from './keywords';
import CruisesModel from "./cruises";

export default class SearchModel {
    constructor() {
        this.destinations = new DestinationsModel();
        this.cruises = new CruisesModel();
        this.offer = new OfferModel();
        this.code = new CodeModel();
        this.hotel = new HotelModel();
        this.city = new CityModel();
        this.airports = new AirportsModel();
        this.traveltype = new TraveltypeModel();
        this.interval = new IntervalModel();
        this.persons = new PersonsModel();
        this.operator = new OperatorModel();
        this.rooms = new RoomsModel();
        this.catering = new CateringModel();
        this.name = new NameModel();
        this.category = new CategoryModel();
        this.price = new PriceModel();
        this.rating = new RatingModel();
        this.keywords = new KeywordsModel();
    }

    init(searchObject = {}) {
        this.each(parameter => {
            parameter.init(searchObject);
        });
        return this;
    }

    each(lambda) {
        if (typeof lambda === 'function') {
            Object.values(this).forEach(parameter => {
                if (parameter instanceof BaseModel) {
                    lambda.call(this, parameter);
                }
            });
        }
    }

    isDefault() {
        return Object.values(this).filter(parameter => parameter instanceof BaseModel).every(parameter => parameter.isDefault());
    }
}
