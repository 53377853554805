export default {
    template: `
        <div class="vip-stoerer" v-bind:class="{logged: state === VipState.isLogged}">
            <div class="skbtn live-button scroll-button hidden">
               <a href="https://mediathek.sonnenklar.tv/aktuell-auf-sonnenklartv" target="_blank">
                    <i class="icon icon-xl icon-stream"></i>Live im TV
                </a>
            </div>
            <div class="skbtn live-button mobile-button" v-bind:class="{hidden: !isMobile()}">
               <a href="https://mediathek.sonnenklar.tv/aktuell-auf-sonnenklartv" target="_blank">
                    <i class="icon icon-xl icon-stream"></i>
                </a>
            </div>
            <div v-if="state === VipState.isLogged" class="is-logged">
                <a :href="extLinkLogged">
                    <div class="vip-logo-mobile">
                        <span>
                            <img :src="vipClubLogo">
                        </span>
                    </div>

                    <div class="vip-logo">
                        <img :src="vipClubLogo">
                        <span>?@ name @</span>
                    </div>
                </a>
                <i class="far fa-external-link vip-logout-icon" aria-hidden="true" v-if="(state & VipState.isLogged) > 0" @click="logout()"></i>
            </div>
            <div v-if="state === VipState.isNotLogged" class="is-not-logged">
                <a :href="extLink">
                    <img :src="vipClubLogo2">
                    <span>VIP CLUB</span>
                </a>
            </div>
        </div>
    `,
    data: function () {
        return {
            blacklist: [
                'rundreisen',
                'kreuzfahrt',
                'ferienwohnungen',
                'gutscheine',
                'tv/skireisen'
            ],
            VipState: Object.freeze({
                none: 0,
                isLogged: 1,
                isNotLogged: 2
            }),
            state: VipState.none,
            name: '',
            vipHint: 'kostenfreie Beratung & Buchung täglich von 09:00 - 22:00 Uhr',
            extLink: 'https://vip.sonnenklar.tv/',
            extLinkLogged: 'https://vip.sonnenklar.tv/meine-vorteile.html',
            extLinkLogout: 'https://vip.sonnenklar.tv/ausloggen.html?logintype=logout ',
            vipClubLogo: (window.SKTV && window.SKTV.cdnHost || '') + '/typo3conf/ext/theme/Resources/Public/Images/vip-logo.png',
            vipClubLogo2: (window.SKTV && window.SKTV.cdnHost || '') + '/typo3conf/ext/theme/Resources/Public/Images/vip-logo-ausgeloggt.png',
            vipPhone: '0800 - 0123334',
            vipChPhone: '00800 - 12333400',
        };
    },
  methods: {
        checkBlacklist() {
            let hosts = window.location.host
                .replace('http://', '')
                .replace('https://', '')
                .split(".");

            this.blacklist.forEach(function (value, key) {
                return hosts.includes(value);
            });
        },
        checkLogin() {
            if (this.state === VipState.none) {
                var cookie = document.cookie.split(';').filter(c => c.trim().split("=")[0] === 'sk_vipclub');

                if (cookie.length > 0) {
                    var encryptedData = cookie[0].split("=")[1];
                    $.ajax({
                        url: '/ajax/decryptCookie',
                        type: 'POST',
                        context: this,
                        data: {encrypted: encryptedData},
                        success: function (data, textStatus, jqXHR) {
                            if (data.response !== false) {
                                var splitted = data.response.split('|');
                                if (splitted.length >= 3) {
                                    this.name = splitted[1] + ' ' + splitted[2];
                                    this.state = VipState.isLogged;

                                    this.showVipHotline();
                                }
                            }
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.error(textStatus); // jshint ignore:line
                        }
                    });
                }
            }
            this.state = VipState.isNotLogged;
        },
        logout() {
            window.location.href = this.extLinkLogout;
        },
        getCookie(name) {
            const cName = name + '=';
            const cookies = document.cookie.split(';');
            for(let i = 0; i <= cookies.length; i++) {
                let cookie = cookies[i];
                while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
                if (cookie.indexOf(cName) === 0) return cookie.substring(cName.length, cookie.length);
            }
            return null;
        },
        deleteCookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.sonnenklar.tv';
        },
        showVipHotline() {
          if (this.state === VipState.isLogged) {
              var footerBar = document.getElementsByClassName("footer-bar");

              if (footerBar.length > 0) {
                  var tel = footerBar[0].getElementsByClassName("tel");

                  if (tel.length > 0) {
                      tel[0].innerHTML = '<i class="fas fa-phone-alt"></i> ' + this.vipPhone;
                  }
              }

              this.$emit('set-vip-state', true);
              this.$emit('show-vip-hotline', this.vipPhone, this.vipChPhone);
          }
        },
        handleScroll(event) {
          const offsetTop = window.scrollY || 0;
          const percentage = (offsetTop * 100) / document.body.scrollHeight;
          var stickyHeader = document.querySelector('.sticky-header--visible');
          var scrollButton = stickyHeader.querySelector('.scroll-button');
          scrollButton.classList.toggle('hidden', percentage < 2);
        },
        isMobile() {
            return window.innerWidth < 960;
        }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
        window.addEventListener("scroll", this.handleScroll);
        if (this.checkBlacklist() === true) {
            this.state = VipState.none;
        } else {
            this.checkLogin();
        }
  },
};











