// Our custom function to get all siblings
let getSiblings = function (e) {
    // for collecting siblings
    let siblings = [];
    // if no parent, return no sibling
    if(!e.parentNode) {
        return siblings;
    }
    // first child of the parent node
    let sibling  = e.parentNode.firstChild;

    // collecting siblings
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== e) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
};

let previewCalendarSwitchButtons = document.querySelectorAll('.previewCalendar__day--days--nextWeekWrap, .previewCalendar__day--days--previousWeekWrap');

for (let button of previewCalendarSwitchButtons) {
    let siblings = getSiblings(button);
    button.addEventListener('click', function () {
        siblings.forEach(function (currentSibling) {
            if(currentSibling.classList.contains('hideOnMobile')) {
                currentSibling.classList.remove('hideOnMobile');
                currentSibling.classList.add('animate__animated', 'animate__fadeIn');
            } else {
                currentSibling.classList.add('hideOnMobile');
            }
        });
        button.classList.add('hideOnMobile');
    });

    button.addEventListener('animationend', () => {
        siblings.forEach(function (currentSibling) {
            currentSibling.classList.remove('animate__animated', 'animate__fadeIn');
        });
    });
}

let previewCalendarDays = document.querySelectorAll('.previewCalendar__day');
let previewCalendarDaysOutsideTrigger = document.querySelectorAll('.modal__outsideTrigger');

$(document).ready(function () {
    let url = window.location.href;
    url = url.split('#')[0];
    for (let day of previewCalendarDays) {
        let currentButton = day.querySelector('.button__link');
        if (currentButton !== null && currentButton.hasAttribute('href')) {
            let buttonHref = currentButton.getAttribute('href');
            let newHref = url + buttonHref;
            currentButton.setAttribute('href', newHref);
        }
    }
    for (let trigger of previewCalendarDaysOutsideTrigger) {
        let triggerHref = trigger.getAttribute('href');
        let newHref = url + triggerHref;
        newHref = newHref.split('#')[0] + '#X';
        trigger.setAttribute('href', newHref);
    }
    let xlinks = document.querySelectorAll('.modal__close');
    for(let currlink of xlinks){
        let url = window.location.href;
        url = url.split('#')[0] + '#X';
        currlink.setAttribute('href', url);
    }
});
