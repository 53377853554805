import SearchObject from "../../SearchObject";

export default {

template: `
  <div class="container" @mousedown="click()">
    <div class="icon"><i class="fa fa-map-marker"></i></div>
    <div class="text">
      <p class="headline" v-html="headline"></p>
      <p class="subline" v-html="subline"></p>
    </div>
    <div class="arrow"><i class="fa fa-chevron-right"></i></div>
  </div>
`,

  name: "ItemLocation",
  data() {
    return {
      searchObj: new SearchObject(),
    };
  },
  computed: {
    headline() {
      return this.boldIt(this.$attrs.item.title, this.$attrs.needle);
    },
    subline() {
      let loc = this.$attrs.item.locations[0];
      return this.boldIt(loc.Country, this.$attrs.needle);
    }
  },
  methods: {
    click() {
      this.$emit('submitLocation');
    }
  }
};
