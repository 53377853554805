import ucStatus from '../utils/ucstatus';

export default {
    name: 'UCStatusView',
    components: {},
    template: `
      <div v-if="!ucStatus" style="color: black" class="uc-embedding-wrapper" ref="ucStatusLayer">
      <h3>Wir benötigen Ihre Zustimmung, um den Google Maps-Service zu laden!</h3>
      <p class="description-text">Wir verwenden einen Service eines Drittanbieters, um Karteninhalte einzubetten. Dieser Service kann Daten zu Ihren Aktivitäten sammeln. Bitte lesen Sie die Details durch und stimmen
        Sie der Nutzung des Service zu, um diese Karte anzuzeigen.</p>
      <div class="uc-embedding-buttons">
        <button onClick="UC_UI.showSecondLayer(); return false;" class="uc-embedding-more-info">Mehr Informationen</button>
        <button @click="reloadGoogleMaps()" class="uc-embedding-accept">Akzeptieren</button>
      </div>
      </div>
    `,
    data() {
        return {
            ucStatus: ucStatus()
        };
    },
    methods: {
        reloadGoogleMaps() {
            UC_UI.acceptService('S1pcEj_jZX').then(
                this.$parent.reloadMap()
            );
        }
    }
};
