import DropdownComponent from './components/dropdown';
import RatingsComponent from './components/ratings';

const _cache = {};

export default {
    data: () => {
        return {
            parsers: {
                countries(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'land';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                regions(data) {
                    let filtered = [];

                    const iterator = (region, topregion) => {
                        if (typeof region === 'string') {
                            return region;
                        }

                        if (!region.regionen && !region.length) {
                            return topregion + ' | ' + region.name;
                        }

                        if (region.length) {
                            for (let i = 0; i < region.length; i++) {
                                const result = iterator(region[i], topregion);
                                if (typeof result === 'string') {
                                    region.splice(i, 1, result);
                                } else {
                                    for (let j = 0; j < result.length; j++) {
                                        region.splice(i++ + j, 0, result[j])
                                    }
                                }

                            }
                            return region;
                        }

                        return iterator(region.regionen.region, (topregion ? topregion + ' | ' : '') + region.name);


                        // return region.regionen ? {
                        //         label: region.name,
                        //         value: region['@attributes'].id,
                        //         regions: region.regionen.region.length ? region.regionen.region.map(iterator) : [iterator(region.regionen.region)]
                        //     } : {
                        //         label: region.name,
                        //         value: region['@attributes'].id
                        //     };
                    };
                    try {
                        data.Resultset.laender.land.regionen.region.forEach((region) => {

                        });
                    } catch (e) {}
                    return [...filtered];
                },
                places(data) {
                    let filtered = [];
                    try {
                        filtered = data.Resultset.laender.land.orte.ort;
                    } catch (e) {}
                    return [...filtered];
                },
                persons(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'personen';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                bedrooms(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'schlafzimmer';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                types(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'haustyp';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                accommodations(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'ausstattung';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                pricesFrom(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'preisvon';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                pricesTo(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'preisbis';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                departure(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'datvon';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                durations(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'reisedauer';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                seadistance(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'meer';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
                skilift(data) {
                    const filtered = data.Resultset.formular.field.filter((field) => {
                        return field['@attributes'].name === 'skilift';
                    });
                    return filtered.length ? filtered[0].option : [];
                },
            },
            dropdowns: {
                countries: false,
                regions: false,
                places: false,
                persons: false,
                bedrooms: false,
                types: false,
                accommodations: false,
                pricesFrom: false,
                pricesTo: false,
                departure: false,
                durations: false,
                seadistance: false,
                skilift: false,
            },
            options: {
                countries: [],
                regions: [],
                places: [],
                persons: [],
                bedrooms: [],
                types: [],
                accommodations: [],
                pricesFrom: [],
                pricesTo: [],
                departure: [],
                durations: [],
                seadistance: [],
                skilift: [],
            },
            selected: {
                countries: null,
                regions: null,
                places: null,
                persons: null,
                bedrooms: null,
                types: null,
                accommodations: null,
                pricesFrom: null,
                pricesTo: null,
                departure: null,
                durations: null,
                seadistance: null,
                skilift: null,
                pets: false,
                internet: false,
                pool: false,
                terrace: false,
                parking: false,
                tv: false,
                dishwasher: false,
                aircondition: false,
                nonsmoker: false,
                washer: false,
                sauna: false,
                grill: false,
                fish: false,
                chimney: false,
                whirlpool: false,
                boat: false,
                category: null
            },
            defaults: {
                countries: null,
                regions: null,
                places: null,
                persons: null,
                bedrooms: null,
                types: null,
                accommodations: null,
                pricesFrom: null,
                pricesTo: null,
                departure: null,
                durations: null,
                seadistance: null,
                skilift: null,
                pets: false,
                internet: false,
                pool: false,
                terrace: false,
                parking: false,
                tv: false,
                dishwasher: false,
                aircondition: false,
                nonsmoker: false,
                washer: false,
                sauna: false,
                grill: false,
                fish: false,
                chimney: false,
                whirlpool: false,
                boat: false,
                category: null
            },
            parameters: {
                countries() {
                    const country = this.getSelectedOption('countries');
                    return country ? `land=${country['@attributes'].value}` : false;
                },
                regions() {
                    const region = this.getSelectedOption('regions');
                    return region ? `region=${region['@attributes'].value}` : false;
                },
                places() {
                    const place = this.getSelectedOption('places');
                    return place ? `ort=${place['@attributes'].value}` : false;
                },
                persons() {
                    const person = this.getSelectedOption('persons');
                    return person ? `personen=${person['@attributes'].value}` : false;
                },
                bedrooms() {
                    const bedroom = this.getSelectedOption('bedrooms');
                    return bedroom ? `schlafzimmer=${bedroom['@attributes'].value}` : false;
                },
                types() {
                    const type = this.getSelectedOption('types');
                    return type ? `haustyp=${type['@attributes'].value}` : false;
                },
                accommodations() {
                    const accommodation = this.getSelectedOption('accommodations');
                    return accommodation ? `ausstattung=${accommodation['@attributes'].value}` : false;
                },
                pricesFrom() {
                    const price = this.getSelectedOption('pricesFrom');
                    return price ? `preisvon=${price['@attributes'].value}` : false;
                },
                pricesTo() {
                    const price = this.getSelectedOption('pricesTo');
                    return price ? `preisbis=${price['@attributes'].value}` : false;
                },
                departure() {
                    const date = this.getSelectedOption('departure');
                    return date ? `datvon=${date['@attributes'].value}` : false;
                },
                durations() {
                    const days = this.getSelectedOption('durations');
                    return days ? `reisedauer=${days['@attributes'].value}` : false;
                },
                seadistance() {
                    const distance = this.getSelectedOption('seadistance');
                    return distance ? `meer=${distance['@attributes'].value}` : false;
                },
                skilift() {
                    const extra = this.getSelectedOption('skilift');
                    return extra ? `skilift set=${extra['@attributes'].value}` : false;
                },
                pets() {
                    return this.selected.pets ? 'tiere=1' : false;
                },
                internet() {
                    return this.selected.internet ? 'internet=1' : false;
                },
                pool() {
                    return this.selected.pool ? 'pool=1' : false;
                },
                terrace() {
                    return this.selected.terrace ? 'terrasse=1' : false;
                },
                parking() {
                    return this.selected.parking ? 'parkplatz=1' : false;
                },
                tv() {
                    return this.selected.tv ? 'tv=1' : false;
                },
                dishwasher() {
                    return this.selected.dishwasher ? 'geschirr=1' : false;
                },
                aircondition() {
                    return this.selected.aircondition ? 'klima=1' : false;
                },
                nonsmoker() {
                    return this.selected.nonsmoker ? 'nichtraucherhaus=1' : false;
                },
                washer() {
                    return this.selected.washer ? 'waschmasch=1' : false;
                },
                sauna() {
                    return this.selected.sauna ? 'sauna=1' : false;
                },
                grill() {
                    return this.selected.grill ? 'grill=1' : false;
                },
                fish() {
                    return this.selected.fish ? 'angeln=1' : false;
                },
                chimney() {
                    return this.selected.chimney ? 'kamin=1' : false;
                },
                whirlpool() {
                    return this.selected.whirlpool ? 'whirlpool=1' : false;
                },
                boat() {
                    return this.selected.boat ? 'bootsverleih=1' : false;
                },
                category() {
                    return this.selected.category != null ? `rating=${this.selected.category}` : false;
                }
            }
        };
    },
    props: {
        traveltype: {
            default() {
                return '';
            }
        },
        expanded: {
            default() {
                return false;
            }
        },
        expandSearch: {
            default() {
                return () => {
                    this.expanded = !this.expanded;
                };
            }
        }
    },
    methods: {
        getBinaryOptions() {
            return Object.values(this.defaults)
        },
        getSelectedOption(type) {
            return this.options[type][this.selected[type] !== null ? this.selected[type] : this.defaults[type]];
        },

        getSearchParams() {
            return Object.values(this.parameters).map(parameter => parameter.call(this)).filter(parameter => parameter);
        },

        submitSearch(event) {
            const el = event.currentTarget;
            if (el.classList) {
                el.classList.add('is--busy');
            } else {
                el.className += ' is--busy';
            }

            const base = 'http://sonnenklar.staging.e-kolumbus.de/115611000000/';
            const parameters = [
                'fuseaction=search.main',
                'search=true'
            ]
                .concat(this.getSearchParams()).join('&');
            window.location = encodeURI(`${base}?${parameters}`);
        },

        optionHandler(option) {
            return (active) => {
                this.selected[option] = active;
            };
        },

        toggleDropdown(option) {
            return () => {
                for (const key in this.dropdowns) {
                    this.dropdowns[key] = key === option ? !this.dropdowns[key] : false;
                }
            };
        }
    },
    components: {
        dropdown: DropdownComponent,
        ratings: RatingsComponent
    },
    mounted() {
        const $inputs = $('.edomizil .reisezeitraum input'),
            $departure = $inputs.first(),
            $departureTrigger = $departure.closest('.input-and-button-wrap'),
            $arrival = $inputs.last(),
            $arrivalTrigger = $arrival.closest('.input-and-button-wrap');

        $departure.datepicker({
            minDate: moment().add(28, 'days').toDate(),
            onSelect: (selected) => {
                this.selected.departure = selected;
            }
        });
        $arrival.datepicker({
            minDate: moment().add(33, 'days').toDate(),
            onSelect: (selected) => {
                this.selected.arrival = selected;
            }
        });

        $departureTrigger.on('click', function () {
            $departure.datepicker('show');
        });
        $arrivalTrigger.on('click', function () {
            $arrival.datepicker('show');
        });
    }
};