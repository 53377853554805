;(function (window, $) {
    'use strict';
    var Common = {
        loadResourcesAndInit: function () {
            var that = SKTV.Mediathek.Common;
            that.videoDetailPage();
            that.tvArchivPlayers();
            that.tvArchivFilter();
            that.tvArchivDateSelection();
            that.tvArchivSearch();
            that.tvArchivSorting();
            that.tvprogrammHosts();
            that.tvprogrammCategory();
            that.fromTvLiveToTvProgramm();
            SKTV.Slider.static.hotel();
            SKTV.Slider.static.video();

            that.fromTvLiveToTvProgramm();
        },
        videoDetailPage: function () {
            function switchToVideoSearch() {
                $('.notice--error').hide();
                $('.results-section').addClass('active');
                $('.selected-video-section').addClass('inactive');

                SKTV.common.videoJsGallery.pauseAllPlayers();
            }

            if ($('#videodetail').length) {
                //VIDEO DETAIL PAGE: SWITCH TO SEARCH WHEN CHANGING OR TYPING THE VIDEO SEARCH MASK
                $('.ais-search-box input').blur();

                $('.ais-search-box input').keyup(function () {
                    switchToVideoSearch();
                });
                $('.extended-video-search select').change(function () {
                    switchToVideoSearch();
                });

                $('.ais-refinement-list--list').on('click', function () {
                    switchToVideoSearch();
                });

                // INIT VideoJS ON VIDEO DETAIL PAGE
                let videoContainer = document.getElementById('video-container');
                if (videoContainer !== null) {
                    SKTV.Common.videoJsGallery.initVideoSlider(videoContainer.getElementsByClassName('slides')[0], '#video-container');
                }

                // change video title
                $('#videodetail .flex-control-thumbs li').on('click', function () {
                    //change video title when switching videos in the playlist
                    var videotitleChange = $(this).attr('title');
                    $('.videotitle').text(videotitleChange);
                });
            }
        },

        tvArchivPlayers: function () {
            var el = document.getElementsByClassName('tvarchivVideoContainer');

            if (el.length > 0) {
                SKTV.Common.videoJsGallery.initArchiveVideos(el[0]);
            }

            $('body').on("overlay:opened", function () {
                setTimeout(function () {
                    SKTV.Common.videoJsGallery.pauseAllPlayers();
                }, 500);
            });
        },

        extractSourcesForConfig: function ($elem) {
            var $htmlSources = $elem.find('source'),
                sources = new Array($htmlSources.length);

            $htmlSources.each(function (ix, elem) {
                sources[ix] = {
                    type: $(elem).attr('type'),
                    src: $(elem).attr('src'),
                };
            });

            $elem.html('');

            return sources;
        },

        tvArchivFilter: function () {
            var tvProgramFilters = $('#desktopTvProgramm, #mobileTvProgramm');

            if (tvProgramFilters.length === 0) {
                return;
            }

            var $count = $('.sortingListCaption .total-offers'),
                $time = $('.sortingListCaption .filter-time'),
                $header = $('.hotelListSorting'),
                $sortingCriterias = $('.sortingListCriterias');

            function filter($el, skipScroll) {
                var $input = $el,
                    from = $input.data('fromTimestamp'),
                    to = $input.data('toTimestamp'),
                    showAll = from === 0 && to === 0,
                    count = 0;

                $('.filterTable tr').each(function (idx, el) {
                    var $el = $(el),
                        timestamp = $el.data('timestamp');
                    $el[showAll || timestamp >= from && timestamp <= to ? (count++, 'show') : 'hide']();
                });

                $count.text(count + '');
                $sortingCriterias.toggle(count > 1);

                $time.text(showAll ? '' : (
                    '(' + moment(from * 1000).format('HH:mm') + ' - ' + moment(to * 1000).format('HH:mm') + ')')
                );

                if (!skipScroll) {
                    $('html, body').stop(true, false);
                    SKTV.UI.scrollTo($header);
                }

                $(window).trigger('stickyHeader.update');
            }

            tvProgramFilters.change(function (e) {
                SKTV.Common.videoJsGallery.pauseAllPlayers();
                filter($(e.target.tagName.toLowerCase() === 'select' ? e.target.options[e.target.selectedIndex] : e.target));
            });

            filter($('#desktopTvProgramm').find(':checked'), true);
        },

        tvArchivDateSelection: function () {

            $('#daysSelection').on('change', function () {
                var searchDate = $(this).find(':selected').data('searchdate');
                window.location.href = '/tv-archiv/' + searchDate + '.html';
            });
        },
        tvArchivSearch: function () {
            $('#tvArchivSuche').on('submit', function (e) {
                var inputValue = $('#input-filter-video').val();
                var url;

                e.preventDefault();

                url = (inputValue !== '') ? '/tv-archiv/' + inputValue + '.html' : '/tv-archiv.html';

                window.location.href = url;
            });

            $('#button-filter-video').on('click', function () {
                $('#tvArchivSuche').submit();
            });
        },
        tvArchivSorting: function () {
            var $toggles = $('#date-sorting, #price-sorting'),
                $entries = $('.filterTable tr'),
                $wrap = $entries.parent();

            function getValue(el, field) {
                return field === 'date' ? $(el).data('timestamp') : parseInt($(el).find('.price-wrapper').text().replace(/[^\d]/g, ''), 10);
            }

            $toggles.click(function (e) {
                var $self = $(this),
                    field = $self.is('#date-sorting') ? 'date' : 'price',
                    hasDirection = $self.hasClass('asc') || $self.hasClass('desc'),
                    direction = $self.hasClass('desc') || !hasDirection ? 1 : -1;

                $entries.detach().sort(function (a, b) {
                    var valueA = getValue(a, field),
                        valueB = getValue(b, field);

                    return (valueA === valueB ? 0 : valueA < valueB ? -1 : 1) * direction;
                }).appendTo($wrap);

                $toggles.not($self).removeClass('asc desc');
                if (!hasDirection) {
                    $self.addClass('asc');
                } else {
                    $self.toggleClass('asc desc');
                }

                $(window).trigger('stickyHeader.update');
                e.preventDefault();
            });
        },

        tvprogrammHosts: function () {
            $('#tvphosts').on('change', function () {
                var host = $(this).find(':selected').val();
                $('#tvpcategories > option[value="category"]').prop('selected', 'selected');

                if (host === 'host') {
                    $('.list-element').show();
                } else {
                    $('.list-element').hide();

                    $('.list-element').each(function () {
                        if (host === $(this).data('tvphost')) {
                            $(this).show();
                        }
                    });
                }

            });
        },
        tvprogrammCategory: function () {
            $('#tvpcategories').on('change', function () {
                var cat = $(this).find(':selected').val();

                $('#tvphosts > option[value="host"]').prop('selected', 'selected');

                if (cat === 'category') {
                    $('.list-element').show();
                } else {
                    $('.list-element').hide();

                    $('.list-element').each(function () {
                        if (cat === $(this).data('tvptitle')) {
                            $(this).show();
                        }
                    });
                }

            });
        },

        fromTvLiveToTvProgramm: function () {
            var paramHost = $('#paramHost').val();
            var paramCat = $('#paramCat').val();

            $('#tvphosts > option').each(function () {
                if ($(this).val() === paramHost) {
                    $(this).prop('selected', 'selected').change();
                }
            });

            $('#tvpcategories > option').each(function () {
                if ($(this).val() === paramCat) {
                    $(this).prop('selected', 'selected').change();
                }
            });
        }
    };

    window.SKTV.Mediathek = window.SKTV.Mediathek || {};
    window.SKTV.Mediathek.Common = Common;

})(window, $);
