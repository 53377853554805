var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"ctime ctimeB\" style=\"background-color: "
    + alias4(((helper = (helper = helpers.bgColor || (depth0 != null ? depth0.bgColor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bgColor","hash":{},"data":data}) : helper)))
    + "; color: "
    + alias4(((helper = (helper = helpers.textColor || (depth0 != null ? depth0.textColor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"textColor","hash":{},"data":data}) : helper)))
    + "\">\n    TAGE\n    <br>\n    <span id=\"countdownc1\" class=\"days\">"
    + alias4(((helper = (helper = helpers.days || (depth0 != null ? depth0.days : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"days","hash":{},"data":data}) : helper)))
    + "</span>\n</div>\n\n<div class=\"ctime ctimeB ctimeP\" style=\"background-color: "
    + alias4(((helper = (helper = helpers.bgColor || (depth0 != null ? depth0.bgColor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bgColor","hash":{},"data":data}) : helper)))
    + "; color: "
    + alias4(((helper = (helper = helpers.textColor || (depth0 != null ? depth0.textColor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"textColor","hash":{},"data":data}) : helper)))
    + "\">\n    STUNDEN\n    <br>\n    <span id=\"countdownc2\" class=\"minute\">"
    + alias4(((helper = (helper = helpers.hours || (depth0 != null ? depth0.hours : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hours","hash":{},"data":data}) : helper)))
    + "</span>\n</div>\n";
},"useData":true});