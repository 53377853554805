import BaseModel from './base';
import IntervalHydrator from '../hydrator/interval';

export default class IntervalModel extends BaseModel {
    constructor() {
        super();
        this._dateFormat = 'DD.MM.YYYY';

        this._hydrator = new IntervalHydrator({
            fromDate: '_from',
            toDate: '_to',
            minDuration: '_minDays',
            maxDuration: '_maxDays',
            isExact: '_isExact'
        });

        this._prefix = 'zeitraum';
        this._defaults = {
            _from: moment().add(10, 'days').format(this._dateFormat),
            _cruiseFrom: 'all',
            _cruiseFromFirst: moment().add(5, 'days').format(this._dateFormat),
            _cruiseTo: 'all',
            _cruiseToFirst: moment().add(17, 'days').format(this._dateFormat),
            _cruiseDuration: [{label: 'Beliebige Dauer', value: 'all', selected: false}],
            _cruiseDurationText: 'Beliebige Dauer',
            _cruiseArrivalFrom: moment().add(5, 'days').format(this._dateFormat),
            _cruiseArrivalTo: moment().add(4, 'years').format(this._dateFormat),
            _cruiseDepartureFrom: moment().add(5, 'days').format(this._dateFormat),
            _cruiseDepartureTo: moment().add(4, 'years').format(this._dateFormat),
            _cruiseArrivalFromBackup: null,
            _cruiseArrivalToBackup: null,
            _cruiseDepartureFromBackup: null,
            _cruiseDepartureToBackup: null,
            _to: moment().add(10, 'days').add(3, 'months').format(this._dateFormat),
            _minDays: 'beliebig',
            _maxDays: null,
            _isExact: false,
            durationInput: null,
            duration: null
        };

        this.reset();
    }

    _getDays() {
        let days = this._minDays;
        if (this._maxDays) {
                days += '-' + this._maxDays;
        }
        return this._isExact ? 'exakt' : days;
    }

    _getDaysNoRepetition() {
        let days = this._minDays;
        if (this._maxDays) {
            if(this._maxDays != this._minDays){
                days += '-' + this._maxDays;
            }
        }
        return this._isExact ? 'exakt' : days;
    }

    _setDays(code) {
        if(code.includes('-')){
            let days = code.split('-');
            this._minDays = days.shift();
            this._maxDays = days.shift() || null;
            this._isExact = this._minDays === 'exakt';
        } else {
            this._setDaysNumber(code);
        }
    }

    selectDurationOption(duration){
        if(this._getDays() !== (duration + '-' + duration)) {
            this._setDays(String(duration));
            this.durationInput = duration + ' Tage';
            this.duration = duration;
        } else {
            this.durationInput = 'Beliebige Reisedauer';
            this.duration = null;
            this.resetDuration()
        }
    }

    _setDaysNumber(number){
        this._setDays(number + '-' + number);
    }

    dayString() {
        const days = this._getDays();

        return days == 'beliebig' ? 'Reisedauer beliebig' :
            days == 'exakt' ? 'Reisedauer exakt' :
                days.indexOf('-') >= 0 ? (days + ' Tage').replace('-', ' - ') :
                    days === 1 ? days + ' Tag' : days + ' Tage';
    }

    getDuration() {
        let daysArray = this._getDays().split('-');
        return this._getDays().includes( '-') ? (daysArray[0] == daysArray[1] ? daysArray[0] + ' Tage' : daysArray[0] + ' - ' + daysArray[1] + ' Tage') :  ! isNaN(this._getDays().trim()) ? 'Reisezeitraum' : this._getDays();
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${this._from}-${this._to},${this._getDaysNoRepetition()})`;
    }

    resetDuration() {
        this._setDays('beliebig');
    }

    resetDate() {
        this.reset();
    }
}
