// main entry point

$(document).ready(function () {

    SKTV.UI.init();
    SKTV.Slider.init();
    SKTV.SearchPresse.init();

    SKTV.Common.backToTopButton();
    SKTV.Common.initStickySidebar();
    SKTV.Common.removeBlinkingCursor();
    SKTV.Common.initStrikeprice();

    SKTV.Navigation.init();

    SKTV.Newsletter.init();

    SKTV.Promo.init();
    SKTV.Optionsbuchung.init();
    SKTV.Common.initSEOLinks();
    SKTV.Common.initOffersearch();
    SKTV.UserSiteMap.init();

    SKTV.Common.addFramedContentAdditions();
});

// wait that everything is loaded
$(window).load(function () {
    SKTV.Tvlive.init();
});

// handle window resize
$(window).resize(function () {

});
