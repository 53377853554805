import RegionSorting from './model/region';
import MetasearchSorting from './model/metasearch';

const SKTV = window.SKTV || (window.SKTV = {});

let sorting = RegionSorting;
let selector = '.current-step2 .sortingListCriterias';
if ($('.multisearch').length) {
    sorting = MetasearchSorting;
    selector = '.multisearch .sortingListCriterias';
}
SKTV.sorting = new sorting().init({el: selector});