import HotelInfo from './components/hotel-info';
import VipStoerer from '../FixedHeader/components/vip-stoerer';
import ContactModalButton from './components/contact-modal-button';
import ContactModalModal from './components/contact-modal-modal';
import {VTooltip, VPopover, VClosePopover} from 'v-tooltip';
import eventbus from "../FixedHeader/components/eventbus";


Vue.component('sticky-header', {
    components: {
        HotelInfo,
        VipStoerer,
        VTooltip,
        VPopover,
        ContactModalButton,
        ContactModalModal,
        VClosePopover
    },
    template: `
      <div>
      <div class="sticky-header" :class="{'sticky-header--visible': visible}">
      <div class="sticky-header__content">
        <div class="sticky-header__logo">
          <a href="/" title="sonnenklar.TV - zur Startseite">
            <img alt="sonnenklar.TV" :src="logoPath">
          </a>
        </div>
        <div class="sticky-header__info">
          <hotel-info :name="hotelname" :category="category"></hotel-info>
        </div>

        <div class="sticky-header__contact">
          <span v-if="vipState==true">VIP Hotline</span>
          <a :href="'tel:' + formatNumber(phone.de)">?@ phone.de @</a>

          <v-popover
              placement="left-start"
              delay="100"
          >
            <div class="sticky-header__dummy tooltip-target"></div>

            <template slot="popover">
              <!-- Standard user popover -->
              <div class="tooltip-content sticky-header__tooltip" v-if="!vipState">
                <p class="sticky-header__service-text" v-if="service.text">
                  ?@ service.text @
                </p>
                <table class="sticky-header__phone">
                  <tr v-if="phone.de">
                    <td>DE: </td><td><i class="fas fa-phone-alt"></i></td><td><a :href="'tel:+49' + formatNumber(phone.de)">
                    ?@ phone.de @</a></td>
                  </tr>
                  <tr v-if="phone.at">
                    <td>AT: </td><td><i class="fas fa-phone-alt"></i></td><td><a :href="'tel:+43' + formatNumber(phone.at)">
                    ?@ phone.at @</a></td>
                  </tr>
                  <tr v-if="phone.ch">
                    <td>CH: </td><td><i class="fas fa-phone-alt"></i></td><td><a :href="'tel:+41' + formatNumber(phone.ch)">
                    ?@ phone.ch @</a></td>
                  </tr>
                </table>
                <p class="sticky-header__service-hint" v-if="service.hint">
                  ?@ service.hint @
                </p>
              </div>

              <!-- VIP user popover -->
              <div class="tooltip-content sticky-header__tooltip" v-else>
                <p class="sticky-header__service-text">
                  Kostenfreie Beratung und Buchung, täglich von 08:00 - 24:00 Uhr
                </p>
                <div class="sticky-header__phone">
                  <p><b>VIP HOTLINE: <a href="tel:8000123334">0800 - 0123334</a></b></p>
                  <p><b>(AT / CH: <a href="tel:008000123334">00800 - 0123334</a>)</b></p>
                </div>
              </div>
            </template>
          </v-popover>
        </div>
        <div class="skbtn live-button" v-bind:class="{hidden : !isMobile()}">
            <a href="https://mediathek.sonnenklar.tv/aktuell-auf-sonnenklartv" target="_blank">
                <i class="icon icon-xl icon-stream"></i>
            </a>
        </div>
        <div class="sticky-header__quicksearch mobile-search-button" v-on:click="openMobileSearch">
          <i class="fas fa-search"></i>
        </div>
        <div class="phone-icon-mobile left-buffer" onclick="
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            document.querySelector('.sticky-header').classList.remove('sticky-header--visible');
            let cm = document.querySelector('#contact-modal');
            cm.style.visibility = 'visible';
            let htbd = document.querySelector('.main-wrap');
            htbd.appendChild(cm);
            cm.addEventListener('click', function(evt){

                if(evt.target === document.querySelector('#contact-modal')){
                    document.getElementsByTagName('body')[0].style.overflow = 'auto';
                    document.querySelector('#contact-modal').style.visibility = 'hidden';
                }
            });
            let mCloser = document.querySelector('#closeCModal');
            mCloser.addEventListener('click', function(evt){
                document.getElementsByTagName('body')[0].style.overflow = 'auto';
                document.querySelector('#contact-modal').style.visibility = 'hidden';
            });
        ">
          <i class="fas fa-phone-alt"></i>
        </div>
        <vip-stoerer @show-vip-hotline="showVipHotline" @set-vip-state="setVipState" v-show="step === 0"></vip-stoerer>
      </div>
      </div>
      </div>`,

    data() {
        return {
            visible: false,

            elements: null,

            hotelname: '',

            category: 0,

            vipState: false,

            service: {
                text: '',
                hint: ''
            },

            step: 0,

            phone: {
                de: '',
                at: '',
                ch: ''
            },

            logoPath: (window.SKTV && window.SKTV.cdnHost || '') + '/typo3conf/ext/theme/Resources/Public/Images/logo.svg',
        };
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler, {
            passive: true
        });

        window.addEventListener('resize', this.resizeHandler, {
            passive: true
        });

        $(window).on('stickyHeader.update', this.updateHandler);

        this.readContentFromDOM();

        this.updateElements();

        this.getCurrentIbeStep();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.resizeHandler);
        $(window).off('stickyHeader.update', this.updateHandler);
    },

    methods: {
        openMobileSearch() {
            eventbus.$emit('open-canvas-search');

            let mobileView = document.getElementById('sk-input-quicksearch-mobile');
            if(mobileView !== null){
                mobileView.click();
            }
        },

        scrollHandler() {
            const viewport = this.elements.root.el.getBoundingClientRect();
            const obsolete = viewport.width !== this.elements.root.width || viewport.height !== this.elements.root.height;
            this.updateElements({
                skipInit: !obsolete,
                skipRefresh: !obsolete
            });
        },

        getCurrentIbeStep() {
            if (document.getElementById('sktv-search')) {
                const match = document.getElementById('sktv-search').className.match(/current-step(\d?)/i);
                if (match && match[1]) {
                    this.step = parseInt(match[1], 10);
                }
            }
        },

        resizeHandler() {
            this.updateElements({skipInit: true, skipRoot: true});
        },

        updateHandler({skipInit = false, skipRoot = true, skipRefresh = false} = {}) {
            this.updateElements({skipInit, skipRoot, skipRefresh});
        },

        readContentFromDOM() {
            function getText(query) {
                const el = document.querySelector(query);
                return el ? el.innerText : '';
            }
            function setText(query, text) {
                const el = document.querySelector(query);
                if (el) {
                    el.innerText = text;
                }

            }
            let text = getText('#sp-down').replace(/\*+/g, '');
            if (this.vipState) {
                text = 'kostenfreie Beratung und Buchung, täglich von 08:00 - 24:00 Uhr';
            } else {
                text = text.replace('24:00 Uhr', '23:00 Uhr');
            }

            setText('#sp-down', text);

            this.service.text = text;
            this.service.hint = getText('#service-hint').replace(/\*+/g, '');

            if (this.vipState === false) {
                this.phone.de = getText('#hotline').trim();
                this.phone.at = getText('#sp-up a:nth-child(1)').trim();
                this.phone.ch = getText('#sp-up a:nth-child(2)').trim();
            }
        },

        initElements() {
            this.elements = Object.freeze({
                root: {
                    el: document.compatMode === 'CSS1Compat'
                        ? document.documentElement
                        : document.body
                },
                header: {
                    el: document.querySelector('header > .row')
                },
                items: [...document.querySelectorAll('[data-hotel-name][data-hotel-category]')].map((el) => ({
                    el,
                    hotel: el.getAttribute('data-hotel-name'),
                    category: parseFloat(el.getAttribute('data-hotel-category')) || 0
                }))
            });

            if (!/\bsk-body\b/.test(document.body.className) || !this.elements.header.el) {
                this.$destroy(true);
            }
        },

        refreshElements(opts) {
            opts = Object.assign({
                skipRoot: false
            }, opts);

            const scrollTop = window.pageYOffset || this.elements.root.el.scrollTop;

            function refreshElement(element) {
                if (element.el) {
                    const rect = element.el.getBoundingClientRect();
                    element.top = rect.top + scrollTop;
                    element.height = rect.height;
                    element.width = rect.width;
                }
            }

            if (!opts.skipRoot) {
                refreshElement(this.elements.root);
            }

            refreshElement(this.elements.header);
            this.elements.items.forEach((item) => refreshElement(item));
        },

        updateElements(opts) {
            opts = Object.assign({
                skipInit: false,
                skipRefresh: false,
                skipRoot: false
            }, opts);

            if (!opts.skipInit || !this.elements) {
                this.initElements();
            }

            if (!opts.skipRefresh) {
                this.refreshElements({skipRoot: opts.skipRoot});
            }

            const scrollTop = window.pageYOffset || this.elements.root.el.scrollTop;

            if (this.elements.items.length) {
                const items = this.elements.items.filter((item) => item.width && item.height);
                let i = 0;
                for (; i < items.length; i += 1) {
                    if (items[i].top > scrollTop + 65) {
                        break;
                    }
                }
                i = i > 0 ? i - 1 : i;
                if (items.length === 0 || i > 0 && i === items.length - 1 && items[i].top + items[i].height < scrollTop) {
                    this.hotelname = '';
                    this.category = 0;
                } else {
                    this.hotelname = items[i].hotel;
                    this.category = items[i].category;
                }
            }

            this.visible = scrollTop > this.elements.header.height;
        },

        formatNumber(number) {
            var cleaned = number.replace(/\D/g, '');

            if (cleaned.substr(0, 1) == 0) {
                return cleaned.substr(1);
            } else {
                return cleaned;
            }
        },

        showVipHotline(num1, num2) {
            this.phone.de = num1;
            this.phone.ch = num2;
            this.phone.at = num2;
        },

        setVipState(value) {
            this.vipState = value;
            this.readContentFromDOM();
        },
        isMobile() {
          // check if screen less than 599px
          return window.innerWidth < 599;
        }
    },
});

document.addEventListener('DOMContentLoaded', () => {
    const el = document.createElement('div');
    document.body.appendChild(el);

    Vue.config.delimiters = ['?@', '@'];
    new Vue({
        el: el,
        template: '<sticky-header></sticky-header>'
    });
});


document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector('#pType') !== null){
        document.querySelector('.hotel-info__name').style.visibility = 'visible';
        document.querySelector('.hotel-info__category').style.visibility = 'visible';
    }
});
