import Vue from 'vue';

export default Vue.component('hotel-select-box', {
    name: 'HotelSelectBox',
    template: `
        <div class="select-wrapper" ref="selectContainer">
            <select id="hotellist" ref="hotellist" name="hotelGiataId" v-on:change="onHotelChange($event)" >
                <option v-for="hotel in hotels" :value="hotel.id" :data-giata="hotel.giataid">?@ hotel.name @</option>
            </select>
        </div>`,
    props: {
        hotels: Array
    },
    methods: {
        init: function() {
            const hotelSelect = this.$refs.hotellist;
            const selectContainer = this.$refs.selectContainer;
            if (this.hotels.length === 1) {
                hotelSelect.setAttribute("disabled", "false");
                selectContainer.classList.remove('select-wrapper');
                selectContainer.classList.add('select-wrapper-for-disabled');
            }
        },
        onHotelChange: function(event) {
            const activeHotel = this.hotels.filter((value) => {
                return value.id === event.target.value;
            });
            this.$root.$emit('activeHotelChanged', {hotel: activeHotel[0]});
        }
    },
    mounted() {
        this.init();
    }
});
