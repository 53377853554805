import BaseHydrator from './base';

export default class PriceHydrator extends BaseHydrator {
    hydrate(data, model) {
        for (let key in data) {
            if (key in this._dataMap) {
                model[this._dataMap[key]] = (data[key] || 0) + '';
            }
        }

        return model;
    }

    dehydrate(model) {
        const ret = {};
        for (let key in this._dataMap) {
            ret[key] = model[this._dataMap[key]] + ''
        }

        return ret;
    }
}