import BaseHydrator from './base';

export default class PersonsHydrator extends BaseHydrator {
    hydrate(data, model) {
        for (let key in data) {
            if (key in this._dataMap) {
                if (data[key] == null) {
                    continue;
                }

                if (key === 'adults') {
                    model[this._dataMap[key]] = parseInt(data[key], 10);
                } else {
                    model[this._dataMap[key]] = data[key];
                }
            }
        }

        return model;
    }
}