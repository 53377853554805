import BaseModel from './base';
import RatingHydrator from '../hydrator/rating';

export default class RatingModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new RatingHydrator({
            countReviews: '_countReviews',
            recommendation: '_recommendation'
        });
        this.export = false;

        this._prefix = 'bewertung';
        this._defaults = {
            _countReviews: '0',
            _recommendation: '0'
        };

        this.reset();
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${this._countReviews},${this._recommendation})`;
    }
}