import SearchObject from "../../SearchObject";


export default {

template: `
  <div class="container" @mousedown="click" v-bind:title="title">
    <div class="icon"><i class="fa fa-tv"></i></div>
    <div class="text">
      <p class="headline" v-html="headline"></p>
      <p class="subline" v-html="subline"></p>
    </div>
    <div class="arrow"><i class="fa fa-chevron-right"></i></div>
  </div>
`,

  name: "ItemOfferEuvia",
  data() {
    return {
      searchObj: new SearchObject(),
    };
  },
  computed: {
    title(){
      return this.$attrs.item.title || '';
    },
    headline() {
      return this.boldIt(`${this.$attrs.item.title}`, this.$attrs.needle);
    },
    subline() {
      return this.boldIt(`Bestellnummer  ${this.$attrs.item.orderNumber}`, this.$attrs.needle);
    }
  },
  methods: {
    click() {
      this.searchObj.openWindow(this.$attrs.item);
    }
  }
};
