export default {
    props: {
        cruiseAreaPart: {
            default() {
                return [];
            }
        },
        cruisesModel: {
            default() {
                return null;
            }
        }
    },
    data () {
        return {

        }
    },
    methods: {

    },
    template: `
      <div class="col col3">
          <div class="cruise-area-search-checkbox" v-for="region in cruiseAreaPart">
            <input type="checkbox" :id="cruisesModel.cruiseArea.cruisePrefix + region.code.toLowerCase()"
                   :name="cruisesModel.cruiseArea.cruisePrefix + region.code.toLowerCase()" :value="cruisesModel.cruiseArea.cruisePrefix + region.code"
                   :checked="region.checked"
                   @change="cruisesModel.select(region.code, 'area', false)">
            <label :for="cruisesModel.cruiseArea.cruisePrefix + region.code.toLowerCase()">
              <span>?@ region.label @</span>
            </label>
            <div class="cruise-areas-content">
              <div class="cruise-area-search-checkbox" v-for="subValue in region.subValues">
                <input type="checkbox" :id="cruisesModel.cruiseArea.cruisePrefix + subValue.code.toLowerCase()"
                       :name="cruisesModel.cruiseArea.cruisePrefix + subValue.code.toLowerCase()" :value="cruisesModel.cruiseArea.cruisePrefix + subValue.code"
                       :checked="subValue.checked"
                       @change="cruisesModel.select(subValue.code, 'area')">
                <label :for="cruisesModel.cruiseArea.cruisePrefix + subValue.code.toLowerCase()">
                  <span>?@ subValue.label @</span>
                </label>
                <div class="cruise-area-search-checkbox sub-checkbox" v-if="subValue.subValues.length > 0">
                  <div class="cruise-area-search-checkbox"
                       v-for="subSubValue in subValue.subValues">
                    <input type="checkbox" :id="cruisesModel.cruiseArea.cruisePrefix + subSubValue.code.toLowerCase()"
                           :name="cruisesModel.cruiseArea.cruisePrefix + subSubValue.code.toLowerCase()" :value="cruisesModel.cruiseArea.cruisePrefix + subSubValue.code"
                           :checked="subSubValue.checked"
                           @change="cruisesModel.select(subSubValue.code, 'area')">
                    <label :for="cruisesModel.cruiseArea.cruisePrefix + subSubValue.code.toLowerCase()">
                      <span>?@ subSubValue.label @</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    `
};
