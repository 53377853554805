;(function (window, $) {
    'use strict';
    var Optionsbuchung = {
		init: function () {
			this.loginCookies();

		},
		loginCookies: function(){

			// COOCKIES for unsuccessful login attempts
			var loginattempt = $('input.login-attempts').val();

			//First unsuccessful Login (set first coockie) #########
			if (loginattempt >= '1' && loginattempt <= '4') {
				Cookies.set('loginattempt', loginattempt);
			}

			//locked
			else if ($('.locked').is(":visible")) {
				var date = new Date();
				date.setTime(date.getTime() + (12 * 60 * 60 * 1000));
				Cookies.set('loginattempt', '5', { expires: date });
			}
			//successful login, reset cookies
			else if ($('.optionsBuchung .uebersicht-wrap').is(":visible")) {
				$.removeCookie('loginattempt');
			}
		}
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.Optionsbuchung = Optionsbuchung;

})(window, $);
