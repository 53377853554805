var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.euviaData),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/tabs-description-content.hbs"),depth0,{"name":"tabs-description-content","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <li class=\""
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " row operator-hotel-description-content tabcontent\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "active";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <li class=\"row operator-hotel-description-content tabcontent\">\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"hotelTabsDescription\">\n    <aside>\n        <div class=\"operators row\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/operators-list.hbs"),depth0,{"name":"operators-list","hash":{"id":"Description"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"operators-alt row\">\n            <div class=\"operators-alt-selected\">\n                <img src=\"fileadmin/sonnenklar/admin/logos/"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/toLowerCase.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.listData : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.operator : stack1),{"name":"toLowerCase","hash":{},"data":data}))
    + ".png\" alt=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.listData : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.operatorName : stack1), depth0))
    + "\">\n                <button class=\"operators-trigger\">\n                    <i class=\"fas fa-caret-down\"></i>\n                </button>\n            </div>\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/operators-list.hbs"),depth0,{"name":"operators-list","hash":{"id":"DescriptionAlt"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"descriptionContentWrap row\">\n            <ul class=\"operator-hotel-description-list\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.listData : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </aside>\n</div>";
},"usePartial":true,"useData":true});