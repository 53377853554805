import Vue from 'vue';
window.Vue = Vue;
import HotelInfo from '../StickyHeader/components/hotel-info';
import ContactModalButton from '../StickyHeader/components/contact-modal-button';
import ContactModalModal from '../StickyHeader/components/contact-modal-modal';
import {VTooltip, VPopover, VClosePopover} from 'v-tooltip';
import VipStoerer from './components/vip-stoerer';
import eventbus from "./components/eventbus"
import OfferSearch from "../OfferSearch/components/Search/OfferSearch";
import SearchObject from "../OfferSearch/components/SearchObject";

document.addEventListener('DOMContentLoaded', () => {
    Vue.config.delimiters = ['?@', '@'];
    new Vue({
        el: '#fixedHeader',

        components: {
            HotelInfo,
            VTooltip,
            VPopover,
            VClosePopover,
            VipStoerer,
            OfferSearch,
            ContactModalButton,
            ContactModalModal,
        },

        data() {
            return {
                elements: null,

                isOpen: false,
                isSearchActive: false,

                vipState: false,

                service: {
                    text: '',
                    hint: ''
                },

                phone: {
                    de: '',
                    at: '',
                    ch: ''
                },

                searchObject: new SearchObject(window.searchObj || {})
            };
        },

        mounted() {
            this.$refs.vPopover.popoverClass = 'fixed-header-popover';

            window.addEventListener('scroll', this.scrollHandler, {
                passive: true
            });

            window.addEventListener('resize', this.resizeHandler, {
                passive: true
            });

            $(window).on('fixedHeader.update', this.updateHandler);

            this.readContentFromDOM();

            this.updateElements();
        },

        beforeDestroy() {
            window.removeEventListener('scroll', this.scrollHandler);
            window.removeEventListener('resize', this.resizeHandler);
            $(window).off('stickyHeader.update', this.updateHandler);
        },

        methods: {
            scrollHandler() {
                const viewport = this.elements.root.el.getBoundingClientRect();
                const obsolete = viewport.width !== this.elements.root.width || viewport.height !== this.elements.root.height;
                this.updateElements({
                    skipInit: !obsolete,
                    skipRefresh: !obsolete
                });
            },

            resizeHandler() {
                this.updateElements({skipInit: true, skipRoot: true});
            },

            updateHandler({skipInit = false, skipRoot = true, skipRefresh = false} = {}) {
                this.updateElements({skipInit, skipRoot, skipRefresh});
            },

            readContentFromDOM() {
                function getText(query) {
                    const el = document.querySelector(query);
                    return el ? el.innerText : '';
                }
                function setText(query, text) {
                    const el = document.querySelector(query);
                    if (el) {
                        el.innerText = text;
                    }

                }
                let text = getText('#sp-down').replace(/\*+/g, '');
                if (this.vipState) {
                    text = text.replace('23:00 Uhr', '24:00 Uhr');
                } else {
                    text = text.replace('24:00 Uhr', '23:00 Uhr');
                }
                setText('#sp-down', text);

                this.service.text = text;
                this.service.hint = getText('#service-hint').replace(/\*+/g, '');

                if (this.vipState !== true) {
                    this.phone.de = getText('#hotline').trim();
                    this.phone.at = getText('#sp-up a:nth-child(1)').trim();
                    this.phone.ch = getText('#sp-up a:nth-child(2)').trim();
                }
            },

            initElements() {
                this.elements = Object.freeze({
                    root: {
                        el: document.compatMode === 'CSS1Compat'
                            ? document.documentElement
                            : document.body
                    },
                    header: {
                        el: document.querySelector('header > .row')
                    },
                });

                if (!/\bsk-body\b/.test(document.body.className) || !this.elements.header.el) {
                    this.$destroy(true);
                }
            },

            refreshElements(opts) {
                opts = Object.assign({
                    skipRoot: false
                }, opts);

                const scrollTop = window.pageYOffset || this.elements.root.el.scrollTop;

                function refreshElement(element) {
                    if (element.el) {
                        const rect = element.el.getBoundingClientRect();
                        element.top = rect.top + scrollTop;
                        element.height = rect.height;
                        element.width = rect.width;
                    }
                }

                if (!opts.skipRoot) {
                    refreshElement(this.elements.root);
                }

                refreshElement(this.elements.header);
            },

            updateElements(opts) {
                opts = Object.assign({
                    skipInit: false,
                    skipRefresh: false,
                    skipRoot: false
                }, opts);

                if (!opts.skipInit || !this.elements) {
                    this.initElements();
                }

                if (!opts.skipRefresh) {
                    this.refreshElements({skipRoot: opts.skipRoot});
                }

                const scrollTop = window.pageYOffset || this.elements.root.el.scrollTop;

                this.visible = scrollTop > this.elements.header.height;
            },

            formatNumber(number) {
                var cleaned = number.replace(/\D/g, '');

                if (cleaned.substr(0, 1) == 0) {
                    return cleaned.substr(1);
                } else {
                    return cleaned;
                }
            },

            openNavigation() {
                this.isSearchActive = false;

                let ulFirst = document.querySelector('ul.first');

                if (typeof (ulFirst) != 'undefined') {
                    ulFirst.style.display = 'block';
                }

                this.isOpen = !this.isOpen;

                if (this.isOpen === true) {
                    this.showOverlay();
                } else {
                    this.hideOverlay();
                }
            },

            showOverlay() {
                let navMobileOverlay = document.getElementsByClassName('nav-mobile')[0].getElementsByClassName('overlay')[0];
                navMobileOverlay.classList.add('openTouch');
            },

            hideOverlay() {
                this.isOpen = false;

                let navMobileOverlay = document.getElementsByClassName('nav-mobile')[0].getElementsByClassName('overlay')[0];
                navMobileOverlay.classList.remove('openTouch');

                let ulFirst = document.querySelector('ul.first');

                if (typeof (ulFirst) != 'undefined') {
                    ulFirst.style.display = 'none';
                }
            },

            openMobileSearch() {
                eventbus.$emit('open-canvas-search');

                let mobileView = document.getElementById('sk-input-quicksearch-mobile');
                if(mobileView !== null){
                    mobileView.click();
                }
            },

            showVipHotline(num1, num2) {
                this.phone.de = num1;
                this.phone.ch = num2;
                this.phone.at = num2;
                document.querySelector('#sp-up').innerHTML = '<a href="tel:0' + this.phone.de + '">' + 'AT/CH: 0'+ this.phone.de + '</a>';
                document.querySelector('#hotline').innerHTML = this.phone.de;
            },

            setVipState(value) {
                this.vipState = value;
                this.readContentFromDOM();
            }

        },
    });
});
