import Vue from 'vue';
window.Vue = Vue;
import HotelMapLink from './../HotelMap/components/hotel-map-link';
import HotelSelectBox from './components/hotel-select-box';

Vue.mixin({ delimiters: ['?@','@'] });
document.addEventListener("DOMContentLoaded", function(event) {
    if(document.getElementById('hotel-select')) {
        new Vue({
            el: '#hotel-select',
            components: {HotelSelectBox, HotelMapLink},
            template: `
              <ul class="hotel-info-header" v-if="hotels && hotels.length > 0 && this.activeHotel !== null">
              <li v-if="!this.activeHotel.iscruise"><h2>Hotelinformationen</h2></li>
              <li v-if="this.activeHotel.iscruise"><h2>Schiffsinformationen</h2></li>
              <li>
                <hotel-select-box :hotels="hotels" />
              </li>
              <li v-if="activeHotel">
                <hotel-map-link :hotelname="activeHotel.name" :category="activeHotel.category" :region="activeHotel.region" :ibe-link="ibeLink"
                                :longitude="parseFloat(activeHotel.longitude)" :latitude="parseFloat(activeHotel.latitude)"/>
              </li>
              </ul>`,
            data: function () {
                const el = document.getElementById('hotel-select');
                return {
                    hotels: hotelsForSelect,
                    activeHotel: null,
                    ibeLink: el.dataset.ibelink
                };
            },
            mounted() {
                this.$root.$on('activeHotelChanged', (data) => {
                    this.activeHotel = data.hotel;
                    const elList = document.getElementsByClassName('hotel-content');
                    for(let i = 0; i < elList.length; i++) {
                        let el = elList[i];
                        if(el.classList.contains('active')) {
                            el.classList.remove('active');
                        }
                        if (el.classList.contains('hotel-content-' + this.activeHotel.id)) {
                            el.classList.add('active');
                        }
                    }
                });
                if (this.hotels && this.hotels.length > 0) {
                    this.activeHotel = this.hotels[0];
                    this.$root.$emit('activeHotelChanged', {hotel: this.activeHotel});
                }
            }
        });
    }
});
