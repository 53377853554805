;(function (window, $) {
    'use strict';

    var IBE = {
        resultList: $('table.resultList'),
        bestPriceWidget: $('#bestprice'),
        totalResultCount: $('#totalResultCount').data('total'), // store total result count of initial page load.
        bestPriceData: false,
        filterCriteria: $('li.filterCriteria'),     // items of blue bar at top to filter
        paginationCount: 20,                         // number of results to fetch
        currentPage: 2,                         // pagination counter for dynamic loaded list items
        ratingAccordion: false,                        // rating accordion
        ratingUiState: {},                            // ratingUiState will be initialized in ratingsTab()
        cachedRegionInfos: {},                      // cache regioninfo ajax data
        cachedHotelInfos: {},                       // cache hotelinfo ajax data
        cachedHotelRatings: {},                     // cache hotelratings ajax data
        cachedCheckAvailability: {},                     // cache checkAvailability ajax data,
        fetchedResultIds: [],
        init: function () {
            this.common.initAppointmentsSlider();
            this.common.filtering.regionList();
            this.hotelPages.tabs();
            this.bestPrice();
            this.initTooltipster();
            this.bindTooltipsterCloser();
            this.setVisaLinks();
            this.bindNationality();
            this.bindPriceButton();
            this.initPagination();
            this.preventNavigationFromIframe();
        },
        common: {
            /**
             * This function toggles a button state and an additional dom element.
             *
             * @param btn {mixed[string|object]} selector or the element itself (this) of the button to toggle
             * @param idToToggle {string} id (or selector) of element(s) to toggle
             */
            toggleButton: function (btn, idToToggle) {
                $(btn).toggleClass('open');

                if (idToToggle) {
                    $(idToToggle).toggleClass('visible');
                }
            },
            /**
             * This function toggles dom element.
             *
             * @param idToToggle {string} id (or selector) of element(s) to toggle
             */
            toggleID: function (idToToggle) {
                if (idToToggle) {
                    $(idToToggle).toggleClass('visible');
                }
            },
            /**
             * This function toggles a tab and controls the visibility of depending content elements (css class .tab-content).
             *
             * @param currentTab {object} the current clicked (this) tab element
             * @param id {string} selector of scope in dom
             * @param content {string} selector of element in scope to show
             */
            toggleTabs: function (currentTab, id, content) {
                var $currentTab = $(currentTab),
                    $id = $(id),
                    // $content = $(id).find(content),
                    idx = $currentTab.is('.tabs-content-toggle') ? $currentTab.index('.tabs-content-toggle') : $currentTab.parent().index();

                $id.find('.infotabs li').removeClass('active').eq(idx).addClass('active');
                $id.find('.tabs-content-toggle').eq(idx).toggleClass('active').siblings('.tabs-content-toggle').removeClass('active');
                $id.find('.tab-content').eq(idx)[$currentTab.is('.tabs-content-toggle') ? 'toggleClass' : 'addClass']('active').siblings('.tab-content').removeClass('active');

                // Needed for correct Operators Carousel view
                $(window).trigger('resize');
            },
            /**
             * This function initialzes an given slider element only once.
             */
            initAppointmentsSlider: function () {
                var $sliderImages = $('.sliderImages'),
                    $sliderCarousel = $('.sliderCarousel');

                if ($sliderImages.length > 0 && $sliderCarousel.length > 0) {

                    $sliderImages.flexslider({
                        animation: 'slide',
                        controlNav: false,
                        prevText: '',
                        nextText: '',
                        slideshow: false,
                        sync: $sliderCarousel,
                        start: function () {
                            SKTV.UI.bLazy.revalidate();
                        },
                        after: function () {
                            SKTV.UI.bLazy.revalidate();
                        }
                    });

                    $sliderCarousel.flexslider({
                        animation: 'slide',
                        controlNav: false,
                        prevText: '',
                        nextText: '',
                        slideshow: false,
                        itemWidth: 50,
                        itemMargin: 0,
                        minItems: 6,
                        maxItems: 6,
                        asNavFor: $sliderImages,
                        start: function () {
                            SKTV.UI.bLazy.revalidate();
                        },
                        after: function () {
                            SKTV.UI.bLazy.revalidate();
                        }
                    });

                    // Needed for correct Operators Carousel view, sliders in overlay need also some delay
                    setTimeout(function () {
                        $(window).trigger('resize');
                    }, 1000);
                }
            },
            filtering: {
                regionList: function () {
                    // destination list (suchen/weltweit/...) => filter regions with filterregion widget
                    $('#input-filter-region').filterregion();
                },
            },
            /**
             * Toggle opened infobuttons and remove all media boxes in dom.
             */
            removeMediaBoxes: function () {
                //$('.open').toggleClass('open');
                $('.ibe-list-infobox').remove();
            },
            /**
             * build uri string from searchobject. a stringified object in uri won't work.
             *
             * @param obj
             * @returns {string}
             */
            getUriFromSearchOject: function (obj) {
                var params = [];
                $.each(obj, function (index, value) {
                    params.push(index + '(' + value + ')');
                });
                return params.join('/');
            },

            displayLightboxFlightData: function () {
                $('body').overlayBase({
                    content: $('#flightsinfo')[0].outerHTML(),
                    style: 'style="max-width:600px;height:450px;"'
                });
            },
        },
        xhr: {
            /**
             * getInfo - get media-/infobox
             *
             * @param evt {object} event object of the dom element
             * @param opts {object} object with properties for giataId, specialId, operator
             * @param trigger {string} selector for tab
             */
            getInfoBox: function (evt, opts, trigger) {
                evt.stopPropagation();

                var id = '';
                if (opts.euviaOrderId) {
                    id = opts.euviaOrderId;
                } else if (opts.giataId === '') {
                    if (opts.specialId || opts.specialOperator) {
                        id = opts.specialId || opts.edvCode + opts.specialOperator;
                    } else if (opts.edvCode && opts.operator) {
                        id = opts.edvCode + opts.operator;
                    }
                } else {
                    if (opts.specialId || opts.giataId) {
                        id = opts.specialId || opts.giataId;
                    }
                }

                var guid = SKTV.Common.getSaveGUID(id);

                var regionSkId = opts.regionSkId;

                // prepare ajax params
                var params = [];

                if (opts.giataId > 0) {
                    params.push('ids_giata=' + opts.giataId);
                }

                if (opts.regionSkId) {
                    params.push('regionSkId=' + opts.regionSkId);
                }

                if (opts.operator) {
                    params.push('operator=' + opts.operator);
                }

                if (opts.fromDate) {
                    params.push('fromDate=' + opts.fromDate);
                }

                if (opts.edvCode) {
                    params.push('edvCode=' + opts.edvCode);
                }

                function renderData(listData) {
                    initContent(SKTV.templates.hotelinfos(listData));
                }

                function initContent(html) {
                    $('body').overlayBase({
                        content: html
                    });

                    SKTV.IBE.xhr.initSliders();
                    SKTV.UI.bLazy.revalidate();

                    if (trigger) {
                        // activate/trigger needed tab on hotelinfobox
                        $('a.' + trigger).trigger('click');
                    }

                    if (SKTV.Tracking && SKTV.Tracking.DTM) {
                        SKTV.Tracking.DTM.track('ProdView', {
                            products: SKTV.Tracking.DTM.formatId({
                                giata: opts.giataId,
                                edv: opts.edvCode
                            }),
                            events: 'prodView'
                        });
                    }
                }

                if ($('#hotelinfo').length === 0) {
                    SKTV.IBE.common.removeMediaBoxes();
                    var cachedHotelInfo = SKTV.IBE.cachedHotelInfos[id];
                    if (cachedHotelInfo) {
                        renderData(cachedHotelInfo.listData);
                    } else {
                        $.ajax({
                            url: 'ajax/getHotelInfo?' + params.join('&'),
                            type: 'GET',
                            dataType: 'json',
                            beforeSend: function (xhrObj) {
                                $('#item-' + guid).after(SKTV.templates.layerajax({id: guid}));
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                console.log('Error > xhr.getInfoBox: ', jqXHR, textStatus, errorThrown);
                            },
                            success: function (data) {
                                $.extend(data.listData, {
                                        id: id,                 // id for handlebars template
                                        hotline: $('#hotline').text(),
                                        regionSkId: regionSkId,
                                        ibestep: opts.ibestep
                                    },
                                    opts);

                                if (data.listData.euviaData || data.listData.total > 0) {
                                    if (data.listData.euviaData) {
                                        data.listData.listData = [data.listData.euviaData];
                                    }
                                    SKTV.IBE.cachedHotelInfos[id] = data;
                                    renderData(data.listData);
                                } else {
                                    $('<div id="info-' + guid + '" class="ibe-list-infobox row visible"><b><i class="fa fa-exclamation-circle red"></i> Aktuell sind von diesem Veranstalter keine Hotelinformationen verfügbar.</b></div>').insertAfter('#item-' + guid);
                                }
                            }
                        }).always(function () {
                            $('#layer-' + guid).remove();
                        });

                    }
                }
            },
            getInfoBoxWithDescriptionSite: function (evt, opts) {
                evt.stopPropagation();

                $('body').overlayBase({
                    content: '<iframe id="iframeLB" width="100%" height="100%" class="description-frame" ' +
                    'src="' + opts.url + '" ' +
                    'frameborder="0"></iframe>'
                });
            },

            getLightboxFlightData: function (event, obj) {
                SKTV.IBE.showFlightDetails(event, SKTV.IBE.cachedCheckAvailability[obj.id].apiResponse.offer.flightOffer);
            },
            initSliders: function () {
                var $operators = $('.ui-widget-overlay-wrap').find('.operators');
                $operators.each(function () {
                    var $that = $(this);
                    if ($that.find('img').length > 1) {
                        $that.flexslider({
                            animation: 'slide',
                            controlNav: false,
                            slideshow: false,
                            itemWidth: 100,
                            itemMargin: 2
                        });
                    }
                });

                $('.gallerybox').each(function (index, slidersWrap) {
                    var $images = $(slidersWrap).find('.smallSliderImages');

                    $images.flexslider({
                        animation: 'slide',
                        controlNav: (window.ismobile) ? false : 'thumbnails',
                        start: function () {
                            SKTV.UI.bLazy.revalidate();
                        },
                        after: function () {
                            SKTV.UI.bLazy.revalidate();
                        }
                    });

                });
                // Needed for correct Operators Carousel view, sliders in overlay need also some delay
                setTimeout(function () {
                    $(window).trigger('resize');
                }, 1000);
            },
            getInfoBoxRegion: function (regionId) {
                if ($('#hotelinfo').length === 0) {
                    var cachedRegionInfo = SKTV.IBE.cachedRegionInfos[regionId];
                    if (cachedRegionInfo) {
                        $('body').overlayBase({
                            content: SKTV.templates.regioniteminfos(cachedRegionInfo),
                        });
                        return;
                    }

                    $.ajax({
                        url: '/ajax/getRegionInfo/' + regionId,
                        type: 'GET',
                        beforeSend: function (xhrObj) {
                            $('#item-' + regionId).append(SKTV.templates.layerajax({id: regionId}));
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            $('#layer-' + regionId).remove();
                            console.log('Error > xhr.getInfoBox: ', jqXHR, textStatus, errorThrown);
                        },
                        success: function (data) {
                            $('#layer-' + regionId).remove();
                            data.regionId = regionId;
                            SKTV.IBE.cachedRegionInfos[regionId] = data;
                            $('body').overlayBase({
                                content: SKTV.templates.regioniteminfos(data)
                            });
                        }
                    });
                }
            },
            /**
             * getMoreItems - loads the next items in the result list on hotel and date result lists
             *
             * @param btn {object} that triggered this function
             * @param forwardUrl if we are on promo-pages we need to pass the uriService-url
             */
            getMoreItems: function (btn, forwardUrl) {
                // prevent nervous clicking users ;-)
                $(btn).prop('disabled', true);

                var pageRgx = /\/seite\(\d+,(\d+)\)/ig,
                    nameRgx = /\/name\([\w\s\d%]+\)/ig,
                    currentUrl = window.location.pathname,
                    currentParams = window.location.search.substr(1),
                    $sortingLinks = $('.sortingListCriterias a'),
                    $moreButton = $('.ibe-bottom-nav .morebutton'),
                    hotelNameSearch = $('#hotelname-search').val();

                currentUrl = (typeof forwardUrl === 'string' && forwardUrl.trim() !== '' ? forwardUrl : currentUrl).replace(nameRgx, '').replace(pageRgx, '');

                if (hotelNameSearch) {
                    currentUrl += '/name(' + encodeURIComponent(hotelNameSearch) + ')';
                }

                currentUrl += '/seite(' + IBE.currentPage + ',' + IBE.paginationCount + ')';

                if (currentUrl.indexOf(window.location.host) !== -1) {
                    currentUrl = currentUrl + '?' + currentParams;
                } else {
                    currentUrl = window.location.origin + currentUrl + '?' + currentParams;
                }

                function disablePagination() {
                    $moreButton
                        .remove();
                }

                function enablePagination() {
                    $moreButton
                        .prop('disabled', false)
                        .html('<i class="fa fa-refresh fa-spin"></i>weitere Angebote anzeigen <i class="fa fa-caret-down"></i>');
                }

                function updateSorting() {
                    $sortingLinks.each(function (idx, el) {
                        var rgx = /name\([^)]*\)/i,
                            $that = $(el),
                            url = $that.attr('href'),
                            urlHost = url.match(/\/\/[^\/]+/),
                            urlParts = (urlHost ? url.replace(urlHost[0], '') : url).split('/');

                        if (rgx.test(url)) {
                            urlParts = urlParts.map(function (part) {
                                if (!rgx.test(part)) {
                                    return part;
                                }
                                return part.replace(rgx, hotelNameSearch ? 'name(' + hotelNameSearch + ')' : '');
                            });
                        } else if (hotelNameSearch) {
                            urlParts.push('name(' + hotelNameSearch + ')');
                        }

                        $that.attr('href', (urlHost ? urlHost[0] : '') + '/' + urlParts
                            .filter(function (value) {
                                return value !== '';
                            })
                            .join('/'));
                    });
                }

                $.ajax({
                    url: currentUrl,
                    type: 'GET',
                    dataType: 'json',
                    beforeSend: function (xhrObj) {
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('Error > xhr.ajaxGetMoreItems: ', jqXHR, textStatus, errorThrown);
                    },
                    success: function (response) {

                        // trim returned data (whitespaces, linebreaks) to check if it is really empty
                        var html = response.html.trim();

                        // filter result list only when triggered from morebutton when results will be added
                        // otherwise the hotelFullTextSearch is broken
                        if ($(btn).hasClass('morebutton') && response.count) {
                            html = $(html).filter(function (idx, el) {
                                var id = IBE.getResultItemId($(el).find('.data-wrapper'));
                                if (IBE.fetchedResultIds.indexOf(id) < 0) {
                                    IBE.fetchedResultIds.push(id);
                                    return true;
                                }
                            }).map(function (idx, el) {
                                return el.outerHTML;
                            }).toArray().join();
                        }

                        // because we are getting 'only' html markup, lets check if returned data is not empty
                        if (response.count > 0 && html.length) {
                            if (IBE.currentPage === 1) {
                                IBE.resultList.html(html);
                                $('.sortingListCaption span').text(response.count);
                            } else {
                                // show
                                IBE.resultList.append(html);
                            }

                            IBE.currentPage++;

                            $(btn).prop('disabled', false);

                            updateSorting();
                        } else {
                            // if returned data is empty then replace button at the bottom
                            disablePagination();
                        }

                        // give the dom some time :D
                        setTimeout(function () {
                            SKTV.UI.bLazy.revalidate();
                            IBE.initTooltipster();
                        }, 300);


                        // $('.sortingListCaption span').text(SKTV.IBE.totalResultCount);

                        if (response.count > 0 && html.length) {
                            enablePagination();
                        }
                    }
                });
            },
            getBestPriceData: function () {
                $.ajax({
                    dataType: 'json',
                    url: 'ajax/getBestpriceData/' + SKTV.IBE.common.getUriFromSearchOject(contextSearchParams),
                    type: 'GET',
                    beforeSend: function (xhrObj) {

                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('Error > xhr.getBestPriceData: ', jqXHR, textStatus, errorThrown);
                    },
                    success: function (data) {
                        console.log('%c Response Object [getBestPriceData]', 'background:#333;color:#0f0');
                        console.log(data);

                        SKTV.IBE.bestPriceData = data;

                        $(SKTV.IBE.bestPriceWidget).bestprice({
                            data: SKTV.IBE.bestPriceData,
                            callBack: function (selected) {
                                console.log('%c Bestprice Widget callback executed', 'background:#333;color:#0f0');
                                console.log(selected);
                                console.log('%c TODO: what to do now in FE?', 'background:#333;color:#f00');
                            }
                        });

                    }
                });
            },
            /**
             *
             * @param obj {object} object data
             */
            checkAvailability: function (obj) {

                var _obj = obj,
                    id = _obj.uid,   // id for ajax callback in local scope
                    params = {},
                    trackingParams = {
                        eVar29: '+1',
                        eVar50: obj.region,
                        eVar56: moment(obj.fromDate, 'Y-M-D').format('DD.MM.Y'),
                        eVar57: moment(obj.toDate, 'Y-M-D').format('DD.MM.Y'),
                        products: SKTV.Tracking.DTM.formatId({
                            euvia: obj.euviaId,
                            giata: obj.giataId,
                            edv: obj.edvCode
                        }),
                        VakancyType: 'manuell',
                        events: ['event9']
                    };

                if ($('#item-' + id + ' .verfuegbarkeit').hasClass('autovacancy')) {
                    trackingParams.VakancyType = 'auto';
                }

                id = id.replace(/\s/ig, '_');

                params.id = id;
                params.bookingCode = _obj.code;
                params.searchUri = document.location.href;
                params.apiCode = _obj.apiCode;
                params.operator = _obj.operator;
                params.listPrice = _obj.listPrice;
                params.accommodationTypeList = _obj.accommodationTypeList;

                var url = 'buchen/checkAvailability',
                    $listItem = $('#item-' + id),
                    giataId = id.split('-');

                function setButton(headerMsg, btnText) {
                    var headerMsg = headerMsg || 'Das Angebot ist zu diesem Zeitpunkt leider nicht mehr verfügbar',
                        btnText = btnText || 'Leider schon ausgebucht';
                    $listItem.parent().removeClass('unchecked').addClass('notavailable');
                    $listItem.find('.header-bar span').html('<i class="fa fa-thumbs-down"></i> ' + headerMsg);
                    $listItem.find('.btn-common').removeAttr('onclick').html(btnText).css('cursor', 'auto');
                    $listItem.find('.deposit,.optionalBooking').remove();
                    $listItem.find('.price-wrapper').hide();
                    trackingParams.events.push('event11');
                }

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: params,
                    beforeSend: function (xhrObj) {
                        $('#item-' + id).after(SKTV.templates.layeravailability({id: id}));
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        setButton();
                        $('#layer-' + id).remove();
                        console.log('%c checkAvailability error', 'background:#333;color:#f00;font-weight:800');
                        console.log(jqXHR, textStatus, errorThrown);
                    },
                    success: function (data) {
                        SKTV.IBE.cachedCheckAvailability[id] = data;

                        data.apiResponse.hotelData = hotelData;
                        // inject id
                        data.apiResponse.dateid = id;
                        // inject benefits content
                        data.apiResponse.contentBenefits = $('#item-' + id + ' .benefits > ul').html();
                        // inject organizer content
                        data.apiResponse.contentOrganizer = $('#item-' + id + ' .organizer').html();
                        data.apiResponse.skBenefits = $('.services-list > ul').html();

                        var bookingData = {
                            data: data.apiResponse || {},
                            bookingHost: data.bookingHost || '',
                            giataId: giataId[0] || '',
                            searchUri: document.location.pathname,
                            ibestep: 4, // checkavailability is always on ibe step 4
                            uuid: data.uuid
                        };

                        $.extend(bookingData, _obj);

                        if (data.available) {

                            if (!bookingData.data.status) {
                                bookingData.data.status = {};
                            }
                            var listPrice = parseFloat(bookingData.listPrice),
                                pricePerPerson = parseFloat(bookingData.data.pricePerPerson.value);

                            if (listPrice > pricePerPerson) {
                                bookingData.data.status.color = 'green';
                            }
                            else if (listPrice < pricePerPerson) {
                                bookingData.data.status.color = 'black';
                            }

                            var $response = $(SKTV.templates.dateavailable(bookingData)),
                                $timer = $('.valid-timer__value', $response),
                                expirationTime = moment().add(1, 'hour'),
                                timerInterval,
                                updateTimer = function () {
                                    var diff = moment(expirationTime.diff(moment()));
                                    $('input[name=bookingTimer]').val(expirationTime.format('x'));
                                    $timer.text(diff.format('mm:ss'));
                                    if (diff.format('x') < 0) {
                                        $listItem.show();
                                        $response.remove();
                                        clearInterval(timerInterval);
                                    }
                                };

                            $listItem.after($response);
                            $listItem.hide();
                            updateTimer();
                            timerInterval = setInterval(updateTimer, 1000);

                            trackingParams.events.push('event10');
                            var priceDiff = parseFloat(bookingData.data.pricePerPersonDifference.value);
                            if (isNaN(priceDiff)) {
                                priceDiff = bookingData.data.pricePerPerson.value - obj.listPrice;
                            }
                            if (!isNaN(priceDiff) && priceDiff !== 0) {
                                trackingParams.events.push('event16');
                                if (priceDiff > 0) {
                                    trackingParams.events.push('event20');
                                    trackingParams.events.push('event22=' + priceDiff.toFixed(2));
                                }
                                else {
                                    trackingParams.events.push('event21');
                                    trackingParams.events.push('event23=' + (priceDiff * -1).toFixed(2));
                                }
                            }

                            if (bookingData.data.offer.operator.code) {
                                trackingParams.eVar53 = bookingData.data.offer.operator.code;
                            }
                            if (bookingData.data.offer.flightOffer) {
                                if (bookingData.data.offer.flightOffer.departureAirport) {
                                    trackingParams.eVar58 = bookingData.data.offer.flightOffer.departureAirport.code;
                                }
                                if (bookingData.data.offer.flightOffer.arrivalAirport) {
                                    trackingParams.eVar59 = bookingData.data.offer.flightOffer.arrivalAirport.code;
                                }
                            }
                            console.log('%c checkAvailability success', 'background:#333;color:#0f0;font-weight:800');
                        } else {
                            trackingParams.eVar53 = bookingData.operator || '';
                            setButton();
                            console.log('%c checkAvailability fail', 'background:#333;color:#FFA500;font-weight:800');
                        }

                        $('#layer-' + id).remove();

                        console.log({xhrData: data, bookingData: bookingData});
                    },
                    complete: function () {
                        if (SKTV.Tracking && SKTV.Tracking.DTM) {
                            SKTV.Tracking.DTM.reset();
                            SKTV.Tracking.DTM.track('VacancyCheck', trackingParams);
                            console.log('%c checkAvailability trackingParams', 'background:#333;color:#0f0;font-weight:800');
                            console.log({trackingParams: trackingParams});
                        }
                    }
                });
            }
        },
        hotelPages: {
            tabs: function () {

                /**
                 * set current active state of current operators tabs
                 * @param tab
                 */
                function setActiveTab(tab) {
                    var elementRoot = tab.parents('aside')[0],  // find element 'root'
                        tabIndex = tab.parent().index(),
                        tabcontent = $(elementRoot).find('.tabcontent');

                    // tab status setzen
                    tab.parent('li').siblings().removeClass('active');
                    tab.parent('li').addClass('active');

                    // slider li status setzen
                    tabcontent.removeClass('active');
                    tabcontent.eq(tabIndex).addClass('active');
                }

                // handle operators tabs for descriptions
                $(document.body).on('click', '#operatorsTabDescription > li > a', function(e) {
                    e.preventDefault();

                    setActiveTab($(this));

                    $('.smallSliderImages').resize();
                    $('.smallSliderCarousel').resize();
                    SKTV.UI.bLazy.revalidate();
                    return false;
                });

                // handle operator tabs for gallery
                $(document.body).on('click', '#operatorsTabGallery > li > a', function () {
                    setActiveTab($(this));
                    var $gallery = $('.galleryContentWrap .galleryContentList > li.active');

                    if ($gallery.length > 0) {
                        $gallery.flexslider({
                            animation: 'slide',
                            controlNav: 'thumbnails',
                            start: function () {
                                SKTV.UI.bLazy.revalidate();
                            },
                            after: function () {
                                SKTV.UI.bLazy.revalidate();
                                //vertical scroll/slide to active thumbnail image
                                var $container = $('.tabcontent .flex-control-nav:visible'),
                                    $scrollTo = $('.tabcontent .flex-control-nav > li > img.flex-active:visible');
                                if ($container.length && $scrollTo.length) {
                                    $container.animate({
                                        scrollTop: $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
                                    });
                                }
                            }
                        });
                    }

                    SKTV.UI.bLazy.revalidate();
                    return false;
                });

                $(document.body).on('click', '#operatorsTabDescriptionAlt > li, #operatorsTabGalleryAlt > li', function (e) {
                    var $self = $(this),
                        $selfImg = $self.find('img'),
                        $selected = $self.closest('.operators-alt').find('.operators-alt-selected');

                    $self.addClass('active').siblings().removeClass('active');
                    $selected.find('img').attr({
                        src: $selfImg.attr('src'),
                        alt: $selfImg.attr('alt')
                    });

                    if ($self.is('#operatorsTabDescriptionAlt > li')) {
                        $('#operatorsTabDescription').find('> li > a').eq($self.index()).click();
                    } else {
                        $('#operatorsTabGallery').find('> li > a').eq($self.index()).click();
                    }

                    e.preventDefault();
                });

                $(document.body).on('click', '.operators-alt-selected', function () {
                    $(this).toggleClass('active');
                });

                $(document.body).on('click', '.operators-alt .slides li', function () {
                    $(this).closest('.operators-alt').find('.operators-alt-selected').removeClass('active');
                });
            },
            /**
             * {
         *  giataid: 123456,
         *  isAppointmentList: true|false,
         *  type: hc|tt,
         *  iffCode: 123456,
         *  hcId: 123456,
         *  recommendation: 88,
         *  reviewCount: 123
         * }
             *
             * @param opts object
             */
            ratingsTab: function (opts) {
                var _o = opts;

                // set the current review options, ratingsTab is the entry point for all later actions
                SKTV.IBE.ratingUiState = {
                    offset: 0,               // offset for dyn. loaded reviews
                    filter: '',             // single|couple|family|friends
                    giataId: _o.giataId,    // current giataId
                    type: _o.type,          // current type hc|tt
                    iffCode: _o.iffCode,    // current iffCode for tt ratings
                    hcId: _o.hcId          // current hcId for hc ratings
                };

                var requestUrl = '/ajax/getHotelRatings?' + $.param(SKTV.IBE.ratingUiState);
                var totalScore = this.getTotalScore(_o.type);

                function initContent(html) {
                    $('#layer-' + _o.giataId).remove();
                    if (_o.isAppointmentList) {
                        $('body').overlayBase({
                            content: html
                        });
                    } else {
                        $('.tab-content.rating').html(html);
                    }

                    //initialize accordion
                    SKTV.IBE.ratingAccordion = $('#reviewsAccordion');
                    SKTV.IBE.ratingAccordion.accordion({
                        heightStyle: 'content',
                        autoHeight: false,
                        clearStyle: true,
                        collapsible: true
                    });

                    SKTV.IBE.hotelPages.initRatingFilter();

                    // raise offset to load next bunch of ratings
                    SKTV.IBE.ratingUiState.offset++;
                }

                if ($('#hotelinfo div.rating div.ratingsBox').length === 0) {
                    var _params = $.param(SKTV.IBE.ratingUiState),
                        cachedRating = SKTV.IBE.cachedHotelRatings[_params];

                    if (cachedRating) {
                        initContent(SKTV.templates.tabsRatings(cachedRating));
                        return;
                    }

                    var tmpPopup;
                    $.ajax({
                        url: requestUrl,
                        type: 'GET',
                        beforeSend: function (xhrObj) {
                            tmpPopup = $('body').overlayBase({
                                content: '<div class="description-frame"></div>'
                            });

                            $('#hotelinfo').append(SKTV.templates.layerajax({id: _o.giataId}));
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            $('#layer-' + _o.giataId).remove();
                            console.log('Error > xhr.getInfoBox: ', jqXHR, textStatus, errorThrown);
                        },
                        success: function (data) {
                            // extend data from ajax request
                            $.extend(data, SKTV.IBE.ratingUiState, {
                                // set totalScore depending to api hc|tt
                                totalScore: totalScore,
                                // passthru countReviews and recommendation from the corresponding api hc|tt
                                countReviews: _o.countReviews,
                                recommendation: _o.recommendation
                            });

                            // caching ratings using params string as hash
                            SKTV.IBE.cachedHotelRatings[_params] = data;
                            if (tmpPopup.overlayBase('instance')) {
                                tmpPopup.overlayBase('destroy');
                            }
                            initContent(SKTV.templates.tabsRatings(data));
                        }
                    });
                }
            },
            moreReviews: function () {

                var $btn = $('#moreReviewsButton');
                $btn.prop('disabled', true);    // prevent nervous clicking users ;-)

                var _params = $.param(SKTV.IBE.ratingUiState),
                    cachedRating = SKTV.IBE.cachedHotelRatings[_params];

                if (cachedRating) {
                    $btn.prop('disabled', false);
                    var html = SKTV.templates.moreRatings(cachedRating);
                    SKTV.IBE.hotelPages.appendRatings(html);
                    return;
                }

                var totalScore = this.getTotalScore(SKTV.IBE.ratingUiState.type);
                $.ajax({
                    url: '/ajax/getHotelRatings?' + _params,
                    type: 'GET',
                    beforeSend: function (xhrObj) {
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('Error > hotelPages.moreReviews: ', jqXHR, textStatus, errorThrown);
                    },
                    success: function (data) {
                        data.totalScore = totalScore;
                        if (data.reviews === null) {
                            SKTV.IBE.cachedHotelRatings[_params] = data;
                            SKTV.IBE.ratingAccordion.append('<div class="notice notice--warn "><p class="notice__content">Keine' +
                                ' (weiteren) Bewertungen' +
                                ' gefunden</p></div>');
                            $('.morebutton.moreReviewsButton').hide().prop('disabled', false);
                            return;
                        }
                        if (data.reviews !== null && typeof data.reviews === 'object') {
                            SKTV.IBE.cachedHotelRatings[_params] = data;
                            $btn.prop('disabled', false);
                            var html = SKTV.templates.moreRatings(data);
                            SKTV.IBE.hotelPages.appendRatings(html);
                            if (data.reviews.length < 10) {
                                $('.morebutton.moreReviewsButton').hide().prop('disabled', false);
                            }
                        }
                    }
                });
            },
            /**
             * append ratings to accordion and raise offset
             * @param html
             */
            appendRatings: function (html) {
                SKTV.IBE.ratingAccordion.append(html);
                SKTV.IBE.ratingAccordion.accordion('refresh');

                // raise offset to load next bunch of ratings
                SKTV.IBE.ratingUiState.offset++;
            },
            /**
             * initialize the rating filter select
             */
            initRatingFilter: function () {

                $('.filterInput')
                    .on('click', function () {
                        ctrlSelect();  // show select
                    });

                function ctrlSelect(ctrl) {
                    var $filter = $('.filterWrapper');
                    switch (ctrl) {
                        case 'show':
                            $filter.addClass('show');
                            break;
                        case 'hide':
                            $filter.removeClass('show');
                            break;
                        default:
                            $filter.toggleClass('show');
                    }
                }

                $('.ui-widget-overlay-wrap').bind('mouseup', function (e) {
                    var $openDropDown = $('.filterWrapper.show .filterInput');

                    if ($openDropDown.length > 0) {
                        // if the target of the click isn't the container nor a descendant of the container
                        if (!$openDropDown.is(e.target) && $openDropDown.has(e.target).length === 0) {
                            ctrlSelect('hide');
                        }
                    }
                });

                function loadFilteredResults() {
                    // removing ratings in accordion when filtering
                    SKTV.IBE.ratingUiState.offset = 0;  // reset offset
                    SKTV.IBE.ratingAccordion.html('');
                    SKTV.IBE.ratingAccordion.accordion('refresh');
                    SKTV.IBE.hotelPages.moreReviews();
                }

                $('.filter-dropmenu-inner').on('click', 'ul li', function (event) {
                    event.stopPropagation();
                    ctrlSelect('hide'); // hide select
                    SKTV.IBE.ratingUiState.filter = ''; // reset filter
                    $('.morebutton.moreReviewsButton').show();

                    if (!$(this).hasClass('selected')) {
                        $('ul#filters li.selected').removeClass('selected');
                        $(this).addClass('selected');
                        var filterText = $(this).text(),
                            filter = $(this).data('filter');
                        $('#input-review-filter').val(filterText).attr('placeholder', '');
                        SKTV.IBE.ratingUiState.filter = filter;
                    } else {
                        $(this).removeClass('selected');
                        $('#input-review-filter').val('').attr('placeholder', 'verreist als');
                    }

                    loadFilteredResults();

                });
            },
            getTotalScore: function (api) {
                return 6;
            }
        },
        bestPrice: function () {
            if (IBE.bestPriceWidget.length > 0) {
                this.xhr.getBestPriceData();
            }
        },

        initTooltipster: function () {
            $('.js-tooltip:not(.tooltipstered)').each(function () {
                var $el = $(this),
                    zIndex = 0;

                while (!$el.is($el.offsetParent()) && !$el.is('html')) {
                    if ($el.css('zIndex') !== 'auto') {
                        zIndex += parseInt($el.css('zIndex'), 10);
                    }
                    $el = $el.offsetParent();
                }

                if (!zIndex) {
                    zIndex = 9999;
                } else {
                    zIndex += 1;
                }


                $(this).tooltipster({
                    theme: $(this).data('theme') || 'tooltipster-sktv',
                    side: ['bottom', 'top'],
                    trigger: 'ontouchstart' in window || navigator.maxTouchPoints ? 'click' : 'hover',
                    delay: 100,
                    delayTouch: [100, 300],
                    animationDuration: 0,
                    distance: 6,
                    zIndex: zIndex,
                    functionPosition: function (instance, helper, position) {
                        if (helper.geo.window.size.width - position.size.width > helper.geo.origin.offset.left) {
                            position.coord.left = helper.geo.origin.offset.left + helper.geo.origin.size.width / 2 - 16;
                        }
                        return position;
                    }
                });
            });
        },

        bindTooltipsterCloser: function () {
            /* if tooltips exist, add listener to close opened tooltip when trigger disappears due to resizing */
            if (document.getElementsByClassName('js-tooltip').length) {
                window.addEventListener('resize', function () {
                    var openedTrigger = $('.tooltipster-base .tooltip-content').attr('id');

                    if (openedTrigger !== undefined) {
                        $('.tooltipstered').tooltipster('reposition');
                        /* check if trigger element is visible or hidden */
                        if ($('[data-tooltip-content="#' + openedTrigger + '"]').first().height() === 0) {
                            $('.tooltipstered').tooltipster('hide');
                        }
                    }
                });
            }
        },

        setVisaLinks: function ($nationality) {
            ($nationality || $('select[id$="_nationality"]')).each(function () {
                var nationality = $(this).val(),
                    destination = $(this).data('destination');

                if (nationality) {
                    var visaPdfLink = (destination) ? 'https://www.ftigroup-info.com/?&l=DE&c=' + destination + '&n=' + nationality : '/pass-visainformationen.html';

                    $(this).closest('.row').next()
                        .find('.visa-info').attr('href', visaPdfLink).end()
                        .find('.visa-link').val(visaPdfLink).end()
                        .find(':checkbox').attr('required', true).end()
                        .removeClass('disabled-fields');
                }
            });
        },

        bindNationality: function () {
            $(document).on('change', 'select[id$="_nationality"]', function (e) {
                e.preventDefault();
                IBE.setVisaLinks($(e.currentTarget));
            });
        },

        bindPriceButton: function () {
            $(document).on('click', '.price-link-button', function (e) {
                e.preventDefault();
                $(this).siblings('form').submit();
            });

            $(document).on('click', '.price-link', function () {
                var $btn = $(this).closest('.row').find('.verfuegbarkeit');
                if ($btn.length) {
                    $btn.click();
                }
            });
        },
        initPagination: function () {
            var pageInfo = /\/seite\((\d+),(\d+)\)/ig.exec(window.location.href);
            if (pageInfo) {
                this.currentPage = parseInt(pageInfo[1], 10) + 1 || this.currentPage;
                this.paginationCount = parseInt(pageInfo[2], 10) || this.paginationCount;
            }

            this.fetchedResultIds = this.resultList.find('.data-wrapper').toArray()
                .map(function (el) {
                    return IBE.getResultItemId($(el));
                });
        },
        getResultItemId: function ($el) {
            var id = $el.attr('id');
            if (!id) {
                id = $el.closest('[id^=item-]').attr('id');
            }
            return id ? id.replace('item-', '') : '';
        },
        preventNavigationFromIframe: function () {
            if ($('body').hasClass('tmpl-contentonly')) {
                $(document).on('click', '.tmpl-contentonly a', function (e) {
                    var $link = $(e.currentTarget),
                        href = $link.attr('href');
                    if (window.parent !== self && /^(http|\/)/i.test(href)) {
                        window.parent.open(href, $link.attr('target') || '_parent');
                        e.preventDefault();
                    }
                });
            }
        },
        showFlightDetails: function (e, dataset) {

            var $trigger = $(e.target);

            if (!$trigger[0].hasAttribute('id')) {
                $trigger.attr('id', 'flightdetails-' + ($('.flightinfobox').length + 1));
            }

            var $flightsDetailedBox = $('.flightinfobox[data-for="' + $trigger.attr('id') + '"]');


            // if overview already exists -> use this for the overlay
            if ($flightsDetailedBox.length) {
                $('body').overlayBase({content: $flightsDetailedBox.html(), flexHeight: true});
            }
            else {
                $trigger.addClass('loading');

                // otherwise get it ...

                SKTV.Common.getView('flights-detailed', dataset, function (res) {

                    if (res !== false) {

                        // add result to dom for re-view
                        $flightsDetailedBox = $('<div></div>');
                        $flightsDetailedBox.addClass('flightinfobox').attr('data-for', $trigger.attr('id')).hide().html(res);

                        $('body').append($flightsDetailedBox).overlayBase({
                            content: $flightsDetailedBox.html(),
                            flexHeight: true
                        });
                    }

                    $trigger.removeClass('loading');
                });
            }
        }
    };
    window.SKTV = window.SKTV || {};
    window.SKTV.IBE = IBE;

})(window, $);
