/**
 * {{formatDate dateString}} render german date format
 * @todo make this thingy more flexible!?
 *
 * @param string date "yyyy-mm-dd"
 * @return string
 */
module.exports = function (date, options) {
    var _options = {
        format: 'DD.MM.YYYY'
    }

    // check if date format is german
    if (date.match(/(\d{1,2}\.\d{1,2}\.\d{4})/g)) {
        // convert it to english format ISO 8601 for momentjs
        date = date.split('.').reverse().join('-');
    }

    if (options) {
        $.extend(_options, options);
    }
    return moment(date).format(_options.format);
};
