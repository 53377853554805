export default {
    data() {
        return {
            isOpen: false,
            spin: false,
            usrInput: '',
            idx: -1,
            selected: '',
            type: '',
            timeoutId: 0,
            delimiter: ' | ',
            localOptions: this.options
        };
    },
    props: {
        options: {
            default() {
                return [];
            }
        },
        handler: {
            default: function () {
                return () => {
                };
            }
        },
        hideButton: {
            default: function () {
                return false;
            }
        },
        defaultValue: {
            type: String,
            default: ''
        },
    },
    computed: {
        prepared() {
            return this.prepareData(this.usrInput);
        },
        active() {
            // const filtered = this.filterBy(this.usrInput, true);
            // return this.usrInput && filtered.length ? filtered[0] : null;
        }
    },
    methods: {
        select(item) {
            if (item.value === 'noresult') {
                return;
            }

            this.usrInput = this.selected = item && item.hotelName || '';
            SKTV.searchApp.parameters.name.setName(item.hotelName || '');

            if (this.type === 'hotel') {
                if (item.giataId !== '') {
                    SKTV.searchApp.parameters.hotel.setHotel({
                        api: item.api,
                        id: [item.giataId]
                    });
                } else {
                    SKTV.searchApp.parameters.code.addCode(item.edvCode);
                    SKTV.searchApp.parameters.operator.addOperator(item.touroperator);
                }
            }
            if (this.type === 'region') {
                SKTV.searchApp.parameters.destinations
                    .reset()
                    .addDestination(
                        item.subregionName != '' ? item.subregionName : item.regionName
                    );
            }
            this.$nextTick(function () {
                this.isOpen = false;
            });
        },
        id(bool){
            if(bool){
                return 'item-focus';
            }
        },
        prepareData(usrInput) {
            var self = this;
            if (this.localOptions[0] && this.localOptions[0].value === 'noresult') {
                return this.localOptions;
            }

            return this.localOptions.map(function (option) {
                if (self.type === 'hotel') {
                    var re = new RegExp('(' + usrInput + ')', 'i'),
                        optOut = $.extend(option, {
                            label: option.regionName + self.delimiter + option.hotelName.replace(re, '<strong class="underline">$1</strong>'),
                            title: option.regionName + self.delimiter + option.hotelName,
                            value: option.hotelName
                        });
                }
                if (self.type === 'region') {
                    var label = [option.subregionName, option.regionName]
                        .filter(function (itm) {
                            return itm !== '' ? true : false;
                        })
                        .join(self.delimiter);
                    var optOut = $.extend(option, {
                        label: label,
                        title: label,
                        value: ''
                    });
                }
                return optOut;
            });
        },
        reset(keepText) {
            SKTV.searchApp.parameters.name.reset();
            SKTV.searchApp.parameters.hotel.reset();
            // maybe resetting destinations makes no sense depending to ibe step?
            //SKTV.searchApp.parameters.destinations.reset();
            this.selected = '';
            if (!keepText) {
                this.usrInput = '';
            }
            this.idx = -1;
            this.scrollPos = 1;
            this.localOptions = [];
            this.isOpen = this.spin = false;
            clearTimeout(this.timeoutId);
            if (this.xhr) {
                this.xhr.abort();
                this.xhr = null;
            }
        },
        moveDown() {
            if (this.isOpen && this.idx < this.localOptions.length - 1) {
                this.idx++;
                Vue.nextTick(function () {
                    document.getElementById('item-focus').focus();
                }.bind(this));
            }
        },
        moveUp() {
            if (this.isOpen && this.idx > 0) {
                this.idx--;
                Vue.nextTick(function () {
                    document.getElementById('item-focus').focus();
                }.bind(this));
            }
        },
        submit() {
            if (this.isOpen) {
                this.select(this.localOptions[this.idx]);
            }
            this.isOpen = false;
            this.idx = -1;
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        fetchOptions() {
            var self = this;
            if (self.xhr) {
                self.xhr.abort();
                self.xhr = null;
            }

            clearTimeout(self.timeoutId);

            if (self.usrInput.length > 2) {
                this.timeoutId = setTimeout(
                    function () {
                        self.usrInput = self.usrInput.replace(/[^A-Za-zäöüß0-9 ]/gi, '');
                        self.localOptions = [];
                        self.spin = true;
                        SKTV.searchApp.parameters.name.setName(self.usrInput);
                        SKTV.searchApp.parameters.offer.reset();
                        SKTV.searchApp.parameters.code.reset();
                        var url = '/searchhotelname' + SKTV.searchApp.toUrl();
                        self.xhr = $.get(url)
                            .done((data) => {
                                const options = [];
                                if ('ok' === data.status) {
                                    self.type = data.type;
                                    $(data.response).each(function (idx, el) {
                                        options.push(el);
                                    });
                                    self.localOptions = options;
                                    self.isOpen = true;
                                    self.spin = false;
                                }
                                if ('no results' === data.status) {
                                    self.isOpen = true;
                                    self.spin = false;
                                    self.localOptions.push({
                                        label: '<i class="fas fa-exclamation-triangle red"></i> kein Treffer',
                                        title: 'kein Treffer',
                                        value: 'noresult'
                                    });
                                }
                            })
                            .fail((xhr, txt, err) => {
                            })
                            .always();
                    }, 700);
            } else {
                this.reset(true);
            }
        }
    },
    created() {

        if(this.defaultValue.length > 0 && this.usrInput.length == 0){
            this.usrInput = this.defaultValue;
        }
    },
    mounted() {
        this.$watch('usrInput', function (value) {
            if (this.usrInput !== this.selected) {
                this.fetchOptions();
            }
        });
    }
};
