import BaseModel from './base';
import RoomsHydrator from '../hydrator/rooms';

export default class RoomsModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new RoomsHydrator({
            roomType: '_rooms'
        });
        this.export = false;

        this._prefix = 'zimmer';
        this._defaults = {
            _rooms: []
        };
        this._options = [
            {code: 'ap', label: 'Appartement'},
            {code: 'bu', label: 'Bungalow'},
            {code: 'dx', label: 'Deluxe'},
            {code: 'dz', label: 'Doppelzimmer'},
            {code: 'dk', label: 'Doppelkabine'},
            {code: 'tz', label: 'Dreibettzimmer'},
            {code: 'dp', label: 'Duplex'},
            {code: 'eb', label: 'Einzelbett'},
            {code: 'ez', label: 'Einzelzimmer'},
            {code: 'fs', label: 'Familiensuite'},
            {code: 'fz', label: 'Familienzimmer'},
            {code: 'fh', label: 'Ferienhaus'},
            {code: 'fw', label: 'Ferienwohnung'},
            {code: 'js', label: 'Juniorsuite'},
            {code: 'ma', label: 'Maisonette'},
            {code: 'mz', label: 'Mehrbettzimmer'},
            {code: 'st', label: 'Studio'},
            {code: 'su', label: 'Suite'},
            {code: 'sp', label: 'Superior'},
            {code: 'vi', label: 'Villa'}
        ];

        this._options.forEach(option => option.checked = option.filtered = false);

        this.reset();
    }

    select(code) {
        code = code.toLowerCase();
        if (this._rooms.map(room => room.toLowerCase()).indexOf(code) >= 0) {
            this._rooms = this._rooms.filter(room => room.toLowerCase() !== code);
        } else {
            this._rooms = [...this._rooms, code];
        }

        this.updateOptions();
    }

    updateOptions() {
        const rooms = this._rooms.map(room => room.toLowerCase());
        const options = [...this._options];
        options.forEach(option => option.checked = rooms.indexOf(option.code.toLowerCase()) >= 0);
        this._options = options;
    }

    updateFilter(filter) {
        this._options.forEach(option => option.filtered = !(option.code.toUpperCase() in filter));
    }

    filteredCheckedOptions () {
        return this._options.filter(option => (option.checked && !option.filtered));
    }

    toString(onlyCheckedNotFiltered = true) {
        return this.isDefault() ? '' : (onlyCheckedNotFiltered ? this.filteredCheckedOptions() : this._options.filter(option => option.checked)).map(option => option.label).join(', ');
    }

    toUrl(onlyCheckedNotFiltered = true) {
        return this.isDefault() ? '' : `${this._prefix}(${(onlyCheckedNotFiltered ? this.filteredCheckedOptions().map(option => option.code) : this._rooms).join(',').toLowerCase()})`;
    }

    getUnfiltered() {
        return this._options.filter(option => !option.filtered);
    }

    createCanvasString() {
        switch( this._rooms.length) {
            case 0:
                return '';
            case 1:
                return this.toString();
            default:
                return this._rooms.length + ' Zimmerarten';
        }
    }
}
