import BaseModel from './base';
import CityHydrator from '../hydrator/city';

export default class CityModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new CityHydrator({
            city: '_city'
        });
        this.export = false;

        this._prefix = 'ort';
        this._defaults = {
            _city: null
        };

        this.reset();
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${Object.entries(this._city).map(([provider, value]) => `${provider}-${value}`).join(',')})`;
    }
}