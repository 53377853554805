var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"box-grey carrental rel\">\n    <a href=\"#\" onclick=\"SKTV.BookingForm.UI.InfoBoxes.removeCarRental();return false;\"><div class=\"remove abs\"><i class=\"far fa-trash-alt\"></i></div></a>\n    <p class=\"bold headline\">Ihr Mietwagen vor Ort</p>\n    <a href=\"http://drivefti.de\" target=\"_blank\" title=\"G&uuml;nstige Mietwagen &#8211; schnell und einfach buchen! &#8211; Autovermietung ohne Selbstbeteiligung &#8211; driveFTI &#8211; Drive FTI\"><div class=\"drivefti\"></div></a>\n    <p class=\"bold\">"
    + alias4(((helper = (helper = helpers.car || (depth0 != null ? depth0.car : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"car","hash":{},"data":data}) : helper)))
    + "</p>\n    <div class=\"row bold grey\">\n        <div class=\"col9\">Gesamtpreis für die Mietdauer von "
    + alias4(((helper = (helper = helpers.duration || (depth0 != null ? depth0.duration : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"duration","hash":{},"data":data}) : helper)))
    + " Tagen</div>\n        <div class=\"col3 tright\">€ "
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.price : depth0),true,{"name":"asPrice","hash":{},"data":data}))
    + "</div>\n    </div>\n</div>\n";
},"useData":true});