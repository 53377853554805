var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.countryData : depth0)) != null ? stack1.General : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                Es tut uns Leid, leider sind aktuell keine Allgemeinen Informationen vorhanden.</>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"row tab-content common active\">\n\n    <div class=\"col3\">\n        <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.regionPictures : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.storageMount : stack1), depth0))
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.regionPictures : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.identifier : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.regionPictures : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.regionPictures : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.title : stack1), depth0))
    + "\">\n    </div>\n    <div class=\"col9\">\n        <h4>Allgemeine Infos - "
    + alias2(((helper = (helper = helpers.regionName || (depth0 != null ? depth0.regionName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"regionName","hash":{},"data":data}) : helper)))
    + "</h4>\n        <p>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.countryData : depth0)) != null ? stack1.General : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </p>\n    </div>\n</div>";
},"useData":true});