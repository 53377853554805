import Vue from 'vue';
import AlternativeOffer from './components/alternative-offer';

export default Vue.component('alternative-description-offers', {
    template: `
        <div class="alternative-description-offers">
            <slick ref="slick" :options="slickOptions">
                <alternative-offer v-for="offer in offers" v-bind:offer="offer" v-bind:showHotelOfferPlaceholder="showHotelOfferPlaceholder"></alternative-offer>
            </slick>
        </div>
    `,
    components: {AlternativeOffer},
    data () {
        return {
            showHotelOfferPlaceholder: false,
            slickOptions: {
                mobileFirst: true,
                dots: true,
                arrows: true,
                swipeToSlide: true,
                infinite: true,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            variableWidth: true
                        }
                    },
                    {
                        breakpoint: 1100,
                        settings: 'unslick'
                    }
                ]
            }
        };
    },
    props: {
        offers: Array
    },
    mounted () {
        this.offers.forEach(offer => {
            if (offer.offer.type !== 'hotelonly') {
                this.showHotelOfferPlaceholder = true;
                return;
            }
        });
    },
    methods: {
        next () {
            this.$refs.slick.next();
        },
        prev () {
            this.$refs.slick.prev();
        },
        reInit () {
            this.$refs.slick.destroy();
            this.$nextTick(() => {
                this.$refs.slick.create();
                this.$refs.slick.goTo(0, true);
            });
        }
    }
});
