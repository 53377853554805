import CruiseAreasHydrator from "../hydrator/cruiseareas";

export default class CruiseLineModel {
    constructor() {

        this._hydrator = new CruiseAreasHydrator({
            cruiseLine: '_cruiseLines'
        });

        this._options = [];
        this.cruisePrefix =  'line-';
        this.cruiseAutoComplete = [];
        this.dataLoaded = false;
        this._cruiseTags = [];
        this._cruiseTagsForUrl = [];
    }
}
