;(function (window, $) {
    'use strict';
    var Search = {
        init: function () {

        },
        getTemplate: function (templateName) {
            return document.querySelector('#' + templateName + '-template').innerHTML;
        }
    };

    window.SKTV.Mediathek = window.SKTV.Mediathek || {};
    window.SKTV.Mediathek.Search = Search;

})(window, $);
