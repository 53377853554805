import EkolumbusVue from './ekolumbus';
import EdomizilVue from './edomizil';
import PersonsComponent from './components/persons';
import RatingsComponent from './components/ratings';
import NamesearchComponent from './components/namesearch';
import Datepicker from './components/datepicker';
import cruiseDatepicker from "./components/cruise-datepicker";
import cruiseAreaPart from "./components/cruise-area-part";
import cruiseShipPart from "./components/cruise-ship-part";
import cruisesButtons from "./components/cruises-buttons";
import cruiseDatepickerDuration from "./components/cruise-datepicker-duration";
import datepickerFactory from 'jquery-datepicker';
import ModalComponent from './components/Modal';
import ChipComponent from './components/Chip';
import eventbus from '../../FixedHeader/components/eventbus'
import OfferSearch from "../../OfferSearch/components/Search/OfferSearch";
import SearchObject from "../../OfferSearch/components/SearchObject";
import {GroupNames} from "../model/airports";

const axios = require("axios");

export default model => {
    return {
        el: '#sktv-search',
        components: {
            ekolumbusComponent: EkolumbusVue,
            edomizilComponent: EdomizilVue,
            persons: PersonsComponent,
            ratings: RatingsComponent,
            namesearch: NamesearchComponent,
            'datepick-r': Datepicker,
            'cruise-datepick-r-from': cruiseDatepicker,
            'cruise-datepick-r-to': cruiseDatepicker,
            'cruise-datepick-r-duration': cruiseDatepickerDuration,
            'cruise-area-part': cruiseAreaPart,
            'cruise-ship-part': cruiseShipPart,
            'cruises-buttons': cruisesButtons,
            modal: ModalComponent,
            chip: ChipComponent,
            OfferSearch
        },
        data: {
            search: model,
            airportSearch: '',
            destinationSearch: '',
            cruiseAreaSearch: '',
            cruiseLineSearch: '',
            cruiseShipSearch: '',
            cruisesLoaded: false,
            parameters: model.parameters,
            locale: 'de',
            searchObject: new SearchObject(window.searchObj || {}),
            durationsFromMarkerValues: null,
            firstDurationMarkerValue: null,
            dropdowns: {
                destinations: false,
                airports: false,
                interval: false,
                duration: false,
                namesearch: false,
                trduration: false,
                persons: false,
                catering: false,
                rooms: false,
                operator: false,
                interval_mobile_from: false,
                interval_mobile_to: false,
                quicksearch: false,
                cruiseAreas: false,
                cruiseLines: false,
                cruiseShips: false,
                cruiseFrom: false,
                cruiseTo: false,
                cruiseDuration: false
            },
            modal: {
                airports: false,
                interval: false,
                duration: false,
                catering: false,
                rooms: false,
                quicksearch: false,
                traveltype: false,
                destinations: false,
                operators: false,
                offersearch: false,
                persons: false,
                canvassearch: false,
                cruiseAreas: false,
                cruiseLines: false,
                cruiseShips: false,
                cruiseFrom: false,
                cruiseTo: false,
                cruiseDuration: false
            },
            tabs: {
                destinations: 'tab1'
            },
            destinations: {},
            autocomplete: [],
            expanded: false,
            canvasExpanded: false,
            personsValid: true,
            step: 0,
            daysLocale: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            showCanvasSearch: false,
            componentKey: 0,
            showPauschal: false,
            defaultQsDestination: null,
            defaultQsTab: null,
            defaultCruiseDestinations: null,
            defaultCruiseLines: null,
            defaultCruiseShips: null,
            defaultCruisePackages: null,
            defaultCruiseSeaRiver: null
        },
        computed: {
            destinationTags() {
                return this.parameters.destinations._destinations;
            },
            intervalTags() {
                let intervalList = [];

                if (this.travelDate.length > 0) {
                    let date = [];
                    date.value = this.travelDate.toString();
                    date.type = 'date';
                    intervalList.push(date);
                }

                if (this.parameters.interval._getDays().length > 0) {
                    let duration = [];
                    duration.type = 'duration';

                    switch (this.parameters.interval._getDays()) {
                        case '7-7':
                            duration.value = '1 Woche';
                            break;
                        case '14-14':
                            duration.value = '2 Wochen';
                            break;
                        case '21-21':
                            duration.value = '3 Wochen';
                            break;
                        case '1-3':
                            duration.value = '1-3 Tage';
                            break;
                        default:

                            let daysArray = this.parameters.interval._getDays().split('-');
                            if (daysArray.length > 1) {
                                duration.value = daysArray[0] == daysArray[1]
                                    ? daysArray[0] + ' Tage'
                                    : daysArray[0] + ' - ' + daysArray[1] + ' Tage';
                            } else {
                                duration.value = this.parameters.interval._getDays() + ' Tage';
                            }

                    }

                    if (this.parameters.interval._getDays() !== 'exakt' && duration.value != null) {
                        intervalList.push(duration);
                    }
                }

                return intervalList;
            },
            personTags() {
                let personsList = [];

                if (this.parameters.persons._adults > 0) {
                    let adult = [];

                    adult.type = 'adult';
                    adult.value = `${this.parameters.persons._adults} ${this.parameters.persons._adults > 1 ? 'Erwachsene' : 'Erwachsene/r'}`;

                    personsList.push(adult);
                }

                if (this.parameters.persons._children.length > 0) {
                    let child = [];

                    child.type = 'child';
                    child.value = `${this.parameters.persons._children.length ? `${this.parameters.persons._children.length} ${this.parameters.persons._children.length > 1 ? 'Kinder' : 'Kind'}` : ''}`;

                    personsList.push(child);
                }

                return personsList;
            },
            travelDate() {
                const from = moment(this.parameters.interval._from, 'DD-MM-YYYY', this.locale).format('dd, DD.MM.');
                const to = moment(this.parameters.interval._to, 'DD-MM-YYYY', this.locale).format('dd, DD.MM.YYYY');
                const date = [from, to].join(' - ');
                return date;
            },

            filteredAirports() {
                let airports = {
                    'Deutschland': [],
                    'Österreich': [],
                    'Schweiz': [],
                    'Sonstige Flughäfen': []
                };
                let groupNamesList = [
                    GroupNames.NORD_DEUTSCHLAND,
                    GroupNames.OST_DEUTSCHLAND,
                    GroupNames.SUED_DEUTSCHLAND,
                    GroupNames.WEST_DEUTSCHLAND,
                    GroupNames.BELIEBTE_FLUGHAEFEN
                ];

                if (this.airportSearch.length > 0) {
                    const search = this.airportSearch.toLowerCase();

                    Object.keys(this.parameters.airports._options).forEach(key => {
                        let list = this.parameters.airports._options[key].filter(airport => {
                            const iataCode = airport.iataCode.toLowerCase();
                            const label = airport.label.toLowerCase();

                            return (((label.indexOf(search)) > -1 ||
                                (iataCode.indexOf(search)) > -1)) && !airport.filtered;
                        });
                        if (groupNamesList.includes(key)) {
                            if (list.length > 0) {
                                airports[GroupNames.DEUTSCHLAND].push({
                                    title: key,
                                    list: list
                                });
                            }
                        } else if (key !== GroupNames.DEUTSCHLAND && !groupNamesList.includes(key)) {
                            if (list.length > 0) {
                                airports[key].push({
                                    title: key,
                                    list: list
                                });
                            } else {
                                delete airports[key];
                            }
                        }
                    });
                    return airports;
                } else {
                    Object.keys(this.parameters.airports._options).forEach(key => {
                        let available = 0;
                        if (groupNamesList.includes(key)) {
                            this.parameters.airports._options[key].forEach(item => {
                                if (!item.filtered) {
                                    available++;
                                }
                            });
                            airports[GroupNames.DEUTSCHLAND].push({
                                title: key,
                                list: this.parameters.airports._options[key],
                                available: available
                            });
                        } else if (key !== GroupNames.DEUTSCHLAND && !groupNamesList.includes(key)) {
                            this.parameters.airports._options[key].forEach(item => {
                                if (!item.filtered) {
                                    available++;
                                }
                            });
                            airports[key].push({
                                title: key,
                                list: this.parameters.airports._options[key],
                                available: available
                            });
                        }
                    });
                    return airports;
                }
            },
            filteredDestinations() {
                if (this.destinationSearch.length > 0) {
                    let destinations = [];
                    const search = this.destinationSearch.toLowerCase();

                    let list = this.parameters.destinations.flatOptionsSearch().filter(destination => {
                        const code = destination.code.toLowerCase();
                        const label = destination.label.toLowerCase();

                        return ((label.indexOf(search)) > -1 ||
                            (code.indexOf(search)) > -1) && !destination.filtered;
                    });

                    destinations.push({
                        title: 'Reiseziele',
                        list: list
                    });

                    return destinations;

                } else {
                    let destinations = [];

                    Object.keys(this.parameters.destinations._options).forEach(key => {
                        let available = 0;

                        this.parameters.destinations._options[key].forEach(item => {
                            if (!item.filtered) {
                                available++;
                            }
                        });

                        destinations.push({
                            title: key,
                            list: this.parameters.destinations._options[key],
                            available: available
                        });
                    });

                    return destinations;
                }
            },
            searchType() {
                let type;
                switch (this.parameters.traveltype._traveltype) {
                    case 'ekolumbus':
                        type = 'roundtrip';
                        break;
                    case 'edomizil':
                        type = 'apartment';
                        break;
                    default:
                        type = 'regular';
                }
                return type;
            },
            travelDuration() {
                return this.parameters.interval.getDuration();
            },
            fromDay() {
                const day = this.getCalendarDay(this.parameters.interval._from);
                return day ? (day + ',') : '';
            },
            toDay() {
                const day = this.getCalendarDay(this.parameters.interval._to);
                return day ? (day + ',') : '';
            },
            cruisesFromDateDay() {
                const day = this.getCalendarDay(this.parameters.interval._cruiseFrom);
                return day ? (day + ',') : '';
            },
            cruisesToDateDay() {
                const day = this.getCalendarDay(this.parameters.interval._cruiseTo);
                return day ? (day + ',') : '';
            },
            travelInterval() {
                return `${this.fromDay} ${this.formatDate(this.parameters.interval._from)} - ${this.toDay} ${this.formatDate(this.parameters.interval._to)}`;
            },
            cruisesFromDate() {
                if (this.parameters.interval._cruiseFrom !== 'all' && this.parameters.interval._cruiseFrom !== undefined) {
                    return `Früheste Hinreise: ${this.formatDate(this.parameters.interval._cruiseFrom)}`;
                } else {
                    return `Früheste Hinreise`
                }
            },
            cruisesToDate() {
                if (this.parameters.interval._cruiseTo !== 'all' && this.parameters.interval._cruiseTo !== undefined) {
                    return `Späteste Rückreise: ${this.formatDate(this.parameters.interval._cruiseTo)}`;
                } else {
                    return `Späteste Rückreise`
                }
            },
            cruisesDuration() {
                let cd = this.parameters.interval._cruiseDuration.filter(cd => cd.selected === true);
                if (cd.length > 0) {
                    this.parameters.cruises.cruiseDurationHasInput = true;
                    return cd[0].label;
                } else {
                    this.parameters.cruises.cruiseDurationHasInput = false;
                    this.parameters.interval._cruiseDuration[0].selected = true;
                    return this.parameters.interval._cruiseDuration[0].label;
                }
            },
            operatorList() {
                return this.parameters.operator.filterByType(this.parameters.traveltype._traveltype).filter(option => !option.filtered);
            },
            operatorListString() {
                return this.parameters.operator.toString();
            }
        },
        methods: {
            getDurationsFromMarkerValues() {
                let div = document.getElementById('search-duration-container');
                if (div !== null && div !== undefined) {
                    this.durationsFromMarkerValues = JSON.parse(div.getAttribute('data-durations'));

                    if (this.durationsFromMarkerValues.length === 1) {
                        this.firstDurationMarkerValue = this.durationsFromMarkerValues[0] + ' Tage';
                        this.parameters.interval.selectDurationOption(this.durationsFromMarkerValues[0]);
                    }
                }
            },
            filteredCruises(type) {
                let cruiseOptions;
                let model;
                let cruiseSearch;
                let cruises = [];
                if (type === 'area') {
                    model = this.parameters.cruises.cruiseArea;
                    cruiseOptions = model._options;
                    cruiseSearch = this.cruiseAreaSearch;
                } else if (type === 'line') {
                    model = this.parameters.cruises.cruiseLine;
                    cruiseOptions = model._options;
                    cruiseSearch = this.cruiseLineSearch;
                } else {
                    model = this.parameters.cruises.cruiseShip;
                    cruiseOptions = model._options;
                    cruiseSearch = this.cruiseShipSearch;
                }

                if (cruiseSearch.length > 0) {
                    const search = cruiseSearch.toLowerCase();
                    cruiseOptions.forEach(function (group, index) {
                        cruises.push({
                            checked: group.checked,
                            label: group.label,
                            list: group['subValues'].filter(cruiseArea => {
                                const code = cruiseArea.code.toLowerCase();
                                const label = cruiseArea.label.toLowerCase();

                                return ((label.indexOf(search)) > -1 ||
                                    (code.indexOf(search)) > -1);
                            })
                        });
                    });

                    return cruises.filter(group => {
                        return group.list.length > 0;
                    });
                } else {
                    cruiseOptions.forEach(group => {
                        let available = 0;

                        group['subValues'].forEach(item => {
                            if (!item.filtered) {
                                available++;
                            }
                        });

                        cruises.push({
                            checked: group.checked,
                            code: group.code,
                            label: group.label,
                            list: group['subValues'],
                            available: available
                        });
                    });
                    return cruises;
                }
            },
            isBeliebteFlughaefenWhileSearch(title) {
                if (this.airportSearch !== '' && title === GroupNames.BELIEBTE_FLUGHAEFEN) {
                    return true;
                } else {
                    return false;
                }
            },
            checkCanvasSearch() {
                if (this.isMobile()) {
                    this.initCanvasSliders();
                    this.parameters.catering.select('dummy');

                    this.showCanvasSearch = true;

                    window.addEventListener("orientationchange", function () {
                        if (screen.width >= 600) {
                            eventbus.$emit('close-canvas-search');
                        }
                    });

                    let isIOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/);
                    if (isIOS) {
                        window.addEventListener('resize', function () {
                            if (window.innerWidth >= 600) {
                                eventbus.$emit('close-canvas-search');
                            }
                        });
                    }
                    return true;
                }

                this.showCanvasSearch = false;
                return false;
            },
            getCalendarDay(date) {
                return this.daysLocale[moment(date, this.parameters.interval._dateFormat).day()];
            },
            formatDate(date) {
                return moment(date, this.parameters.interval._dateFormat).format('DD.MM.YY');
            },
            toggleDropdown(type) {
                if (this.personsValid) {
                    Object.keys(this.dropdowns).forEach(dropdown => {
                        if (dropdown === type) {
                            this.dropdowns[dropdown] = !this.dropdowns[dropdown];
                            if (!this.dropdowns[dropdown] && this.parameters.cruises.cruiseHasChanges) {
                                this.selectRequestByType(type);
                                this.parameters.cruises.cruiseHasChanges = false;
                            }
                        }
                    });
                }

            },
            toggleModal(type) {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                if (document.getElementsByClassName('footer-bar').length > 0) {
                    document.getElementsByClassName('footer-bar')[0].style.display = 'none';
                }
                if (document.getElementsByClassName('sticky-header').length > 0) {
                    document.getElementsByClassName('sticky-header')[0].style.display = 'none';
                }
                Object.keys(this.modal).forEach(element => {
                    if (element === type) {
                        this.modal[element] = !this.modal[element];
                        if (!this.modal[element]) {
                            this.selectRequestByType(type);
                        }
                    }
                });
            },


            closeModal(type) {
                Object.keys(this.modal).forEach(element => {
                    if (element === type) {
                        // this.modal[element] = !this.modal[element];
                        this.modal[element] = false;
                    }
                });

                document.getElementsByTagName('body')[0].style.overflow = 'auto';
                if (document.getElementsByClassName('footer-bar').length > 0) {
                    document.getElementsByClassName('footer-bar')[0].style.display = 'block';
                }
                if (document.getElementsByClassName('sticky-header').length > 0) {
                    document.getElementsByClassName('sticky-header')[0].style.display = 'block';
                }
            },
            togglePersonsDropdown() {
                return () => {
                    this.toggleDropdown('persons');
                };
            },
            formatPrice(price) {
                price = parseFloat(price);
                return price == 0 ? 'beliebig' : price.toFixed(2).replace('.', ',') + '€';
            },
            allChecked(list) {
                return (list.length ? list : Object.values(list)).every(entry => entry.checked);
            },
            toggleAll(list) {
                const checked = !this.allChecked(list);
                return (list.length ? list : Object.values(list)).forEach(entry => entry.checked = checked);
            },
            uncheckAll(list) {
                return list.forEach(entry => entry.checked = false);
            },
            deUmlaut(value) {
                /* value = value.toLowerCase();*/
                value = value.replace(/ä/g, 'ae');
                value = value.replace(/ö/g, 'oe');
                value = value.replace(/ü/g, 'ue');
                value = value.replace(/ß/g, 'ss');
                value = value.replace(/ /g, '_');
                value = value.replace(/\./g, '');
                value = value.replace(/,/g, '');
                value = value.replace(/\(/g, '');
                value = value.replace(/\)/g, '');
                value = value.replace(/\//g, '_');
                value = value.replace('-', '_');
                return value;
            },
            selectRequestByType(type) {
                if (type === 'cruiseAreas') {
                    this.requestNewData(null, 'area');
                } else if (type === 'cruiseShips') {
                    this.requestNewData(null, 'ship');
                } else if (type === 'cruiseLines') {
                    this.requestNewData(null, 'line');
                } else if (type === 'cruiseFrom') {
                    this.requestNewData(null, 'from');
                } else if (type === 'cruiseTo') {
                    this.requestNewData(null, 'to');
                } else if (type === 'cruiseDuration') {
                    this.requestNewData(null, 'duration');
                }
            },
            resetCruises(type) {
                let model;
                if (type === 'area') {
                    model = this.parameters.cruises.cruiseArea;
                } else if (type === 'line') {
                    model = this.parameters.cruises.cruiseLine;
                } else {
                    model = this.parameters.cruises.cruiseShip;
                }
                model._cruiseTags = [];
                model._cruiseTagsForUrl = [];

                model._options.forEach(function (group) {
                    group.checked = false;
                    group['subValues'].forEach(function (subValue) {
                        subValue.checked = false;
                        if (subValue['subValues'] !== []) {
                            subValue['subValues'].forEach(function (subSubValue) {
                                subSubValue.checked = false;
                            });
                        }
                    });
                });
                this.requestNewData(null, type);
            },

            requestNewData(code, type) {
                if (type === 'sea') {
                    this.parameters.cruises.requestData = {'sea': 1};
                    this.parameters.cruises.requestData['sea'] = code;
                } else if (type === 'area') {
                    this.parameters.cruises.requestData['area'] = this.parameters.cruises.cruiseArea._cruiseTagsForUrl.map(area => area.code);
                } else if (type === 'line') {
                    this.parameters.cruises.requestData['line'] = this.parameters.cruises.cruiseLine._cruiseTagsForUrl.map(line => line.code);
                } else if (type === 'ship') {
                    this.parameters.cruises.requestData['ship'] = this.parameters.cruises.cruiseShip._cruiseTagsForUrl.map(ship => ship.code);
                } else if (type === 'duration') {
                    let duration = this.parameters.interval._cruiseDuration.filter(cd => cd.selected === true).shift()
                    this.parameters.cruises.requestData['duration'] = duration.value;
                } else if (type === 'from') {
                    if (this.parameters.interval._cruiseFrom !== 'all') {
                        this.parameters.cruises.requestData['from'] = moment(this.parameters.interval._cruiseFrom, 'DD.MM.YYYY').format('YYYY-MM-DD').toString();
                    } else {
                        delete this.parameters.cruises.requestData['from'];
                    }
                } else if (type === 'to') {
                    if (this.parameters.interval._cruiseTo !== 'all') {
                        this.parameters.cruises.requestData['to'] = moment(this.parameters.interval._cruiseTo, 'DD.MM.YYYY').format('YYYY-MM-DD').toString();
                    } else {
                        delete this.parameters.cruises.requestData['to'];
                    }
                } else if (type === 'package') {
                    if (this.parameters.cruises.packageCheckbox.checked) {
                        this.parameters.cruises.requestData['package'] = 'packages';
                    } else {
                        this.parameters.cruises.requestData['package'] = null;
                    }
                }
                this.initCruiseHostData(this.parameters.cruises.requestData, false);
            },

            selectCruiseDuration(value) {
                this.parameters.interval._cruiseDuration.forEach(function (duration) {
                    if (duration.value === value) {
                        duration.selected = true;
                    } else {
                        duration.selected = false;
                    }
                });
            },

            submitCruiseSearch(event) {
                let self = this;
                let areaUrl = 'all';

                let area = this.parameters.cruises.cruiseArea._cruiseTagsForUrl;
                if (area.length > 0) {
                    area.forEach(function (value, index) {
                        areaUrl = areaUrl + '+' + self.deUmlaut(value.label);
                    });
                    areaUrl = areaUrl.slice(4)
                }

                let linesChecked = [];
                this.parameters.cruises.cruiseLine._options.forEach(function (group) {
                    group['subValues'].forEach(function (subValue) {
                        if (subValue.checked) {
                            linesChecked.push(subValue);
                        }
                    });
                });

                let shipUrl = 'all'
                if (this.parameters.cruises.cruiseShip._cruiseTagsForUrl.length > 0 || linesChecked.length > 0) {
                    let ship = this.parameters.cruises.cruiseShip._options;
                    ship.forEach(function (option) {
                        let subValuesChecked = option['subValues'].filter(subValue => subValue.checked === true);
                        let lineValuesChecked = linesChecked.filter(line => line.code === option.code);
                        if (lineValuesChecked.length > 0 && subValuesChecked.length === 0) {
                            shipUrl = shipUrl + self.deUmlaut(option.label) + '+'
                        } else if (subValuesChecked.length > 0) {
                            subValuesChecked.forEach(function (subValue) {
                                shipUrl = shipUrl + self.deUmlaut(option.label) + '-' + self.deUmlaut(subValue.label) + '+';
                            });
                        }
                    });
                    shipUrl = shipUrl.slice(3, shipUrl.length - 1);
                }

                let from = this.parameters.interval._cruiseFrom;
                if (from !== 'all') {
                    from = moment(from, 'DD.MM.YYYY').locale("en").format('DD_MMMMYYYY');
                }

                let to = this.parameters.interval._cruiseTo;
                if (to !== 'all') {
                    to = moment(to, 'DD.MM.YYYY').locale("en").format('DD_MMMMYYYY');
                }

                let duration = 'all';
                duration = this.parameters.interval._cruiseDuration.filter(cd => cd.selected === true).shift();
                duration = this.deUmlaut(duration.label);
                if (duration == 'Beliebige_Dauer') {
                    duration = 'all';
                }

                let flussMeerRadioButtonChecked;
                this.parameters.cruises.cruisesRadioButtons.forEach(function (rb) {
                    if (rb.checked) {
                        flussMeerRadioButtonChecked = rb.label;
                    }
                })

                let packageCheckbox = 'all';
                if (this.parameters.cruises.packageCheckbox.checked) {
                    packageCheckbox = 'packages';
                }
                let url = 'https://kreuzfahrt.sonnenklar.tv/kreuzfahrten/Ergebnisse/' +
                    flussMeerRadioButtonChecked + '/' +
                    areaUrl + '/' +
                    shipUrl + '/' +
                    duration + '/' +
                    from + '/' +
                    to + (packageCheckbox !== 'all' ? '/' + packageCheckbox + '?aid=202207' : '?aid=202207');
                window.open(url);

            },
            submitSearch(event) {
                const el = event.currentTarget;

                if (window.location.toString().includes('beschreibung')) {
                    let airportList = [];
                    Object.values(this.parameters.airports._options).forEach(group => {
                        group.forEach(airport => {
                            airportList.push(airport.iataCode);
                        });
                    });

                    if (airportList.length === 0) {
                        this.parameters.traveltype._traveltype = 'hotel';
                    } else {
                        this.parameters.traveltype._traveltype = 'pauschal';
                    }
                }

                if (el.classList) {
                    el.classList.add('is--busy');
                } else {
                    el.className += ' is--busy';
                }

                if (SKTV.Tracking && SKTV.Tracking.tracker) {
                    if (/^\/(?!suchen|promo|angebot)/i.test(window.location.pathname)) {
                        SKTV.Tracking.tracker.setType('search');
                    }
                    SKTV.Tracking.tracker.setSearchUsed();
                }

                // if destinations changed -> clear selected city
                if (this.search.parameters.destinations._destinations !== SKTV.searchObj.destination) {
                    this.search.parameters.city.reset();
                }

                window.location = this.search.toUrl();
            },
            initSliders() {
                const defaults = {
                    range: 'min',
                    min: 0,
                    max: 100
                };

                const ratingHandler = (event, ui) => this.parameters.rating._countReviews = ui.value;
                const ratingSlider = $('#slider-total-ratings').slider(Object.assign({}, defaults, {
                    step: 25,
                    change: ratingHandler,
                    slide: ratingHandler,
                    value: this.parameters.rating._countReviews
                }));

                this.$watch('parameters.rating._countReviews', (newVal) => {
                    if (newVal !== ratingSlider.slider('value')) {
                        ratingSlider.slider('value', newVal);
                    }
                });

                const recommendationHandler = (event, ui) => this.parameters.rating._recommendation = ui.value;
                const recommendationSlider = $('#slider-total-recomendations').slider(Object.assign({}, defaults, {
                    change: recommendationHandler,
                    slide: recommendationHandler,
                    value: this.parameters.rating._recommendation
                }));

                this.$watch('parameters.rating._recommendation', (newVal) => {
                    if (newVal !== recommendationSlider.slider('value')) {
                        recommendationSlider.slider('value', newVal);
                    }
                });

                const maxPrice = 4000;
                const priceHandler = (event, ui) => this.parameters.price._price = Math.min(ui.value, maxPrice - 1);
                const priceSlider = $('#slider-total-price').slider(Object.assign({}, defaults, {
                    max: maxPrice,
                    step: 50,
                    change: priceHandler,
                    slide: priceHandler,
                    value: this.parameters.price._price
                }));

                this.$watch('parameters.price._price', (newVal) => {
                    if (newVal !== priceSlider.slider('value')) {
                        priceSlider.slider('value', newVal);
                    }
                });
            },
            initCanvasSliders() {
                const defaults = {
                    range: 'min',
                    min: 0,
                    max: 100
                };

                const ratingHandler = (event, ui) => this.parameters.rating._countReviews = ui.value;
                const ratingSlider = $('#canvas-total-ratings').slider(Object.assign({}, defaults, {
                    step: 25,
                    change: ratingHandler,
                    slide: ratingHandler,
                    value: this.parameters.rating._countReviews
                }));

                this.$watch('parameters.rating._countReviews', (newVal) => {
                    if (newVal !== ratingSlider.slider('value')) {
                        ratingSlider.slider('value', newVal);
                    }
                });

                const recommendationHandler = (event, ui) => this.parameters.rating._recommendation = ui.value;
                const recommendationSlider = $('#canvas-total-recomendations').slider(Object.assign({}, defaults, {
                    change: recommendationHandler,
                    slide: recommendationHandler,
                    value: this.parameters.rating._recommendation
                }));

                this.$watch('parameters.rating._recommendation', (newVal) => {
                    if (newVal !== recommendationSlider.slider('value')) {
                        recommendationSlider.slider('value', newVal);
                    }
                });

                const maxPrice = 4000;
                const priceHandler = (event, ui) => this.parameters.price._price = Math.min(ui.value, maxPrice - 1);
                const priceSlider = $('#canvas-total-price').slider(Object.assign({}, defaults, {
                    max: maxPrice,
                    step: 50,
                    change: priceHandler,
                    slide: priceHandler,
                    value: this.parameters.price._price
                }));

                this.$watch('parameters.price._price', (newVal) => {
                    if (newVal !== priceSlider.slider('value')) {
                        priceSlider.slider('value', newVal);
                    }
                });
            },
            prepareCalendars() {
                $(document).ready(function () {
                    datepickerFactory($);
                    $.datepicker.setDefaults({
                        minDate: 2,
                        dateFormat: 'dd.mm.yy',
                        prevText: '&#x3c;zurück', prevStatus: '',
                        prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
                        nextText: 'Vor&#x3e;', nextStatus: '',
                        nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
                        currentText: 'heute', currentStatus: '',
                        todayText: 'heute', todayStatus: '',
                        clearText: '-', clearStatus: '',
                        closeText: 'schließen', closeStatus: '',
                        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                            'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
                            'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        showMonthAfterYear: false,
                        firstDay: 1
                    });
                });
            },
            initAutocomplete() {
                const $input = $('input#reiseziel-filter');

                $input.autocompleteHL({
                    source: this.autocomplete,
                    minLength: 3,
                    select: (event, ui) => {
                        $(this).siblings('button.reset-autocomplete').addClass('visible');

                        const value = ui.item.value.split(' | ');
                        this.parameters.destinations.select(value[value.length - 1]);

                        $input.val('');
                        return false;
                    },

                    open: function () {
                        const $that = $(this);
                        $that.autocompleteHL('instance').menu.element.css('width', $that.width() + 10);
                    }
                });
            },
            initCruiseHostData(data, withBackendParameter, withPackage, withRiver) {
                let self = this;

                $.ajax({
                    dataType: 'json',
                    url: 'cruiseHostData.json',
                    data: data,
                    method: "GET",
                    success: function (cruiseHostData) {

                        if (self.parameters.traveltype._traveltype === 'kreuzfahrten') {
                            self.parameters.destinations._destinations = [];
                        }
                        if (cruiseHostData !== undefined) {
                            self.cruisesLoaded = true;

                            if (data['sea'] !== undefined) {
                                let id = data['sea'].toString();
                                let riverNode = self.findById(cruiseHostData['sea'], "0");
                                let seaNode = self.findById(cruiseHostData['sea'], "1");

                                if (id === "0") {
                                    riverNode.selected = true;
                                    delete seaNode.selected;
                                } else {
                                    seaNode.selected = true;
                                    delete riverNode.selected;
                                }
                            }

                            if (data['area'] && data['area'].length) {
                                self.syncCruiseDataWithRequest(cruiseHostData['areas'], data['area']);
                            }

                            if (data['line'] && data['line'].length) {
                                for (const line in cruiseHostData['cruiselines']) {
                                    self.syncCruiseDataWithRequest(cruiseHostData['cruiselines'][line]['data'], data['line']);
                                }
                            }

                            if (data['ship'] && data['ship'].length) {
                                for (const ship in cruiseHostData['ships']) {
                                    self.syncCruiseDataWithRequest(cruiseHostData['ships'][ship]['data'], data['ship']);
                                }
                            }

                            if (data === undefined || withBackendParameter) {
                                self.parameters.cruises.initRadioButtons(cruiseHostData['sea']);
                                self.parameters.cruises.initPackageCheckbox(withPackage);
                            }

                            self.parameters.cruises.initCanvas(cruiseHostData['areas'], 'area');
                            self.parameters.cruises.initCanvas(cruiseHostData['cruiselines'], 'line');
                            self.parameters.cruises.initCanvas(cruiseHostData['ships'], 'ship');
                            self.initCruiseDurations(cruiseHostData['durations']);
                            self.initCruiseDepartureDates(cruiseHostData['departures']);
                            self.initCruiseArrivalDates(cruiseHostData['arrivals']);
                            self.parameters.cruises.cruisesCount = cruiseHostData['count'];

                        } else {
                            self.cruisesLoaded = false;
                        }

                    }, error: function (message) {
                        self.cruisesLoaded = false;
                        throw new Error('Error loading cruise areas for search form!');
                    }
                });
            },

            syncCruiseDataWithRequest(data, request) {
                let self = this;

                for (let id of request) {
                    let node = self.findById(data, id);
                    if (typeof node !== "undefined" && node !== false) {
                        node.selected = true;
                    }
                }
            },

            findById(tree, nodeId) {
                let self = this;

                for (let node of tree) {
                    if (node.id === nodeId) {
                        return node;
                    }
                    if (node.children) {
                        let desiredNode = self.findById(node.children, nodeId)
                        if (desiredNode) {
                            return desiredNode;
                        }
                    }
                }

                return false
            },

            initCruiseArrivalDates(data) {
                let self = this;
                Object.values(data).forEach(function (value, index) {
                    if (index === 0) {
                        self.parameters.interval._cruiseArrivalFrom = moment(value[0]['id']).format(self.parameters.interval._dateFormat);
                        self.parameters.interval._cruiseArrivalFromBackup = moment(value[0]['id']).format(self.parameters.interval._dateFormat);
                        if (value[1] !== undefined) {
                            self.parameters.interval._cruiseArrivalTo = moment(value[1]['id']).format(self.parameters.interval._dateFormat);
                        }
                    } else if (index === 1) {
                        self.parameters.interval._cruiseArrivalTo = moment(value[0]['id']).format(self.parameters.interval._dateFormat);
                    }
                });
            },
            initCruiseDepartureDates(data) {
                let self = this;
                Object.values(data).forEach(function (value, index) {
                    if (index === 0) {
                        self.parameters.interval._cruiseDepartureFrom = moment(value[0]['id']).format(self.parameters.interval._dateFormat);
                        if (value[1] !== undefined) {
                            self.parameters.interval._cruiseDepartureTo = moment(value[1]['id']).format(self.parameters.interval._dateFormat);
                            self.parameters.interval._cruiseDepartureToBackup = moment(value[1]['id']).format(self.parameters.interval._dateFormat);
                        }
                    } else if (index === 1) {
                        self.parameters.interval._cruiseDepartureTo = moment(value[0]['id']).format(self.parameters.interval._dateFormat);
                        self.parameters.interval._cruiseDepartureToBackup = moment(value[0]['id']).format(self.parameters.interval._dateFormat);
                    }
                });
            },
            initCruiseDurations(data) {
                let self = this;
                let selected = self.parameters.interval._cruiseDuration.filter(cd => cd.selected === true);
                self.parameters.interval._cruiseDuration = [{label: 'Beliebige Dauer', value: 'all', selected: false}];

                data.forEach(function (value) {
                    let result = {
                        value: value['id'],
                        label: value['text'],
                        selected: selected.filter(option => option.value === value['id']).length > 0
                    };
                    self.parameters.interval._cruiseDuration.push(result);
                });
            },
            initDestinations() {
                $.ajax({
                    url: SKTV.cdnHost + SKTV.destinationsJson,
                    type: 'get',
                    dataType: 'json',
                    success: (destinations) => {
                        this.destinations = destinations;

                        /**
                         * generates a string for each destination for autocpmplete function
                         */
                        $.each(this.destinations, (indexCountry, valueCountry) => {
                            this.autocomplete.push(indexCountry);
                            $.each(valueCountry, (indexDestination, valueDestination) => {
                                this.autocomplete.push(indexCountry + ' | ' + valueDestination);
                            });
                        });
                        this.initAutocomplete();
                        this.initCanvasDestinations(destinations);
                        this.showPauschal = true;
                    },
                    error: () => {
                        throw new Error('Error loading resources for search form!');
                    }
                });

                let destTarget = document.getElementsByClassName('main-wrap content');
                if (destTarget.length > 0) {
                    this.defaultQsDestination = destTarget[0].getAttribute('data-quicksearch-default-target');
                    this.defaultQsTab = destTarget[0].getAttribute('data-quicksearch-default-tab');
                    this.defaultCruiseDestinations = destTarget[0].getAttribute('data-quicksearch-cruise-default-destinations');
                    this.defaultCruiseLines = destTarget[0].getAttribute('data-quicksearch-cruise-default-lines');
                    this.defaultCruiseShips = destTarget[0].getAttribute('data-quicksearch-cruise-default-ships');
                    this.defaultCruisePackages = destTarget[0].getAttribute('data-quicksearch-cruise-default-packages');
                    this.defaultCruiseSeaRiver = destTarget[0].getAttribute('data-quicksearch-cruise-default-sea-river');
                }

                if (this.defaultQsDestination !== null && this.defaultQsDestination !== '') {
                    this.parameters.destinations.addDestination(this.defaultQsDestination);
                }
                if (this.defaultCruiseDestinations !== null && this.defaultCruiseDestinations !== '') {
                    this.defaultCruiseDestinations = this.defaultCruiseDestinations.split(',');
                    this.defaultCruiseDestinations = this.defaultCruiseDestinations.map(function (el) {
                        return el.trim();
                    });
                    ;
                }
                if (this.defaultCruiseLines !== null && this.defaultCruiseLines !== '') {
                    this.defaultCruiseLines = this.defaultCruiseLines.split(',');
                    this.defaultCruiseLines = this.defaultCruiseLines.map(function (el) {
                        return el.trim();
                    });
                }
                if (this.defaultCruiseShips !== null && this.defaultCruiseShips !== '') {
                    this.defaultCruiseShips = this.defaultCruiseShips.split(',');
                    this.defaultCruiseShips = this.defaultCruiseShips.map(function (el) {
                        return el.trim();
                    });
                }
                if (window.location.href.indexOf("kreuzfahrten") > -1 || this.defaultQsTab === 'qs-kreuzfahrten-tab') {
                    this.parameters.traveltype._traveltype = 'kreuzfahrten';
                } else if (window.location.href.indexOf('rundreisen.html') >= 1) {
                    this.parameters.traveltype._traveltype = 'rundreisen';
                } else if (this.defaultQsTab === 'qs-kreuzfahrten-tab') {
                    this.parameters.traveltype._traveltype = 'hotel';
                }

            },

            initCruiseWithBackendParameters() {
                let self = this;
                let withPackage = false;
                let withRiver = false;

                if (this.defaultCruiseSeaRiver !== null && this.defaultCruiseSeaRiver !== '') {
                    this.parameters.cruises.requestData = {'sea': 0};
                    withRiver = true;
                }
                if (this.defaultCruiseDestinations !== null && this.defaultCruiseDestinations !== '') {
                    let codes = [];
                    this.defaultCruiseDestinations.forEach(function (code) {
                        codes.push(code);
                    });
                    self.parameters.cruises.requestData['area'] = codes;
                }
                if (this.defaultCruiseLines !== null && this.defaultCruiseLines !== '') {
                    let lines = [];
                    this.defaultCruiseLines.forEach(function (code) {
                        lines.push(code);
                    });
                    self.parameters.cruises.requestData['line'] = lines;
                }
                if (this.defaultCruiseShips !== null && this.defaultCruiseShips !== '') {
                    let ships = [];
                    this.defaultCruiseShips.forEach(function (code) {
                        ships.push(code);
                    });
                    self.parameters.cruises.requestData['ship'] = ships;
                }

                if (this.defaultCruisePackages !== null && this.defaultCruisePackages !== '') {
                    this.parameters.cruises.requestData['package'] = 'packages';
                    withPackage = true;
                }
                this.initCruiseHostData(this.parameters.cruises.requestData, true, withPackage, withRiver);
            },

            async initAirports() {
                let self = this;
                await axios.get('/ajax/getDepartureAirports')
                    .then(function (data) {
                        (data['data']['response']).forEach(function (value) {
                            if (value['_source'].code === 'BER' || value['_source'].code === 'DUS'
                                || value['_source'].code === 'FRA' || value['_source'].code === 'MUC') {
                                self.parameters.airports._options[GroupNames.BELIEBTE_FLUGHAEFEN].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.BELIEBTE_FLUGHAEFEN,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            }
                            if (value['_source'].regionsCode === 0) {
                                self.parameters.airports._options[GroupNames.NORD_DEUTSCHLAND].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.NORD_DEUTSCHLAND,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            } else if (value['_source'].regionsCode === 1) {
                                self.parameters.airports._options[GroupNames.OST_DEUTSCHLAND].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.OST_DEUTSCHLAND,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            } else if (value['_source'].regionsCode === 2) {
                                self.parameters.airports._options[GroupNames.SUED_DEUTSCHLAND].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.SUED_DEUTSCHLAND,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            } else if (value['_source'].regionsCode === 3) {
                                self.parameters.airports._options[GroupNames.WEST_DEUTSCHLAND].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.WEST_DEUTSCHLAND,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            } else if (value['_source'].countryCode === 'CH') {
                                self.parameters.airports._options[GroupNames.SCHWEIZ].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.SCHWEIZ,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            } else if (value['_source'].countryCode === 'AT') {
                                self.parameters.airports._options[GroupNames.OESTERREICH].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.OESTERREICH,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            } else {
                                self.parameters.airports._options[GroupNames.SONSTIGE_FLUGHAEFEN].push(
                                    self.parameters.airports.buildObject(
                                        GroupNames.SONSTIGE_FLUGHAEFEN,
                                        value['_source'].name,
                                        value['_source'].code
                                    )
                                );
                            }
                        });
                        self.parameters.airports.sortOptions();

                    }).catch(error => {
                        console.log('initAirports error', error)
                    });
            },
            cruiseQuickSearchHasBackendParameters() {
                return (this.defaultCruiseDestinations !== null && this.defaultCruiseDestinations !== '') ||
                    (this.defaultCruiseLines !== null && this.defaultCruiseLines !== '') ||
                    (this.defaultCruiseShips !== null && this.defaultCruiseShips !== '') ||
                    (this.defaultCruisePackages !== null && this.defaultCruisePackages !== '') ||
                    (this.defaultCruiseSeaRiver !== null && this.defaultCruiseSeaRiver !== '');
            },

            initCanvasDestinations(destinations) {
                if (this.parameters.traveltype._traveltype === 'rundreisen') {
                    this.parameters.destinations.addDestination('Rundreisen');
                }
                let list = [];

                $.each(destinations, (indexCountry, valueCountry) => {
                    let entry = {
                        checked: this.parameters.destinations._destinations.indexOf(indexCountry) > -1,
                        code: indexCountry,
                        filtered: false,
                        label: indexCountry,
                        subregions: []
                    };

                    $.each(valueCountry, (indexDestination, valueDestination) => {
                        let subentry = {
                            checked: this.parameters.destinations._destinations.indexOf(indexDestination) > -1,
                            code: valueDestination,
                            label: valueDestination
                        };

                        entry.subregions.push(subentry);
                    });

                    list.push(entry);
                });

                this.parameters.destinations._options.atoz = list;
            },

            initDropdownClicksOutside() {
                var debounce;

                $(document).on('touchend mouseup', function (e) {
                    if (!debounce) {
                        debounce = true;
                        var containers = $(".search-input.show, .search-input.show .dropmenu-wrapper, .ui-autocomplete");
                        if (!containers.is(e.target) && containers.has(e.target).length === 0) {
                            containers.find('.dropmenu-trigger').trigger('click');
                        }
                        setTimeout(function () {
                            debounce = false;
                        }, 50);
                    }
                });
            },

            expandSearch() {
                this.expanded = !this.expanded;
            },

            expandCanvasSearch() {
                this.canvasExpanded = !this.canvasExpanded;
            },

            initAutoscroll() {
                const api = {
                    getDimension(el) {
                        const rect = el.getBoundingClientRect();

                        return {
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height
                        };
                    },
                    getViewport() {
                        const root = document.compatMode === 'CSS1Compat' ? document.documentElement : document.body;

                        return {
                            top: window.pageYOffset || root.scrollTop,
                            left: window.pageXOffset || root.scrollLeft,
                            width: window.innerWidth || root.clientWidth,
                            height: window.innerHeight || root.clientHeight
                        };
                    },
                    getScrollPosition(dimension, viewport) {
                        const dTop = dimension.top;
                        const dLeft = dimension.left;
                        const dWidth = dimension.width;
                        const dHeight = dimension.height;
                        const vWidth = viewport.width;
                        const vHeight = viewport.height;

                        const beyond = dTop >= 0;
                        const above = dTop + dHeight <= vHeight;
                        const right = dLeft >= 0;
                        const left = dLeft + dWidth <= vWidth;

                        const needScrollY = !(beyond && above);
                        const needScrollX = !(left && right);
                        const canFitY = dHeight <= vHeight;
                        const canFitX = dWidth <= vHeight;

                        let deltaY = 0;
                        let deltaX = 0;

                        if (needScrollY) {
                            if (beyond && canFitY) {
                                deltaY = dTop + dHeight - vHeight;
                            } else {
                                deltaY = dTop;
                            }
                        }
                        if (needScrollX) {
                            if (right && canFitX) {
                                deltaX = dLeft + dWidth - vWidth;
                            } else {
                                deltaX = dLeft;
                            }
                        }

                        return {scrollTop: '+=' + deltaY, scrollLeft: '+=' + deltaX};
                    }
                };

                const footer = $('.footer-bar')[0];
                const fHeight = footer ? api.getDimension(footer).height : 0;

                this.$watch('dropdowns', () => {
                    this.$nextTick(() => {
                        const $dropdown = $('.dropmenu-wrapper:visible', this.$el);

                        if (this.dropdowns.destinations) {
                            $('#reiseziel-filter', $dropdown).focus();

                        } else if (this.dropdowns.airports) {
                            $('#airport-filter', $dropdown).focus();
                        }
                        if (this.dropdowns.cruiseAreas) {
                            $('#cruise-area-filter', $dropdown).focus();
                        }

                        if ($dropdown.length) {
                            const iHeight = api.getDimension($dropdown.parent()[0]).height;
                            const viewport = api.getViewport();
                            const dimension = api.getDimension($dropdown[0]);
                            const spacing = 5;

                            viewport.height -= fHeight; // remove footer from viewport
                            dimension.top -= iHeight + spacing; // add input height and little spacing
                            dimension.height += iHeight + spacing * 2;

                            $('html, body').stop().animate(api.getScrollPosition(dimension, viewport), 500);
                        }
                    });
                }, {deep: true});
            },

            initOptionFilter() {
                if (!SKTV.searchFilter) {
                    return;
                }

                const map = {
                    airports: 'departureAirports',
                    catering: 'boards',
                    rooms: 'rooms',
                    operator: 'tourOperators'
                };

                for (let key in map) {
                    if (SKTV.searchFilter[map[key]] !== undefined && Object.keys(SKTV.searchFilter[map[key]]).length > 0) {
                        this.parameters[key].updateFilter(SKTV.searchFilter[map[key]]);
                    }
                }
            },
            setDefaultDate() {
                this.parameters.interval._from = moment().add(10, 'days').format('DD-MM-YYYY');
                this.parameters.interval._to = moment().add(10, 'days').add(3, 'months').format('DD-MM-YYYY');
            },
            updateInterval(args) {
                this.parameters.interval._from = args[0];
                this.parameters.interval._to = args[1];
                this.parameters.interval._setDays(args[2]);
            },
            updateCruiseFrom(args) {
                this.parameters.interval._cruiseFrom = args[0];
                this.parameters.cruises.cruiseHasChanges = true;
                if (args[0] === 'all') {
                    this.parameters.interval._cruiseArrivalFrom = this.parameters.interval._cruiseArrivalFromBackup;
                    this.parameters.cruises.cruiseFromHasInput = false;
                } else {
                    this.parameters.cruises.cruiseFromHasInput = true;
                    this.parameters.interval._cruiseArrivalFrom = moment(args[0], 'DD.MM.YYYY').add(2, 'days').format('DD.MM.YYYY');
                    this.parameters.interval._cruiseFromText = 'Früheste Hinreise: ' + args[0];
                }
            },
            updateCruiseTo(args) {
                this.parameters.cruises.cruiseHasChanges = true;
                this.parameters.interval._cruiseTo = args[0];
                if (args[0] === 'all') {
                    this.parameters.interval._cruiseDepartureTo = this.parameters.interval._cruiseDepartureToBackup;
                    this.parameters.cruises.cruiseToHasInput = false;
                } else {
                    this.parameters.cruises.cruiseToHasInput = true;
                    this.parameters.interval._cruiseDepartureTo = moment(args[0], 'DD.MM.YYYY').subtract(2, 'days').format('DD.MM.YYYY');
                    this.parameters.interval._cruiseToText = 'Späteste Rückreise: ' + args[0];
                }
            },
            updateCruiseDuration(args) {
                this.parameters.cruises.cruiseHasChanges = true;
                let self = this;
                this.parameters.interval._cruiseDuration.forEach(function (duration) {
                    if (args[0] == duration['value']) {
                        duration.selected = true;
                        self.parameters.cruises.cruiseDurationHasInput = true;
                    } else {
                        duration.selected = false;
                        self.parameters.cruises.cruiseDurationHasInput = false;
                    }
                });
                this.parameters.interval._cruiseDurationText = 'Reisedauer: ' + args[0] + ' Tage';
            },

            isMobile() {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.outerWidth < 600) {
                    return true;
                } else {
                    return false;
                }
            },
            updateComponents() {
                this.$forceUpdate();
            }
        },
        created() {
            const match = $(this.$options.el)[0].className.match(/current-step(\d?)/i);
            if (match && match[1]) {
                this.step = parseInt(match[1], 10);
            }

            this.prepareCalendars();
        },
        mounted() {
            this.$nextTick(() => {
                this.getDurationsFromMarkerValues();
                this.initSliders();
                this.initDestinations();
                this.initDropdownClicksOutside();
                this.initAutoscroll();
                this.initAirports().then(r => this.initOptionFilter()).then(t => this.parameters.airports.initAutoComplete());
                this.$nextTick(() => {
                    this.checkCanvasSearch()
                });
                if (!this.cruiseQuickSearchHasBackendParameters) {
                    this.initCruiseHostData();
                } else {
                    this.initCruiseWithBackendParameters();
                }
            });

            eventbus.$on('open-canvas-search', () => this.toggleModal('canvassearch'));
            eventbus.$on('close-canvas-search', () => this.closeModal('canvassearch'));

            window.addEventListener('resize', this.updateComponents);
        },
        watch: {
            'parameters.destinations._destinations'(newVal, oldVal) {
                if (this.hasOwnProperty('$event') && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    for (const prop of ['code', 'hotel', 'offer', 'city']) {
                        if (!this.parameters[prop].isDefault()) {
                            this.parameters[prop].reset();
                        }
                    }
                }
            },
            'parameters.traveltype._traveltype'(newVal, oldVal) {
                if (newVal.indexOf('/') > -1) {
                    window.location = newVal;
                    this.parameters.traveltype._traveltype = oldVal; // prevent search mask from switching
                } else if (newVal === 'hotel') {
                    this.parameters.keywords._transfer = false;
                }
            },
            'modal.quicksearch'() {
                let bodyTag = document.getElementsByTagName('body')[0];
                bodyTag.classList.toggle('overlayOpen');
            },
        }
    };
};
