var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-rating-content";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <img src=\"/typo3conf/ext/theme/Resources/Public/Images/holidaycheck_logo.png\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"col col2 specificRatings\">\n                    Hotel:<br>\n                    <div class=\"hc-rating-inactive\">\n                        <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.hotelRating : stack1)) != null ? stack1.overall : stack1),(depth0 != null ? depth0.totalScore : depth0),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                    </div>\n                    <div class=\"ratingValue\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/roundFloat.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.hotelRating : stack1)) != null ? stack1.overall : stack1),{"name":"roundFloat","hash":{},"data":data}))
    + "<span class=\"ratingLast\">/"
    + alias2(((helper = (helper = helpers.totalScore || (depth0 != null ? depth0.totalScore : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalScore","hash":{},"data":data}) : helper)))
    + "</span></div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"col col2 specificRatings\">Umgebung:<br>\n                    <div class=\"hc-rating-inactive\">\n                        <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.locationRating : stack1)) != null ? stack1.overall : stack1),(depth0 != null ? depth0.totalScore : depth0),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                    </div>\n                    <div class=\"ratingValue\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/roundFloat.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.locationRating : stack1)) != null ? stack1.overall : stack1),{"name":"roundFloat","hash":{},"data":data}))
    + "<span class=\"ratingLast\">/"
    + alias2(((helper = (helper = helpers.totalScore || (depth0 != null ? depth0.totalScore : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalScore","hash":{},"data":data}) : helper)))
    + "</span></div>\n                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"col col2 specificRatings\">Gastronomie:<br>\n                    <div class=\"hc-rating-inactive\">\n                        <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.foodRating : stack1)) != null ? stack1.overall : stack1),(depth0 != null ? depth0.totalScore : depth0),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                    </div>\n                    <div class=\"ratingValue\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/roundFloat.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.foodRating : stack1)) != null ? stack1.overall : stack1),{"name":"roundFloat","hash":{},"data":data}))
    + "<span class=\"ratingLast\">/"
    + alias2(((helper = (helper = helpers.totalScore || (depth0 != null ? depth0.totalScore : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalScore","hash":{},"data":data}) : helper)))
    + "</span></div>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"col col2 specificRatings\">Zimmer:<br>\n                    <div class=\"hc-rating-inactive\">\n                        <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.roomRating : stack1)) != null ? stack1.overall : stack1),(depth0 != null ? depth0.totalScore : depth0),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                    </div>\n                    <div class=\"ratingValue\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/roundFloat.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.roomRating : stack1)) != null ? stack1.overall : stack1),{"name":"roundFloat","hash":{},"data":data}))
    + "<span class=\"ratingLast\">/"
    + alias2(((helper = (helper = helpers.totalScore || (depth0 != null ? depth0.totalScore : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalScore","hash":{},"data":data}) : helper)))
    + "</span></div>\n                </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"col col2 specificRatings\">Sevice:<br>\n                    <div class=\"hc-rating-inactive\">\n                        <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.serviceRating : stack1)) != null ? stack1.overall : stack1),(depth0 != null ? depth0.totalScore : depth0),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                    </div>\n                    <div class=\"ratingValue\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/roundFloat.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.serviceRating : stack1)) != null ? stack1.overall : stack1),{"name":"roundFloat","hash":{},"data":data}))
    + "<span class=\"ratingLast\">/"
    + alias2(((helper = (helper = helpers.totalScore || (depth0 != null ? depth0.totalScore : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalScore","hash":{},"data":data}) : helper)))
    + "</span></div>\n                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                <div class=\"col col2 specificRatings\">Sport und Unterhaltung:<br>\n                    <div class=\"hc-rating-inactive\">\n                        <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.sportRating : stack1)) != null ? stack1.overall : stack1),(depth0 != null ? depth0.totalScore : depth0),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                    </div>\n                    <div class=\"ratingValue\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/roundFloat.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.sportRating : stack1)) != null ? stack1.overall : stack1),{"name":"roundFloat","hash":{},"data":data}))
    + "<span class=\"ratingLast\">/"
    + alias2(((helper = (helper = helpers.totalScore || (depth0 != null ? depth0.totalScore : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalScore","hash":{},"data":data}) : helper)))
    + "</span></div>\n                </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),9,{"name":"compare","hash":{"operator":"<="},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ratings.hbs"),depth0,{"name":"ratings","fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "";
},"21":function(container,depth0,helpers,partials,data) {
    return "                    <p>Fehler, keine Bewertungen vorhanden.</p>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row\">\n                <div class=\"row ibe-bottom-nav\">\n                    <div class=\"col6\">&nbsp;</div>\n                    <div class=\"col6\">\n                        <button id=\"moreReviewsButton\" class=\"morebutton moreReviewsButton right\"\n                                onclick=\"SKTV.IBE.hotelPages.moreReviews(this);\">\n                            <i class=\"fas fa-sync-alt fa-spin\"></i>\n                            weitere Bewertungen anzeigen <i class=\"fas fa-caret-down\"></i>\n                        </button>\n\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"ratingsBox row "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"tt",{"name":"compare","hash":{"operator":"=="},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" >\n    <div class=\"ratingContent col col12\">\n        <h3>Hotelbewertung</h3>\n\n        <div class=\"generalRatings row firstRow\">\n            <div class=\"col ratingBlue\"><span class=\"rubric\">WEITEREMPFEHLUNG</span> <br><span class=\"digit\">"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/round.js")).call(alias1,(depth0 != null ? depth0.recommendation : depth0),{"name":"round","hash":{},"data":data}))
    + "%</span></div>\n            <div class=\"col ratingBlue\"><span class=\"rubric\">BEWERTUNGEN</span> <br><span class=\"digit\">"
    + alias2(((helper = (helper = helpers.countReviews || (depth0 != null ? depth0.countReviews : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"countReviews","hash":{},"data":data}) : helper)))
    + "</span></div>\n\n            <div class=\"col ratingLogo\">\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.type : depth0),"tt",{"name":"compare","hash":{"operator":"!="},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"generalRatings row secondRow\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.hotelRating : stack1)) != null ? stack1.overall : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.locationRating : stack1)) != null ? stack1.overall : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.foodRating : stack1)) != null ? stack1.overall : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.roomRating : stack1)) != null ? stack1.overall : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.serviceRating : stack1)) != null ? stack1.overall : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.sportRating : stack1)) != null ? stack1.overall : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"filterRatings row thirdRow\">\n            <div class=\"filterWrapper row\">\n                <div class=\"filterRatingLabel\">Bewertungen:</div>\n                <div class=\"filterInput\">\n                    <input type=\"text\" id=\"input-review-filter\" name=\"input-review-filter\" placeholder=\"verreist als\"\n                           value=\"\" dat-filter=\"\" readonly>\n                    <span id=\"show-filters\" class=\"fas fa-caret-down\"></span>\n                    <div class=\"filter-dropmenu-wrapper row\">\n                        <div class=\"filter-dropmenu-inner row\">\n                            <ul id=\"filters\">\n                                <li data-filter=\"single\">Single</li>\n                                <li data-filter=\"couple\">Paar</li>\n                                <li data-filter=\"family\">Familie</li>\n                                <li data-filter=\"friends\">Freunde</li>\n                            </ul>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"class allReviews row fourthRow\">\n            <div id=\"reviewsAccordion\" class=\"accordion-spinner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.reviews : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,((stack1 = (depth0 != null ? depth0.reviews : depth0)) != null ? stack1.length : stack1),10,{"name":"compare","hash":{"operator":">="},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});