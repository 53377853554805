let consentStatus = true;

export default function init() {
    if (window.UC_UI && window.UC_UI.isInitialized()) {
        let ucServices = UC_UI.getServicesBaseInfo();
        ucServices.forEach((service, index) => {
            if (service.id === 'S1pcEj_jZX') {
                consentStatus = service.consent.status;
            }
        });
    }

    return consentStatus;
}
