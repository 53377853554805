import BaseModel from './base';
import KeywordsHydrator from '../hydrator/keywords';

export default class KeywordsModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new KeywordsHydrator({
            beach: '_beach',
            club: '_club',
            family: '_family',
            pool: '_pool',
            seascape: '_seascape',
            sport: '_sport',
            transfer: '_transfer',
            wellness: '_wellness',
            internet: '_internet',
            adultshotel: '_adultshotel',
        });
        this.export = false;

        this._prefix = {
            _beach: 'strand',
            _club: 'club',
            _family: 'familie',
            _pool: 'pool',
            _seascape: 'meerblick',
            _sport: 'sport',
            _transfer: 'transfer',
            _wellness: 'wellness',
            _internet: 'internetzugang',
            _adultshotel: 'erwachsenenhotel',
        };

        this._defaults = {
            _beach: false,
            _club: false,
            _family: false,
            _pool: false,
            _seascape: false,
            _sport: false,
            _transfer: false,
            _wellness: false,
            _internet: false,
            _adultshotel: false,
        };

        this.reset();
    }

    toString() {
        return this.isDefault() ? '' : Object.keys(this._defaults).filter(keyword => this[keyword]).map(keyword => this._prefix[keyword]).join('/');
    }
}