;import Cookies from '../../Libs/js.cookie.js';
window.Cookies = Cookies;

class Auth {
    constructor() {
        const fields = ['username', 'password', 'loginType'];

        function isAuthenticated() {
            return !!Cookies.get('sk_auth');
        }

        function authenticate(hash) {
            Cookies.set('sk_auth', hash);
        }

        function deauthenticate() {
            Cookies.remove('sk_auth');
        }

        function submit(payload) {
            return $.ajax('auth/login', {
                method: 'POST',
                data: JSON.stringify(payload)
            }).done((data) => {
                if (data && data.success) {
                    authenticate(data.hash);
                }
            });
        }

        this.do = (...args) => {
            const payload = {};
            fields.forEach((field, idx) => payload[field] = args[idx]);
            return submit(payload);
        };

        this.undo = () => {
            deauthenticate();
        };

        this.check = () => {
            return isAuthenticated();
        };
    }
}

class AuthView {
    constructor(model) {
        this.model = model;

        const $login = $('.alogin');
        const $form = $('.alogin__form');

        $form.on('submit', (e) => {
            e.preventDefault();
            const data = $form.serializeArray().slice(2).map(input => input.value).concat('global');

            this.model.do(...data).always(() => {
                $login.toggle(!this.model.check());
            });
        });

        $login.toggle(!this.model.check());
    }
}

const auth = new Auth();
window.alogin = new AuthView(auth);