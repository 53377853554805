import Vue from 'vue';
import HotelMapView from './hotel-map-view';
import ucStatus from '../utils/ucstatus';

export default Vue.component('hotel-map-link', {
    name: 'HotelMapLink',
    components: { HotelMapView },
    template: `
        <div class="hotel-info-map"  v-if="geoDataFound">
            <a v-on:click="initHotelMapsView">
                <span>Hotel auf der Karte anzeigen</span>
                <img src="typo3conf/ext/theme/Resources/Public/Images/ico-gmaps.png" alt="ico-gmaps.png"/>
            </a>
        </div>`,
    props: {
        hotelname: String,
        category: String,
        region: String,
        ibeLink: String,
        longitude: Number,
        latitude: Number
    },
    data() {
        return {
            geoDataFound: false,
            mapsView: null
        };
    },
    mounted() {
        if (this.checkGeoData(this.longitude, this.latitude)) {
            this.geoDataFound = true;
        }
    },
    watch: {
        longitude: function(newLongitude) {
            if (this.checkGeoData(newLongitude, this.latitude)) {
                this.geoDataFound = true;
            } else {
                this.geoDataFound = false;
            }
        },
        latitude: function (newLatitude) {
            if (this.checkGeoData(this.longitude, newLatitude)) {
                this.geoDataFound = true;
            } else {
                this.geoDataFound = false;
            }
        }
    },
    methods: {
        checkGeoData(long, lat) {
            return long && !isNaN(long) && lat && !isNaN(lat);
        },
        initHotelMapsView() {
            if (this.mapsView === null) {
                const HotelMapsViewClass = Vue.extend(HotelMapView);

                this.mapsView = new HotelMapsViewClass({
                    propsData: {
                        hotelname: this.hotelname,
                        category: this.category.replace('\"', '"'),
                        region: this.region,
                        longitude: this.longitude,
                        latitude: this.latitude,
                        activeHotelId: this.activeHotelId,
                        ibeLink: this.ibeLink,
                    }
                });
                this.mapsView.$mount();

                document.body.appendChild(this.mapsView.$el);
            } else {
                this.mapsView.hotelname = this.hotelname;
                this.mapsView.category = this.category;
                this.mapsView.region = this.region;
                this.mapsView.longitude = this.longitude;
                this.mapsView.latitude = this.latitude;
                this.mapsView.ibeLink = this.ibeLink;

                if(!ucStatus()) {
                    this.mapsView.initOverlay();
                } else {
                    this.mapsView.openMapsView(this.activeHotelId);
                }
            }
        }
    }
});
