var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"box-grey travelinsurance rel hide--mobile hide--tablet\">\n    <a href=\"#\" onclick=\"SKTV.BookingForm.UI.InfoBoxes.removeTravelInsurance();return false;\"><div class=\"remove abs\"><i class=\"fa fa-trash-o\"></i></div></a>\n    <p class=\"bold headline col12\">Ihre Reiseversicherung</p>\n    <p class=\"bold\">"
    + alias2(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</p>\n    <div class=\"row bold\">\n        <div class=\"col9\">Gesamtpreis für alle Reiseteilnehmer</div>\n        <div class=\"col3 tright\">€ "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.price : depth0),true,{"name":"asPrice","hash":{},"data":data}))
    + "</div>\n    </div>\n</div>\n";
},"useData":true});