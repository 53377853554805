window.ParsleyConfig = window.ParsleyConfig || {};

window.ParsleyConfig.validators = {
    paymentvalidator: {

        fn: function (value) {

            if (value.length === 0) {
                return false;
            }

            var type = JSON.parse(value)[0];
            var token = JSON.parse(value)[2];

            // token and type must be set. Only if type is RE or TF, then no token is needed!
            if ((token.length >= 0 && type.length >= 0) || $.inArray(type, ['RE', 'TF'])) {
                return true;
            } else {
                return false;
            }
        },
        priority: 32
    },
    orderidvalidator: {
        fn: function (value) {
            return /^B?\d{6}$/.test(value) || $.Deferred().reject('Bitte tragen Sie eine gültige Bestellnummer ein' +
                    ' (z.B. 159223 oder B157052 - zwischen 6 und 7 Zeichen).');
        },
        priority: 32
    }
};
