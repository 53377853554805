(function ($) {
    var clsList = ['outer-left', 'left', 'center', 'right', 'outer-right'];

    function CFlow(el) {
        this.lastIdx = null;
        this.idx = 0;
        this.el = el;
        this.slides = this.el.querySelectorAll('.cflw__slide');
        this.nav = {
            position: this.el.querySelector('.cflw__nav--position'),
            left: this.el.querySelector('.cflw__nav--left'),
            right: this.el.querySelector('.cflw__nav--right')
        };
        this.clsList = this.slides.length < 5  ? clsList.slice(1,4) : clsList.slice(0);

        this.initNav();
        this.initAutoplay();
        this.render();
    }

    CFlow.prototype.initNav = function () {
        var self = this,
            list = document.createElement('ul');

        this.nav.left.addEventListener('click', function (e) {
            self.prev().render();
        });

        this.nav.right.addEventListener('click', function (e) {
            self.next().render();
        });

        Array.prototype.forEach.call(this.slides, function (slide, i) {
            var item = document.createElement('li');
            if (i === self.idx) {
                item.classList.add('is--active');
            }
            item.addEventListener('click', function () {
                self.idx = Array.prototype.indexOf.call(list.children, this);
                self.render();
            });
            list.appendChild(item);

            slide.querySelector('.cflw__content').addEventListener('click', function () {
                self.idx = i;
                self.render();
            });
        });
        this.nav.position.appendChild(list);
    };

    CFlow.prototype.initAutoplay = function () {
        var speed = this.el.dataset.speed,
            self = this;

        function startInterval() {
            clearInterval(self.timerId);
            self.timerId = setInterval(function () {
                self.next().render();
            }, speed || 5000);
        }

        this.el.addEventListener('mouseenter', function () {
            clearInterval(self.timerId);
        });

        this.el.addEventListener('mouseleave', startInterval);

        startInterval();
    };

    CFlow.prototype.next = function () {
        this.idx++;
        return this;
    };

    CFlow.prototype.prev = function () {
        this.idx--;
        return this;
    };

    CFlow.prototype.render = function () {
        var clsList = this.clsList,
            len = this.slides.length,
            slidesNav = this.nav.position.querySelectorAll('li'),
            idx = this.idx;

        if (idx !== this.lastIdx) {
            Array.prototype.forEach.call(this.slides, function (el, i) {
                var cls = clsList[(len - (idx % len) + Math.floor(clsList.length / 2) + i) % len];

                clsList.forEach(function (cls) {
                    el.classList.remove('cflw__slide--' + cls);
                });
                if (cls) {
                    el.classList.add('cflw__slide--' + cls);
                }

                slidesNav[i].classList[cls === 'center' ? 'add' : 'remove']('is--active');
            });

            this.lastIdx = idx;
        }
    };


    $.widget('sktv.cflow', {
        options: {},
        _create: function () {
            var cf = new CFlow(this.element[0]);
            this.option('_cf', cf);
            this.option('_ht', new Hammer(this.element[0])
                .on('swipeleft', function () {
                    cf.next().render();
                })
                .on('swiperight', function () {
                    cf.prev().render();
                }));

            this._superApply(arguments);
        }
    });
}(window.jQuery));