export default {
    template: `
          <div class="contact-cta" v-on:click="showCModal()">
            <span>
              <b>Kundenservice</b>
            </span>
          </div>
`,
    props: {
        cModalIsOpen: {
            default: false
        }
    },
    methods: {
        showCModal() {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            document.querySelector('.sticky-header').classList.remove('sticky-header--visible');
            let cm = document.querySelector('#contact-modal');
            cm.style.visibility = 'visible';
            let htbd = document.querySelector('.main-wrap');
            htbd.appendChild(cm);
            cm.addEventListener('click', function(evt){

                if(evt.target === document.querySelector('#contact-modal')){
                    document.getElementsByTagName('body')[0].style.overflow = 'auto';
                    document.querySelector('#contact-modal').style.visibility = 'hidden';
                }
            });
            let mCloser = document.querySelector('#closeCModal');
            mCloser.addEventListener('click', function(evt){
                document.getElementsByTagName('body')[0].style.overflow = 'auto';
                document.querySelector('#contact-modal').style.visibility = 'hidden';
            });
        },
    }
};