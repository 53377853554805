import Vue from 'vue';
window.Vue = Vue;
import VideoImageGallery from './video-image-gallery';
import Slick from 'vue-slick';

Vue.component('slick', Slick);

Vue.mixin({ delimiters: ['?@','@'] });
document.addEventListener("DOMContentLoaded", function(event) {
    if(document.getElementById('image-video-gallery__container')) {
        new Vue({
            el: '#image-video-gallery__container',
            components: {VideoImageGallery},
            template: '<video-image-gallery v-bind:images="images" v-bind:preview-images="previewImages" v-bind:videos="videos" v-bind:preview-elements="previewIds" ' +
                'v-bind:hotel-data="hotelData" v-bind:overlay="overlay" preload="none"/>',
            data: function () {
                return {
                    images: images,
                    videos: videos,
                    previewIds: previewIds,
                    previewImages: previewImages,
                    hotelData: data,
                    overlay: overlay
                };
            }
        });
    }
});
