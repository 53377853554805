var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"far fa-thumbs-down\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"far fa-thumbs-up\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    "
    + container.escapeExpression(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstName","hash":{},"data":data}) : helper)))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.firstName : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\n                ";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    "
    + container.escapeExpression(((helper = (helper = helpers.age || (depth0 != null ? depth0.age : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"age","hash":{},"data":data}) : helper)))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.ageGroup : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.ageGroup : stack1), depth0))
    + "\n                ";
},"15":function(container,depth0,helpers,partials,data) {
    return "verreist als Single";
},"17":function(container,depth0,helpers,partials,data) {
    return "verreist als Paar";
},"19":function(container,depth0,helpers,partials,data) {
    return "verreist als Familie";
},"21":function(container,depth0,helpers,partials,data) {
    return "verreist als Freunde";
},"23":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    "
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"text","hash":{},"data":data}) : helper)))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.texts : depth0)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.texts : depth0)) != null ? stack1.GENERAL : stack1), depth0))
    + "\n                ";
},"28":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    "
    + container.escapeExpression(((helper = (helper = helpers.textHotel || (depth0 != null ? depth0.textHotel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"textHotel","hash":{},"data":data}) : helper)))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.texts : depth0)) != null ? stack1.HOTEL : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.texts : depth0)) != null ? stack1.HOTEL : stack1), depth0))
    + "\n                ";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                    <div class=\"col col2 specificRatings\">\n                        Hotel:<br>\n                        <div class=\"hc-rating-inactive\">\n                            <div class=\"hc-rating-active\" style=\"width:"
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.HOTEL : stack1)) != null ? stack1.GENERAL : stack1),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                        </div>\n                        <div class=\"ratingValue\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.HOTEL : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "<span class=\"ratingLast\">/"
    + alias1(alias2(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratingHotel : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <div class=\"col col2 specificRatings\">\n                        Hotel:<br>\n                       <div class=\"hc-rating-inactive\">\n                           <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,(depth0 != null ? depth0.ratingHotel : depth0),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                       </div>\n                       <div class=\"ratingValue\">"
    + alias2(((helper = (helper = helpers.ratingHotel || (depth0 != null ? depth0.ratingHotel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ratingHotel","hash":{},"data":data}) : helper)))
    + "<span class=\"ratingLast\">/"
    + alias2(container.lambda(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n                    ";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                    <div class=\"col col2 specificRatings\">\n                        Umgebung:<br>\n                        <div class=\"hc-rating-inactive\">\n                            <div class=\"hc-rating-active\" style=\"width:"
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.LOCATION : stack1)) != null ? stack1.GENERAL : stack1),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                        </div>\n                        <div class=\"ratingValue\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.LOCATION : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "<span class=\"ratingLast\">/"
    + alias1(alias2(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratingLocation : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <div class=\"col col2 specificRatings\">\n                       Umgebung:<br>\n                       <div class=\"hc-rating-inactive\">\n                           <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,(depth0 != null ? depth0.ratingLocation : depth0),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                       </div>\n                       <div class=\"ratingValue\">"
    + alias2(((helper = (helper = helpers.ratingLocation || (depth0 != null ? depth0.ratingLocation : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ratingLocation","hash":{},"data":data}) : helper)))
    + "<span class=\"ratingLast\">/"
    + alias2(container.lambda(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n                    ";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                        <div class=\"col col2 specificRatings\">\n                            Gastronomie:<br>\n                            <div class=\"hc-rating-inactive\">\n                                <div class=\"hc-rating-active\" style=\"width:"
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.FOOD : stack1)) != null ? stack1.GENERAL : stack1),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                            </div>\n                            <div class=\"ratingValue\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.FOOD : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "<span class=\"ratingLast\">/"
    + alias1(alias2(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                        </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratingFood : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <div class=\"col col2 specificRatings\">\n                        Gastronomie:<br>\n                        <div class=\"hc-rating-inactive\">\n                            <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,(depth0 != null ? depth0.ratingFood : depth0),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                        </div>\n                        <div class=\"ratingValue\">"
    + alias2(((helper = (helper = helpers.ratingFood || (depth0 != null ? depth0.ratingFood : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ratingFood","hash":{},"data":data}) : helper)))
    + "<span class=\"ratingLast\">/"
    + alias2(container.lambda(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n                    ";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                        <div class=\"col col2 specificRatings\">\n                            Zimmer:<br>\n                            <div class=\"hc-rating-inactive\">\n                                <div class=\"hc-rating-active\" style=\"width:"
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.ROOM : stack1)) != null ? stack1.GENERAL : stack1),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                            </div>\n                            <div class=\"ratingValue\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.ROOM : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "<span class=\"ratingLast\">/"
    + alias1(alias2(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                        </div>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratingRoom : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <div class=\"col col2 specificRatings\">\n                        Zimmer:<br>\n                        <div class=\"hc-rating-inactive\">\n                            <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,(depth0 != null ? depth0.ratingRoom : depth0),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                        </div>\n                        <div class=\"ratingValue\">"
    + alias2(((helper = (helper = helpers.ratingRoom || (depth0 != null ? depth0.ratingRoom : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ratingRoom","hash":{},"data":data}) : helper)))
    + "<span class=\"ratingLast\">/"
    + alias2(container.lambda(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n                    ";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                        <div class=\"col col2 specificRatings\">\n                            Service:<br>\n                            <div class=\"hc-rating-inactive\">\n                                <div class=\"hc-rating-active\" style=\"width:"
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.SERVICE : stack1)) != null ? stack1.GENERAL : stack1),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                            </div>\n                            <div class=\"ratingValue\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.SERVICE : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "<span class=\"ratingLast\">/"
    + alias1(alias2(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                        </div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratingService : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                    <div class=\"col col2 specificRatings\">\n                        Service:<br>\n                        <div class=\"hc-rating-inactive\">\n                            <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,(depth0 != null ? depth0.ratingService : depth0),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                        </div>\n                        <div class=\"ratingValue\">"
    + alias2(((helper = (helper = helpers.ratingService || (depth0 != null ? depth0.ratingService : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ratingService","hash":{},"data":data}) : helper)))
    + "<span class=\"ratingLast\">/"
    + alias2(container.lambda(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                    </div>\n                    ";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "                        <div class=\"col col2 specificRatings\">\n                            Sport und Unterhaltung:<br>\n                            <div class=\"hc-rating-inactive\">\n                                <div class=\"hc-rating-active\" style=\"width:"
    + alias1(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.SPORT : stack1)) != null ? stack1.GENERAL : stack1),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                            </div>\n                            <div class=\"ratingValue\">"
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.SPORT : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "<span class=\"ratingLast\">/"
    + alias1(alias2(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                        </div>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ratingSport : depth0),{"name":"if","hash":{},"fn":container.program(61, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                         <div class=\"col col2 specificRatings\">\n                             Sport und Unterhaltung:<br>\n                             <div class=\"hc-rating-inactive\">\n                                 <div class=\"hc-rating-active\" style=\"width:"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/calculatePercentage.js")).call(alias1,(depth0 != null ? depth0.ratingSport : depth0),((stack1 = (data && data.root)) && stack1.totalScore),{"name":"calculatePercentage","hash":{},"data":data}))
    + "%\"></div>\n                             </div>\n                             <div class=\"ratingValue\">"
    + alias2(((helper = (helper = helpers.ratingSport || (depth0 != null ? depth0.ratingSport : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ratingSport","hash":{},"data":data}) : helper)))
    + "<span class=\"ratingLast\">/"
    + alias2(container.lambda(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span></div>\n                         </div>\n                    ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<h3>\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.recommendation : depth0),"false",{"name":"compare","hash":{"operator":"=="},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.recommendation : depth0),"true",{"name":"compare","hash":{"operator":"=="},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <span class=\"averageRating\">"
    + alias4(((helper = (helper = helpers.averageRating || (depth0 != null ? depth0.averageRating : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"averageRating","hash":{},"data":data}) : helper)))
    + "</span>\n    <span class=\"ratingEndingInner\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.GENERAL : stack1)) != null ? stack1.GENERAL : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (data && data.root)) && stack1.totalScore), depth0))
    + "</span> "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n</h3>\n<div class=\"row reviewTextContainer\">\n    <div class=\"col col12\">\n        <div class=\"row\">\n            <div class=\"col\">\n                <strong>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.firstName : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ",\n                </strong> Alter:\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.age : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "                <br>\n                Reisezeit: "
    + alias4(((helper = (helper = helpers.travelDate || (depth0 != null ? depth0.travelDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"travelDate","hash":{},"data":data}) : helper)))
    + " <br>\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"SINGLE",{"name":"compare","hash":{"operator":"=="},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"COUPLE",{"name":"compare","hash":{"operator":"=="},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"FAMILY",{"name":"compare","hash":{"operator":"=="},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"FRIENDS",{"name":"compare","hash":{"operator":"=="},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"single",{"name":"compare","hash":{"operator":"=="},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"paar",{"name":"compare","hash":{"operator":"=="},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"familie",{"name":"compare","hash":{"operator":"=="},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.traveledWith : depth0),"freunde",{"name":"compare","hash":{"operator":"=="},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"col\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.text : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.textHotel : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"col reviewRatings\">\n                <div class=\"row\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.HOTEL : stack1)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.LOCATION : stack1)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.FOOD : stack1)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.program(45, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.ROOM : stack1)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.SERVICE : stack1)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.program(55, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.ratings : depth0)) != null ? stack1.SPORT : stack1)) != null ? stack1.GENERAL : stack1),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});