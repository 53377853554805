import BaseModel from './base';
import DestinationsHydrator from '../hydrator/destinations';

export default class DestinationsModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new DestinationsHydrator({
            destination: '_destinations'
        });

        this._defaults = {
            _destinations: []
        };

        this._options = {
            'popular': [
                {
                    checked: false,
                    code: 'Türkei',
                    label: 'Türkei',
                    subregions: [
                        {
                            checked: false,
                            code: 'Side & Alanya',
                            label: 'Side & Alanya'
                        },
                        {
                            checked: false,
                            code: 'Antalya & Belek',
                            label: 'Antalya & Belek'
                        },
                        {
                            checked: false,
                            code: 'Türkische Riviera',
                            label: 'Türkische Riviera'
                        },
                        {
                            checked: false,
                            code: 'Türkische Ägäis & Halbinsel Bodrum',
                            label: 'Türkische Ägäis & Bodrum'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Kanaren',
                    label: 'Kanaren',
                    subregions: [
                        {
                            checked: false,
                            code: 'Fuerteventura',
                            label: 'Fuerteventura'
                        },
                        {
                            checked: false,
                            code: 'Gran Canaria',
                            label: 'Gran Canaria'
                        },
                        {
                            checked: false,
                            code: 'Teneriffa',
                            label: 'Teneriffa'
                        },
                        {
                            checked: false,
                            code: 'Lanzarote',
                            label: 'Lanzarote'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Griechenland',
                    label: 'Griechenland',
                    subregions: [
                        {
                            checked: false,
                            code: 'Kreta',
                            label: 'Kreta'
                        },
                        {
                            checked: false,
                            code: 'Rhodos',
                            label: 'Rhodos'
                        },
                        {
                            checked: false,
                            code: 'Kos',
                            label: 'Kos'
                        },
                        {
                            checked: false,
                            code: 'Griechenland Festland',
                            label: 'Griech. Festland'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Naher Osten - Vorderer Orient',
                    label: 'Orient',
                    subregions: [
                        {
                            checked: false,
                            code: 'Oman - Muskat & Salalah',
                            label: 'Salalah & Muskat'
                        },
                        {
                            checked: false,
                            code: 'V.A. Emirate - Ras Al Khaimah',
                            label: 'Ras Al Khaimah'
                        },
                        {
                            checked: false,
                            code: 'V.A. Emirate - Dubai',
                            label: 'Dubai'
                        },
                        {
                            checked: false,
                            code: 'V.A. Emirate - Abu Dhabi',
                            label: 'Abu Dhabi'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Ägypten',
                    label: 'Ägypten',
                    subregions: [
                        {
                            checked: false,
                            code: 'Hurghada - Safaga - El Gouna',
                            label: 'Hurghada & El Gouna'
                        },
                        {
                            checked: false,
                            code: 'Nil Kombi Angebot Nilkreuzfahrten',
                            label: 'Nilkreuzfahrten'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Balearen',
                    label: 'Balearen',
                    subregions: [
                        {
                            checked: false,
                            code: 'Mallorca',
                            label: 'Mallorca'
                        },
                        {
                            checked: false,
                            code: 'Ibiza',
                            label: 'Ibiza'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Italien',
                    label: 'Italien',
                    subregions: [
                        {
                            checked: false,
                            code: 'Oberitalienische Seen - Gardasee',
                            label: 'Gardasee'
                        },
                        {
                            checked: false,
                            code: 'Kalabrien',
                            label: 'Kalabrien'
                        }
                    ]
                },
                {
                    checked: false,
                    code: 'Marokko',
                    label: 'Marokko',
                    subregions: [
                        {
                            checked: false,
                            code: 'Atlantikküste: Agadir - Safi - Tiznit',
                            label: 'Agadir'
                        },
                        {
                            checked: false,
                            code: 'Marrakesch',
                            label: 'Marrakesch'
                        }
                    ]
                }
            ],
            'atoz': [
                {
                    checked: false,
                    code: 'Ägypten',
                    label: 'Ägypten'
                },
                {
                    checked: false,
                    code: 'Bulgarien',
                    label: 'Bulgarien'
                },
                {
                    checked: false,
                    code: 'Deutschland',
                    label: 'Deutschland'
                },
                {
                    checked: false,
                    code: 'Dom. Republik',
                    label: 'Dom. Republik'
                },
                {
                    checked: false,
                    code: 'Griechenland',
                    label: 'Griechenland'
                },
                {
                    checked: false,
                    code: 'Indonesien',
                    label: 'Indonesien'
                },
                {
                    checked: false,
                    code: 'Italien',
                    label: 'Italien'
                },
                {
                    checked: false,
                    code: 'Jamaika',
                    label: 'Jamaika'
                },
                {
                    checked: false,
                    code: 'Kanada',
                    label: 'Kanada'
                },
                {
                    checked: false,
                    code: 'Kenia',
                    label: 'Kenia'
                },
                {
                    checked: false,
                    code: 'Kroatien',
                    label: 'Kroatien'
                },
                {
                    checked: false,
                    code: 'Kuba',
                    label: 'Kuba'
                },
                {
                    checked: false,
                    code: 'Madeira - Porto Santo',
                    label: 'Madeira'
                },
                {
                    checked: false,
                    code: 'Malediven',
                    label: 'Malediven'
                },
                {
                    checked: false,
                    code: 'Malta',
                    label: 'Malta'
                },
                {
                    checked: false,
                    code: 'Marokko',
                    label: 'Marokko'
                },
                {
                    checked: false,
                    code: 'Mauritius',
                    label: 'Mauritius'
                },
                {
                    checked: false,
                    code: 'Mexiko',
                    label: 'Mexiko'
                },
                {
                    checked: false,
                    code: 'Oman - Muskat & Salalah',
                    label: 'Oman'
                },
                {
                    checked: false,
                    code: 'Österreich',
                    label: 'Österreich'
                },
                {
                    checked: false,
                    code: 'Portugal',
                    label: 'Portugal'
                },
                {
                    checked: false,
                    code: 'Singapur',
                    label: 'Singapur'
                },
                {
                    checked: false,
                    code: 'Spanien',
                    label: 'Spanien'
                },
                {
                    checked: false,
                    code: 'Sri Lanka',
                    label: 'Sri Lanka'
                },
                {
                    checked: false,
                    code: 'Südafrika',
                    label: 'Südafrika'
                },
                {
                    checked: false,
                    code: 'Thailand',
                    label: 'Thailand'
                },
                {
                    checked: false,
                    code: 'Trinidad und Tobago',
                    label: 'Trinidad und Tobago'
                },
                {
                    checked: false,
                    code: 'Tunesien',
                    label: 'Tunesien'
                },
                {
                    checked: false,
                    code: 'Türkei',
                    label: 'Türkei'
                },
                {
                    checked: false,
                    code: 'USA',
                    label: 'USA'
                },
                {
                    checked: false,
                    code: 'V.A. Emirate',
                    label: 'V.A. Emirate'
                },
                {
                    checked: false,
                    code: 'Zypern Süd',
                    label: 'Zypern'
                }
            ]
        };

        this.flatOptions().forEach(option => option.checked = option.filtered = false);

        this.reset();
    }

    flatOptions() {
        let options = [];

        Object.values(this._options).forEach(group => {
            // options = options.concat(...group);
            options = options.concat(group, ...group.filter(region => region.subregions).map(region => region.subregions));
        });

        return options;
    }

    flatOptionsSearch() {
        let options = [];

        this._options.atoz.forEach(region => {
            options.push({
                code: region.code,
                label: region.label,
                filtered: region.filtered,
                checked: region.checked
            });

            if (typeof(region.subregions) !== 'undefined' && region.subregions.length > 0) {
                region.subregions.forEach(sub => {
                    options.push({
                        code: sub.code,
                        label: region.label + ' | ' + sub.label,
                        filtered: false,
                        checked: sub.checked
                    });
                });
            }
        });

        return options;
    }

    getOptionsByCode(code) {
        return this.flatOptions().filter(option => option.code == code);
    }

    reset() {
        this._destinations = [];
        this.updateOptions();
        return this;
    }

    updateOptions() {
        this.flatOptions().forEach(option => option.checked = this._destinations.indexOf(option.code) > -1);
    }

    select(code) {
        if (this._destinations.indexOf(code) > -1) {
            this._destinations = this._destinations.filter(destination => destination != code);
        } else {
            this._destinations = [...this._destinations, code];
        }

        this.updateOptions();
    }

    isSelected(destination) {
        return this._destinations.indexOf(destination) > -1;
    }

    toggleAll(list) {
        const checked = this._options[list].every(entry => entry.checked);

        this._options[list].forEach(option => this._destinations = this._destinations.filter(destination => destination != option.code));
        if (!checked) {
            this._options[list].forEach(option => this._destinations.push(option.code));
        }

        this.updateOptions();
    }

    addDestination(destination) {
        if(!this._destinations.filter(dest => dest === destination).length > 0){
            this._destinations.push(destination);
        }
        this.updateOptions();
    }

    toString() {
        return this._destinations.map(destination => {
            const options = this.getOptionsByCode(destination);
            return options.length ? [...new Set(options.map(option => option.label))].join(', ') : destination;
        }).join(', ');
    }

    toUrl() {
        return this.isDefault() ? 'weltweit' : this._destinations.map(destination => {
            const options = this.getOptionsByCode(destination);
            return options.length ? [...new Set(options.map(option => option.code))].join(',') : destination;
        }).join(',');
    }

    createInputFieldString() {
        if (this._destinations.length === 1) {
            return this._destinations[0][0].toUpperCase() + this._destinations[0].substring(1);
        } else if (this._destinations.length > 1) {
            return this._destinations.length + ' Reiseziele';
        }
    }
}
