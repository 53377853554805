import BaseModel from './base';
import OperatorHydrator from '../hydrator/operator';

export default class OperatorModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new OperatorHydrator({
            tourOperatorOrigin: '_operators'
        });
        this.export = false;

        this._prefix = 'anbieter';
        this._defaults = {
            _operators: []
        };
        this._options = [];
        this._filter = null;
        this.fetchOptions();
        this.reset();
    }

    select(code) {
        code = code.toLowerCase();
        if (this._operators.map(operator => operator.toLowerCase()).indexOf(code) >= 0) {
            this._operators = this._operators.filter(operator => operator.toLowerCase() !== code);
        } else {
            this._operators = [...this._operators, code];
        }

        this.updateOptions();
    }

    updateOptions() {
        const operators = this._operators.map(operator => operator.toLowerCase());
        const options = [...this._options];
        options.forEach(option => option.checked = operators.indexOf(option.code.toLowerCase()) >= 0);
        this._options = options;
        this._updateFilter();
    }

    _updateFilter() {
        if (this._filter) {
            const _this = this;
            this._options.forEach(
                function(option){
                    const code = option.code.toUpperCase();

                    if(code === "BIG"){
                        // SPECIAL CASE
                        // don't filter BIG (sonnenklar.tv) out  when requested filter holds
                        // "SONN" for sonnenklar.tv in traffics or
                        // "SKTV" for sonnenklar.tv in traveltainment
                        option.filtered = !("SONN" in _this._filter) && !("SKTV" in _this._filter) && !("BIG" in _this._filter);
                    } else {
                        option.filtered = !(code in _this._filter);
                    }

                    if(option.filtered && option.checked){
                        option.checked = false;
                    }
                });
        }
    }

    updateFilter(filter) {
        this._filter = Object.assign({}, filter);
    }

    addOperator(operatorCode) {
        this._operators.push(operatorCode);
    }

    filteredCheckedOptions () {
        return this._options.filter(option => (option.checked && !option.filtered));
    }

    toString(onlyCheckedNotFiltered = true) {
        return this.isDefault() ? '' : (onlyCheckedNotFiltered ? this.filteredCheckedOptions() : this._options.filter(option => option.checked)).map(option => option.label).join(', ');
    }

    toUrl(onlyCheckedNotFiltered = true) {
        return this.isDefault() ? '' : `${this._prefix}(${(onlyCheckedNotFiltered ? this.filteredCheckedOptions().map(option => option.code) : this._operators).join(',').toLowerCase()})`;
    }

    fetchOptions() {
        $.ajax({
            url: SKTV.cdnHost + SKTV.operatorsJson,
            type: 'get',
            dataType: 'json',
            success: (data) => {
                this._options = OperatorModel.prepareOptions(data);
                this.updateOptions();
            }
        });
    }

    filterByType(type) {
        const options = this._options.filter(operator => operator[type]);

        if (options.length) {
            this._operators = [...this._operators].filter(operator => {
                return !!options.filter(option => operator.toLowerCase() === option.code.toLowerCase()).length;
            });

            this.updateOptions();
        }

        return options;
    }

    static prepareOptions(options) {
        return options.map(option => {
            option.checked = option.checked || false;
            option.filtered = option.filtered || false;
            return option;
        });
    }

    createCanvasString() {
        switch( this._operators.length) {
            case 0:
                return '';
            case 1:
                return this.toString();
            default:
                return this._operators.length + ' Anbieter';
        }
    }
}
