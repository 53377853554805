import Vue from 'vue';
window.Vue = Vue;
import HotelMapLink from './components/hotel-map-link';

Vue.mixin({ delimiters: ['?@','@'] });
document.addEventListener("DOMContentLoaded", function(event) {
    if(document.getElementById('hotel-map')) {
        new Vue({
            el: '#hotel-map',
            components: {HotelMapLink},
            template: '<hotel-map-link :hotelname="hotelName" :category="category" :region="region" :ibe-link="ibeLink" :longitude="longitude" :latitude="latitude"/>',
            data: function () {
                const el = document.getElementById('hotel-map');
                return {
                    hotelName: el.dataset.hotelname,
                    category: el.dataset.category,
                    region: el.dataset.region,
                    ibeLink: el.dataset.ibelink,
                    longitude: parseFloat(el.dataset.longitude),
                    latitude: parseFloat(el.dataset.latitude)
                };
            }
        });
    }
});
