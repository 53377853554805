import Vue from 'vue';

window.Vue = Vue;
import SearchModel from './model/search';
import SearchVue from './view/search';
import '../../Scripts/_storage';

class SearchApp {
    constructor() {
        this.parameters = new SearchModel();
        this.storage = new SKTV.Storage('search', 'local', true);

        if (!document.querySelector('#sktv-search')) {
            return;
        }

        Vue.mixin({delimiters: ['?@', '@']});
        this.vue = new Vue(SearchVue(this));
    }

    init(searchObject) {
        /*if(SKTV.searchFilter) {
            if (SKTV.searchFilter.datefrom && moment(searchObject.fromDate.date).isBefore(moment(SKTV.searchFilter.datefrom))) {
                searchObject.fromDate.date = moment(SKTV.searchFilter.datefrom).toDate();
            }
            if (SKTV.searchFilter.dateto && moment(searchObject.toDate.date).isAfter(moment(SKTV.searchFilter.dateto))) {
                searchObject.toDate.date = moment(SKTV.searchFilter.dateto).toDate();
            }
        }*/
        this.parameters.init(searchObject);
        if (this.isDefault()) { // if default try local storage
            this.load();
        }
        return this;
    }

    load() {
        const data = this.storage.getItem('params');
        if (this.storage.getItem('expire') >= Date.now() && data) {
            this.parameters.init(data);
        }
        return this;
    }

    save() {
        const params = {};
        Object.values(this.parameters).forEach(parameter => {
            Object.assign(params, parameter.forExport());
        });
        this.storage.setItem('params', params);
        this.storage.setItem('expire', Date.now() + 30 * 24 * 60 * 60 * 1000);
    }

    toUrl() {
        this.save();
        let isHotelOnly = document.getElementsByClassName("departure-airport")[0].innerHTML.includes('Eigenanreise');
        let directFlight = false;
        if( document.getElementById('directflightcb') != undefined){
            directFlight = document.getElementById('directflightcb').checked;
        }
        if( document.getElementById('directflightcbmobile') != undefined){
            directFlight = document.getElementById('directflightcbmobile').checked || directFlight;
        }
        let retUrl =  '/suchen/' + Object.values(this.parameters).map(parameter => parameter.toUrl()).filter(parameter => parameter).join('/').replace('bestellnummer()/', '');
        let containsHotelParam = retUrl.includes('seart(hotel');
        if(directFlight){
            retUrl = retUrl + '/direktflug';
        }
        if(isHotelOnly && ! containsHotelParam){
            return retUrl + '/reiseart(hotel)';
        }
        return retUrl;
    }

    isDefault() {
        return this.parameters.isDefault();
    }
}

const SKTV = window.SKTV || (window.SKTV = {});
const searchApp = new SearchApp();
SKTV.searchApp = searchApp.init(SKTV.searchObj);

