import PersonsModel from '../../model/persons';

export default {
    data: function () {
        return {
            showBulkBooking: false,
            hotline: $('#hotline').html(),
            isExpanded: this.expanded,
            isValid: this.valid
        };
    },
    props: {
        model: {
            default() {
                return new PersonsModel();
            }
        },
        expanded: {
            default() {
                return false;
            }
        },
        valid: {
            default() {
                return true;
            }
        }
    },
    computed: {
        showError() {
            return !this.isValid && this.model._children.length;
        }
    },
    methods: {
        addAdult() {
            if (this.model._adults == 4) {
                this.showBulkBooking = true;
            }
            this.model.addAdult();
        },
        removeAdult() {
            this.showBulkBooking = false;
            this.model.removeAdult();
        },
        addChild() {
            if (this.model._children.length == 3) {
                this.showBulkBooking = true;
            }
            this.model.addChild();
        },
        removeChild() {
            this.showBulkBooking = false;
            this.model.removeChild();
        },
        reset() {
            this.model.reset();
            this.showBulkBooking = false;
            this.toggle();
        },
        toggle() {
            this.isExpanded = !this.isExpanded;
        },
        validate() {
            const valid = this.model._children.every(child => child !== '--');
            this.$nextTick(() => { this.isValid = valid });
            return valid;
        }
    },
    template: `
          <div  v-if="pageType === 'beschreibung' || pageType === 'angebot'" class="search-input personen col4" :class="{ show: isExpanded, 'is--invalid': showError }">
          <div class="input-and-button-wrap" @click="validate() && toggle()">
            <input class='personen-input' type="text" placeholder="Personen" :value="model.toString()"
                   readonly />
            <button class="dropmenu-trigger personen-trigger">
              <i class="fas fa-caret-down closed"></i>
              <i class="fas fa-caret-down open"></i>
            </button>
          </div>
          <div class="dropmenu-wrapper dropmenu--fixedwidth row">
            <div class="dropmenu-inner row">
    
              <p class='h2 row'>Reiseteilnehmer</p>
              <div class='row erwachsene-kinder-wrapper'>
                <div class="erwachsene col6">
                  <span>Erwachsene</span>
                  <div class="input-counter">
                    <input type="button" value="-" class="minus" @click="removeAdult()">
                    <input type="text" min='1' max='4' size="25" :value="model._adults" class="countErwachsene"
                           disabled>
                    <input type="button" value="+" class="plus" @click="addAdult()">
                  </div>
                </div>
                <div class="kinder-anzahl col6">
                  <span>Kinder</span>
                  <div class="input-counter">
                    <input type="button" value="-" class="minus" @click="removeChild()">
                    <input type="text" min='0' max='3' size="25" :value="model._children.length"
                           class="countKinder" disabled>
                    <input type="button" value="+" class="plus" @click="addChild()">
                  </div>
                </div>
              </div>
    
              <div class="kinder-wrapper row">
                <div class='row kinder-alter' v-for="(child, index) in model._children" v-bind:key="index">
                  <div class="col6">
                    <span>Alter Kind ?@ index + 1 @ bei Rückreise</span>
                  </div>
                  <div class="input-counter col6">
                    <select v-model="model._children[index]">
                      <option value="--">--</option>
                      <option v-for="idx in 17" :value="idx">?@ idx < 2 ? 'unter 2' : idx @</option>
                    </select>
                  </div>
                </div>
                <p class="red" v-show="showError">Bitte geben Sie für jedes Kind ein entsprechendes Alter
                  an!</p>
              </div>
    
              <div class="row" v-show="showBulkBooking">
                <div class="col12 bulkbooking">
                  <p>Wollen Sie mit mehr als vier Erwachsenen oder drei Kindern vereisen?</p>
                  <p>Wollen Sie mehr als ein Zimmer buchen?</p>
                  <p>Unsere Hotline hilft Ihnen gerne dabei weiter:<br>?@ hotline @</p>
                </div>
              </div>
    
              <div class="close-and-safe row">
                <button class='closesearch' @click="reset()">
                  <i class="fas fa-times"></i>
                  Auswahl aufheben
                </button>
                <button class='safesearch' @click="validate() && toggle()"> AUSWAHL ÜBERNEHMEN</button>
              </div>
            </div>
          </div>
          </div>
          <div  v-else class="search-input personen col col4" :class="{ show: isExpanded, 'is--invalid': showError }">
            <div class="input-and-button-wrap" @click="validate() && toggle()">
              <input class='personen-input' type="text" placeholder="Personen" :value="model.toString()"
                     readonly />
              <button class="dropmenu-trigger personen-trigger">
                <i class="fas fa-caret-down closed"></i>
                <i class="fas fa-caret-down open"></i>
              </button>
            </div>
            <div class="dropmenu-wrapper dropmenu--fixedwidth row">
              <div class="dropmenu-inner row">

                <p class='h2 row'>Reiseteilnehmer</p>
                <div class='row erwachsene-kinder-wrapper'>
                  <div class="erwachsene col6">
                    <span>Erwachsene</span>
                    <div class="input-counter">
                      <input type="button" value="-" class="minus" @click="removeAdult()">
                      <input type="text" min='1' max='4' size="25" :value="model._adults" class="countErwachsene"
                             disabled>
                      <input type="button" value="+" class="plus" @click="addAdult()">
                    </div>
                  </div>
                  <div class="kinder-anzahl col6">
                    <span>Kinder</span>
                    <div class="input-counter">
                      <input type="button" value="-" class="minus" @click="removeChild()">
                      <input type="text" min='0' max='3' size="25" :value="model._children.length"
                             class="countKinder" disabled>
                      <input type="button" value="+" class="plus" @click="addChild()">
                    </div>
                  </div>
                </div>

                <div class="kinder-wrapper row">
                  <div class='row kinder-alter' v-for="(child, index) in model._children" v-bind:key="index">
                    <div class="col6">
                      <span>Alter Kind ?@ index + 1 @ bei Rückreise</span>
                    </div>
                    <div class="input-counter col6">
                      <select v-model="model._children[index]">
                        <option value="--">--</option>
                        <option v-for="idx in 17" :value="idx">?@ idx < 2 ? 'unter 2' : idx @</option>
                      </select>
                    </div>
                  </div>
                  <p class="red" v-show="showError">Bitte geben Sie für jedes Kind ein entsprechendes Alter
                    an!</p>
                </div>

                <div class="row" v-show="showBulkBooking">
                  <div class="col12 bulkbooking">
                    <p>Wollen Sie mit mehr als vier Erwachsenen oder drei Kindern vereisen?</p>
                    <p>Wollen Sie mehr als ein Zimmer buchen?</p>
                    <p>Unsere Hotline hilft Ihnen gerne dabei weiter:<br>?@ hotline @</p>
                  </div>
                </div>

                <div class="close-and-safe row">
                  <button class='closesearch' @click="reset()">
                    <i class="fas fa-times"></i>
                    Auswahl aufheben
                  </button>
                  <button class='safesearch' @click="validate() && toggle()"> AUSWAHL ÜBERNEHMEN</button>
                </div>
              </div>
            </div>
          </div>
    `
};