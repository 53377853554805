;(function (window, $) {
    'use strict';

    var Promo = {
        init: function () {

            let imageGalleries = document.getElementsByClassName('promoRegionImageGallery');
            if(imageGalleries.length > 0) {
                for(let i = 0; i < imageGalleries.length; i++) {
                    imageGalleries[i].id = 'promoRegionImageGallery-' + this.createRandomId();
                    SKTV.Common.initSlider(imageGalleries[i].getElementsByClassName('slides')[0], '#' + imageGalleries[i].id);
                }
            }

            let videoGalleries = document.getElementsByClassName('promoRegionVideoGallery');
            if(videoGalleries.length > 0) {
                for(let i = 0; i < videoGalleries.length; i++) {
                    videoGalleries[i].id = 'promoRegionVideoGallery-' + this.createRandomId();
                    SKTV.Common.videoJsGallery.initVideoSlider(videoGalleries[i].getElementsByClassName('slides')[0], '#' + videoGalleries[i].id);
                }
            }

            this.phoneNumber();
            this.linksCloud();
        },

        createRandomId() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16);
        },

        phoneNumber: function () {
            $('a.tel').text($('#hotline').text());
            $('a.tel').attr('href', $('#hotline').attr('href'));
        },
        linksCloud: function () {

            function linksTagForMobile() {
                if ($(window).width() < 960) {
                    $("#linksTagcloud").addClass('mobile');
                }
                else {
                    $("#linksTagcloud").removeClass('mobile');
                    $('#linksTagcloud').jQCloud('update', words);
                }
            }

            if ($('#unsortedLinksArray').length > 0) {
                var links = jQuery.parseJSON($('#unsortedLinksArray').val()),
                    words = [],
                    element;

                $.each(links, function (key, value) {
                    element = {
                        text: value.linksObject.linkLabel,
                        weight: 5,
                        link: value.linksObject.link,
                        html: value.linksObject.html || {}
                    };
                    words.push(element);
                });

                if ($('#linksTagcloud')) {
                    $('#linksTagcloud').jQCloud(words, {
                        autoResize: true,
                        removeOverflowing: false
                    });

                    linksTagForMobile();

                    $(window).resize(function () {
                        linksTagForMobile();
                    });
                }
            }
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.Promo = Promo;
})(window, $);
