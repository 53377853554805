import ES6Promise from "es6-promise";
ES6Promise.polyfill();
import Vue from 'vue';
window.Vue = Vue;
import AlternativeDescriptionOffers from './alternative-description-offers';
import Slick from 'vue-slick';

Vue.component('slick', Slick);

Vue.mixin({ delimiters: ['?@','@'] });
document.addEventListener("DOMContentLoaded", function(event) {
    if(document.getElementById('alternative-description-offers')) {
        new Vue({
            el: '#alternative-description-offers',
            components: {AlternativeDescriptionOffers},
            template: '<alternative-description-offers v-bind:offers="offers"></alternative-description-offers>',
            data: function () {
                return {
                    offers: alternativeDescriptionOffers
                };
            }
        });
    }
});
