export default Vue.component('video-container', {
    template: `
    <div class="skg-video">
        <div class="skg-video__container">
            <span v-if="showNavButtons" class="skg-video__prev" v-on:click="onPrevClick"><i class="fas fa-angle-left"></i></span>
            <div class="skg-video__wrapper">
                <video v-bind:src="src" controls="controls" v-bind:preload="preload" v-bind:ref="'video' + id"
                       v-on:click="videoClicked(this)" v-bind:poster="thumb"></video>
            </div>
            <span v-if="showNavButtons" class="skg-video__next" v-on:click="onNextClick"><i class="fas fa-angle-right"></i></span>
            <p v-if="title" v-html="title"></p>
        </div>
    </div>
    `,
    props: {
        id: String,
        title: String,
        src: String,
        thumb: String,
        showNavButtons: {
            type: Boolean,
            default: false
        },
        onPrevClick: {
            type: Function,
            default: () => {}
        },
        onNextClick: {
            type: Function,
            default: () => {}
        },
        preload: {
            type: String,
            default: 'metadata'
        }
    },
    methods: {
        videoClicked(el) {
            el.paused ? this.play() : this.pause()
        }
    },
    mounted() {
        this.$root.$on('interruptVideos', () => {
            if (this.$refs['video' + this.id] && !this.$refs['video' + this.id].paused) {
                this.$refs['video' + this.id].pause();
            }
        });
    }
});
