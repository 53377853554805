var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"supportContentWrap row\">\n    <div class=\"supportContentText col8\">\n        <br>\n        <br>\n        <h2>Wir sind für Sie da</h2>\n        <p>Wenn Sie Unterstützung mit diesem Produkt brauchen, dann wenden Sie sich bitte an unsere Hotline: Tel.: <b> "
    + alias4(((helper = (helper = helpers.hotline || (depth0 != null ? depth0.hotline : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hotline","hash":{},"data":data}) : helper)))
    + " </b> </p>\n        <p>Zur Identifikation des Produktes nennen Sie dem Support Mitarbeiter bitte folgende Identifikationsnummer:  <b> "
    + alias4(((helper = (helper = helpers.supportCode || (depth0 != null ? depth0.supportCode : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"supportCode","hash":{},"data":data}) : helper)))
    + " </b> </p>\n        <br>\n    </div>\n\n    <div class=\"supportContentImage col4\">\n        <br>\n        <img src=\"/typo3conf/ext/theme/Resources/Public/Images/support2.jpg\" alt=\"support\">\n    </div>\n</div>\n\n";
},"useData":true});