(function (win, doc, $, NS) {
    'use strict';

    $.widget('sktv.gapi', {
        options: {
            gapi: {
                API_KEY: SKTV.Common.getGoogleMapsKey(),
                API_URL: 'https://maps.googleapis.com/maps/api/js?key={{API_KEY}}&libraries=places,geometry',
                CALLBACK_FUNCTION: 'gmapsLoaded',
                infowindows: []
            }
        },
        _buildUrl: function (url, params) {
            return url.replace(/{{[^}]+}}/ig, function (param) {
                return params[param.substring(2, param.length - 2)];
            });
        },
        _injectScript: function () {
            var ref = doc.getElementsByTagName('script')[0],
                script = doc.createElement('script'),
                promise = $.Deferred();

            /**
             * Callback function when google maps is loaded. This function needs to in the GET-params of the calling URL.
             * This function has to be accessible globally.
             * S.a. https://stackoverflow.com/questions/1398657/dynamically-loading-google-maps-apis
             * If you are wondering why onload isn't used: As we are manually setting the type of the script to text/plain
             * which is not a MIME-type recognized as a script, browsers will not be firing events like onload.
             * S.a. https://stackoverflow.com/questions/18059796/javascript-onload-callback-for-type-or-rel-text-plain-on-script-or-link-t
             */
            window.gmapsLoaded = function(){
                script = script.onload = script.onreadystatechange = null;
                promise.resolve();
            };

            script.type = 'text/plain';
            script.dataset.usercentrics = 'Google Maps';
            script.src = this._buildUrl(this.option('gapi.API_URL'), this.options.gapi) + '&callback=' + this.options.gapi.CALLBACK_FUNCTION;
            ref.parentNode.insertBefore(script, ref);

            return promise;
        },
        _getCreateOptions: function () {
            var gapi = $.sktv.gapi.prototype.options.gapi;
            if (!gapi.promise) {
                gapi.promise = this._injectScript();
            }

            return {
                gapi: {
                    promise: gapi.promise
                }
            };
        },
        _queue: function (next) {
            var self = this, args = arguments;
            return this.option('gapi.promise').then(function () {
                var promise = $.Deferred();
                promise.resolveWith(self, next.apply(self, Array.prototype.slice.call(args, 1)));
                return promise;
            });
        }
    });

    $.widget('sktv.gmap', $.sktv.gapi, {
        options: {
            gmap: {
                width: '100%',
                height: 550,
                center: {lat: 50.79302077, lng: 10.09747438},
                zoom: 6,
                markers: []
            }
        },
        _create: function () {
            this._queue(this._initMap)
                .then(this._initSearch)
                .then(this._initSelect)
                .then(this.resize)
                .then(this._initMarkers);
        },
        _createMarker: function (opts) {

            var map = this.option('gmap.map');
            var self = this;
            var infowindow = new google.maps.InfoWindow({
                content: '<div>' + opts.info + '</div>',
                maxWidth: 230
            });
            this.option('gapi.infowindows').push(infowindow);

            var marker = new google.maps.Marker($.extend({
                position: this.option('gmap.center'),
                map: this.option('gmap.map')
            }, opts));

            marker.addListener('click', function () {
                $.each(self.option('gapi.infowindows'), function (idx, iw) {
                    iw.close();
                });

                infowindow.open(map, marker);
                map.setCenter(marker.getPosition());

                // content images of all infowindows are loaded on object creation!?
                // in fact of very large travel agency images we are loading them only
                // on infowindow open with some lazy logic
                $('#reisebueros .lazy').attr('src', $('#reisebueros .lazy').data('src')).removeClass('lazy');
            });

            return marker;
        },
        _initMap: function () {
            var self = this, map, mc;
            this.option('gmap.map', map = new google.maps.Map(this.element[0], {
                center: this.option('gmap.center'),
                fullscreenControl: false,
                clickableIcons: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoom: this.option('gmap.zoom')
            }));
            this.option('gmap.mc', mc = new MarkerClusterer(map, [], {
                imagePath: '/typo3conf/ext/theme/Resources/Public/maps-images/m',
                maxZoom: 15,
            }));
            this.option('gmap.markers', mc.getMarkers());
            var resetBtn = new this._initReset(doc.createElement('div'), map);
            resetBtn.index = 1;
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(resetBtn);

            var southWest = new google.maps.LatLng(54.978, 3.835),
                northEast = new google.maps.LatLng(47.356, 15.31),
                bounds = new google.maps.LatLngBounds(southWest, northEast);
            map.fitBounds(bounds);

            resetBtn.addEventListener('click', function () {
                self._resetSearch();
            });
        },
        _initSearch: function () {
            var self, completer;
            function drawRoute(place) {
                self._updateHome(place.geometry.location);
            }
            $('form#gmap-reisebueros').submit(function (evt) {
                evt.preventDefault();

                var service = new google.maps.places.PlacesService(self.option('gmap.map')),
                    germany = new google.maps.LatLng(51.165691, 10.451526000000058),
                    radius = 450000,
                    searchText = self.option('search').val();

                if (searchText) {
                    service.textSearch({
                        location: germany,
                        radius: radius,
                        query: searchText
                    }, function (results, status) {
                        if (status === 'OK' && results && typeof results !== "undefined" && results.length > 0 && results[0].geometry) {
                            drawRoute(results[0]);
                            self._resetSelect();
                        }
                    });
                } else {
                    alert('Adressfeld leer!');
                }
                return false;
            });

            if (this.option('search') && !this.option('gmap.autocomplete')) {
                var self = this,
                    completer = new google.maps.places.Autocomplete(this.option('search')[0], {
                        componentRestrictions: {country: 'de'}
                    });
                this.option(
                    'gmap.autocomplete',
                    completer
                );
                completer.addListener('place_changed', function () {
                    var place = completer.getPlace();
                    if (place.geometry) {
                        drawRoute(place);
                        self._resetSelect();
                    }
                });
            }
        },
        _initSelect: function () {
            var self = this,
                data = this.option('data'),
                select = this.option('select');

            if (select && data && data.cityList) {
                $.each(data.cityList, function (name, value) {
                    select.append($('<option/>', {
                        text: name,
                        value: value
                    }));
                });

                select.change(function (evt) {
                    var selVal = select.find('option:selected').val(),
                        latLngList = selVal.split(';');

                    if (latLngList && typeof latLngList !== undefined && latLngList.length > 1) {
                        // multiples latLng => generate bounds
                        var bnds = new google.maps.LatLngBounds();
                        $.each(latLngList, function (idx, item) {
                            var tmpPosition = JSON.parse(item);
                            bnds.extend(tmpPosition);
                        });
                        self.option('gmap.map').fitBounds(bnds);
                    } else {
                        // single latLng use position/zoomLevel
                        var tmpPosition = JSON.parse(latLngList[0]);
                        self.option('gmap.map').setCenter(tmpPosition);
                        self.option('gmap.map').setZoom(12);
                    }

                    self._resetRoute();
                    self._resetSearchInput();
                });
            }
        },
        _initMarkers: function () {

            var self = this,
                data = this.option('data'),
                markers = this.option('gmap.markers');

            if(data && data.markerList && markers && typeof markers !== undefined){
                $.each(data.markerList, function (idx, marker) {
                var latlng = new google.maps.LatLng(
                    parseFloat(marker.lat),
                    parseFloat(marker.lon)
                );

                //get array of markers currently in cluster
                var allMarkers = markers;

                //final position for marker, could be updated if another marker already exists in same position
                var finalLatLng = latlng;

                //check to see if any of the existing markers match the latlng of the new marker
                if (allMarkers.length !== 0) {
                    for (var i = 0, j = allMarkers.length; i < j; i++) {
                        var existingMarker = allMarkers[i],
                            pos = existingMarker.getPosition();

                        //if a marker already exists in the same position as this marker
                        if (latlng.equals(pos)) {
                            //update the position of the coincident marker by applying a small multipler to its coordinates
                            var newLat = parseFloat(latlng.lat() + (Math.random() - 0.4) / 1500);
                            var newLng = parseFloat(latlng.lng() + (Math.random() - 0.4) / 1500);
                            finalLatLng = new google.maps.LatLng(newLat, newLng);
                        }
                    }
                }

                markers.push(self._createMarker({
                    position: finalLatLng,
                    info: marker.infowindow
                }));
            });
            }
        },
        _initReset: function (button, map) {
            var controlUI = document.createElement('div');
            controlUI.className = 'gm-reset pointer';
            controlUI.title = 'Karte zurücksetzen';
            button.appendChild(controlUI);

            var controlText = document.createElement('div');
            controlText.className = 'inner';
            controlText.innerHTML = '&times;';
            controlUI.appendChild(controlText);

            return button;
        },
        _getBounds: function (markers) {
            var bnds = new google.maps.LatLngBounds();
            $.each(markers, function (idx, marker) {
                bnds.extend(marker.position);
            });

            return bnds;
        },
        _getClosest: function (target, count) {
            var sorted = this.option('gmap.markers').slice(0);

            count = count || 10;

            sorted.sort(function (a, b) {
                var spherical = google.maps.geometry.spherical,
                    dA = spherical.computeDistanceBetween(target.position, a.position),
                    dB = spherical.computeDistanceBetween(target.position, b.position);

                return dA < dB ? -1 : dB < dA ? 1 : 0;
            });

            return sorted.slice(0, count);
        },
        _getDirection: function (destination) {
            var promise = $.Deferred(),
                direction = this.option('gmap.direction');

            if (!direction) {
                this.option('gmap.direction', direction = new google.maps.DirectionsService());
            }

            direction.route({
                origin: this.option('gmap.home').position,
                destination: destination.position || destination,
                travelMode: google.maps.TravelMode.DRIVING
            }, function (result, status) {
                promise.resolve(result);
            });

            return promise;
        },
        _getRenderer: function () {
            var renderer = this.option('gmap.renderer');

            if (!renderer) {
                this.option('gmap.renderer', renderer = new google.maps.DirectionsRenderer({
                    map: this.option('gmap.map'),
                    suppressMarkers: true,
                    preserveViewport: true
                }));
            }
            renderer.setMap(this.option('gmap.map'));

            return renderer;
        },
        _updateHome: function (latlng) {
            var self = this,
                count = 1,
                home = this.option('gmap.home'),
                closestMarker;

            if (!home) {
                this.option('gmap.home', home = this._createMarker({
                    clickable: false,
                    position: latlng,
                    icon: 'http://www.freefontspro.com/themes/blue/images/home_icon.png'
                }));
            }

            home.setPosition(latlng);
            home.setVisible(true);
            closestMarker = this._getClosest(home, count);

            this.option('gmap.map').fitBounds(this._getBounds(closestMarker.concat([home])));

            $.each(closestMarker, function (idx, marker) {
                self._getDirection(marker).then(function (result) {
                    self._getRenderer().setDirections(result);
                });
            });
        },
        _resetSearch: function () {
            this._resetMap();
            this._resetSearchInput();
            this._resetRoute();
            this._resetSelect();
        },
        _resetRoute: function () {
            var home = this.option('gmap.home'),
                renderer = this._getRenderer();
            if (home) {
                home.setVisible(false);
            }
            if (renderer) {
                renderer.setMap(null);
            }
        },
        _resetMap: function () {
            var map = this.option('gmap.map');
            if (map) {
                map.fitBounds(this._getBounds(this.option('gmap.markers')));
            }
        },
        _resetSearchInput: function () {
            var search = this.option('search');
            if (search) {
                search.trigger('blur').val('');
            }
        },
        _resetSelect: function () {
            var select = this.option('select');
            if (select) {
                select.trigger('blur').val(select.find('option:eq(0)').val());
            }
        },
        _resize: function (size) {
            this.option('gmap.width', size.width);
            this.option('gmap.height', size.height);

            google.maps.event.trigger(this.option('gmap.map'), 'resize');
        },
        _center: function (latlng) {
            this.option('gmap.center.lat', latlng.lat);
            this.option('gmap.center.lng', latlng.lng);

            this.option('gmap.map').panTo(this.option('gmap.center'));
        },
        resize: function (width, height) {
            var size = $.extend({
                width: this.option('gmap.width'),
                height: this.option('gmap.height')
            }, typeof width === 'object' ? width : {
                width: width,
                height: height
            });

            this.element.css(size);

            this._queue(this._resize, size);
        },
        center: function (lat, lng) {
            var latlng = $.extend({
                lat: this.option('gmap.center.lat'),
                lng: this.option('gmap.center.lng')
            }, typeof lat === 'object' ? lat : {
                lat: lat,
                lng: lng
            });

            this._queue(this._center, latlng);
        }
    });

    $('.widget--location-finder .widget__element--map').gmap({
        search: $('.widget--location-finder .widget__input--search input'),
        select: $('.widget--location-finder .widget__input--search select'),
        data: SKTV.rbData || {markerList: [], cityList: []}
    });

}(window, window.document, window.jQuery, window.SKTV || (window.SKTV = {})));
