var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"ui-widget-bestprice-day hasoffer "
    + alias4(((helper = (helper = helpers.css || (depth0 != null ? depth0.css : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"css","hash":{},"data":data}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = helpers.dayId || (depth0 != null ? depth0.dayId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dayId","hash":{},"data":data}) : helper)))
    + "\" data-date=\""
    + alias4(((helper = (helper = helpers.ibeDate || (depth0 != null ? depth0.ibeDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ibeDate","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"date "
    + alias4(((helper = (helper = helpers.cssWeekEnd || (depth0 != null ? depth0.cssWeekEnd : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssWeekEnd","hash":{},"data":data}) : helper)))
    + "\">\n        <b>"
    + alias4(((helper = (helper = helpers.day || (depth0 != null ? depth0.day : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"day","hash":{},"data":data}) : helper)))
    + "."
    + alias4(((helper = (helper = helpers.monthName || (depth0 != null ? depth0.monthName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"monthName","hash":{},"data":data}) : helper)))
    + ".</b><br>\n        "
    + alias4(((helper = (helper = helpers.weekDay || (depth0 != null ? depth0.weekDay : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"weekDay","hash":{},"data":data}) : helper)))
    + "\n    </div>\n    <div class=\"priceBarWrap\">\n        <div class=\"priceBar\" data-price=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.personPrice : depth0)) != null ? stack1.value : stack1), depth0))
    + "\">\n            "
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,((stack1 = (depth0 != null ? depth0.personPrice : depth0)) != null ? stack1.value : stack1),true,{"name":"asPrice","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n    <div class=\"checkbox\">\n        <span class=\"radio\"></span>\n    </div>\n</li>";
},"useData":true});