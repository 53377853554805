export default Vue.component('header-slider', {
    template: `
    <div class="header-slider">
        <div>
            <slick ref="headerSlider" v-bind:options="slickOptions">
                <div class="slider-item" v-for="image in images">
                    <img class="slider-image" v-bind:src="image.src" v-bind:alt="hotelName + ' ' + image.title" />
                </div>
            </slick>
        </div>
        <div class="deprecated-image-layer" v-if="isDeprecated">
            <img v-bind:src="deprecateIcon" alt="derzeit nicht buchbar">
        </div>
    </div>
    `,
    props: {
        images: Array,
        hotelName: String,
        isDeprecated: Boolean,
        deprecateIcon: String
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 3,
                slidesToScroll: 1,
                lazyLoad: 'ondemand',
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 1,
                            initialSlide: 1,
                            centerMode: true,
                            variableWidth: true
                        }
                    }
                ]
            }
        };
    },
    mounted() {
        document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
    },
    methods: {
        next() {
            this.$refs.headerSlider.next();
        },

        prev() {
            this.$refs.headerSlider.prev();
        },

        reInit() {
            this.$nextTick(() => {
                this.$refs.headerSlider.reSlick();
            });
        },
    }
});
