;import Cookies from '../Libs/js.cookie.js';
window.Cookies = Cookies;

(function (window, $) {
	'use strict';

	var cookieSet = false;

	var Newsletter = {
		//nlTeaser: $('#sktv_newsletter_teaser').parsley({
		//	focus: 'first',
		//	errorsContainer: '.sktv-newsletter .error-list'
		//}),
		init: function () {
			this.newsletterCheckboxes();
			this.newsletterDepartureAirports();
			this.initParsleyValidation();
		},
		initParsleyValidation: function (){
			var $form = $('.newsletter-form');
			if ($form.length > 1) {
				$form.parsley();
			}
		},
		showLayer: function () {
			// TODO show layer only on pages with no newsletter registration forms except the newsletter teaser
			// TODO appearance on vip pages => defined over vip.css => how to handle specific vip text?
			$.ajax({
				url: 'layer/newsletter/current',
				success: function (data) {
					var $nlForm = $(data).filter('.main-wrap'),
						width = $nlForm.find('.newsletter-layer').data('width'),
						height = $nlForm.find('.newsletter-layer').data('height');
					$('body').overlayBase({
						content: $nlForm.html(),
						style: 'style="width:' + width + 'px;height:' + height + 'px;"'
					});
					SKTV.Newsletter.init();
				}
			});

		},
		newsletterCheckboxes: function () {
			$(".checkbox-col").on("click", function (event) {
				if ($(event.target).is(':not(label, input)')) {
					var $chkbox = $(event.target).closest('.checkbox-col').find(".nlBox");
					$chkbox.prop('checked', !$chkbox.prop('checked')).trigger('change');
				}
			});

			$(".nlBox").change(function (event) {
				var form = $(this).closest('form');
				var confirmationBox = form.siblings('.newsletter-confirmation');
				var checkboxStatus = event.target.checked;

				SKTV.Newsletter.nlBoxValidation(form);

				//toggle specific confirmation for each newsletter
				confirmationBox.find("#confirmation-" + $(this).attr("id")).toggle(checkboxStatus);
				$(this).closest('.newsletteroption').toggleClass('checked', checkboxStatus);

				if(this.id === 'sk_telefon') {
					var checkboxStatus = $(this).is(':checked');
					form.find("#purchaser_telephone").prop('required',checkboxStatus);
					form.find("#telephone").prop('required',checkboxStatus);
				}

			});

		},
		newsletterDepartureAirports: function () {
			var $daSelect = $('#fce-newsletter-abflughafen');

			if ($daSelect.length > 0) {
				$.when(
					$.getJSON('typo3conf/ext/theme/Resources/Public/SearchData/airports.json')
				)
					.done(function (airports) {
						var sortedAP = [],
							options = [];

						// collect objects for sorting
						$.each(airports, function (index, val) {
							sortedAP.push(val);
						});

						// sorting airports
						sortedAP.sort(function(a,b){
							var nameA = a.name.toUpperCase(); // ignore upper and lowercase
							var nameB = b.name.toUpperCase(); // ignore upper and lowercase
							if (nameA < nameB) {
								return -1;
							}
							if (nameA > nameB) {
								return 1;
							}

							// names must be equal
							return 0;
						});

						// build options for select
						$.each(sortedAP, function (index, val) {
							options.push('<option value="' + val.code + '">' + val.name + '</option>');
						});

						// append ap options
						$daSelect.append(options.join(''));

					})
					.fail(function () {
						throw new Error('Error loading airports for newsletter form!');
					});
			}
		},
		subscribeNewsletter: function (form, event) {
			event.preventDefault();
			if (!$(form).parsley().isValid()) {
				SKTV.Newsletter.nlBoxValidation(form);
				return false;
			}
			$.ajax({
				url: 'ajax/subscribeToNewsletter?' + $(form).serialize(),
				type: "GET",
				dataType: 'json',
				beforeSend: function (xhrObj) {
					$(form).children('.newsletter-loading-layer').show();
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log('subscription error');
				},
				success: function(data) {
					if (data.success) {
						var newsletterWrapper = $(form).parent();
						var newsletterConfirmation = $(form).siblings('.newsletter-confirmation');
						var email = $(form).find('input[name="email"]').first().val();

						//Replace wrapper-content with confirmation-content
						$(newsletterWrapper).html(newsletterConfirmation.html());

						//if there is a co-registration adopt the email-address
						$(newsletterWrapper).find('input[name="email"]').val(email);

						//Scroll to the new content
						SKTV.UI.scrollTo(newsletterWrapper);
					} else {
						console.log('Newsletter-Subscription not successful');
					}
				}
			});
		},
		nlBoxValidation: function (form) {
			if ($(form).parsley().isValid({group: 'nlBoxes'})){
				$(form).find('.nlBoxes-error-message').hide();
			} else {
				$(form).find('.nlBoxes-error-message').show();
			}
		},
	};

	window.SKTV = window.SKTV || {};
	window.SKTV.Newsletter = Newsletter;

})(window, $);
