import ES6Promise from "es6-promise";
ES6Promise.polyfill();
import Vue from 'vue';
window.Vue = Vue;
import Slick from 'vue-slick';
import HeaderSlider from './header-slider';

Vue.component('slick', Slick);

Vue.mixin({ delimiters: ['?@','@'] });
document.addEventListener("DOMContentLoaded", function(event) {
    if (document.getElementById('header-slider')) {
        new Vue({
            el: '#header-slider',
            components: {HeaderSlider},
            template: `
            <header-slider v-bind:images="images" v-bind:hotelName="hotelName" v-bind:isDeprecated="isDeprecated" v-bind:deprecateIcon="deprecateIcon"></header-slider>
            `,
            data() {
                return {
                    images: headerImages,
                    hotelName: hotelName,
                    isDeprecated: isDeprecated,
                    deprecateIcon: deprecateIcon
                };
            }
        });
    }
});
