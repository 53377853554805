// main entry point

$(document).ready(function(){
	SKTV.Mediathek.Search.init();
	SKTV.Mediathek.Common.loadResourcesAndInit();
});

// wait that everything is loaded
$(window).load(function () {

});

// handle window resize
$(window).resize(function(){

});
