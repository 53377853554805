export default {
    template: `
          <modal id="contact-modal" class="contact-modal" style="visibility: hidden;">
            <div class="cMWrap">
            
                <div class="cust-service-div">
                  <h4 class="c-s-h">
                    Unser Kundenservice
                  </h4>
                  <h3 class="modalCloser" id="closeCModal">
                    &#x2715;
                  </h3>
                </div>
              
                <div class="cModalContent">
                  
                  <div class="cm-info-div">
                    <h5><div class="infoicon">&#9432</div>  Wichtiger Hinweis:</h5>
                    <p>
                      Aufgrund der aktuell erhöhten Nachfrage kann es bei unserer telefonischen Hotline zu längeren Wartezeiten kommen.
                    </p>
                    <p>
                      Wir haben deshalb die wichtigsten Fragen und Antworten auf unserer <a class="supp-link" href="/service-und-hilfe/hilfe-und-kontakt.html">Hilfe und Kontakt-</a>Seite für Sie bereitgestellt.
                    </p>
                  </div>
                  
                  <div class="cMRow">
                    <div class="light-blue-div">
                      <h5 class="red-header">
                        Hilfe & Kontakt
                      </h5>
                      <p>
                        In unserem Kontakt und Hilfebereich finden Sie die wichtigsten Fragen 
                        und Antworten zu Themen wie:
                      </p>
                      <ul>
                        <li>
                          <table>
                            <tr>
                              <td>
                                <i class="fas fa-check bluehook"></i>
                              </td>
                              <td>
                                <div class="cMLi">Zahlung und Stornierung</div>
                              </td>
                            </tr>
                          </table>
                        </li>
                        <li>
                          <table>
                            <tr>
                              <td>
                                <i class="fas fa-check bluehook"></i>
                              </td>
                              <td>
                                <div class="cMLi">Corona: Reiseschutz, Einreise, Rückerstattung</div>
                              </td>
                            </tr>
                          </table> 
                        </li>
                        <li>
                          <table>
                            <tr>
                              <td>
                                <i class="fas fa-check bluehook"></i>
                              </td>
                              <td>
                                <div class="cMLi">Fragen zur Buchung</div>
                              </td>
                            </tr>
                          </table> 
                        </li>
                      </ul><!--
                      <p>
                        Falls Sie dennoch keine Antwort auf Ihre Frage finden, nutzen Sie einfach unser <a class="supp-link" href="/kontakt.html">Kontaktformular</a>.
                      </p>
                      <div class="cMLinkwrap">
                        <a class="cMFAQLink" href="/service-und-hilfe/hilfe-und-kontakt.html">
                          ZUR HILFE & KONTAKT SEITE
                        </a>
                      </div>-->
                    </div>
    
                    <div class="light-blue-div">
                      <h5 class="red-header">
                        Service Hotline
                      </h5>
                        <p>
                          Benötigen Sie Hilfe oder eine Beratung zu Ihrer Reisebuchung?
                          Kontaktieren Sie uns gerne telefonisch.
                        </p>
                      <p>
                        Sie haben bereits eine Buchung? Bitte halten Sie Ihre Buchungsnummer bereit.
                      </p>
                      <p>
                        Sie haben Ihre Buchung über ein anderes Reisebüro getätigt? 
                        Bitte wenden Sie sich direkt an dieses Reisebüro.
                      </p>
                      <p class="cMBlueBold">
                        ?@ service.text @
                      </p>
                      <p class="cMBlueSmall">
                        Hinweis: Aktuell kann es zu längeren Wartezeiten kommen.
                      </p>
                      <p class="cMBlueFat">
                        DE: ?@ phone.de @
                      </p>
                      <p class="cMBlueFat">
                        AT: ?@ phone.at @
                      </p>
                      <p class="cMBlueFat">
                        CH: ?@ phone.ch @
                      </p>
                    </div>
                  </div>
                  
                </div>
              
              
            </div>
          </modal>
`,
    data() {
        return {
            service: {
                text: ''
            },

            phone: {
                de: '',
                at: '',
                ch: ''
            }
        };
    },
    mounted() {
        function getText(query) {
            const el = document.querySelector(query);
            return el ? el.innerText : '';
        }

        this.service.text = getText('#sp-down').trim();

        this.phone.de = getText('#hotline').trim();
        this.phone.at = getText('#sp-up a:nth-child(1)').trim();
        this.phone.ch = getText('#sp-up a:nth-child(2)').trim();
    }
};
