export default {
    template: `
      <transition class="slide-group" name="slide" mode="out-in">
      <div class="sk-modal slide-item"
           :class="{ 'sk-modal--fullscreen': fullscreen, 'sk-modal--no-padding': noPadding}">
        <div class="sk-modal__header" v-show="hasHeaderSlot">
          <div class="sk-modal__header-back" @click.self="onClose()">zurück</div>
          <div class="sk-modal__header-title">
            <slot name="header"></slot>
          </div>
        </div>
        <div class="sk-modal__content">
          <slot name="content"></slot>
        </div>
        <div class="sk-modal__footer">
          <slot name="footer"></slot>
          <template v-if="hasFooterSlot"></template>
          <template v-else>
            <button class="sk-modal__footer-button" @click.self="onClose()">Übernehmen</button>
          </template>
        </div>
      </div>
      </transition>
    `,
    data: function () {
        return {
            fullscreen: true,
            parentElement: null
        };
    },
    props: {
        noPadding: {
            default: function () {
                return false;
            }
        },
    },
    computed: {
        hasHeaderSlot() {
            return !!this.$slots.header;
        },
        hasContentSlot() {
            return !!this.$slots.content;
        },
        hasFooterSlot() {
            return !!this.$slots.footer;
        },
    },
    methods: {
        onClose() {
            this.$emit('on-close');

            // if( this.parentElement !== null) {
            //     this.parentElement.appendChild(this.$el);
            // }
        },

        onOpen: function (event) {
            this.parentElement = event.target.parentElement;

            let bodyTag = document.getElementsByTagName('body')[0];
            bodyTag.appendChild(this.$el);
        }
    },
    created: function () {
        this.$parent.$on('open-canvas', this.onOpen);
    }
};
