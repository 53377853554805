module.exports = function (array) {

    if (array[0].flightCarrierCode !== 'undefined' &&
        (array[0].flightCarrierCode === 'X1' || array[0].flightCarrierCode === 'X2')
    ) {
        // hide dummy flights X1 for inbound and X2 for outbound
        return '';
    }

    var stops = array.length - 1,
        finaleStops = '';

    if (stops === 0) {
        finaleStops = 'Direktflug'
    } else if (stops === 1) {
        finaleStops = '1 Stop'
    } else {
        finaleStops = stops + ' Stops'
    }
    return finaleStops;
};
