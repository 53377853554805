import CruiseAreasHydrator from "../hydrator/cruiseareas";

export default class CruiseShipModel{
    constructor() {

        this._hydrator = new CruiseAreasHydrator({
            cruiseShip: '_cruiseShips'
        });
        this._options = [];
        this._cruiseTags = [];
        this.cruisePrefix =  'ship-';
        this.cruiseAutoComplete = [];
        this.dataLoaded = false;
        this._cruiseTagsForUrl = [];
    }
}
