export default class Results {

    constructor(props) {
        const instance = this.constructor.instance;
        if (instance) {
            return instance;
        }

        this.constructor.instance = this;

        this.offerEuvias = [];
        this.hotelGiatas = [];
        this.locations = [];
        this.idsEuvia = [];
        this.idsGiata = [];
    }

    reset() {
        this.offerEuvias = [];
        this.hotelGiatas = [];
        this.locations = [];
        this.locationsLocations = [];
        this.idsEuvia = [];
        this.idsGiata = [];
    }
    appendGiataId(id) {
        this.idsGiata.push(id);
    }
    appendEuviaId(id){
        this.idsEuvia.push(id);
    }
    removeOfferEuvias(id){
        this.offerEuvias = [];
    }

    appendOfferEuvia(offerEuvia) {
        this.offerEuvias.push(offerEuvia);
    }
    appendHotelGiata(hotelGiata) {
        this.hotelGiatas.push(hotelGiata);
    }

    setLocations(locations) {
        this.locations = locations;
    }

    appendLocation(location) {
        this.locations.push(location);
        this.locationsLocations.push(location.locations[0]);
    }


}
