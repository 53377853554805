export default {
    template:
        `<div class="datepickr" @click.capture="clickInside">
        <div class="datepickr__backdrop" v-if="backdrop" @click.stop="clickBackdrop"></div>
        <div class="datepickr__wrap datepickr__wrap--header" v-if="header">
          <dpr-submit label="Auswahl übernehmen" @clicked="submit(true)"></dpr-submit>
        </div>
        <div class="datepickr__wrap">
          <dpr-toggle :value="mode" @update="setMode"></dpr-toggle>
          <div>
            <dpr-input :label="fromLabel" :value="fromValue" icon="fa-calendar" :focus="hasFocus
                                ('from')" @clicked="toggle('from')">
                        <calend-r :from="from" :to="to" :first="first" :last="last" :holidays="holidays"
                                :selected="fromSelected" @select="select('from', $event)" slot="content">
                            <dpr-input :value="holidayValue" icon="fa-map-marker" class="destination"
                                    :options="states" :focus="holidayFocus" @clicked="toggleHoliday"
                                    @select="selectHoliday($event)" show-class="hide-on-mobile">
                                <div class="calendr__legend" slot="label">
                                    <span class="calendr__day calendr__day--holiday">Ferientermine</span> und
                                    <span class="calendr__day calendr__day--holiday calendr__day--public">Feiertage</span>
                                </div>
                            </dpr-input>
                        </calend-r>
                    </dpr-input>
                    <dpr-input :label="toLabel" :value="toValue" icon="fa-calendar" :focus="hasFocus('to')"
                            @clicked="toggle('to')">
                        <calend-r :from="from" :to="to" :last="last" :holidays="holidays" :first="from || first"
                                :selected="toSelected" @select="select('to', $event)" slot="content">
                            <dpr-input :value="holidayValue" icon="fa-map-marker" class="destination"
                                    :options="states" :focus="holidayFocus" @clicked="toggleHoliday"
                                    @select="selectHoliday($event)" show-class="hide-on-mobile">
                                <div class="calendr__legend" slot="label">
                                    <span class="calendr__day calendr__day--holiday">Ferientermine</span> und
                                    <span class="calendr__day calendr__day--holiday calendr__day--public">Feiertage</span>
                                </div>
                            </dpr-input>
                        </calend-r>
                    </dpr-input>
                    <dpr-input label="Reisedauer" :value="durationValue" icon="fa-clock"
                            :options="durations" :focus="hasFocus('duration')" @clicked="toggle('duration')"
                            @select="select('duration', $event)" v-if="mode ===
                                        'beliebig' &&  isNoOffer"  ></dpr-input>
                </div>
                <dpr-reset @clicked="reset"></dpr-reset>
            </div>
        </div>`,
    props: {
        locale: {
            default: function () {
                return 'de';
            }
        },
        header: {
            default: function () {
                return false;
            }
        },

        backdrop: {
            default: function () {
                return true;
            }
        },
        format: {
            default: function () {
                return 'DD.MM.YYYY';
            }
        },
        fromDate: {
            default: function () {
                return moment().locale(this.locale).add(10, 'days').format(this.format);
            }
        },
        toDate: {
            default: function () {
                return moment().locale(this.locale).add(10, 'days').add(3, 'months').format(this.format);
            }
        },
        length: {
            default: function () {
                return 'beliebig';
            }
        },
        isExact: '',

        isNoOffer: {
            default: function(){
                if(document.querySelector('#pType') !== null){
                    if(document.querySelector('#pType').getAttribute('value') != 'angebot'){
                        return true;
                    }
                } else {
                    return true;
                }
                    return false;
            }

        }
    },
    data: function () {
        let date = moment().locale(this.locale),
            first = SKTV.searchFilter && SKTV.searchFilter.datefrom ? moment(SKTV.searchFilter.datefrom) : date.clone().add(3, 'days'),
            last = SKTV.searchFilter && SKTV.searchFilter.dateto ? moment(SKTV.searchFilter.dateto) : date.clone().add(2, 'years'),
            from = moment(this.fromDate, this.format, this.locale),
            to = moment(this.toDate, this.format, this.locale),
            duration = String(this.length),
            mode = this.length === 'exakt' ? 'exakt' : 'beliebig',
            holidayCache = {
                bw: 'Baden-Württemberg',
                by: 'Bayern',
                be: 'Berlin',
                bb: 'Brandenburg',
                hb: 'Bremen',
                hh: 'Hamburg',
                he: 'Hessen',
                mv: 'Mecklenburg-Vorpommern',
                ni: 'Niedersachsen',
                nw: 'Nordrhein-Westfalen',
                rp: 'Rheinland-Pfalz',
                sl: 'Saarland',
                sn: 'Sachsen',
                st: 'Sachsen-Anhalt',
                sh: 'Schleswig-Holstein',
                th: 'Thüringen'
            };

        for (let state in holidayCache) {
            holidayCache[state] = {
                name: holidayCache[state],
                data: null
            };
        }

        if(first.isBefore(moment())) {
            first = date.clone().add(3, 'days');
        }

        if (from.isBefore(first, 'day')) {
            from = date.clone().add(9, 'days');
            this.fromDate = from.format(this.format);
            duration = mode = 'beliebig';
        }
        if (to.isAfter(last)) {
            to = last.clone();
            this.toDate = to.format(this.format);
        }

        return {
            date: date,
            first: first,
            last: last,
            from: from,
            to: to,
            focus: '',
            mode: mode,
            duration: duration,
            holidayCache: holidayCache,
            holidayFocus: false,
            holiday: null
        };
    },
    watch: {
        fromDate: function() {
            this.from = moment(this.fromDate, this.format, this.locale);
            if (this.from.isBefore(this.first)) {
                this.from = this.first.clone();
                this.submit();
            }
        },
        toDate: function() {
            this.to = moment(this.toDate, this.format, this.locale);
            if (this.to.isAfter(this.last)) {
                this.to = this.last.clone();
                this.submit();
            }
        },
        length: function() {
            this.duration = String(this.length);
        }
    },
    computed: {
        isExakt: function () {
            return this.mode === 'exakt';
        },
        isNoOffer:  function(){
            if(document.querySelector('#pType') !== null){
                if(document.querySelector('#pType').getAttribute('value') != 'angebot'){
                    return true;
                }
            } else {
                return true;
            }
            return false;
        },
        fromValue: function () {
            return this.from ? this.from.format('dd, DD. MMMM YYYY') : 'Datum wählen';
        },
        toValue: function () {
            return this.to ? this.to.format('dd, DD. MMMM YYYY') : 'Datum wählen';
        },
        fromLabel: function () {
            return this.isExakt ? 'Ihre Anreise <div class="flex_or_exact_chip"> Exakt </div>' : 'Früheste Anreise <div class="flex_or_exact_chip"> Flexibel </div>';
        },
        toLabel: function () {
            return this.isExakt ? 'Ihre Rückreise <div class="flex_or_exact_chip"> Exakt </div>' : 'Späteste Rückreise  <div class="flex_or_exact_chip"> Flexibel </div>';
        },
        durations: function () {
            let options = [{label: 'Beliebige Dauer', value: 'beliebig'}],
                ranges = [{value: '2-3', label: '2 - 3 Tage'},
                    {value: '4-7', label: '4 - 7 Tage'},
                    {value: '5-8', label: '5 - 8 Tage'},
                    {value: '8-11', label: '8 - 11 Tage'},
                    {value: '12-15', label: '12 - 15 Tage'},
                    {value: '16-21', label: '16 - 21 Tage'},
                    {value: '22-30', label: '22 - 30 Tage'},
                    {value: '31-56', label: '31 - 56 Tage'}],
                weeks = [],
                singles = [],
                hasFilter = SKTV.searchFilter && SKTV.searchFilter.durations;

            function createWeekObj(w) {
                return {
                    label: w + (w === 1 ? ' Woche ' : ' Wochen '),
                    value: String((w * 7) + 1) + '-' + String((w * 7) + 1)
                }
            }

            function createDayObj(d) {
                return {
                    label: d + (d === 1 ? ' Tag' : ' Tage'),
                    value: String(d) + '-' + String(d)
                };
            }

            if (this.from && this.to) {
                if (hasFilter) {
                    ranges = [];

                    Object.values(SKTV.searchFilter.durations).forEach((duration) => {
                        if (duration > 1 && duration  % 7 === 1) {
                            weeks.push(createWeekObj(duration / 7));
                        }

                        singles.push(createDayObj(duration));
                    });

                } else {
                    const from = this.from.clone().utc().startOf('day'),
                        to = this.to.clone().utc().startOf('day'),
                        weeksDiff = to.diff(from, 'weeks'),
                        daysDiff = to.diff(from, 'days');

                    for (let w = 1; w <= Math.min(weeksDiff, 8); w++) {
                        weeks.push(createWeekObj(w));
                    }

                    ranges = ranges.filter(function (range) {
                        range = range.value.split('-');
                        return range[1] === '99' ? (daysDiff >= range[0]) : (daysDiff >= range[1]);
                    });

                    for (let d = 2; d <= Math.min(daysDiff, 21); d++) {
                        singles.push(createDayObj(d));
                    }
                }

                if (weeks.length) {
                    weeks.unshift({label: '---w', value: 0});
                }

                if (ranges.length) {
                    ranges.unshift({label: '---r', value: 0});
                }

                if (singles.length) {
                    singles.unshift({label: '---s', value: 0});
                }
            }

            return options.concat(weeks, ranges, singles);
        },
        selectedDurations: function () {
            const value = this.duration === 'exakt' && !this.isExakt ? 'beliebig' : this.duration;

            return this.durations.filter(function (duration) {
                return duration.value === value;
            });
        },
        durationValue: function () {
            const selected = this.selectedDurations;

            return selected.length ? selected[0].label : 'Reisezeitraum wählen';
        },
        states: function () {
            const cache = this.holidayCache;
            return Object.keys(cache).map(function (state) {
                return {
                    label: cache[state].name,
                    value: state
                };
            });
        },
        holidayValue: function () {
            return this.holiday ? this.holidayCache[this.holiday].name : 'Bundesland wählen'
        },
        holidays: function () {
            return this.holidayCache[this.holiday];
        },
        fromSelected: function () {
            return this.from || this.first;
        },
        toSelected: function () {
            return this.fromSelected;
        }
    },
    methods: {
        hasFocus: function (field) {
            return this.focus === field || !this[field];
        },
        select: function (field, value) {
            if (field === 'from') {
                this.from = value;
                this.toggle('to');

                if (value.isSameOrAfter(this.to, 'day')) {
                    this.to = null;
                }
            }
            else if (field === 'to') {
                if (value.isAfter(this.from, 'day')) {
                    this.to = value;
                    this.toggle('to');
                }
            }
            else if (field === 'duration') {
                this.duration = value;
                this.toggle('duration');
            }

            if (!this.isExakt && this.from && this.to && !this.selectedDurations.length) {
                this.duration = null;
                this.toggle('duration');
            }

            this.submit();
        },
        toggle: function (value) {
            if (this.focus === value) {
                value = '';
            }

            if (!this.from) {
                value = 'from';
            }
            else if (!this.to) {
                value = 'to';
            }
            else if (!this.isExakt && !this.duration && this.isNoOffer) {
                value = 'duration';
            }

            if (value === 'from' || value === 'to') {
                this.holidayFocus = false;
            }

            this.focus = value;
        },
        setMode: function (value) {
            this.mode = value;

            if (value === 'exakt') {
                this.$emit('update:isExact', true);
            } else {
                this.$emit('update:isExact', false);
            }
        },
        selectHoliday: function (value) {
            this.holiday = value;
            this.holidayFocus = false;

            if (!this.holidayCache[this.holiday].data) {
                this.fetchHolidays(this.holiday);
            }
        },
        toggleHoliday: function () {
            this.holidayFocus = !this.holidayFocus;
        },
        submit: function (close) {
                this.$emit('submit', this.from.format(this.format), this.to.format(this.format),
                    this.isExakt ? 'exakt' :
                        this.duration !== 'exakt' ? this.duration : 'beliebig');

            if (close) {
                this.$emit('close');
            }
        },
        reset: function () {
            const from = this.date.clone().add(10, 'days'),
                to = from.clone().add(3, 'months');

            this.from = from;
            this.to = to;
            this.duration = this.mode = 'beliebig';
            this.submit(true);
        },
        clickInside: function (e) {
            if (!$(e.target).closest('.datepickr__dropdown').length && !$(e.target).is('.datepickr__backdrop')) {
                this.toggle();
            }
        },
        clickBackdrop: function () {
            if (!this.focus) {
                this.submit(true);
            }
            else {
                this.toggle();
            }
        },
        fetchHolidays: function (state) {
            let url = '/typo3conf/ext/theme/Resources/Public/SearchData/Holidays/',
                cache = this.holidayCache,
                first = this.first.year(),
                last = this.last.year(),
                years = [];

            for (; first <= last; first++) {
                years.push(first);
            }

            $.when.apply($, years.map(function (year) {
                let filename = year <= 2020 ? (year + '-' + state) : 'fallback';
                return $.get(url + filename + '.json');
            })).then(function () {
                const data = {};
                [].forEach.call(arguments, function (year) {
                    if(year[0].jahr.length > 0){
                        data[year[0].jahr] = {
                            ferien: year[0].ferien,
                            feiertage: year[0].feiertage
                        };
                    }
                });

                cache[state].data = data;
            });
        }
    },
    components: {
        dprToggle: {
            template:
                `<div class="datepickr__toggle">
                <a class="datepickr__option" v-for="option in options" v-if="option.value === 'beliebig' || isNoOffer"
                   :class="{ 'oneToggler' : !isNoOffer, 'datepickr__option--active': option.value === value }" @click="click(option.value)">
                  <span style="word-break: keep-all;">?@option.label@</span>&nbsp;
                  <i class="fas fa-check-circle-o"></i>
                </a>
                </div>`,
            props: ['value'],
            data: function () {
                return {
                    options: [
                        {label: 'Flexibler Reisezeitraum', value: 'beliebig'},
                        {label: 'Exakter Reisezeitraum', value: 'exakt'}
                    ],

                };
            },
            methods: {
                click: function (value) {
                    this.$emit('update', value);
                }
            },
            computed: {
                isNoOffer: function(){
                    if(document.querySelector('#pType') !== null){
                        if(document.querySelector('#pType').getAttribute('value') != 'angebot'){
                            return true;
                        }
                    } else {
                        return true;
                    }
                    return false;
                }

            },
        },
        dprSubmit: {
            template:
                `<a class="datepickr__submit" @click.stop="click">
                ?@ label @
                </a>`,
            props: ['label'],
            methods: {
                click: function () {
                    this.$emit('clicked');
                }
            }
        },
        dprReset: {
            template:
                `<a class="datepickr__reset" @click.stop="click">
                <i class="fas fa-times"></i>&nbsp;
                <span>Auswahl aufheben</span>
                </a>`,
            methods: {
                click: function () {
                    this.$emit('clicked');
                }
            }
        },
        dprInput: {
            template:
                `<div class="datepickr__input" :class="clsName">
                <div class="datepickr__lbl" v-if="label">
                  <span v-html="label"></span>
                </div>
                <slot name="label" v-else></slot>
                <div class="datepickr__dropdown">
                  <div class="datepickr__ctrl" :class="{'datepickr__ctrl--focus': focus}" @click="click">
                    <i class="fa" :class="icon"></i>
                    <span>?@ value @</span>
                    <div class="datepickr__ctrl-toggle" v-if="options">
                      <i class="fas fa-caret-up" v-if="focus"></i>
                      <i class="fas fa-caret-down" v-else></i>
                    </div>
                  </div>
                  <div class="datepickr__drop" :class="{'datepickr__drop--scrollable': options}" v-if="focus">
                    <slot name="content">
                      <div v-for="option in options" :key="option.label">
                        <div class="datepickr__drop-separator" v-if="/^---.*/.test(option.label)"></div>
                        <div class="datepickr__drop-item" :class="{'datepickr__drop-item--selected': value === option.label}" @click="select(option.value)" v-else>?@ option.label @</div>
                      </div>
                    </slot>
                  </div>
                </div>
                </div>`,
            props: ['label', 'value', 'icon', 'focus', 'options', 'showClass'],
            computed: {
                clsName: function () {
                    if (this.showClass) {
                        return 'datepickr__input--' + this.showClass;
                    }
                }
            },
            methods: {
                click: function () {
                    this.$emit('clicked');
                },
                select: function (val) {
                    this.$emit('select', val);
                }
            }
        },
        calendR: {
            template:
                `<div class="calendr">
                <clr-nav :first="first" :last="last" :current="current" @update="update({month: $event})">
                  <clr-select :selected="current.month()" :options="monthsAvailable" @update="update({month: $event})"></clr-select>
                  <clr-select :selected="current.year()" :options="yearsAvailable" @update="update({year: $event})"></clr-select>
                </clr-nav>
                <div class="calendr__spacer"></div>
                <clr-month :current="current" :first="first" :last="last" :from="from" :to="to"
                           :holidays="holidays" @select="select"></clr-month>
                <slot></slot>
                </div>`,
            props: ['from', 'to', 'first', 'last', 'selected', 'holidays'],
            data: function () {
                return {
                    current: this.selected.clone(),
                    names: this.selected.localeData().months()
                };
            },
            computed: {
                firstAvailable: function () {
                        return this.current.isBefore(this.first, 'day') ? this.current : this.first;
                },
                lastAvailable: function () {
                        return this.current.isAfter(this.last, 'day') ? this.current : this.last;
                },
                monthsAvailable: function () {
                    let months = [],
                        year = this.current.year(),
                        start = this.firstAvailable.year() === year ? this.firstAvailable.month() : 0,
                        end = this.lastAvailable.year() === year ? this.lastAvailable.month() : 11;

                    for (; start <= end; start++) {
                        months.push({val: start, lbl: this.names[start]});
                    }

                    return months;
                },
                yearsAvailable: function () {
                    let yrs = [],
                        start = this.firstAvailable.year(),
                        end = this.lastAvailable.year();

                    for (; start <= end; start++) {
                        yrs.push({val: start, lbl: start});
                    }

                    return yrs;
                }
            },
            methods: {
                select: function (val) {
                    this.$emit('select', val);
                },
                update: function (update) {
                    let date = this.current.clone().set(update);

                    if (date.isBefore(this.first, 'day')) {
                        date = this.first.clone();
                    }
                    else if (date.isAfter(this.last, 'day')) {
                        date = this.last.clone();
                    }

                    this.current = date;
                }
            },
            components: {
                clrNav: {
                    template:
                        `<div class="calendr__nav">
                        <a class="calendr__btn" :class="{'calendr__btn--disabled': isFirst}" @click="update(-1)"><i class="fas fa-angle-left"></i></a>
                        <slot></slot>
                        <a class="calendr__btn" :class="{'calendr__btn--disabled': isLast}" @click="update(1)"><i class="fas fa-angle-right"></i></a>
                        </div>`,
                    props: ['first', 'last', 'current'],
                    computed: {
                        isFirst: function () {
                            let hidinp = document.getElementById('intervalinfo');
                            if(typeof(hidinp) != 'undefined' && hidinp != null){
                                if(hidinp.getAttribute('value').split('-').length > 1){
                                    let btnurl = hidinp.getAttribute('value');
                                    let favdate = btnurl.split('-')[0];
                                    let isF = moment(favdate);
                                    return isF.isSame(this.current, 'month');
                                } else {
                                    let isF = this.current.isBefore(this.first, 'day') ? this.current : this.first;
                                    return isF.isSame(this.current, 'month');
                                }
                            } else {
                                let isF = this.current.isBefore(this.first, 'day') ? this.current : this.first;
                                return isF.isSame(this.current, 'month');
                            }
                        },
                        isLast: function () {
                            return this.last.isSame(this.current, 'month');
                        }
                    },
                    methods: {
                        update: function (val) {
                            this.$emit('update', this.current.month() + val);
                        }
                    }
                },
                clrMonth: {
                    template:
                        `<div class="calendr__month">
                        <div class="calendr__lbl" v-for="lbl in labels">?@ lbl @</div>
                        <clr-day v-for="day in days" :key="day.valueOf()" :day="day" :current="current" :first="first"
                                 :last="last" :from="from" :to="to" :holidays="holidays" @select="select"></clr-day>
                        </div>`,
                    props: ['current', 'first', 'last', 'from', 'to', 'holidays'],
                    data: function () {
                        return {
                            labels: ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO']
                        };
                    },
                    computed: {
                        days: function () {
                            let first = this.current.clone().startOf('month').startOf('isoWeek');
                            let last = this.current.clone().endOf('month').endOf('isoWeek');
                            let days = [];
                            while (first.isSameOrBefore(last, 'day')) {
                                days.push(first.clone());
                                first.add(1, 'day');
                            }
                            return days;
                        }
                    },
                    methods: {
                        select: function (val) {
                            this.$emit('select', val);
                        }
                    },
                    components: {
                        clrDay: {
                            template:
                                `<div class="calendr__day" :class="{'calendr__day--inactive': inactive, 'calendr__day--disabled':
                                            disabled, 'calendr__day--start': start, 'calendr__day--end': end, 'calendr__day--between': between,
                                            'calendr__day--holiday': holiday, 'calendr__day--public': publicHoliday}" :title="title" @click="select">
                                ?@ day.date() @
                                </div>`,
                            props: ['day', 'current', 'first', 'last', 'from', 'to', 'holidays'],
                            computed: {
                                inactive: function () {
                                    return this.day.month() !== this.current.month();
                                },
                                disabled: function () {
                                    return !this.day.isBetween(this.first, this.last, 'day', '[]');
                                },
                                start: function () {
                                    return this.day.isSame(this.from, 'day');
                                },
                                end: function () {
                                    return this.day.isSame(this.to, 'day');
                                },
                                between: function () {
                                    return this.day.isBetween(this.from, this.to, 'day', '()');
                                },
                                holiday: function () {
                                    let day = this.day,
                                        holiday;
                                    if (this.holidays && this.holidays.data && this.holidays.data[day.year()]) {
                                        this.holidays.data[day.year()].ferien.forEach(function (data) {
                                            if (day.isBetween(data.beginn * 1000, data.ende * 1000, 'day', '[)')) {
                                                holiday = data.title;
                                            }
                                        })
                                    }

                                    return holiday;
                                },
                                publicHoliday: function () {
                                    var day = this.day,
                                        publicHoliday;
                                    if (this.holidays && this.holidays.data && this.holidays.data[day.year()]) {
                                        this.holidays.data[day.year()].feiertage.forEach(function (data) {
                                            if (day.isBetween(data.beginn * 1000, data.ende * 1000, 'day', '[)')) {
                                                publicHoliday = data.title;
                                            }
                                        })
                                    }

                                    return publicHoliday;
                                },
                                title: function () {
                                    let publicHoliday = this.publicHoliday;
                                    let holiday = this.holiday;
                                    return publicHoliday && holiday ? (publicHoliday + '\n' + holiday) : (publicHoliday || holiday);
                                }
                            },
                            methods: {
                                select: function () {
                                    if (!this.disabled) {
                                        this.$emit('select', this.day.clone());
                                        //this.submit(false);
                                    }
                                }
                            }
                        }
                    }
                },
                clrSelect: {
                    template:
                        `<label class="calendr__select" v-if="options">
                        <div class="calendr__lbl">?@ current.lbl @</div>
                        <select v-model="isSelected" @change="update($event.target.value)">
                          <option v-for="opt in options" :key="opt.val" :value="opt.val">?@opt.lbl@</option>
                        </select>
                        </label>`,
                    data: function () {
                        return {
                            isSelected: this.selected
                        }
                    },
                    props: {
                        selected: '',
                        options: {
                            default: function () {
                                return [];
                            }
                        }
                    },
                    computed: {
                        current: function () {
                            const selected = this.isSelected;
                            return this.options.filter(function (opt) {
                                return opt.val === selected;
                            })[0];
                        }
                    },
                    methods: {
                        update: function (val) {
                            this.$emit('update', val);
                        }
                    },
                    watch: {
                        selected: function () {
                            this.isSelected = this.selected
                        }
                    }
                }
            }
        }
    }
}
