import BaseHydrator from './base';

export default class IntervalHydrator extends BaseHydrator {
    hydrate(data, model) {
        for (let key in data) {
            if (key in this._dataMap) {
                let _data = data[key];
                if (typeof _data === 'object' && _data != null && _data.date) {
                    _data = moment(_data.date).format(model._dateFormat);
                }
                else if (key === 'minDuration' && !_data) {
                    _data = 'beliebig';
                }
                model[this._dataMap[key]] = _data;
            }
        }

        let first = moment().add(1, 'day'),
            from = moment(model._from, model._dateFormat),
            to = moment(model._to, model._dateFormat);

        if (from.isBefore(first, 'day') || to.isBefore(first, 'day')) {
            model.reset();
        }

        return model;
    }

    dehydrate(model) {
        const ret = {};
        for (let key in this._dataMap) {
            ret[key] = key === 'fromDate' || key === 'toDate' ? {
                date: moment(model[this._dataMap[key]], model._dateFormat).format('Y-MM-DD 00:00:00'),
                timezone: 'Europe/Berlin',
                timezone_type: 3
            } : model[this._dataMap[key]]
        }

        return ret;
    }
}