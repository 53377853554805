import CruiseAreasHydrator from "../hydrator/cruiseareas";

export default class CruiseAreaModel {
    constructor() {

        this._hydrator = new CruiseAreasHydrator({
            cruiseArea: '_cruiseAreas'
        });

        this._options = [];
        this.cruisePrefix =  'area-';
        this.cruiseAutoComplete = [];
        this.dataLoaded = false;
        this._cruiseTags = [];
        this._cruiseTagsForUrl = [];
    }
}
