import DropdownComponent from './components/dropdown';
import AutocompleteComponent from './components/autocomplete';

const _cache = {};

export default {
    data: () => {
        return {
            dropdowns: {
                durations: false,
                prices: false,
                features: false,
                topics: false,
                operators: false,
                departure: false,
                arrival: false
            },
            options: {
                durations: [
                    {
                        label: 'kürzer als 5 Tage',
                        value: '1'
                    },
                    {
                        label: '5-8 Tage',
                        value: '2'
                    },
                    {
                        label: '9-11 Tage',
                        value: '3'
                    },
                    {
                        label: '12-15 Tage',
                        value: '4'
                    },
                    {
                        label: 'länger als 15 Tage',
                        value: '5'
                    }
                ],
                prices: [
                    {
                        label: 'bis € 1000',
                        value: '1'
                    },
                    {
                        label: '€ 1000 bis € 2000',
                        value: '2'
                    },
                    {
                        label: '€ 2000 bis € 3000',
                        value: '3'
                    },
                    {
                        label: '€ 3000 bis € 4000',
                        value: '4'
                    },
                    {
                        label: 'über € 4000',
                        value: '5'
                    }
                ],
                features: [
                    {
                        label: 'Bahnreise zum Abreiseort',
                        value: '130'
                    },
                    {
                        label: 'Garantierte Durchführung',
                        value: '105'
                    },
                    {
                        label: 'Inklusive Flug',
                        value: '113'
                    },
                    {
                        label: 'Kleine Reisegruppe',
                        value: '115'
                    },
                    {
                        label: 'Zug zum Flug',
                        value: '114'
                    },
                    {
                        label: 'Zug zum Flug 1. Klasse',
                        value: '129'
                    }
                ],
                topics: [
                    {
                        label: 'Abenteuerreisen & Expeditionen',
                        value: '112'
                    },
                    {
                        label: 'Aktivreisen',
                        value: '53'
                    },
                    {
                        label: 'Ärztlich begleitete Reisen',
                        value: '103'
                    },
                    {
                        label: 'Englischsprachige Rundreisen',
                        value: '131'
                    },
                    {
                        label: 'Erlebnisreisen',
                        value: '111'
                    },
                    {
                        label: 'Event-Reisen',
                        value: '62'
                    },
                    {
                        label: 'Familienreisen',
                        value: '117'
                    },
                    {
                        label: 'Foto-Reisen',
                        value: '56'
                    },
                    {
                        label: 'Gruppenreisen',
                        value: '60'
                    },
                    {
                        label: 'Individualreisen',
                        value: '63'
                    },
                    {
                        label: 'junges Reisen',
                        value: '137'
                    },
                    {
                        label: 'Kombinationsreisen',
                        value: '64'
                    },
                    {
                        label: 'Kreuzfahrten',
                        value: '55'
                    },
                    {
                        label: 'Kulinarische Reisen',
                        value: '59'
                    },
                    {
                        label: 'Kunst-/Kulturreisen',
                        value: '66'
                    },
                    {
                        label: 'Luxusrundreisen',
                        value: '69'
                    },
                    {
                        label: 'Mietwagen-/ Selbstfahrerrundreisen',
                        value: '74'
                    },
                    {
                        label: 'Nachhaltige Reisen',
                        value: '127'
                    },
                    {
                        label: 'Naturreisen',
                        value: '71'
                    },
                    {
                        label: 'Preisknaller',
                        value: '13'
                    },
                    {
                        label: 'Rundreisen inkl. Badeaufenthalt',
                        value: '132'
                    },
                    {
                        label: 'Safaris',
                        value: '78'
                    },
                    {
                        label: 'Singles und Alleinreisende',
                        value: '106'
                    },
                    {
                        label: 'Städtereisen',
                        value: '84'
                    },
                    {
                        label: 'Studienreisen',
                        value: '110'
                    },
                    {
                        label: 'Wanderreisen',
                        value: '116'
                    },
                    {
                        label: 'Weihnachten/Silvester',
                        value: '86'
                    },
                    {
                        label: 'Weltreisen',
                        value: '88'
                    },
                    {
                        label: 'Wunderwelten Reisen',
                        value: '138'
                    },
                    {
                        label: 'Zugreisen',
                        value: '7'
                    },
                ],
                operators: [
                    {
                        label: '1AVista',
                        value: '48'
                    },
                    {
                        label: 'a&e Erlebnisreisen',
                        value: '74'
                    },
                    {
                        label: 'ASI Reisen',
                        value: '33'
                    },
                    {
                        label: 'B&T Touristik',
                        value: '96'
                    },
                    {
                        label: 'Bavaria Fernreisen',
                        value: '12'
                    },
                    {
                        label: 'Berge & Meer',
                        value: '75'
                    },
                    {
                        label: 'Chamäleon',
                        value: '10'
                    },
                    {
                        label: 'DERTOUR',
                        value: '32'
                    },
                    {
                        label: 'DIAMIR Erlebnisreisen',
                        value: '49'
                    },
                    {
                        label: 'Dr. Tigges',
                        value: '37'
                    },
                    {
                        label: 'Gebeco',
                        value: '31'
                    },
                    {
                        label: 'Ikarus Tours',
                        value: '14'
                    },
                    {
                        label: 'IT\'S YOUR TRIP',
                        value: '92'
                    }, {
                        label: 'Karawane Reisen',
                        value: '94'
                    },
                    {
                        label: 'Kiwi Tours',
                        value: '5'
                    },
                    {
                        label: 'Lernidee Erlebnisreisen',
                        value: '11'
                    },
                    {
                        label: 'Marco Polo',
                        value: '15'
                    },
                    {
                        label: 'Marco Polo Young Line',
                        value: '29'
                    },
                    {
                        label: 'Maris Reisen',
                        value: '67'
                    },
                    {
                        label: 'Mediplus Reisen',
                        value: '43'
                    },
                    {
                        label: 'MEIER\'S WELTREISEN',
                        value: '34'
                    },
                    {
                        label: 'Oasis Travel',
                        value: '55'
                    },
                    {
                        label: 'reisefieber',
                        value: '91'
                    },
                    {
                        label: 'SKR Reisen',
                        value: '76'
                    },
                    {
                        label: 'Studiosus',
                        value: '3'
                    },
                    {
                        label: 'Südamerika-Line',
                        value: '72'
                    },
                    {
                        label: 'TOUR VITAL',
                        value: '40'
                    },
                    {
                        label: 'TUI Deutschland',
                        value: '64'
                    },
                    {
                        label: 'Wikinger Reisen',
                        value: '66'
                    },
                    {
                        label: 'Windrose Finest Travel',
                        value: '2'
                    }
                ]
            },
            selected: {
                destination: null,
                departure: null,
                arrival: null,
                durations: null,
                prices: null,
                features: null,
                topics: null,
                operators: null
            },
            defaults: {
                durations: null,
                prices: null,
                features: null,
                topics: null,
                operators: null
            },
            parameters: {
                destination() {
                    let destination = this.selected.destination || false;
                    if (typeof destination === 'string' && destination !== '') {
                        destination = `reiseZiel=${destination}&textsearch=${destination}`;
                    } else if (typeof destination === 'object') {
                        const url = [];
                        if (destination.IDLAND) {
                            url.push(`IDLand=${destination.IDLAND}`);
                        }
                        if (destination.IDREGION) {
                            url.push(`IDRegion=${destination.IDREGION}`);
                        }
                        if (destination.IDKONTINENT) {
                            url.push(`IDKontinent=${destination.IDKONTINENT}`);
                        }
                        destination =  url.length && url.join('&');
                    }
                    return destination;
                },
                departure() {
                    const departure = this.selected.departure;
                    return departure ? `datumVon=${departure}` : false;
                },
                arrival() {
                    const arrival = this.selected.arrival;
                    return arrival ? `datumBis=${arrival}` : false;
                },
                durations() {
                    const duration = this.getSelectedOption('durations');
                    return duration ? `IDReiseDauer=${duration.value}` : false;
                },
                prices() {
                    const price = this.getSelectedOption('prices');
                    return price ? `PreisPP=${price.value}` : false;
                },
                features() {
                    const feature = this.getSelectedOption('features');
                    return feature ? `IDReiseAttribut=${feature.value}` : false;
                },
                topics() {
                    const topic = this.getSelectedOption('topics');
                    return topic ? `IDReiseThema=${topic.value}` : false;
                },
                operators() {
                    const operator = this.getSelectedOption('operators');
                    return operator ? `IDVeranstalter=${operator.value}` : false;
                }
            },
            daysLocale: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            dateFormat: 'DD.MM.Y'
        };
    },
    props: {
        traveltype: {
            default() {
                return '';
            }
        }
    },
    methods: {
        getSelectedOption(type) {
            return this.options[type][this.selected[type] !== null ? this.selected[type] : this.defaults[type]];
        },
        getSearchParams() {
            return Object.values(this.parameters).map(parameter => parameter.call(this)).filter(parameter => parameter);
        },
        submitSearch(event) {
            const el = event.currentTarget;
            if (el.classList) {
                el.classList.add('is--busy');
            } else {
                el.className += ' is--busy';
            }

            const base = 'https://rundreisen.sonnenklar.tv/';
            const parameters = [
                'fuseaction=search.main',
                'search=true'
            ]
                .concat(this.getSearchParams()).join('&');
            window.location = encodeURI(`${base}?${parameters}`);
        },
        optionHandler(option) {
            return (active) => {
                this.selected[option] = active;
            };
        },
        toggleDropdown(option) {
            return () => {
                for (const key in this.dropdowns) {
                    this.dropdowns[key] = key === option ? !this.dropdowns[key] : false;
                }
            };
        }
    },
    computed: {
        departureString() {
            if (!this.selected.departure) {
                return '';
            }

            return this.daysLocale[moment(this.selected.departure, this.dateFormat).day()] + ', ' + this.selected.departure;
        },
        arrivalString() {
            if (!this.selected.arrival) {
                return '';
            }

            return this.daysLocale[moment(this.selected.arrival, this.dateFormat).day()] + ', ' + this.selected.arrival;
        }
    },
    components: {
        dropdown: DropdownComponent,
        autocomplete: AutocompleteComponent
    },
    mounted() {
        const $departure = $('#ekolumbus-departure'),
            $arrival = $('#ekolumbus-arrival');

        $(document).ready(() => {
            $departure.datepicker({
                minDate: moment().add(28, 'days').toDate(),
                onSelect: (selected) => {
                    this.dropdowns.departure = false;

                    let departure = moment(selected, this.dateFormat);
                    let arrival = moment(this.selected.arrival, this.dateFormat);

                    if (departure.isValid() && arrival.isValid()) {
                        if (arrival.format('x') < departure.format('x')) {
                            let tmp = departure;
                            departure = arrival;
                            $arrival.datepicker('setDate', this.selected.arrival = tmp.format(this.dateFormat));
                        }
                    }

                    $arrival.datepicker('option', 'minDate', departure.format(this.dateFormat));
                    $departure.datepicker('setDate', this.selected.departure = departure.format(this.dateFormat));
                }
            });

            $arrival.datepicker({
                minDate: moment().add(33, 'days').toDate(),
                onSelect: (selected) => {
                    this.dropdowns.arrival = false;
                    this.selected.arrival = selected;
                }
            });
        });
    }
};