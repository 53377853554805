export default {
    props: {
        cruiseShipPart: {
            default() {
                return [];
            }
        },
        cruisesModel: {
            default() {
                return null;
            }
        }
    },
    data () {
        return {

        }
    },
    methods: {

    },
    template: `
      <div  class="col col3">
      <div class="cruise-ship-search-checkbox"  v-if="ship.visible" v-for="(ship, index) in cruiseShipPart" >
        <input type="checkbox" :id="cruisesModel.cruiseShip.cruisePrefix + ship.code.toLowerCase()"
               :name="cruisesModel.cruiseShip.cruisePrefix + ship.code.toLowerCase() " :value="cruisesModel.cruiseShip.cruisePrefix + ship.code"
               :checked="ship.checked"
               @change="cruisesModel.select(ship.code, 'ship')">
        <label :for="cruisesModel.cruiseShip.cruisePrefix + ship.code.toLowerCase()">
          <span>?@ ship.label @</span>
        </label>
        <div class="cruise-ships-content cruise-ship-search-checkbox">
          <div class="cruise-ship-search-checkbox"
               v-for="subcategory in ship.subValues">
            <input type="checkbox" :id="cruisesModel.cruiseShip.cruisePrefix + subcategory.code.toLowerCase()"
                   :name="subcategory.code.toLowerCase()" :value="cruisesModel.cruiseShip.cruisePrefix + subcategory.code"
                   :checked="subcategory.checked"
                   @change="cruisesModel.select(subcategory.code, 'ship')">
            <label :for="cruisesModel.cruiseShip.cruisePrefix + subcategory.code.toLowerCase()">
              <span>?@ subcategory.label @</span>
            </label>
          </div>
        </div>
      </div>
      </div>
    `
};
