import BaseModel from './base';
import PersonsHydrator from '../hydrator/persons';

export default class PersonsModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new PersonsHydrator({
            adults: '_adults',
            children: '_children'
        });

        this._prefix = 'personen';
        this._defaults = {
            _adults: 2,
            _children: []
        };

        this.reset();
    }

    addAdult() {
        if (this._adults < 4) {
            this._adults += 1;
        }
    }

    removeAdult() {
        if (this._adults > 1) {
            this._adults -= 1;
        }
    }

    addChild() {
        if (this._children.length < 3) {
            this._children.push('--');
        }
    }

    removeChild() {
        this._children.pop();
    }

    toString() {
        return `${this._adults} ${this._adults > 1 ? 'Erwachsene' : 'Erwachsene/r'}${this._children.length ? `, ${this._children.length} ${this._children.length > 1 ? 'Kinder' : 'Kind'}` : ''}`;
    }

    toUrl() {
        return this.isDefault() ? '' : `${this._prefix}(${[].concat(this._adults, this._children.filter(age => age !== '--')).join(',')})`;
    }

    resetAdult() {
        this._adults = 1;
    }

    resetChild() {
        this._children = [];
    }
}
