import Vue from 'vue'
import enquire from '../Libs/enquire.js'
import Blazy from '../Libs/blazy'

;
import Cookies from "../Libs/js.cookie";

(function (window, $) {
    'use strict';

    var UI = {
        bLazy: false,
        init: function () {
            this.ensureUC();
            this.preventCacheOnHistoryBack();
            window.Parsley.setLocale('de');
            window.ismobile = false;
            this.initEnquire();
            this.dealsCounter();
            this.addOrientationChangeEvent();
            this.initLazyLoading();
            this.initScrollToHash();
            this.initAccordion();
            this.initMarquee();
            this.initTabs();
            this.initAnchorLinkList();
            this.giataMaps.checkAndHide();
            this.teaserOfferTimer();
            this.descriptionOfferCounter();
            this.initCombobox();
            this.initPowermailForms();
            this.initClickSelector();
            this.initBacklinks();
            this.addPowerMailUX();
        },
        preventCacheOnHistoryBack: function(){
            window.onpageshow = function(event) {
                if (event.persisted) {
                    window.location.reload();
                }
            };
        },
        // adding orientation change event for real mobile devices or ios simulator on mac, this event won't be fired in desktop browsers and devtool simulators
        addOrientationChangeEvent: function () {

            $(window).off('orientationchange.description').on('orientationchange.description', function () {
                // re-init subnavigation on service pages
                SKTV.Navigation.subNavigation();

                if(typeof window.parent === 'object' && window.parent.document.getElementById("iframeLB")){
                    window.parent.SKTV.UI.checkResizeIframeContent();
                } else {
                    UI.checkResizeIframeContent();
                }

                // scroll to video, if video is playing
                // give client some time to rerender after orientation changed (important as otherwise the scrolled to
                // location is not correct)

                setTimeout(function() {
                    UI.scrollToVideoGalleryHeader();
                }, UI.isIframeOnIOsMobile() ? 1200 : 500);
            });


            if(UI.isIframeOnIOsMobile()){
                $('body').off('orientationchange.resizeGallery').on('orientationchange.resizeGallery', function () {
                    // resize flexsliders depending on
                    setTimeout(function() {
                        UI.resizeFlexsliders();
                    }, 1000);
                });
            }
        },
        resizeFlexsliders: function() {
            $('.flexslider').each(function(){
                var flexslider = $(this).data('flexslider');

                if(flexslider){
                    flexslider.resize();
                }
            });
        },
        scrollToVideoGalleryHeader: function(){
            var $video = $('.staticHotelVideoGallery');

            for(let i=0; i < $video.length; i++) {
                if($video[i].find('#fPlayer').hasClass("is-playing")) {

                    // check for headline inside video block
                    var $scrollToElem = $video[i].find('#videoLegend');

                    if($scrollToElem.length === 0 || $scrollToElem.text().length === 0){
                        // check for headline outside of video block
                        var $headerSibling = $video[i].parent().prev();

                        if($headerSibling.length > 0 && $.inArray($headerSibling.prop("tagName").toLowerCase(), ['h1','h2','h3'] )){
                            $scrollToElem = $headerSibling;
                        }
                    }

                    $scrollToElem = $scrollToElem.length > 0 ? $scrollToElem : $video[i];

                    UI.scrollTo($scrollToElem, {margin: 5});

                    if($scrollToElem !== undefined && $scrollToElem.length > 0) {
                        break;
                    }
                }
            }
        },
        isIframeOnIOsMobile : function() {
          return (navigator.platform === 'iPhone' || navigator.platform === 'iPad') && (document.getElementById("iframeLB") || window.parent.document.getElementById("iframeLB"));
        },
        checkResizeIframeContent: function(){
            if(UI.isIframeOnIOsMobile) {
                // give client some time to render after orientation change before doing width checks

                setTimeout(function () {
                    UI.updateIframeContentWidth();
                }, 500);
            }
        },
        reloadWhenVideoStops: function() {
            var iFrameElem = document.getElementById("iframeLB"),
                iFrameContent = (iFrameElem.contentWindow || iFrameElem.contentDocument);

            if (iFrameContent.document){
                iFrameContent = iFrameContent.document;
            }

            var playerElem = iFrameContent ? iFrameContent.getElementById("fPlayer") : null,
                playerInAction = playerElem ? playerElem.classList.contains("is-playing") : false;

            /* if player is available and in action while orientation change, wait and check if video
             finished, then do the reload otherwise do the reload immediately */
            flexslider
            if (playerInAction){
                var videoPlayFinished = setInterval(function(){
                    if(!playerElem.classList.contains("is-playing")){
                        clearInterval(videoPlayFinished);
                        iFrameElem.contentWindow.location.reload();
                    }
                }, 500);
            } else {
                iFrameElem.contentWindow.location.reload();
            }
        },
        updateIframeContentWidth: function(callback) {
            var iFrameElem = document.getElementById("iframeLB"),
                iFrameWidth = iFrameElem ? $(iFrameElem).parent().innerWidth() : '100%',
                iFrameContent = iFrameElem ? (iFrameElem.contentWindow || iFrameElem.contentDocument) : document;

            if (iFrameContent.document){
                iFrameContent = iFrameContent.document;
            }

            var iFrameContentBody = $(iFrameContent.getElementsByTagName("body")[0]);

            if(iFrameWidth !== iFrameContentBody.width()){
                iFrameContentBody.css({'max-width': (iFrameWidth-5)});
                UI.resizeFlexsliders();
            }

            return true;
        },
        initAccordion: function () {
            $('.accordion').accordion({
                animate: false,             // animation is deactivated for the anchor-link-list scrolling option!
                heightStyle: 'content',
                collapsible: true,
                active : false,
                icons: false,
                active_class: 'active',
                multi_expand: true,
                beforeActivate: function (evt, ui) {
                    var $target = $(evt.currentTarget);
                    //only if its not hotel description page
                }
            });
        },
        initMarquee: function () {
            $('.crisis-banner .marquee').each(function(index) {
                var linkWidth = $(this).find('a').width();
                var headingWidth = $(this).find('.crisis-banner__heading').width();
                if (window.innerWidth <= linkWidth + headingWidth ) {
                    $('.marquee').marquee({
                        startVisible: true,
                        duration: 10000,
                        duplicated: true,
                        gap: 100
                    });
                }
            });
        },
        /**
         * function to click/trigger another element using a selector
         * stored in data attribute. keep bubbling in mind if elements are nested.
         */
        initClickSelector: function () {
            $('.click-selector').on('click', function (evt) {
                var $target = $(evt.target);
                // prevent endless bubbling loop
                if ($target.is('a, button') || $target.is('.prevent-click-selector') || $target.closest('.prevent-click-selector').length) {
                    return;
                }
                var sel = $(this).data('click-selector'),
                    $sel = $(sel).first();

                if ($sel.is('a')) {
                    $sel[0].click();
                } else {
                    $sel.click();
                }
            });
        },
        initTabs: function () {

            $('.tabs').tabs({               // common tabs e.g. static hotel/promotion, service pages with url angebot/... and beschreibung/...
            });

            $('.seo-tabs').tabs({           // seo tabs
                active: 0,
                collapsible: true
            });

        },
        initAnchorLinkList: function () {
            $('.anchor-link-list').on('click', 'a', function (evt) {
                evt.preventDefault();
                var href = $(this).attr('href'),
                    anchor = href.split('#')[1],
                    element = $('#' + anchor);


                // open needed accordion element
                if (!$(element).hasClass('ui-state-active')) {
                    $(element).click();
                }

                //// scroll to accordion element
                SKTV.UI.scrollTo(element);
            });
        },
        initLazyLoading: function () {
            // http://dinbror.dk/blog/blazy/
            SKTV.UI.bLazy = new Blazy({
                offset: 300, // Loads images 300px before they're visible
                container: '.flex-viewport', // Default is window
                loadInvisible: true, // because of mobile only elements on resize
                selector: '.b-lazy', // only these images
                success: function (ele) {
                    if ($(ele).parent().hasClass('focuspoint') && $(ele).parent().data('focusPoint') !== undefined) {
                        $(ele).parent().data('focusPoint').adjustFocus();
                    }
                }
            });
        },
        initScrollToHash: function () {
            // *only* if we have anchor on the url
            if (window.location.hash && $(window.location.hash).length) {

                // smooth scroll to the anchor id
                $(window).on('load',function(){
                    let element = $(window.location.hash).prev();
                    UI.scrollTo(element);
                });
            }

            // no anchor yet, assign scroll function to links
            $('a.scrollToHash').on('click', null, function (e) {
                let hashSplit = $(this).attr('href').split('#');

                if (hashSplit[1]) {
                    let element = $('#' + hashSplit[1]).prev();
                    e.preventDefault();
                    UI.scrollTo(element);
                }
            });
        },
        initEnquire: function () {
            var hasDealsHeader = !!$('.tmpl-home #deals-header').length,
                $search = $('#sktv-search');

            function setTeaserSpacing() {
                if (hasDealsHeader) {
                    $search.css('margin-top', '11.5em');
                }
            }

            function unsetTeaserSpacing() {
                if (hasDealsHeader) {
                    $search.css('margin-top', '');
                }
            }
            enquire.register('screen and (max-width: 59.99em)', {
                setup: function () {
                    setTeaserSpacing();
                },
                match: function () {
                    window.ismobile = true;

                    // if there is an header deals counter on tmpl-home then change margin-top of .search-home/#sktv-search
                    // related to: SR-2166, SR-1751, SR-278
                    unsetTeaserSpacing();
                },
                unmatch: function () {
                    window.ismobile = false;

                    setTeaserSpacing();
                }
            });
        },
        giataMaps: {
            checkAndHide: function () {
                // TODO: !map-refactoring
                // Hide map related headline => Function can be deleted when new map component exists
                var holderSibling = $('.map-holder').prev(),
                    mapHeadline = holderSibling.length > 0 ? holderSibling.children('h2') : false;

                if(holderSibling.children().length < 3  && mapHeadline && mapHeadline.length === 1 && mapHeadline.text().toLowerCase().indexOf('karte') >= 0){
                    mapHeadline.hide();
                }
            }
        },
        /**
         * Countdown timer for offers
         */
        teaserOfferTimer: function () {
            $('.jscountdown').each(function (event) {
                var realId = $(this).attr('id'),
                    enddate = $(this).data('countdown');
                SKTV.Common.offertimer(realId, enddate);
            });
        },
        descriptionOfferCounter: function () {
            SKTV.Common.offertimer('promo-counter');
        },
        dealsCounter: function () {
            SKTV.Common.offertimer('deals-counter');
        },
        initCombobox: function () {
            var location = /\/ort\(([^)]+)\)/i.exec(window.location.pathname);

            if (!$('#citiesDropdown').length) {
                return;
            }

            new Vue({
                data: {
                    options: [],
                    value: location && location.length > 1 ? location[1] : ''
                },
                el: '#citiesDropdown',
                created: function () {
                    var options = [];
                    $(this.$options.el).children('option').each(function () {
                        var $that = $(this);
                        options.push({
                            label: $that.text(),
                            value: $that.val()
                        });
                    });
                    this.options = options;
                },
                template: '<div class="cities-dd-wrapper"><autocomplete :options="options" :select="value"></autocomplete></div>'
            });

            // first add wrapper and remove after rendering to avoid javascript error because of vue warning (fragment instance)
            $('.cities-dd-wrapper > .autocompl').unwrap();
        },
        initPowermailForms: function () {
            var $form = $('.powermail_form');
            if ($form.length > 0) {
                $form.parsley();
            }
        },
        /**
         * Scrolls to specific position, considering page specials e.g. sticky header
         * @param val (can be an jQuery-Element, jQuery/Css-selector, dom-object, or number (position where to scroll to) )
         * @param options
         */
        scrollingInProgress : false,
        scrollTo: function (val, options) {

            var _options = {
                duration: 'slow',
                easing: 'swing',
                margin: 0,
                blink: false,
                callback: false
            },
                options = typeof options !== 'undefined' ? Object.assign({}, _options, options) : _options,
                offsetVal = 0;


            if(typeof val === 'undefined'){
                return false;
            }

            if(isNaN(val)) {
                if(val.length === 0){
                    return false;
                }

                if(isNaN(val) && val instanceof jQuery !== true){
                    val = $(val);
                }

                offsetVal = val.offset().top;
            } else if (val >= 0) {
                offsetVal = val;
            }

            var stickyHeader = $('.sticky-header'),
              stickyHeaderHeight = stickyHeader && offsetVal > 0 && stickyHeader.first().hasClass('sticky-header--visible') ? stickyHeader.first().height() : 0;



            if($('body').is('.isIOS.isTouch.isIframed')) {
                options.duration = 0;
                var jCont = $('body > .content');
                if(jCont.length > 0){
                    offsetVal = offsetVal - jCont.offset().top;
                }
            }

            var scrollPosition = offsetVal - options.margin - (offsetVal >= $('header').height() ? stickyHeaderHeight : 0);

            // avoid multiple scrolling processes at same time
            if(UI.scrollingInProgress === true){
                return false;
            }

            UI.scrollingInProgress = true;

            $('html, body').animate({scrollTop: scrollPosition}, options.duration, options.easing, function(){

                UI.scrollingInProgress = false;

                // if is mobile, give some time to rerender (eg. due to orientation change)
                setTimeout(function(){

                    var diff = (val.offset().top - window.pageYOffset) - (($('.sticky-header').hasClass('sticky-header--visible') ? $('.sticky-header').height() : 0) + options.margin);

                    // double check if scrolling position is correct due to AndroidNative scrolling issue (... otherwise correct it)
                    if (window.pageYOffset > 0 && diff < 0) {
                        $('html, body').animate({scrollTop: window.pageYOffset + diff});
                    }

                    if(typeof options.callback === 'function'){
                        options.callback();
                    }

                }, SKTV.Common.Browser.isMobile() ? 800 : 0);
            });

            if(options.blink === true && val instanceof jQuery === true){
                UI.blink(val);
            }
        },

        blink: function (elem) {
            if(typeof elem === 'undefined' || elem.length === 0){
                return false;
            }

            if(elem instanceof jQuery !== true){
                elem = $(elem);
            }

            elem.fadeTo('slow', 0.5).fadeTo('slow', 1.0);
        },

        /**
         *
         * @param cssSelector
         * @param fn
         * @param listenToEvent:    optional, if not defined, binds scroll event with default marker
         *                          [boolean false] to check just once elements in current viewport,
         *                          [string] to bind to specific event
         */
        forElementsInViewport: function(cssSelector, fn, listenToEvent) {

            var scrollListenElem = window.self !== window.top ? $('body')[0] : window;

            if($(cssSelector).length === 0){
                $(scrollListenElem).off('scroll.viewportlistener');
                return;
            }

            function getElementPosition(elem) {
                return elem.getBoundingClientRect();
            }

            function getViewport() {
                var root = document.compatMode === 'CSS1Compat' ? document.documentElement : document.body;

                var windowElement = window;

                return {
                    top: windowElement.pageYOffset || root.scrollTop,
                    left: windowElement.pageXOffset || root.scrollLeft,
                    width: windowElement.innerWidth || root.clientWidth,
                    height: windowElement.innerHeight || root.clientHeight
                };
            }

            function inViewport(clientRect, viewport) {
                return !(clientRect.bottom < 0 || clientRect.top > viewport.height);
            }

            function checkViewport() {
                var objViewport = getViewport();
                $(cssSelector).each(function(i, elem) {
                    if ($(elem).height() > 0 && inViewport(getElementPosition(elem), objViewport)) {
                        fn(elem);
                    }
                });
            }

            if(listenToEvent !== false){
                $(scrollListenElem).on(typeof listenToEvent === 'string' ? listenToEvent : 'scroll.viewportListener', function () {
                    return checkViewport();
                });
            }

            return checkViewport();
        },

        initBacklinks: function () {
            if(document.referrer.indexOf(window.location.hostname) >= 0){
                $('.js-backlink').attr('href', document.referrer).click(function(e){
                    e.preventDefault();
                    history.back();
                }).removeClass('js-backlink');
            }
        },

        onScrollStop: function(callback, timeout) {
            $(window).scroll(function(){
                var $this = $(this);
                if ($this.data('scrollTimeout')) {
                    clearTimeout($this.data('scrollTimeout'));
                }
                $this.data('scrollTimeout', setTimeout(callback,timeout));
            });
        },

        addPowerMailUX: function() {
            var formPlugin = $('.powermail_form');

            if(formPlugin.length > 0 && formPlugin.find('.status-wrapper')){
                formPlugin[0].addEventListener('submit', function(evt){
                    let submitBtn = formPlugin.find('input[type="submit"]');
                    submitBtn.attr('disabled', 'disabled').addClass('is--busy');

                    if(submitBtn.parent().hasClass('status-wrapper')){
                        submitBtn.parent().addClass('is--busy')
                    }
                })
            }
        },
        ensureUC: function () {
            if(typeof Cookies.get('userId') === 'undefined'){
                $.ajax({
                    url: '/ajax/getUserCookie',
                    method: "POST",
                    success: function (data) {
                        Cookies.set(data['name'], data['value'], { path: data['path'], domain: data['host'] });
                    }
                });
            }
        },
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.UI = UI;


})(window, $);


