/**
 * {{#replace}} replace specified content
 *
 * @param string find
 * @param string replace
 * @return string
 */
module.exports = function (find, replace, options) {
    var string = options.fn(this);
    return string.replace(new RegExp(find, 'g'), replace);
};
