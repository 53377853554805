import BaseModel from './base';
import TraveltypeHydrator from '../hydrator/traveltype';

export default class TraveltypeModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new TraveltypeHydrator({
            travelType: '_traveltype'
        });

        this._prefix = 'reiseart';

        this._menuOption = '';

        this._defaults = {
            _traveltype: null
        };

        this._options = [
            {
                code: 'pauschal',
                label: 'Flug & Hotel'
            },
            {
                code: 'hotel',
                label: 'nur Hotel'
            },
            {
                code: 'kreuzfahrten',
                label: 'Kreuzfahrten'
            },
            {
                code: '/rundreisen.html',
                label: 'Rundreisen'
            },
            {
                code: '/promo/reiseveranstalter/leitner-reisen.html',
                label: 'Busreisen'
            },
            {
                code: 'https://ferienwohnungen.sonnenklar.tv/',
                label: 'Ferienwohnungen'
            },
            {
                code: '/tv-specials/fluege',
                label: 'nur Flug'
            },
            {
                code: '/camper.html',
                label: 'Camper'
            },
            {
                code: '/mietwagen',
                label: 'Mietwagen'
            }
        ];

        this._options.forEach(option => option.checked = option.filtered = false);

        this.reset();
        this.updateOptions();
    }

    toString() {
        return this.isDefault() || this._traveltype === 'kreuzfahrten' || this._traveltype === 'rundreisen' ? '' : `${this._prefix}(${this._traveltype})`;
    }

    createInputFieldString() {
        let result = [];

        result = this._options.filter(entry => entry.checked);

        if (result !== null && result.length > 0) {
            return result[0].label;
        } else {
            return '';
        }
    }

    getUnfiltered() {
        return this._options.filter(option => !option.filtered);
    }

    select(code) {
        this._menuOption = this._menuOption === '' ? this._traveltype  : this._menuOption;
        this._traveltype = this._traveltype !== code ? code : '';
        this.updateOptions();
    }

    updateOptions() {
        this._options.forEach(option => option.checked = option.code === this._traveltype);
    }

    getPlaceholder() {
        if (window.SKTV.searchFilter && window.SKTV.searchFilter.boards &&
            Object.keys(window.SKTV.searchFilter.boards).length === 1 && this.getUnfiltered().length == 0) {
            return window.SKTV.searchFilter.boards[Object.keys(window.SKTV.searchFilter.boards)[0]];
        } else {
            return 'Reiseart';
        }
    }
}
