import gmapsInit, {reloadGmaps} from '../utils/gmaps';
import ucStatus from '../utils/ucstatus';
import UCStatusView from './ucstatus-view';

export default {
    name: 'HotelMapView',
    components: {UCStatusView},
    template: `
        <div class="hotel-map-view" v-bind:class="{'hotel-map-open': isOpened}">
            <div class="hotel-map-wrapper">
                <div class="hotel-map-header">
                    <div class="hotel-map-container">
                        <div class="title">
                            <h3 v-html="hotelname"></h3>  
                            <div class="category" v-html="category"></div>
                            <p><i class="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; ?@ region @</p> 
                        </div>
                        
                        <div class="cta-dates" v-on:click="openMapsView">
                            <a :href="ibeLink">
                                <span>zu allen Terminen</span>
                                <i class="fas fa-chevron-right" aria-hidden="true"></i>
                            </a>
                        </div>
                        
                        <div class="clearfix"></div>
                    </div>
                    
                    <div class="close" v-on:click="closeMapsView">
                        <i class="icon icon-video-image-gallery-close" />
                    </div>
                </div>
                <div id="gmap"><u-c-status-view></u-c-status-view></div>
            </div>
        </div>
    `,
    data() {
        return {
            isOpened: false,
            googleMapsClient: null
        };
    },
    props: {
        hotelname: String,
        category: String,
        region: String,
        ibeLink: String,
        longitude: Number,
        latitude: Number,
        activeHotelId: String,
    },
    mounted() {
        this.isOpened = true;

        if (ucStatus()) {
            this.initMap();
        } else {
            this.initOverlay();
        }
    },
    methods: {
        closeMapsView() {
            this.isOpened = false;
            document.getElementsByTagName('body')[0].classList.remove('overlayOpen');
        },
        openMapsView(currentHotelId = null, init = false) {
            if (currentHotelId !== null && currentHotelId !== this.activeHotelId) {
                this.activeHotelId = currentHotelId;

                this.setMapMarker();
            } else if (init === true) {
                this.setMapMarker();
            }

            this.initOverlay();
        },
        async initMap() {
            const google = await gmapsInit();
            this.googleMapsClient = new google.maps.Map(document.getElementById('gmap'));
            this.openMapsView(null, true);
        },
        async reloadMap () {
            this.closeMapsView();
            const google = await reloadGmaps();
            this.googleMapsClient = new google.maps.Map(document.getElementById('gmap'));
            this.openMapsView(null, true);
        },
        initOverlay() {
            this.isOpened = true;
            document.getElementsByTagName('body')[0].classList.add('overlayOpen');
        },
        createMarker(position) {
            return new google.maps.Marker({
                position: position,
                map: this.googleMapsClient,
                label: {
                    text: this.htmlEntities(this.hotelname),
                    color: '#ea4335',
                    fontSize: '14px',
                    fontWeight: '700'
                },
                icon: {
                    url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
                    origin: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(6, 54)
                }
            });
        },
        setMapMarker() {
            if (
                (this.longitude != null && typeof (this.longitude) === 'number') &&
                (this.latitude != null && typeof (this.latitude) === 'number')
            ) {
                const location = new google.maps.LatLng(this.latitude, this.longitude);
                const marker = this.createMarker(location);

                this.googleMapsClient.setZoom(16);
                this.googleMapsClient.setCenter(marker.getPosition());
            }
        },
        htmlEntities(str) {
            return String(str).replace('&amp;', '&').replace('&lt;', '<').replace('&gt;', '>').replace('&quot;', '"');
        }
    }
};
