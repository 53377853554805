var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "style=\"border-right: solid "
    + container.escapeExpression(((helper = (helper = helpers.dealsteasercolor || (depth0 != null ? depth0.dealsteasercolor : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dealsteasercolor","hash":{},"data":data}) : helper)))
    + " 1px;\" ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"clabel\">NUR NOCH</div>\n<div class=\"ctime ctimeB\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dealsteasercolor : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span id=\"countdownc1\" class=\"hour\">"
    + alias4(((helper = (helper = helpers.days || (depth0 != null ? depth0.days : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"days","hash":{},"data":data}) : helper)))
    + "</span><br>TAGE</div>\n<div class=\"ctime ctimeB ctimeP\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dealsteasercolor : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span id=\"countdownc2\" class=\"minute\">"
    + alias4(((helper = (helper = helpers.hours || (depth0 != null ? depth0.hours : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hours","hash":{},"data":data}) : helper)))
    + "</span><br>STD</div>\n<div class=\"ctime ctimeP\"><span id=\"countdownc3\" class=\"seconds\">"
    + alias4(((helper = (helper = helpers.minutes || (depth0 != null ? depth0.minutes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minutes","hash":{},"data":data}) : helper)))
    + "</span><br>MIN</div>\n\n\n\n";
},"useData":true});