import GalleryTeaser from './components/gallery-teaser'

export default Vue.component('video-image-gallery', {
    template: `
    <div id="video-image-gallery" v-bind:class="{ inline: !overlay }">
        <gallery-teaser v-if="overlay" v-bind:images="images" v-bind:videos="videos" v-bind:preview-images="previewImages" v-bind:preview-elements="previewElements"
                       v-bind:hotel-data="hotelData" v-bind:sliderContent="sliderContent" v-bind:categories="categories" />
        <div v-if="!overlay" class="skg-wrapper">
            <div class="skg-wrapper__slider">
                <slider :elements="sliderContent" />
            </div>
            <div class="skg-wrapper__filter">        
                <slider-filter :categories="categories" />
            </div>
            <div class="skg-wrapper__thumbnail">
                <thumbnail-slider :images="sliderContent" />
            </div>
        </div>
        
    </div>
    `,
    data() {
        return {
            sliderContent: [],
            categories: []
        }
    },
    props: {
        images: Object,
        videos: Object,
        previewImages: Object,
        previewElements: Array,
        hotelData: Object,
        overlay: Boolean
    },
    methods: {
        initSliderContent: function () {
            this.setSliderContent('both', '');
        },
        setSliderContent: function (type, category) {
            let content = [];
            let categories = [];
            let lengthContent = 0;
            if (type === 'both' || type === 'videos') {
                Object.keys(this.videos).forEach((key) => {
                    if (this.videos[key].length > 0) {
                        lengthContent += this.videos[key].length;
                        categories.push({
                            title: key,
                            value: key,
                            length: this.videos[key].length
                        });
                        if (category === '' || category === key) {
                            for (let i = 0; i < this.videos[key].length; i++) {
                                content.push({
                                    id: this.videos[key][i].id,
                                    src: this.videos[key][i].src,
                                    title: this.videos[key][i].title,
                                    thumbnail: this.videos[key][i].thumbnail,
                                    isVideo: true
                                });
                            }
                        }
                    }
                })
            }
            if (type === 'both' || type === 'images') {
                Object.keys(this.images).forEach((key) => {
                    if (this.images[key].length > 0) {
                        lengthContent += this.images[key].length;
                        categories.push({
                            title: key,
                            value: key,
                            length: this.images[key].length
                        });
                        if (category === '' || category === key) {
                            for (let i = 0; i < this.images[key].length; i++) {
                                content.push({
                                    id: this.images[key][i].id,
                                    src: this.images[key][i].src,
                                    title: this.images[key][i].title,
                                    isVideo: false
                                });
                            }
                        }
                    }
                });
            }
            if(content.length > 0) {
                this.categories = [{
                    title: 'Alle',
                    value: '',
                    length: lengthContent
                }].concat(categories);
                this.sliderContent = content;
            }else {
                this.$root.$emit('finishedRendering');
            }
        }
    },
    components: {
        GalleryTeaser
    },
    mounted: function () {
        this.initSliderContent();
        this.$root.$on('filterSliderElements', (options) => {
            this.$root.$emit('startRendering');
            this.setSliderContent(options.type, options.category);
        });
    }
})
