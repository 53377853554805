function buildSiteNavigation() {
    var lastElement = 0;
    var displaySubNav = false;

    $('#staticSiteNavigation li.nav-left').css('display', 'none');
    $('#staticSiteNavigation li.nav-right').css('display', 'none');

    $('#staticSiteNavigation li.link').removeClass('first-visible-nav last-visible-nav passive active').css('display', 'block');

    // Site Navigation Tablet Mode
    if ($(window).innerWidth() >= 600 && $(window).innerWidth() < 960) {
        $('#staticSiteNavigation li.link').each(function (index) {
            var containerOffset = $('#staticSiteNavigation').offset().left + $('#staticSiteNavigation').innerWidth();
            var offsetRight = $(this).offset().left + $(this).outerWidth() + 40;

            if (offsetRight >= containerOffset || offsetRight < lastElement) {
                $(this).addClass('passive').css('display', 'none');
                displaySubNav = true;
            } else {
                $(this).addClass('active');
            }

            lastElement = offsetRight;
        });

        if (displaySubNav === true) {
            $('li.nav-right').css('display', 'inline-block');
        }
    } else if ($(window).innerWidth() < 600) {
        $('#staticSiteNavigation li').css('display', 'block');
        $('#staticSiteNavigation li.nav-left').css('display', 'none');
        $('#staticSiteNavigation li.nav-right').css('display', 'none');
    }

    $('#staticSiteNavigation li:visible:first').addClass('first-visible-nav');
    $('#staticSiteNavigation li:visible:last').addClass('last-visible-nav');
}


$('#staticSiteNavigation').ready(function () {
    buildSiteNavigation();
});

$(window).resize(function () {
    buildSiteNavigation();
});


$(document).ready(function () {


    // Hotline Box
    setTimeout(function() {
        var hotlineNode = document.getElementById('hotline'),
            hotlineBoxNode = document.getElementById('hotline-box-phone');
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

        var updateHotlinebox = function() {
            if (hotlineNode && hotlineBoxNode) {
                hotlineBoxNode.innerHTML = hotlineNode.innerHTML;

                if (MutationObserver) {
                    var callback = function() {
                        hotlineBoxNode.innerHTML = hotlineNode.innerHTML;
                    };
                    var observer = new MutationObserver(callback);
                    var config = {
                        childList: true,
                        subtree: true,
                        attributes: true
                    };

                    observer.observe(hotlineNode, config);
                }
            } else if(hotlineBoxNode && hotlineBoxNode.innerHTML === '') {
                hotlineBoxNode.innerHTML = '089 - 710 457 505';
            }
        };

        updateHotlinebox();
    }, 0);

    // Site Navigation - scroll function
    $('#staticSiteNavigation a').on('click', function (e) {
        e.preventDefault();
        var href = $(this).data('el');
        var scrollTop = $(href).offset().top;
        if ($('#video-image-gallery') && $('#video-image-gallery').hasClass('inline') == false) {
            scrollTop -= 55;
        }
        if (window.SKTV.Common.Browser.isMobileSafari() && window.SKTV.Common.Browser.inIframe()) {
            window.scrollTo(0, scrollTop);
        } else {
            $('html, body').animate({
                scrollTop: scrollTop
            }, 'slow');
        }
        return false;
    });

    // Site Navigation - Tablet Version
    $('#staticSiteNavigation li.subnav').on('click', function (e) {
        $('#staticSiteNavigation li.passive:first').addClass('first-visible-nav');
        $('#staticSiteNavigation li.passive:last').addClass('last-visible-nav');

        $('#staticSiteNavigation li.active').slideUp('slow', function () {
            $('#staticSiteNavigation li.active').removeClass('first-visible-nav last-visible-nav');
            $(this).removeClass('active').addClass('passive');
        });

        $('#staticSiteNavigation li.passive').delay('slow').slideDown('slow', function () {
            $(this).removeClass('passive').addClass('active');
        });
    });


    // Travel Information
    $('#staticTravelInfo a').on('click', function (e) {
        e.preventDefault();

        var new_id = $(this).attr('href');
        var active_id = $('.travel-info-tabcontent.active').attr('id');

        if (new_id !== ('#' + active_id)) {
            $('.travel-info-tab.active').removeClass('active');
            $(this).parents('.travel-info-tab').addClass('active');

            $('.travel-info-tabcontent.active').css('display', 'none').removeClass('active');
            $(new_id).css('display', 'block').addClass('active');
        }
    });

    // Linkboxes
    $('#staticLinkbox .linkbox-tab a').on('click', function (e) {
        e.preventDefault();

        var new_id = $(this).attr('href');
        var active_id = $('.linkbox-tabcontent.active').attr('id');

        if (new_id !== ('#' + active_id)) {
            $('.linkbox-tab.active').removeClass('active');
            $(this).parents('.linkbox-tab').addClass('active');

            $('.linkbox-tabcontent.active').css('display', 'none').removeClass('active');
            $(new_id).css('display', 'block').addClass('active');
        }
    });
});
