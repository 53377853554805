export default class Sorting {
    constructor() {
        this.methods = {
            _replace(value) {
                return value.replace(/[äöü]/g, (match) => {
                    return {
                        'ä': 'ae',
                        'ö': 'oe',
                        'ü': 'ue',
                    }[match];
                });
            },

            _sort(a, b, direction) {
                if (typeof a === 'string') {
                    a = this._replace(a.toLowerCase());
                }
                else if (typeof a === 'undefined' || typeof a === 'number' && isNaN(a)) {
                    return 1;
                }

                if (typeof b === 'string') {
                    b = this._replace(b.toLowerCase());
                }
                else if (typeof b === 'undefined' || typeof b === 'number' && isNaN(b)) {
                    return -1;
                }

                const result = a === b ? 0 : a < b ? -1 : 1;
                return direction === 'asc' ? result : result * -1;
            },

            toggleDirection(category) {
                if (this.active === category && this.categories[category].sorting === 'dual') {
                    this.categories[category].direction = this.categories[category].direction === 'asc' ? 'desc' : 'asc';
                } else {
                    this.active = category;
                }

                this.sort();
                $(window).trigger('stickyHeader.update');
            }
        };

        this.template = `
            <div class="sortingListCriterias"><p class="h2">sortieren nach:</p>
                <ul>
                    <li class="filterCriteria" 
                        v-for="(category, currentItem) in categories"
                        :class="{ascending: active === currentItem && category.direction === 'asc',
                                descending: active === currentItem && category.direction === 'desc'}" 
                        @click="toggleDirection(currentItem)">
                        <a href="javascript:void(0)" :title="category.direction === 'asc' ? 'Aufsteigend' : 'Absteigend'">
                            <i class="fas fa-long-arrow-alt-up" v-show="category.direction === 'asc' || category.sorting === 'dual'"></i>
                            <i class="fas fa-long-arrow-alt-down" v-show="category.direction === 'desc' || category.sorting === 'dual'"></i>
                            <span v-text="category.label"></span>
                        </a>
                    </li>
                </ul>
            </div>
        `;
    }

    init({el}) {
        if ($(el).length) {
            this.vue = new Vue(Object.assign({el}, this));
        }
        return this;
    }
}