var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"layer-"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/replace.js")).call(depth0 != null ? depth0 : (container.nullContext || {})," ","",{"name":"replace","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" class=\"layer-xhr\">\n    <img src=\"typo3conf/ext/theme/Resources/Public/Images/loading-white.gif\"/>\n</div>";
},"useData":true});