require("datatables.net");
;(function (window, $) {
    'use strict';

    var UserSiteMap = {
        init: function () {
            this.initOrtUserSiteMap();
        },
        initOrtUserSiteMap: function () {
            if ($('#userSiteMap, #ekomiTable').length > 0 && jQuery().dataTable) {

                var pages = 20;
                var orderingCheck = true;
                if ($('#ekomiTable').length > 0) {
                    pages = 10;
                    orderingCheck = false;
                }

                $('#userSiteMap, #ekomiTable').dataTable({
                    select: false,
                    searching: false,
                    ordering: orderingCheck,
                    lengthChange: false,
                    info: false,
                    pageLength: pages,
                    language: {
                        paginate: {
                            previous: 'Zurück',
                            next: 'Weiter'
                        }
                    }
                });
            }
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.UserSiteMap = UserSiteMap;

})(window, $);
