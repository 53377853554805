import BaseHydrator from './base';

export default class CateringHydrator extends BaseHydrator {
    hydrate(data, model) {
        super.hydrate(data, model);

        model.updateOptions();

        return model;
    }
}