var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,((stack1 = (data && data.root)) && stack1.euviaData),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                    <h3>Hotelbilder von "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.operatorName : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                        - "
    + ((stack1 = ((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.images : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "                </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <li class=\""
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.first),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " row flexslider galleryItem tabcontent\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "active";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <li class=\"row flexslider galleryItem tabcontent\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.operatorName || (depth0 != null ? depth0.operatorName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"operatorName","hash":{},"data":data}) : helper)))
    + " ";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.operator || (depth0 != null ? depth0.operator : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"operator","hash":{},"data":data}) : helper)))
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n                        <ul class=\"slides\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                        </ul>\n\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "                                <li data-thumb=\""
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/cimage.js")).call(alias1,depth0,"g",55,55,(depth0 != null ? depth0.ids_giata : depth0),{"name":"cimage","hash":{},"data":data}))
    + "\">\n                                    <img class=\"b-lazy\" src=\"typo3conf/ext/theme/Resources/Public/Images/loading-image_4-3.svg\"\n                                         data-src=\""
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/cimage.js")).call(alias1,depth0,"g",800,600,(depth0 != null ? depth0.ids_giata : depth0),{"name":"cimage","hash":{},"data":data}))
    + "\"/>\n\n                                    <div class=\"flex-caption claim\">\n\n                                    </div>\n                                </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "\n                        <p><i class=\"fas fa-info-circle\"></i> Vom Veranstalter sind leider keine Bilder hinterlegt worden.</p>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"imageGallery row\">\n    <aside>\n        <div class=\"operators row\">\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/operators-list.hbs"),depth0,{"name":"operators-list","hash":{"id":"Gallery"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"operators-alt row\">\n            <div class=\"operators-alt-selected\">\n                <img src=\"fileadmin/sonnenklar/admin/logos/"
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/toLowerCase.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.listData : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.operator : stack1),{"name":"toLowerCase","hash":{},"data":data}))
    + ".png\" alt=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.listData : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.operatorName : stack1), depth0))
    + "\">\n                <button class=\"operators-trigger\">\n                    <i class=\"fas fa-caret-down\"></i>\n                </button>\n            </div>\n"
    + ((stack1 = container.invokePartial(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Partials/ibe/hotel-info/operators-list.hbs"),depth0,{"name":"operators-list","hash":{"id":"GalleryAlt"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"galleryContentWrap row\">\n            <ul class=\"galleryContentList\">\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.listData : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n\n    </aside>\n</div>\n";
},"usePartial":true,"useData":true});