import BaseModel from './base';
import OfferHydrator from '../hydrator/offer';

export default class OfferModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new OfferHydrator({
            orderIds: '_offers'
        });
        this.export = false;

        this._prefix = 'bestellnummer';
        this._defaults = {
            _offers: []
        };

        this.reset();
    }

    toString() {
        return this.isDefault() ? '' : `${this._offers.join(',')}`;
    }

    toUrl() {
        return this.isDefault() ? '' : `${this._prefix}(${this._offers.join(',')})`;
    }
}