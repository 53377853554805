import BaseModel from './base';
import AirportsHydrator from '../hydrator/airports';

export const GroupNames = Object.freeze({
    SONSTIGE_FLUGHAEFEN: "Sonstige Flughäfen",
    DEUTSCHLAND: "Deutschland",
    BELIEBTE_FLUGHAEFEN: "Beliebte Flughäfen",
    NORD_DEUTSCHLAND: "Nord Deutschland",
    OST_DEUTSCHLAND: "Ost Deutschland",
    SUED_DEUTSCHLAND: "Süd Deutschland",
    WEST_DEUTSCHLAND: "West Deutschland",
    OESTERREICH: "Österreich",
    SCHWEIZ: "Schweiz"
});

export default class AirportsModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new AirportsHydrator({
            departureAirport: '_airports'
        });
        this.autocomplete = [] ;
        this._prefix = 'abflug';
        this.loaded = false;

        this._defaults = {
            _tagList: [],
            _airports: [],
        };

        this._options = {
            'Beliebte Flughäfen': [],
            'Nord Deutschland': [],
            'Ost Deutschland': [],
            'Süd Deutschland': [],
            'West Deutschland': [],
            'Schweiz': [],
            'Österreich': [],
            'Sonstige Flughäfen': []
        };

        this._directFlight = false;

        this._groupCheckBoxes =  {
            'Sonstige Flughäfen': false,
            'Deutschland': false,
            'Schweiz': false,
            'Nord Deutschland': false,
            'Ost Deutschland': false,
            'Süd Deutschland' : false,
            'West Deutschland' : false,
            'Beliebte Flughäfen' : false,
            'Österreich': false
        }
        this._groupCheckBoxesFiltered =  {
            'Sonstige Flughäfen': false,
            'Deutschland': false,
            'Schweiz': false,
            'Nord Deutschland': false,
            'Ost Deutschland': false,
            'Süd Deutschland' : false,
            'West Deutschland' : false,
            'Beliebte Flughäfen' : false,
            'Österreich': false
        }

        this.groupCheckboxesGermany = [
            GroupNames.NORD_DEUTSCHLAND,
            GroupNames.OST_DEUTSCHLAND,
            GroupNames.SUED_DEUTSCHLAND,
            GroupNames.WEST_DEUTSCHLAND,
            GroupNames.BELIEBTE_FLUGHAEFEN
        ];

        this.reset();
    }

    updateFilter(filter) {
        let self = this;
        Object.values(this._options).forEach(function(group){
            let keyName = '';
            let filtered = 0;
            group.forEach(function(option){
                if(!(option.iataCode.toUpperCase() in filter)){
                    option.filtered = true;
                  filtered++;
                }
                keyName = option.keyName;
            });
            if(group.length === filtered) {
                self._groupCheckBoxesFiltered[keyName] = true;
            } else {
                self._groupCheckBoxesFiltered[keyName] = false;
            }
        });

        if(self._groupCheckBoxesFiltered[GroupNames.NORD_DEUTSCHLAND] && self._groupCheckBoxesFiltered[GroupNames.SUED_DEUTSCHLAND] &&
            self._groupCheckBoxesFiltered[GroupNames.OST_DEUTSCHLAND] && self._groupCheckBoxesFiltered[GroupNames.WEST_DEUTSCHLAND]){
            self._groupCheckBoxesFiltered[GroupNames.DEUTSCHLAND] = true;
        } else {
            self._groupCheckBoxesFiltered[GroupNames.DEUTSCHLAND] = false;
        }
    }

    buildObject(groupName, label, code){
       return {
            keyName: groupName,
            label: label,
            id:  code + '-' + groupName,
            filtered: false,
            available: true,
            checked: false,
            countChecked: 0,
            iataCode: code
        };
    }
    initAutoComplete(){
        let self = this;
        Object.values( self._options).forEach(function(country, index){
            self.autocomplete.push(Object.keys( self._options)[index]);
            country.forEach(function(airport){

                if(!airport.filtered){
                    self.autocomplete.push(Object.keys( self._options)[index] + ' | ' + airport.label + ' | ' + airport.iataCode.toUpperCase() )
                }
            });
        });

        const $input = $('input#airport-filter');

        $input.autocompleteHL({
            source: this.autocomplete,
            minLength: 3,
            select: (event, ui) => {
                $(this).siblings('button.reset-autocomplete').addClass('visible');

                const value = ui.item.value.split(' | ');
                this.selectAutoComplete(value[0], value[value.length - 1]);

                $input.val('');
                return false;
            },
            open: function () {
                const $that = $(this);
                $that.autocompleteHL('instance').menu.element.css('width', $that.width() + 10);
            }
        });
    }
    sortOptions(){
        let self = this;
        Object.values( self._options).forEach(function(main){
            main.sort(  function compare( a, b ) {
                if ( a.label < b.label ){
                    return -1;
                }
                if ( a.label > b.label ){
                    return 1;
                }
                return 0;
            } );
        });
    }

    selectTag(label, iataCode){
        if( iataCode === 'All') {
            this.reset();
            this.resetValues();
        } else {
            let self = this;
            this._tagList = this._tagList.filter(c => c.iataCode !== iataCode);
            if(label === GroupNames.DEUTSCHLAND){
                self.toggleGermany();
            }
            Object.keys(this._options).forEach(function(groupName){
                if(groupName === label){
                    self.toggleAll(groupName);
                } else {
                    self._options[groupName].forEach(function(checkbox){
                        if(checkbox.iataCode === iataCode){
                            self.checkAirport(false, checkbox.iataCode, true);
                        }
                    });
                }
            });
        }
    }

    toggleDirectFlight(){
        this._directFlight = ! this._directFlight;
    }

    checkDirectFlight(){
        if( document.getElementById('directflightcb') != undefined){
            document.getElementById('directflightcb').checked = ! document.getElementById('directflightcb').checked;
        }
        if( document.getElementById('directflightcbmobile') != undefined){
            document.getElementById('directflightcbmobile').checked = ! document.getElementById('directflightcbmobile').checked;
        }
    }

    resetValues(){
        let self = this;
        self._groupCheckBoxes[GroupNames.DEUTSCHLAND] = false;

        Object.keys(this._options).forEach(function(groupName){
            self._groupCheckBoxes[groupName] = false;
            self._options[groupName].forEach(function(checkbox){
                checkbox.checked = false;
            });
        });
        this._tagList = [];
    }

    select(iataCode) {
        if( iataCode === 'ALL') {
            this.reset();
            this.resetValues();
        } else {
            if (this._airports.map(airport => airport.toLowerCase()).indexOf(iataCode) >= 0) {
                this._airports = this._airports.filter(airport => airport.toLowerCase() !== iataCode);
            } else {
                this._airports = [...this._airports, iataCode];
            }
        }
        this.checkAirport(null, iataCode.toUpperCase(), false)
    }

    selectAutoComplete(groupName, checkBoxCode) {
        let self = this;
        if(groupName === checkBoxCode){
            if(groupName === GroupNames.DEUTSCHLAND){
                this.toggleGermany()
            } else {
                this.toggleAll(groupName);
            }
        }
        this._options[groupName].forEach(function(checkbox){
            if(checkbox.iataCode === checkBoxCode){
                checkbox.checked = true;
                self.checkAirport(true, checkbox.iataCode, false);
            }
        });
        this.checkGroupCheckboxes();
    }

    toggleAll(groupName) {
        this._groupCheckBoxes[groupName] = !this._groupCheckBoxes[groupName];

        let self = this;
        Object.values(self._options[groupName]).forEach(function(checkbox, index){
            self.checkAirport(self._groupCheckBoxes[groupName], checkbox.iataCode, false);
        })

        this.checkGroupCheckboxes();
    }

    checkAirport(checked, iataCode, checkGroup){
        let self = this;
        Object.keys(this._options).forEach(function(group, index) {
            let groupHasIataCode = self._options[group].filter(obj => obj.iataCode === iataCode).length > 0;
            if (groupHasIataCode) {
                Object.values(self._options[group]).forEach(function (checkbox, index) {
                    if (checkbox.iataCode === iataCode && !checkbox.filtered) {
                        if (checked === null) {
                            self._options[group][index].checked = ! self._options[group][index].checked;
                        } else if (checked){
                            self._options[group][index].checked = true;
                        } else {
                            self._options[group][index].checked = false;
                        }
                    }
                });
            }
        });
        if(checkGroup){
            this.checkGroupCheckboxes();
        }
    }

    toggleGermany(){
        this._groupCheckBoxes[GroupNames.DEUTSCHLAND] = !this._groupCheckBoxes[GroupNames.DEUTSCHLAND];

        let self = this;
        this.groupCheckboxesGermany.forEach(function(groupCheckBoxName){
            Object.values(self._options[groupCheckBoxName]).forEach(function(childCheckbox, index){
                if(!self._options[groupCheckBoxName][index].filtered){
                    self._options[groupCheckBoxName][index].checked = self._groupCheckBoxes[GroupNames.DEUTSCHLAND];
                }
            });
        });
        this.checkGroupCheckboxes();
    }


    checkGroupCheckboxes(){
        let self = this;
        Object.values(this._options).forEach(function(group, index, arr){
           let groupName = Object.keys(self._options)[index];
            let filtered = group.filter(obj => obj.filtered === true)
            let checked = group.filter(obj => obj.checked === true)

            if( checked.length === (group.length - filtered.length ) && checked.length > 0){
                self._groupCheckBoxes[groupName] = true;
            } else {
                self._groupCheckBoxes[groupName] = false;
            }
        });
        this._groupCheckBoxes[GroupNames.DEUTSCHLAND] = this.groupCheckboxesGermany.every(function (groupName) {
            return self._groupCheckBoxes[groupName] === true || self._groupCheckBoxesFiltered[groupName];
        });

        this.tagList();

    }


    tagList(){
        let result = [];
        let self = this;

        if(self._groupCheckBoxes[GroupNames.DEUTSCHLAND]){
            result.push({label: GroupNames.DEUTSCHLAND, iataCode : ''});
        }

        Object.keys(this._options).forEach(function(groupName,index, arr){
            let filtered = self._options[groupName].filter(obj => obj.checked === true)
            if(self._groupCheckBoxes[groupName] ){
                result.push({label: groupName, iataCode : ''});
            }
            filtered.forEach(function(checkbox){
                if(! result.filter(obj => obj.iataCode === checkbox.iataCode).length > 0 ){
                    result.push({label: checkbox.label, iataCode: checkbox.iataCode});
                }
            });

        });
        if(result.find(obj => obj.label === GroupNames.DEUTSCHLAND) !== undefined){
            result = result.filter(function(obj) {
                return !self.groupCheckboxesGermany.includes(obj.label);
            });
        }
        if(result.find(obj => obj.label === GroupNames.BELIEBTE_FLUGHAEFEN) !== undefined){
            let iataCodes = [
                'BER', 'DUS', 'MUC', 'FRA'
            ]
            result = result.filter(function(obj) {
                return !iataCodes.includes(obj.iataCode);
            });
        }
        if(result.length > 0 ) {
            result.unshift({label: 'Alle abwählen', iataCode: 'All'});
        }

        this._tagList = result;
    }

    toString() {
       let result = this.getCheckedAirports();
        result = result.filter(obj => obj.iataCode !== 'All');
        return result !== [] ? result.map(h => h.label + ' ' + (h.iataCode !== undefined ? h.iataCode.toUpperCase() : '')).join(', ') : [];
    }

    toUrl() {
        this._airports = this.getCheckedAirports();

        return this.isDefault() ? '' : `${this._prefix}(${this._airports.map(obj => obj.iataCode).join(',').toLowerCase()})`;
    }

    getCheckedAirports(){
        let result = [];
        let self = this;
        Object.keys(this._options).forEach(function(groupName){
            if(groupName !== GroupNames.BELIEBTE_FLUGHAEFEN && groupName !== GroupNames.DEUTSCHLAND) {
                self._options[groupName].forEach(function(checkbox){
                    if(checkbox.checked){
                        result.push(checkbox);
                    }
                });
            }
        });
        return result;
    }

    checkCount(airports){
        return airports.filter(obj => obj.available === true).length > 0
    }

    createCanvasString() {
        let result = this.getCheckedAirports();
        if( result.length <= 0) {
            return '';
        } else if( result.length === 1 ) {
            return result.map(h => h.label);
        } else {
            return result.length + ' Abflughäfen';
        }
    }

    shortName(name){
        if(name === GroupNames.NORD_DEUTSCHLAND){
            return 'Nord';
        } else if (name === GroupNames.OST_DEUTSCHLAND){
            return 'Ost';
        }else if (name === GroupNames.SUED_DEUTSCHLAND){
            return 'Süd';
        }else if (name === GroupNames.WEST_DEUTSCHLAND){
            return 'West';
        } else
            return name;
    }

}
