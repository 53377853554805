export default {
    template:
        `<div class="datepickr" @click.capture="clickInside">
            <div class="datepickr__backdrop" v-if="backdrop" @click.stop="clickBackdrop"></div>
            <div class="datepickr__wrap">
                <dpr-input label="Reisedauer" :value="durationValue" icon="fa-clock"
                :options="durations" :focus="hasFocus('duration')" @clicked="toggle('duration')" @select="select($event)" >
                </dpr-input>
            </div>
        </div>`,
    props: {
        locale: {
            default: function () {
                return 'de';
            }
        },
        backdrop: {
            default: function () {
                return true;
            }
        },
        format: {
            default: function () {
                return 'DD.MM.YYYY';
            }
        },
        fromDate: {
            default: function () {
                return moment().locale(this.locale).add(10, 'days').format(this.format);
            }
        },
        duration: {
            default: function () {
               return [{label: 'Beliebige Dauer', value: 'all', selected: true}];
            }
        },
    },
    data: function () {
        let date = moment().locale(this.locale),
            mode = this.length === 'exakt' ? 'exakt' : 'beliebig',
            first = SKTV.searchFilter && SKTV.searchFilter.datefrom ? moment(SKTV.searchFilter.datefrom) : date.clone().add(3, 'days'),
            from = moment(this.fromDate, this.format, this.locale);

        if(first.isBefore(moment())) {
            first = date.clone().add(3, 'days');
        }

        if (from.isBefore(first, 'day')) {
            from = date.clone().add(9, 'days');
            this.fromDate = from.format(this.format);
        }

        return {
            date: date,
            first: first,
            mode: mode,
           /* duration: duration,*/
            from: from,
            focus: '',
            dateFrom: null
        };
    },
    watch: {
        fromDate: function() {
            this.from = moment(this.fromDate, this.format, this.locale);
            if (this.from.isBefore(this.first)) {
                this.from = this.first.clone();
                this.submit();
            }
        },
    },
    computed: {
        fromSelected: function () {
            return this.from || this.first;
        },
        durations: function () {
            console.log('durations');
            console.log('this.$parent.parameters.interval._cruiseDuration');
            console.log(this.duration);
            let options = [],
                ranges = this.duration,
                weeks = [],
                singles = [],
                hasFilter = SKTV.searchFilter && SKTV.searchFilter.durations;

            function createWeekObj(w) {
                return {
                    label: w + (w === 1 ? ' Woche ' : ' Wochen ') + '(' + w * 7 + ' Tage)',
                    value: String(w * 7) + '-' + String(w * 7)
                }
            }

            function createDayObj(d) {
                return {
                    label: d + (d === 1 ? ' Tag' : ' Tage'),
                    value: String(d) + '-' + String(d)
                };
            }

            if (this.from && this.to) {
                if (hasFilter) {
                    ranges = [];

                    Object.values(SKTV.searchFilter.durations).forEach((duration) => {
                        if (duration % 7 === 0) {
                            weeks.push(createWeekObj(duration / 7));
                        }

                        singles.push(createDayObj(duration));
                    });

                } else {
                    const from = this.from.clone().utc().startOf('day'),
                        to = this.to.clone().utc().startOf('day'),
                        weeksDiff = to.diff(from, 'weeks'),
                        daysDiff = to.diff(from, 'days');

                    for (let w = 1; w <= Math.min(weeksDiff, 3); w++) {
                        weeks.push(createWeekObj(w));
                    }

                    ranges = ranges.filter(function (range) {
                        range = range.value.split('-');
                        return range[1] === '99' ? (daysDiff >= range[0]) : (daysDiff >= range[1]);
                    });

                    for (let d = 1; d <= Math.min(daysDiff, 21); d++) {
                        singles.push(createDayObj(d));
                    }
                }

                if (weeks.length) {
                    weeks.unshift({label: '---w', value: 0});
                }

                if (ranges.length) {
                    ranges.unshift({label: '---r', value: 0});
                }

                if (singles.length) {
                    singles.unshift({label: '---s', value: 0});
                }
            }

            return options.concat(weeks, ranges, singles);
        },
        selectedDurations: function () {
            const value = this.duration === 'exakt' && !this.isExakt ? 'beliebig' : this.duration;

            return this.durations.filter(function (duration) {
                return duration.value === value;
            });
        },
        durationValue: function () {
            const selected = this.selectedDurations;

            return selected.length ? selected[0].label : 'Reisezeitraum wählen';
        },
    },
    methods: {
        hasFocus: function (field) {
            return this.focus === field || !this[field];
        },
        select: function (value) {
            this.duration = value;
            this.toggle('duration');
            this.submit(value);
        },
        toggle: function (value) {
            this.focus = value;
        },

        submit: function (value) {
            this.$emit('submitcruise', value);
            this.$emit('close');
        },
        reset: function () {
        },
        clickInside: function (e) {
            if (!$(e.target).closest('.datepickr__dropdown').length && !$(e.target).is('.datepickr__backdrop')) {
                this.toggle();
            }
        },
        clickBackdrop: function () {
            if (!this.focus) {
                this.$emit('close');
            }
            else {
                this.toggle();
            }
        },
    },
    components: {
        dprReset: {
            template:
                `<a class="datepickr__reset" @click.stop="click">
                    <i class="fas fa-times"></i>&nbsp;
                    <span>Auswahl aufheben</span>
                </a>`,
            methods: {
                click: function () {
                    this.$emit('clicked');
                }
            }
        },
        dprInput: {
            template:
                `<div class="datepickr__drop" :class="{'datepickr__drop--scrollable': options}" >
                <slot name="content">
                  <div v-for="option in options" :key="option.label">
                    <div class="datepickr__drop-separator" v-if="/^---.*/.test(option.label)"></div>
                    <div class="datepickr__drop-item" :class="{'datepickr__drop-item--selected': value === option.label}" @click="select(option.value)" v-else>?@ option.label @</div>
                  </div>
                </slot>
                </div>`,
            props: ['label', 'value', 'icon', 'focus', 'options', 'showClass'],
            computed: {
                clsName: function () {
                    if (this.showClass) {
                        return 'datepickr__input--' + this.showClass;
                    }
                }
            },
            methods: {
                click: function () {
                    this.$emit('clicked');
                },
                select: function (val) {
                    this.$emit('select', val);
                }
            }
        },
    }
}
