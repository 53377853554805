import 'core-js/fn/promise';
import Overlay from './overlay';
import ImageContainer from './image-container';

export default Vue.component('gallery-teaser', {
    template: `
    <div class="skg-gallery-teaser">
    <div class="skg-gallery-teaser__images">
      <div v-for="n in 3" class="skg-gallery-teaser__container" :key="n" >
        <image-container 
            :src="teaserContent[n-1].type === 'image' ? teaserContent[n-1].src : teaserContent[n-1].thumbnail"
            :is-video-thumb="teaserContent[n-1].type === 'video'" 
            @click.native="openOverlay()"></image-container>
        <div v-if="n === 3"
            class="skg-gallery-teaser__image-overlay" 
            @click="openOverlay()">
          <p>
            <template v-if="getNumberOfImages() > 1">Alle ?@ getNumberOfImages() @ Bilder</template>
            <template v-if="getNumberOfImages() == 1">?@ getNumberOfImages() @ Bild</template>
            <template v-if="getNumberOfVideos() > 0"><template v-if="getNumberOfImages() > 1"> & </template>?@ getNumberOfVideos() @ Video<template v-if="getNumberOfVideos() > 1">s</template> </template> ansehen
          </p>
        </div>
      </div>
    </div>
    <overlay 
            :sliderContent="sliderContent" 
            :categories="categories" 
            :hotel-data="hotelData" />
  </div>
  `,

    components: {
        ImageContainer,
        Overlay
    },

    props: {
        images: Object,
        videos: Object,
        sliderContent: Array,
        categories: Array,
        previewImages: Object,
        previewElements: Array,
        hotelData: Object
    },

    data() {
        return {
            teaserContent: []
        };
    },

    methods: {
        openOverlay: function () {
            document.getElementsByTagName('body')[0].classList.add('overlayOpen');

            this.$root.$emit('startRendering');
            this.$root.$emit('openOverlay');
        },

        getNumberOfImages: function () {
            let count = 0;

            Object.keys(this.images).forEach((key) => {
                count += this.images[key].length;
            });

            return count;
        },

        getNumberOfVideos: function () {
            let count = 0;

            Object.keys(this.videos).forEach((key) => {
                count += this.videos[key].length;
            });

            return count;
        }
    },
    created() {
        if (this.previewElements.length >= 3) {
            for (let i = 0; i < 3; i++) {
                Object.keys(this.videos).forEach((key) => {
                    let found = this.videos[key].find((el) => {
                        if (el.id === this.previewElements[i]) {
                            return el;
                        }
                    });

                    if (found) {
                        found.type = 'video';
                        this.teaserContent.push(found);
                    }
                });
            }

            for (let i = this.teaserContent.length; i < 3; i++) {
                Object.keys(this.previewImages).forEach((key) => {
                    let found = this.previewImages[key].find((el) => {
                        if (el.id === this.previewElements[i]) {
                            return el;
                        }
                    });

                    if (found) {
                        found.type = 'image';
                        this.teaserContent.push(found);
                    }
                });
            }
        }

        for (let i = this.teaserContent.length; i < 3; i++) {
            this.teaserContent.push(this.previewImages[i]);
        }
    }
});
