import SearchObject from "../../SearchObject";

export default {

    template: `
  <div class="container" @mousedown="click" v-bind:title="title">
    <div class="icon"><i class="fa fa-hotel"></i></div>
    <div class="text">
      <p class="headline" v-html="headline"></p>
      <p class="subline" v-html="subline"></p>
    </div>
    <div class="arrow"><i class="fa fa-chevron-right"></i></div>
  </div>
`,

    name: "ItemHotelGiata",
    data() {
        return {
            searchObj: new SearchObject(),
        };
    },
    computed: {
        title(){
            return this.$attrs.item.hotelName || '';
        },
        headline() {
            return this.boldIt(`${this.$attrs.item.hotelName}`, this.$attrs.needle);
        },
        subline() {
            let loc = this.$attrs.item.locations[0],
                str = '';
            if (loc.SubRegion === '' &&
                loc.Region === '') {
                str = `${loc.Country}`;
            }
            if (loc.SubRegion !== '') {
                str = `${loc.SubRegion}, ${loc.Country}`;
            } else {
                str = `${loc.Region}, ${loc.Country}`;
            }
            return this.boldIt(str, this.$attrs.needle);
        }
    },
    methods: {
        click() {
            this.searchObj.openWindow(this.$attrs.item);
        }
    }
};
