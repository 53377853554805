var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression;

  return "        onclick=\"SKTV.IBE.xhr.getInfoBoxWithDescriptionSite(event, {\n                url:'/angebot-beschreibung/"
    + alias1(container.lambda((depth0 != null ? depth0.euviaId : depth0), depth0))
    + "/"
    + alias1(((helper = (helper = helpers.ibestep || (depth0 != null ? depth0.ibestep : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ibestep","hash":{},"data":data}) : helper)))
    + ".html'\n                })\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "        onclick=\"SKTV.IBE.xhr.getInfoBox(event, {\n        uid: '"
    + alias2(alias1((depth0 != null ? depth0.uid : depth0), depth0))
    + "',\n        code: '"
    + alias2(alias1((depth0 != null ? depth0.code : depth0), depth0))
    + "',\n        apiCode: '"
    + alias2(alias1((depth0 != null ? depth0.apiCode : depth0), depth0))
    + "',\n        edvCode: '"
    + alias2(alias1((depth0 != null ? depth0.edvCode : depth0), depth0))
    + "',\n        giataId:'"
    + alias2(alias1((depth0 != null ? depth0.giataId : depth0), depth0))
    + "',\n        regionSkId: "
    + alias2(alias1((depth0 != null ? depth0.regionSkId : depth0), depth0))
    + ",\n        type: '"
    + alias2(alias1((depth0 != null ? depth0.type : depth0), depth0))
    + "',\n        hcId: "
    + alias2(alias1((depth0 != null ? depth0.hcId : depth0), depth0))
    + ",\n        iffCode: "
    + alias2(alias1((depth0 != null ? depth0.iffCode : depth0), depth0))
    + ",\n        euviaOrderId: '"
    + alias2(alias1((depth0 != null ? depth0.euviaId : depth0), depth0))
    + "',\n        recommendation: '"
    + alias2(alias1((depth0 != null ? depth0.recommendation : depth0), depth0))
    + "',\n        countReviews: '"
    + alias2(alias1((depth0 != null ? depth0.countReviews : depth0), depth0))
    + "',\n        specialId: '"
    + alias2(alias1((depth0 != null ? depth0.specialId : depth0), depth0))
    + "',\n        operator: '"
    + alias2(alias1((depth0 != null ? depth0.operator : depth0), depth0))
    + "',\n        fromDate:'"
    + alias2(alias1((depth0 != null ? depth0.fromDate : depth0), depth0))
    + "',\n        supportCode: '"
    + alias2(alias1((depth0 != null ? depth0.supportCode : depth0), depth0))
    + "'\n        });\">\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<button id=\"infobutton-"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.specialId : depth0), depth0))
    + "\" class=\"infobutton\"\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.euviaId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <i class=\"fas fa-info-circle\"></i>\n    <span class=\"opentext\">Hotelinfos anzeigen</span>\n</button>";
},"useData":true});