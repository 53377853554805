;(function(window, $){
    'use strict';

    var UnitTesting = {
        injectQUnit: function () {
            if (typeof QUnit === 'undefined') {
                $("head link[rel='stylesheet']").last().after("<link rel='stylesheet' href='http://code.jquery.com/qunit/qunit-1.18.0.css' type='text/css' media='screen'>");
                $('body').prepend('<div id="qunit"></div><div id="qunit-fixture"></div>')
                    .append('<script src="http://code.jquery.com/qunit/qunit-1.18.0.js"></script>');
            }
        },
        runTest: function () {
            if (typeof QUnit !== 'undefined' ) {
                QUnit.test("a basic test example", function (assert) {
                    var value = "hello";
                    assert.equal(value, "hello", "We expect value to be hello");
                });
            } else {
                console.log('QUnit not loaded or injected!');
            }
        }
    };

    window.SKTV = window.SKTV || {};
    window.SKTV.UnitTesting = UnitTesting;
})(window, $);
