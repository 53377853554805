export {default as bookexpiration} from './bookexpiration.hbs'
export {default as bookingAddtionalServices} from './bookingAddtionalServices.hbs'
export {default as bookingcarrental} from './bookingcarrental.hbs'
export {default as bookingTotalPrice} from './bookingTotalPrice.hbs'
export {default as bookingtravelinsurance} from './bookingtravelinsurance.hbs'
export {default as bookonrequest} from './bookonrequest.hbs'
export {default as countdownDays} from './countdownDays.hbs'
export {default as countdownHours} from './countdownHours.hbs'
export {default as dateavailable} from './dateavailable.hbs'
export {default as dealsCountdownDays} from './dealsCountdownDays.hbs'
export {default as dealsCountdownHours} from './dealsCountdownHours.hbs'
export {default as flightData} from './flightData.hbs'
export {default as hotelinfos} from './hotelinfos.hbs'
export {default as layerajax} from './layerajax.hbs'
export {default as layeravailability} from './layeravailability.hbs'
export {default as moreRatings} from './moreRatings.hbs'
export {default as regioniteminfos} from './regioniteminfos.hbs'
export {default as tabsRatings} from './tabsRatings.hbs'
export {default as widgetbestpriceday} from './widgetbestpriceday.hbs'
export {default as widgetbestpricedayempty} from './widgetbestpricedayempty.hbs'
export {default as widgetinfolayer} from './widgetinfolayer.hbs'