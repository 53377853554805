export default class Tracker {
    static getHostname() {
        return window.location.hostname;
    }

    static getPathname() {
        return window.location.pathname;
    }

    static getReferrer() {
        return document.referrer;
    }

    static getHref() {
        return window.location.href;
    }

    constructor() {
        this.storage = new SKTV.Storage('tracking');
        this.storedData = this._load();

        this.map = {
            prop58: 'type'
        };

        this._save(this.data = {
            hostname: Tracker.getHostname(),
            pathname: Tracker.getPathname(),
            referrer: Tracker.getReferrer(),
            href: Tracker.getHref(),
            type: this.storedData && this.storedData.type || 'other',
            searchUsed: this.storedData && this.storedData.searchUsed || false
        });


        $(document).on('click', '[data-track-type], [data-tr]', (ev) => {
            const $el = $(ev.currentTarget);
            this.setType($el.data('tr') ? 'teaser' : $el.data('track-type'));
        });

        this._submit();
    }

    _load() {
        return this.storage.getItem('tracker');
    }

    _save(data = this.data) {
        this.storage.setItem('tracker', data);
        return this;
    }

    _submit() {
        if (SKTV.Tracking && SKTV.Tracking.DTM) {
            const payload = {};
            Object.keys(this.map).forEach((entry) => {
                payload[entry] = this.data[this.map[entry]];
            });
            if (this.data.searchUsed) {
                payload.events = 'event15';
                this.setSearchUsed(false);
            }

            SKTV.Tracking.DTM.addToSatellite(payload);
        }
    }

    firstTime() {
        return this.storedData === null;
    }

    fromInternal() {
        return this.data.referrer !== '' && this.data.referrer.indexOf(this.data.hostname) > -1 || !this.firstTime();
    }

    setType(type) {
        this.data.type = type;
        return this._save();
    }

    setSearchUsed(bool = true) {
        this.data.searchUsed = bool;
        return this._save();
    }
}