/**
 * Returns price-format
 * @param (int, float, string) price
 * @return (string)
 */
module.exports = function (price, replaceZeroDecimalPlacesWithMinus) {
    var decimalPoint = ',';
    var thousandsSeparator = '.';

    replaceZeroDecimalPlacesWithMinus = (replaceZeroDecimalPlacesWithMinus === true) ? true : false;

    price = parseFloat(price).toFixed(2).toString();
    var parts = price.split(".");

    //add thousands separator to first part
    var result = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator) + decimalPoint + parts[1];

    //replace decimals
    result = (replaceZeroDecimalPlacesWithMinus) ? result.replace(decimalPoint + '00', decimalPoint + '-') : result;
    return result;
};
