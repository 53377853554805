import BaseModel from './base';
import NameHydrator from '../hydrator/name';

export default class NameModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new NameHydrator({
            name: '_name'
        });
        this.export = false;

        this._prefix = 'name';
        this._defaults = {
            _name: ''
        };

        this.reset();
    }

    setName(name) {
        this._name = name;
    }

    getName() {
        return this._name;
    }

    // needed when ampersand is in hotelname that results later in a wrong result list
    getEncodedName() {
        return encodeURIComponent(this._name);
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${this.getEncodedName()})`;
    }
}