/**
 * usage:
 * {{cimage url width height}}
 *
 * note:
 * same functionality in custom TwigExtension cimage (frontend/module/Application/src/Application/View/TwigExtension.php)
 *
 * @param url {string} url of picture
 * @param source {string} source of picture (g, ti, e)
 * @param width {number} image target width
 * @param height {number} image target height
 *
 * @return url string for cimage
 */
module.exports = function (url, source, width, height, uid) {
    var cid, iid, fileName, file, params;

    if (url === '' || url.indexOf('dummy.gif') !== -1) {
        return '';
    }

    try {
        if (source === 'g' || source === 'tr') {
            params = url.split('&');

            var subdomain = url.substring((url.indexOf("/") + 2), url.indexOf("."));

            for (var i = 0, l = params.length; i < l; i++) {
                if (params[i].indexOf('cid') !== -1) {
                    cid = params[i].split('=')[1]
                }
                if (params[i].indexOf('iid') !== -1) {
                    iid = params[i].split('=')[1]
                }
                if (params[i].indexOf('file') !== -1) {
                    fileName = params[i].split('=')[1]
                }
            }
            switch (source) {
                case 'g':
                    var file = (fileName)
                        ? subdomain + '_' + iid + '_' + fileName + '.jpg'
                        : subdomain + '_' + iid + '.jpg';
                    break;
                case 'tr':
                    var file = (fileName)
                        ? iid + '_' + fileName + '.jpg'
                        : iid + '.jpg';
                    break;
            }
        } else if (source === 'ti') {
            cid = url;
            file = 'tn.jpg';
        } else if (source === 'e') {
            params = url.split('x/');
            file = params[1];
            cid = uid;
        }

        return SKTV.cdnHost + 'img/' + source + '/' + width + '/' + height + '/' + cid + '/' + file;
    } catch (e) {
        console.log('%c Handlebars cimage exception?', 'background: #222; color: #bada55');
        console.log(url, source, width, height, uid, cid, file, params);
    }
};
