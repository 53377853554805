import HydratorBase from '../hydrator/base';

export default class BaseModel {
    constructor() {
        this._hydrator = new HydratorBase({});
        this._defaults = {};
        this.export = true;
    }

    static create() {
        return new this;
    }

    init(searchObject) {
        this._hydrator.hydrate(searchObject, this);
        return this;
    }

    isDefault() {
        return Object.keys(this._defaults).every(field => JSON.stringify(this._defaults[field]) === JSON.stringify(this[field]));
    }

    reset() {
        Object.keys(this._defaults).forEach(field => {
            const _field = this._defaults[field];
            this[field] = typeof _field === 'object' && _field != null ? Object.assign(_field instanceof Array ? [] : {}, _field) : _field;
        });
    }

    toUrl() {
        return this.toString();
    }

    forExport() {
        return this.export ? this._hydrator.dehydrate(this) : {};
    }
}