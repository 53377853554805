import Vue from 'vue';

export default Vue.component('alternative-offer', {
    template: `
        <div class="alternative-offer">
            <a v-bind:href="offer.offer.url" target="_blank" v-bind:title="'Buchen Sie jetzt ' + offer.hotelName + ' bei sonnenklar.TV'">
                <div class="alternative-offer__container">
                    <div class="alternative-offer__image" v-bind:style="{backgroundImage: 'url(\\'' + offer.hotelImage + '\\')'}">
                        
                    </div>
                    <div class="alternative-offer__name">
                        ?@ offer.hotelName @
                    </div>
                    <div class="alternative-offer__stars" v-html="offer.htmlStars"></div>
                    <div class="alternative-offer__location">
                        <i class="fas fa-map-marker-alt"></i> <span v-html="offer.location"></span>
                    </div>
                    <div class="alternative-offer__offer">
                        <a v-bind:href="offer.offer.url" target="_blank" v-bind:title="'Buchen Sie jetzt ' + offer.hotelName + ' bei sonnenklar.TV'">
                            <div class="promotion-buttons right description-offer">
                                <div class="offer-label">
                                    <span>
                                        ?@ offer.offer.duration @
                                        <template v-if="offer.offer.duration == 1">Tag</template>
                                        <template v-if="offer.offer.duration > 1">Tage</template>
                                        : <template v-if="offer.offer.type !== 'hotelonly'">Flug & Hotel</template><template v-if="offer.offer.type == 'hotelonly'">Eigenanreise</template>
                                    </span>
                                </div>
                                <div class="price-wrapper">
                                    <div class="price-link">
                                        ?@ offer.offer.price @
                                    </div>
                                </div>
                                <div class="book">
                                    <button class="button-green tf-description-offers-button">
                                        <span class="book__text"><i class="fas fa-angle-right"></i></span>
                                    </button>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div v-if="offer.hotelOffer && offer.offer.type !== 'hotelonly'" class="alternative-offer__offer">
                        <a v-bind:href="offer.hotelOffer.url" target="_blank" v-bind:title="'Buchen Sie jetzt ' + offer.hotelName + ' bei sonnenklar.TV'">
                            <div class="promotion-buttons right description-offer">
                                <div class="offer-label">
                                    <span>
                                        ?@ offer.hotelOffer.duration @
                                        <template v-if="offer.hotelOffer.duration == 1">Tag</template>
                                        <template v-if="offer.hotelOffer.duration > 1">Tage</template>
                                        : Nur Hotel
                                    </span>
                                </div>
                                <div class="price-wrapper">
                                    <div class="price-link">
                                        ?@ offer.hotelOffer.price @
                                    </div>
                                </div>
                                <div class="book">
                                    <button class="button-green tf-description-offers-button">
                                        <span class="book__text"><i class="fas fa-angle-right"></i></span>
                                    </button>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div v-else-if="showHotelOfferPlaceholder">
                        <div class="alternative-offer__placeholder"></div>
                    </div>
                </div>
            </a>
        </div>
    `,
    props: {
        offer: Object,
        showHotelOfferPlaceholder: Boolean
    },
    data() {
        return {};
    }
});
