export default Vue.component('hotel-information', {
    template: `
    <div class="skg-hotelinformation">
        <div class="skg-hotelinformation__data">
            <p class="skg-hotelinformation__name">
                <span>?@ hotelData.hotelName @</span> <span v-html="hotelData.htmlStars"></span>
            </p>
            <p class="skg-hotelinformation__location">
                <i class="fas fa-map-marker-alt"></i> <span v-html="hotelData.hotelLocation"></span>
            </p>
        </div>
        <div class="skg-hotelinformation__buttons">
            <a class="skg-hotelinformation__ibe-link" v-bind:href="hotelData.ibeLink">
                <span class="skg-hotelinformation__mobile-button-text">
                    <i class="fas fa-angle-right"></i>
                </span>
                <span class="skg-hotelinformation__button-text">
                    ?@ hotelData.buttonText @
                </span>
            </a>
        </div>
    </div>
    `,
    props: {
        hotelData: Object
    }
});
