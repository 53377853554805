var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li><i class=\"fas fa-exclamation-triangle green\"></i>&nbsp;Jetzt schnell buchen!</li>\n        <li>Ihre Reise ist günstiger geworden!</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <h2 class=\"reisepreis\">Reisepreis:</h2>\n    <p class=\"bold datelist-prices\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.originalTravellerList : stack1)) != null ? stack1.list : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "            "
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + ". "
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + ":\n        <span class=\"participant-price\">\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias3,(depth0 != null ? depth0.type : depth0),"adult",{"name":"compare","hash":{"operator":"=="},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            &nbsp;\n            <span class=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.data)) && stack1.status)) && stack1.color), depth0))
    + "\">€ "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias3,(depth0 != null ? depth0.price : depth0),{"name":"asPrice","hash":{},"data":data}))
    + "</span>\n        </span>\n            <br/>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/isLowerPrice.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.price : depth0),((stack1 = (data && data.root)) && stack1.listPrice),{"name":"isLowerPrice","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"line normal\">€ "
    + container.escapeExpression(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.listPrice),{"name":"asPrice","hash":{},"data":data}))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"deposit green\">\n        <p>\n            Jetzt € "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.totalPrice : stack1)) != null ? stack1.advanceAmount : stack1),{"name":"asPrice","hash":{},"data":data}))
    + " anzahlen und €\n            "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.totalPrice : stack1)) != null ? stack1.remainingAmount : stack1),{"name":"asPrice","hash":{},"data":data}))
    + " später begleichen!\n        </p>\n    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"optionalBooking green\" data-optionalbooking=\"isSet\">\n        <p>3 Tage kostenlos reservieren!</p>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "<ul class=\"list-indent "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.status : stack1)) != null ? stack1.color : stack1), depth0))
    + " bold\">\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.status : stack1)) != null ? stack1.color : stack1),"green",{"name":"compare","hash":{"operator":"=="},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.originalTravellerList : stack1)) != null ? stack1.pricesAvailable : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"price-wrapper checked date-price row\">\n    <h2 class=\"bold gesamtpreis\">Gesamtpreis: <span>€ "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.totalPrice : stack1)) != null ? stack1.value : stack1),{"name":"asPrice","hash":{},"data":data}))
    + "</span></h2>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.totalPrice : stack1)) != null ? stack1.advanceAmount : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.optionalBookingAvailable : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"buttons-row row checked\">\n    <div class=\"price-link-button\">\n        <a href=\"buchen\" class=\"price-link\">€ "
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.totalPrice : stack1)) != null ? stack1.value : stack1),true,{"name":"asPrice","hash":{},"data":data}))
    + "</a>\n    </div>\n\n    <form action=\""
    + alias2(((helper = (helper = helpers.bookingHost || (depth0 != null ? depth0.bookingHost : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"bookingHost","hash":{},"data":data}) : helper)))
    + "/buchen\" method=\"post\" name=\"book\" class=\"button-form\">\n        <input type=\"hidden\" name=\"giataId\" value=\""
    + alias2(((helper = (helper = helpers.giataId || (depth0 != null ? depth0.giataId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"giataId","hash":{},"data":data}) : helper)))
    + "\">\n        <input type=\"hidden\" class=\"searchUri\" name=\"searchUri\" value=\""
    + alias2(((helper = (helper = helpers.searchUri || (depth0 != null ? depth0.searchUri : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"searchUri","hash":{},"data":data}) : helper)))
    + "\"/>\n        <input type=\"hidden\" name=\"bookingCode\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.code : stack1), depth0))
    + "\"/>\n        <input type=\"hidden\" name=\"edvCode\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.edvCode : stack1), depth0))
    + "\"/>\n        <input type=\"hidden\" name=\"operator\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.offer : stack1)) != null ? stack1.operator : stack1)) != null ? stack1.code : stack1), depth0))
    + "\"/>\n        <input type=\"hidden\" name=\"apiCode\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.apiType : stack1), depth0))
    + "\"/>\n        <input type=\"hidden\" name=\"bookingData\" value=\""
    + alias2(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/json.js")).call(alias3,depth0,{"name":"json","hash":{},"data":data}))
    + "\"/>\n        <input type=\"hidden\" name=\"uuid\" value=\""
    + alias2(((helper = (helper = helpers.uuid || (depth0 != null ? depth0.uuid : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"uuid","hash":{},"data":data}) : helper)))
    + "\"/>\n        <input type=\"hidden\" name=\"bookingTimer\" value=\"\"/>\n        <button class=\"btn-common buchen tf-hotellist-booking-button\" type=\"submit\">Jetzt buchen</button>\n    </form>\n</div>";
},"useData":true});