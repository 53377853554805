import BaseModel from './base';
import PriceHydrator from '../hydrator/price';

export default class PriceModel extends BaseModel {
    constructor() {
        super();

        this._hydrator = new PriceHydrator({
            maxPrice: '_price'
        });
        this.export = false;

        this._prefix = 'preis';
        this._defaults = {
            _price: '0'
        };

        this.reset();
    }

    toString() {
        return this.isDefault() ? '' : `${this._prefix}(${this._price})`;
    }
}