var Handlebars = require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <h3>Zusatzleistungen:\n        <span class=\"info-tip\"><i class=\"fas fa-info-circle\"></i><span class=\"info-tip-text\">\n            <span>Die Zusatzleistungen Versicherung und Parkplatz sind von der Anzahlung ausgeschlossen.</span>\n            <span class=\"info-tip-text--triangle\"></span>\n        </span></span>\n    </h3>\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.creditCard : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.paymentFee : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.insurance : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.rentalCar : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/compare.js")).call(alias1,(depth0 != null ? depth0.qualityGuarantee : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row delim-1\"></div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"row\">\n            <div class=\"real-col8\"><span>Kreditkartenentgelt</span> </div>\n            <div class=\"real-col4 tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + container.escapeExpression(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.creditCard : depth0),{"name":"asPrice","hash":{},"data":data}))
    + "</div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"row\">\n            <div class=\"real-col8\"><span>Rechnungsentgelt</span> </div>\n            <div class=\"real-col4 tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + container.escapeExpression(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.paymentFee : depth0),{"name":"asPrice","hash":{},"data":data}))
    + "</div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"row\">\n            <div class=\"real-col8\">\n                <div class=\"dont-wrap dont-wrap-brackets\"><span>Versicherungsart</span> (<span>"
    + alias4(((helper = (helper = helpers.insuranceType || (depth0 != null ? depth0.insuranceType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"insuranceType","hash":{},"data":data}) : helper)))
    + "</span></div>\n            </div>\n            <div class=\"real-col4 tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + alias4(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.insurance : depth0),{"name":"asPrice","hash":{},"data":data}))
    + "</div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"row\">\n            <div class=\"real-col8\"><span>Mietwagen</span> (<span class=\"bold\">DriveFTI</span>)</div>\n            <div class=\"real-col4 tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + container.escapeExpression(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.rentalCar : depth0),{"name":"asPrice","hash":{},"data":data}))
    + " </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"row\">\n            <div class=\"real-col8\"><span>Qualitätsgarantie</span></div>\n            <div class=\"real-col4 tright\">"
    + ((stack1 = ((helper = (helper = helpers.currency || (depth0 != null ? depth0.currency : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currency","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " "
    + container.escapeExpression(__default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/asPrice.js")).call(alias1,(depth0 != null ? depth0.qualityGuarantee : depth0),{"name":"asPrice","hash":{},"data":data}))
    + " </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("/var/lib/jenkins/workspace/SonnenklarTV-Production/Deploy-from-GIT/typo3conf/ext/theme/Build/Assets/JavaScript/Handlebars/Helpers/hasAdditionalServices.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hasAdditionalServices","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});