$(document).ready(function () {
    $('.hotel-description-content .show-more').on('click', function (e) {
        e.preventDefault();
        $(this).parent().children('.hidable-more').slideToggle();
        if ($(this).children(".fas").first().hasClass('fa-caret-down')) {
            $(this).children(".fas").first().removeClass('fa-caret-down');
            $(this).children(".fas").first().addClass('fa-caret-up');
            let spanContent = $(this).parent().children('.show-more').html();
            spanContent = spanContent.replace("mehr", "weniger");
            $(this).parent().children('.show-more').html(spanContent);
        } else {
            $(this).children(".fas").first().removeClass('fa-caret-up');
            $(this).children(".fas").first().addClass('fa-caret-down');
            let spanContent = $(this).parent().children('.show-more').html();
            spanContent = spanContent.replace("weniger", "mehr" );
            $(this).parent().children('.show-more').html(spanContent);
        }
    });
});

